import { Component, EventEmitter, Input, Output, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UsersService } from "../../services/users.service";
import { UserProfile } from '../../../../../common/src/lib/models/user-profile.model';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UserSelectComponent
    }
  ]
})

export class UserSelectComponent implements ControlValueAccessor, AfterViewInit {
  assignAutocompleteOpen = false;
  @Input() isJobDialog!: boolean;
  @Input() assignedUsers!: UserProfile[];
  users$ = this.isJobDialog ? this.usersService.jobAssigneesList$ : this.usersService.assigneesList$;
  currentUser$ = this.usersService.currentUser$;
  singleUser$ = this.users$.pipe(map(users => users!.length === 1));
  _user: UserProfile | null = null;

  @Input() showAllAccounts = false;
  @Input()
  get user(): UserProfile | null {return this._user};
  set user(value: UserProfile | null) {
    this._user = value;
    this.onChange(value);
  };
  @Output() usersSelected = new EventEmitter<UserProfile | 'All Accounts'>(true);
  @ViewChild('assignInput') assignInput!: ElementRef;

  touched = false;
  disabled = false;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(
    private usersService: UsersService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngAfterViewInit() {
    if (this.assignInput && this.showAllAccounts) {
      this.assignInput.nativeElement.classList.add('assign-to-user-selected');
    }
    this.changeDetector.detectChanges();
  }

  userFullName(user: UserProfile) {
    return user.firstName + ' ' + user.lastName;
  }

  usersSelectedChanged(user: UserProfile | 'All Accounts') {
    this.usersSelected.emit(user);
    this.onChange(user ? user : null);
    this.changeDetector.detectChanges();
    this.markAsTouched();
  }

  writeValue(user: UserProfile | null) {
    this.user = user;
    if (user) {
      this.usersSelected.emit(user);
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
