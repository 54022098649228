import { Component, Input, OnChanges } from '@angular/core';
import { Invoice, InvoiceVersion } from "../../models/invoice.model";
import { InvoicesService } from "../../services/invoices.service";

type InvoiceSummaryValues = {
  subtotal: number,
  discountDisplay: string,
  salesTax: number,
  total: number,
};

@Component({
  selector: 'app-invoice-summary',
  templateUrl: './invoice-summary.component.html',
  styleUrls: ['./invoice-summary.component.scss']
})
export class InvoiceSummaryComponent implements OnChanges {

  protected readonly isNaN = isNaN;

  invoiceSummaryValues: InvoiceSummaryValues = {
    subtotal: 0,
    discountDisplay: '',
    salesTax: 0,
    total: 0,
  };

  @Input() invoice!: Invoice;
  @Input() invoiceVersion!: InvoiceVersion;

  currentDiscountType?: 'amount' | 'percent';

  constructor(
    private invoicesService: InvoicesService
  ) { }

  ngOnChanges() {
    this.currentDiscountType = this.invoiceVersion.discountType;
    this.calculateValues();
  }

  calculateValues() {
    this.invoiceSummaryValues.subtotal = 0;
    this.invoiceSummaryValues.salesTax = 0;
    this.invoiceSummaryValues.total = 0;
    let totalDiscountAmount = 0;

    this.invoiceVersion.items?.forEach(item => {
      let discounted = 0;
      let taxed = 0;

      this.invoiceSummaryValues.subtotal += (item.quantity ?? 0) * item.price;
      totalDiscountAmount += (item.discountAmount ?? 0) * (item.quantity ?? 0);

      discounted = item.price - (item.discountAmount ?? 0);

      taxed = !item.taxable
        ? discounted
        : discounted + (discounted * (this.invoiceVersion.clientSalesTaxPercentage ?? 0) / 100);

      if (item.quantity && item.taxable && item.price)
        this.invoiceSummaryValues.salesTax += parseFloat(((taxed - discounted) * item.quantity).toFixed(2));

      if (item.quantity)
        this.invoiceSummaryValues.total += parseFloat((taxed * item.quantity).toFixed(2));
    });

    if (this.currentDiscountType === 'amount')
      this.invoiceSummaryValues.discountDisplay = totalDiscountAmount.toFixed(2);
    else {
      this.invoiceSummaryValues.discountDisplay
        = ((totalDiscountAmount/this.invoiceSummaryValues.subtotal)*100).toFixed(2);
      if (this.invoiceSummaryValues.discountDisplay === 'Infinity')
        this.invoiceSummaryValues.discountDisplay = '0';
      if (this.invoiceSummaryValues.discountDisplay.split('.')[1]?.substring(0, 2) === '00')
        this.invoiceSummaryValues.discountDisplay = this.invoiceSummaryValues.discountDisplay.slice(0, -3);
    }

    this.invoiceSummaryValues.total =
      this.invoiceSummaryValues.subtotal
      - totalDiscountAmount
      + this.invoiceSummaryValues.salesTax;

    // const balance = {
    //   paid: 0,
    //   balanceDue: 0,
    // };
    //
    // if (this.payment) {
    //   const paidAmount = alreadyPaid(this.payment);
    //   balance.paid = paidAmount;
    //   balance.balanceDue = this.invoiceSummaryValues.total - paidAmount;
    // } else
    //   balance.balanceDue = this.invoiceSummaryValues.total;

    //this._balance$.next({ paid: balance.paid, balanceDue: balance.balanceDue });
  }

  async toggleSignature(status: boolean) {
    this.invoiceVersion.signature = status;
    await this.saveInvoiceVersion();
  }

  async toggleDiscountType(type: 'amount' | 'percent') {
    if (type !== this.invoiceVersion.discountType) {
      this.currentDiscountType = type;
      this.calculateValues();
      this.invoiceVersion.discountType = type;
      await this.saveInvoiceVersion();
    }
  }

  private async saveInvoiceVersion() {
    await this.invoicesService.updateInvoiceVersion(this.invoiceVersion.id, {
      signature: this.invoiceVersion.signature,
      discountType: this.invoiceVersion.discountType
    });
  }
}
