import { Component } from '@angular/core';
import {
  ReportsTechPartsTab,
  pageNameToPath,
  reportsTechPartsTab,
  ReportsPages,
  reportsPages, Page, Tab
} from "../../models/navigation.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { NavigationService } from '../../services/navigation.service';
import { ReportsService } from '../../services/reports.service';
import { DateFilter } from '../../models/date-filter.model';
import { ReportDownloadService } from "../../services/report-download.service";
import { combineLatest, Observable } from "rxjs";
import { TechPartsReport } from "../../models/reports.model";
import { map, take, tap } from "rxjs/operators";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { UsersService } from "../../services/users.service";

@Component({
  selector: 'app-reports-tech-parts',
  templateUrl: './reports-tech-parts.component.html',
  styleUrls: ['./reports-tech-parts.component.scss']
})
export class ReportsTechPartsComponent {

  report$: Observable<TechPartsReport> = this.reportsService.report$ as Observable<TechPartsReport>;
  loading$ = this.reportsService.loading$;
  dateFilter$ = this.reportsService.selectedDateRangeForEvent$;
  tabs = Array.from(reportsTechPartsTab);
  pages = Array.from(reportsPages);

  nav$ = combineLatest([
    this.navigationService.page$,
    this.navigationService.tab$
  ]).pipe(
    map(([page, tab]) => {
      return {
        page,
        tab
      };
    })
  );

  get selectedTechPartIds() {
    return this.reportsService.selectedTechPartIds;
  }

  constructor(
    private navigationService: NavigationService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalsService: ModalsService,
    private reportDownloadService: ReportDownloadService,
    private snackbar: MatSnackBar,
    private usersService: UsersService
  ) { }

  onTabSelected(tab: string) {
    const page = pageNameToPath(tab as ReportsTechPartsTab);
    this.reportsService.selectedTechPartIds.clear();
    this.router.navigate([page], { relativeTo: this.route });
  }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.reportsService.selectedTechPartIds.clear();
    this.router.navigateByUrl('reports/' + [chosenPage]);
  }

  selectedRangeChange(event: [Date, Date] | null) {
    this.reportsService.setSelectedDateRange(event ? DateFilter.from(event) : null);
  }

  async markAsPaid() {
    await this.modalsService.open(ConfirmationDialog, {
      data: {
        title: `Mark as Paid`,
        message: `Are you sure you want to mark this as Paid?`,
        cancelTitle: 'No',
        actionTitle: 'Yes',
        action: async () => {
          const selected = Array.from(this.reportsService.selectedTechPartIds);
          await this.reportsService.mark('tech-parts', true);

          const permissions = (await this.usersService.currentUser$.pipe(take(1)).toPromise()).permissions.includes('mark_tech_parts');

          !permissions
            ? showSnackbar(this.snackbar, {
              message: `Marked as Paid`,
              duration: 10000,
            })
            : showSnackbar(this.snackbar, {
              message: `Marked as Paid`,
              duration: 10000,
              actionText: 'Undo',
              action: async () => {
                await this.reportsService.mark('tech-parts', false, selected);
              }
            });
        },
        actionColor: 'primary',
      }
    });
  }

  getSelectedTab(selectedTab: string | null) {
    if (selectedTab) {
      if (selectedTab === 'Pending') {
        return 'techPartsPending';
      } else {
        return 'techPartsPaid';
      }
    }
    return '';
  }

  ngOnDestroy() {
    this.reportsService.selectedTechPartIds.clear();
  }

  checkAllSelected(checked: boolean, allIds: number[]) {
    this.reportsService.selectedTechPartIds = checked
      ? new Set<number>(allIds)
      : new Set<number>();
  }

  isAllSelected(allIds: number[]) {
    return allIds.length > 0 && allIds.length === this.selectedTechPartIds.size
      && allIds.every((x) => this.selectedTechPartIds.has(x));
  }
}
