<div class="page bg">

    <app-toolbar type="login"></app-toolbar>

    <div class="d-flex flex-column flex-fill justify-content-start align-items-stretch">

        <div class="d-flex flex-fill justify-content-center container">

            <mat-card class="mat-elevation-z3">

                <mat-card-title><h2 class="text-center second-title">Reset password</h2></mat-card-title>

                <!-- Adding *ngIf="true" fixes change detection issues in the form template -->
                <form *ngIf="true" class="d-flex flex-column align-items-stretch" [formGroup]="form">
                    <ng-container *ngIf="showReauthentication">
                        <h4>Password</h4>
                        <mat-form-field appearance="outline" appFormFieldFilled>
                            <input matInput #passwordInput
                                formControlName="password"
                                [type]="showPassword ? 'text' : 'password'"
                                placeholder="Enter your password"
                                enterkeyhint="next"
                                name="avoidSearch"
                                autocomplete="off"
                                tabindex="1"
                                (focus)="passwordFocused = true"
                                (focusout)="passwordFocused = false"
                            />
                            <img *ngIf="form.get('password')!.value.length > 0" matSuffix
                                [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                                (click)="showPassword = !showPassword">
                            <mat-error *ngIf="form.get('password')!.hasError('required') && form.get('password')!.touched">
                                Password is required
                            </mat-error>
                            <mat-error *ngIf="form.get('password')!.hasError('password') && form.get('password')!.touched">
                                Invalid password. Re-enter
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                    <h4>New password</h4>
                    <mat-form-field appearance="outline" appFormFieldFilled>
                        <input matInput #newPasswordInput
                            formControlName="newPassword"
                            placeholder="At least 6 characters"
                            autocomplete="password"
                            [type]="showNewPassword ? 'text' : 'password'"
                            enterkeyhint="go"
                            tabindex="2"
                        />
                        <img *ngIf="form.get('newPassword')!.value.length > 0" matSuffix
                             [src]="'../../../assets/icons/password-' + (showNewPassword ? 'hide' : 'show') +'.svg'"
                             (click)="showNewPassword = !showNewPassword">
                        <mat-error *ngIf="form.get('newPassword')!.hasError('required') && form.get('newPassword')!.touched">
                            New password is required
                        </mat-error>
                        <mat-error *ngIf="form.get('newPassword')!.hasError('minlength') && form.get('newPassword')!.touched">
                            At least 6 characters
                        </mat-error>
                        <mat-error *ngIf="form.get('newPassword')!.hasError('samePassword') && form.get('newPassword')!.touched">
                            Enter a different password from the old one
                        </mat-error>
                    </mat-form-field>
                    <loader-button class="footer" [loading]="loading" text="Submit"
                        (onClick)="changePassword()"></loader-button>

                </form>

            </mat-card>
        </div>
    </div>
</div>