import { Component, ViewChild } from '@angular/core';
import { ViewAsService } from '../../services/view-as.service';
import { BusinessService } from '../../services/business.service';
import { SupabaseService } from '../../services/supabase.service';
import { DynamicListComponent, DynamicListEvent, PaginationListItem, Predicate } from '../../components/dynamic-list/dynamic-list.component';
import { Pagination, PaginationOptions } from '../../services/pagination.service';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { LobbyService } from '../../services/lobby.service';
import { limitStep } from '../../services/items.service';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { PoITileComponent } from '../../components/poi-tile/poi-tile.component';
import { Invoice, InvoiceStatus, InvoiceVersion } from '../../models/invoice.model';
import { Proposal, ProposalStatus, ProposalVersion } from '../../models/proposal.model';
import { Workflow } from '../../models/workflow.model';
import { InvoicesTab, ProposalTab } from '../../models/navigation.model';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ProposalsService } from '../../services/proposals.service';
import { InvoicesService } from '../../services/invoices.service';
import { InvoiceRow, InvoiceVersionClientRow, PoITileData, ProposalRow, ProposalVersionClientRow, tileDataFromDocument } from '../../models/poi-list.model';
import { dateString } from 'projects/common/src/public-api';
import { Observable } from 'rxjs';
import { DateFilter } from '../../models/date-filter.model';
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

@Component({
    selector: 'app-poi-list',
    templateUrl: './poi-list.component.html',
    styleUrls: ['./poi-list.component.scss']
})
export class PoIListComponent {
    @ViewChild('list') dynamicList!: DynamicListComponent<PoITileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<PoITileComponent>;

    type?: 'Proposal' | 'Invoice';
    tab?: ProposalTab | InvoicesTab;

    pagination!: Pagination<PoITileData>;
    dateFilterPagination!: Pagination<PoITileData>;

    initialItems!: PaginationListItem<PoITileComponent>[];
    initialFilterItems!: PaginationListItem<PoITileComponent>[];
    initialFilterCanLoadMore = true;

    dateFilter$!: Observable<DateFilter | null>;

    get status(): ProposalStatus | InvoiceStatus | undefined {
        if (!this.type)
            return;

        if (this.type === 'Proposal') {
            switch (this.tab) {
                case 'Canceled': return 'canceled';
                case 'Declined': return 'declined';
                case 'Drafts': return 'created';
                case 'Paid': return 'paid';
                case 'Pending': return 'sent';
                case 'Won': return 'accepted';
            }
        } else {
            switch (this.tab) {
                case 'Canceled': return 'canceled';
                case 'Drafts': return 'created';
                case 'Paid': return 'paid';
                case 'Pending': return 'sent';
            }
        }
        return;
    }

    get tableName() {
        return this.type?.toLowerCase()!;
    }

    constructor(
        private lobbyService: LobbyService,
        private viewAsService: ViewAsService,
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private proposalsService: ProposalsService,
        private invoicesService: InvoicesService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    async ngOnInit() {
        const data = await this.route.data.pipe(take(1)).toPromise();
        this.type = data.type;
        this.tab = data.tab;
        
        if (!this.type || !this.tab)
            return;

        this.initDateFilter();

        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<PoITileData, ProposalRow | InvoiceRow | ProposalVersionClientRow | InvoiceVersionClientRow | Workflow>(
            this.supabaseService, 
            `get_${this.tableName}s`, 
            business.businessId, 
            `${this.tableName}s`
        )
            .on<ProposalRow | InvoiceRow, '*'>('*', this.tableName, this.handleItemChanges.bind(this))
            .on<ProposalVersionClientRow | InvoiceVersionClientRow, 'UPDATE'>('UPDATE', `${this.tableName}_version_client`, this.handleVersionClientChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleWorkflowChanges.bind(this));

        this.dateFilterPagination = new Pagination<PoITileData, ProposalRow | InvoiceRow | ProposalVersionClientRow | InvoiceVersionClientRow | Workflow>(
            this.supabaseService, 
            `get_${this.tableName}s`, 
            business.businessId,
            `filtered_${this.tableName}s`
        )
            .on<ProposalRow | InvoiceRow, '*'>('*', this.tableName, this.handleDateFilterItemChanges.bind(this))
            .on<ProposalVersionClientRow | InvoiceVersionClientRow, 'UPDATE'>('UPDATE', `${this.tableName}_version_client`, this.handleDateFilterVersionClientChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleDateFilterWorkflowChanges.bind(this));
        
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0, inStatus: this.status };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const items = await this.pagination.get(options);
        this.initialItems = this.toPaginationList(items);
        this.setInitialCanLoadMore();
    }

    ngOnDestroy(): void {
        this.pagination.destroy();
        this.dateFilterPagination.destroy();
    }

    initDateFilter() {
        this.dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
            switchMap(async range => {
                if(!range) 
                    return null;
                const inLimit = limitStep;
                const options: PaginationOptions = {
                    inLimit, 
                    inOffset: 0, 
                    inStartDate: frontToServerTranslation(range.from), 
                    inEndDate: frontToServerTranslation(range.to),
                    inStatus: this.status
                };
                const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
                if(userIds)
                    options.inUserIds = userIds;
                
                const items = await this.dateFilterPagination.get(options);
                this.initialFilterItems = this.toPaginationList(items);
                this.initialFilterCanLoadMore = items.length >= inLimit;
                return range;
            }),
            shareReplay({ bufferSize: 1, refCount: true})
        );
    }

    setInitialCanLoadMore() {
        if (!this.initialItems || !this.dynamicList)
            return;

        if(this.initialItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(inOffset = 0) {
        if (!this.type)
            return;

        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset, inStatus: this.status };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const items = await this.pagination.get(options);
        if(items.length < inLimit)
            this.dynamicList.canLoadMoreBottom = false;
        this.dynamicList.addAtBottom(
            this.toPaginationList(items)
        );
    }

    async loadDateFilterItems(inOffset = 0) {
        if (!this.type)
            return;

        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = {
            inLimit,
            inOffset,
            inStartDate: frontToServerTranslation(range!.from),
            inEndDate: frontToServerTranslation(range!.to),
            inStatus: this.status
        };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const items = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;

        if(items.length < inLimit)
            this.dateFilterList.canLoadMoreBottom = false;
        this.dateFilterList.addAtBottom(
            this.toPaginationList(items)
        );
    }

    onItemEvent(event: DynamicListEvent<PoITileData>) {
        switch(event.eventEmitterName) {
            case 'tileClick':
                this.router.navigate([`/${this.tableName}s/`, event.value.workflowId, event.value.id]);
                break;
        }
    }
    
    toPaginationList(items: PoITileData[]) {
        return items.map(item => ({
            componentClass: PoITileComponent,
            header: dateString(item.itemTime),
            args: { document: { ...item, docType: this.type }, showOpenedLabel: this.tab === 'Pending' }
        }));
    }

    private commonRemoveEvent(
        list: DynamicListComponent<PoITileComponent>, 
        predicate: Predicate<any>, 
    ) {
        return list.removeItem('auto', (v, i) => v.instance.document.docType === this.type ? predicate(v, i) : false);
    }

    private commonHandleUpdate(
        list: DynamicListComponent<PoITileComponent>, 
        data: any,
        dataIdProp: string,
        tileIdProp = 'id'
    ) {
        list.updateItems(component => {
            const doc = component.instance.document;
            if (doc.docType !== this.type)
                return false;

            if ((doc as any)[tileIdProp] === data[dataIdProp]) {
                for (const prop in doc) {
                    if (prop === 'type' && !['business', 'personal'].includes(data[prop]))
                        continue;
                    if (prop in data && !(['id', 'createdAt'].includes(prop)))
                        (doc as any)[prop] = data[prop];
                }
                component.instance.refreshData();
            }
            return false;
        });
    }

    private async insertItem(
        list: DynamicListComponent<PoITileComponent>, 
        document: PoITileData, 
        isDateFilterList = false,
    ) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        const firstComponent = list.getFirstComponent();
        if (
            list.includesItem((_, item) => item?.instance.document.docType === this.type && item.instance.document.id === document.id) ||
            isDateFilterList && dateFilter && !moment(document.itemTime).isBetween(dateFilter.from, dateFilter.to, undefined, '[]') ||
            list.canLoadMoreBottom && firstComponent && document.itemTime! < firstComponent.instance.document.itemTime!
        )
            return;

        list.insertItem(
            (prev, current) => {
                if (!prev && document.itemTime! > current.instance.document.itemTime!)
                    return true;

                return prev ? moment(document.itemTime).isBetween(prev.instance.document.itemTime, current.instance.document.itemTime, undefined, '[]') : false;
            },
            {
                componentClass: PoITileComponent,
                header: dateString(document.itemTime),
                args: { document: { ...document, docType: this.type }, showOpenedLabel: this.tab === 'Pending' }
            },
            'bottom',
            !list.canLoadMoreBottom
        );
    }

    private async itemFulfillsFilters(item: Proposal | Invoice, handleDateFilter = false) {
        if (!item.activeVersionId)
            return false;
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        const version = (item.versions as (ProposalVersion | InvoiceVersion)[]).find(version => version.id === item.activeVersionId)!;
        return (!userIds || userIds.includes(version.creatorId)) &&
            version.status === this.status &&
            version.createdAt &&
            (!handleDateFilter || handleDateFilter && dateFilter && moment(version.createdAt).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'));
    }

    async commonHandleItemChanges(
        list: DynamicListComponent<PoITileComponent>, 
        changes: RealtimePostgresChangesPayload<ProposalRow | InvoiceRow>,
        handleDateFilter = false
    ) {
        if (changes.eventType === 'DELETE') {
            this.commonRemoveEvent(list, item => item.instance.document.id === changes.old.id);
            return;
        }

        const itemId = changes.new.id;
        const item = await (this.type === 'Invoice' ? this.invoicesService.getInvoice(itemId) : this.proposalsService.getProposal(itemId));
        if (changes.eventType === 'UPDATE') {
            if (changes.new.activeVersionId !== item.activeVersionId) {
                this.commonRemoveEvent(this.dynamicList, item => item.instance.document.id === changes.new.id);
            } else {
                if (list.includesItem((_, item) => {
                        const doc = item.instance.document;
                        return doc.docType === this.type && doc.id === changes.new.id;
                    })
                ) {
                    if (!(await this.itemFulfillsFilters(item))) {
                        this.commonRemoveEvent(list, item => item.instance.document.id === changes.new.id);
                        return;
                    }
                    this.commonHandleUpdate(list, tileDataFromDocument(item), 'id');
                    return;
                }
            }
        }
        if (await this.itemFulfillsFilters(item, handleDateFilter)) {
            await this.insertItem(list, tileDataFromDocument(item), handleDateFilter);
        }
    }

    async handleItemChanges(changes: RealtimePostgresChangesPayload<ProposalRow | InvoiceRow>) {
        if (changes.table !== this.tableName)
            return;
        this.commonHandleItemChanges(this.dynamicList, changes, false);
    }

    async handleVersionClientChanges(changes: RealtimePostgresChangesPayload<ProposalVersionClientRow | InvoiceVersionClientRow>) {
        const itemId = this.type === 'Invoice' ? (changes.new as InvoiceVersionClientRow).invoiceId : (changes.new as ProposalVersionClientRow).proposalId;
        const item = await (this.type === 'Invoice' ? this.invoicesService.getInvoice(itemId) : this.proposalsService.getProposal(itemId));
        if (item.activeVersionId !== (this.type === 'Invoice' ? (changes.new as InvoiceVersionClientRow).id : (changes.new as ProposalVersionClientRow).proposalVersionId))
            return;
        
        this.commonHandleUpdate(this.dynamicList, changes.new, this.type === 'Proposal' ? 'proposalId' : 'invoiceId');
    }

    handleWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.commonHandleUpdate(this.dynamicList, changes.new, this.type === 'Proposal' ? 'proposalId' : 'invoiceId');
    }

    async handleDateFilterItemChanges(changes: RealtimePostgresChangesPayload<ProposalRow | InvoiceRow>) {
        if (changes.table !== this.tableName)
            return;
        this.commonHandleItemChanges(this.dateFilterList, changes, true);
    }

    async handleDateFilterVersionClientChanges(changes: RealtimePostgresChangesPayload<ProposalVersionClientRow | InvoiceVersionClientRow>) {
        const itemId = this.type === 'Invoice' ? (changes.new as InvoiceVersionClientRow).invoiceId : (changes.new as ProposalVersionClientRow).proposalId;
        const item = await (this.type === 'Invoice' ? this.invoicesService.getInvoice(itemId) : this.proposalsService.getProposal(itemId));
        if (item.activeVersionId !== (this.type === 'Invoice' ? (changes.new as InvoiceVersionClientRow).id : (changes.new as ProposalVersionClientRow).proposalVersionId))
            return;
        
        this.commonHandleUpdate(this.dateFilterList, changes.new, this.type === 'Proposal' ? 'proposalId' : 'invoiceId');
    }

    handleDateFilterWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.commonHandleUpdate(this.dateFilterList, changes.new, this.type === 'Proposal' ? 'proposalId' : 'invoiceId');
    }

}
