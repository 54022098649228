import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Invoice, InvoiceVersion } from "../../models/invoice.model";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { InvoicesService } from "../../services/invoices.service";
import { isEmpty } from "../../helpers/forms"
import { rounder } from "../../../../../common/src/lib/helpers/payments";
import { BusinessService } from "../../services/business.service";
import moment from 'moment';

@Component({
  selector: 'app-invoice-preview-and-send',
  templateUrl: './invoice-preview-and-send.component.html',
  styleUrls: ['./invoice-preview-and-send.component.scss']
})
export class InvoicePreviewAndSendComponent implements OnInit, OnChanges {

  protected readonly isEmpty = isEmpty;

  @Input() invoice!: Invoice;
  @Input() currentInvoiceVersion!: InvoiceVersion;
  @Input() sentMode? = false;

  business$ = this.businessService.selectedBusiness$;

  dateSubject = new BehaviorSubject<Date>(new Date());
  date$ = this.dateSubject.asObservable().pipe(
    map(date => {
      return {
        createdDate: moment(date).format('MM/DD/YYYY'),
        createdTime: moment(date).format('h:mmA')
      }
    })
  );

  badge?: 'preview' | 'not-opened' | 'pending' | 'pending-incomplete' | 'canceled' | 'paid';

  subtotal: number = 0;
  discount: number = 0;
  salesTax: number = 0;
  total: number = 0;
  paid: number | undefined = 0;
  balanceDue: number | undefined = 0;

  constructor(
    private invoicesService: InvoicesService,
    private businessService: BusinessService
  ) { }

  async ngOnInit() {
    if (!this.sentMode) {
      this.badge = 'preview';
      setInterval(() => {
        this.dateSubject.next(new Date());
      }, 1000);
    } else {
      this.dateSubject.next(this.currentInvoiceVersion.clientSentAt!);
    }
    this.calculatePreviewSummary();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calculatePreviewSummary();

    if ('currentInvoiceVersion' in changes) {
      this.calculatePreviewSummary();
      this.setBadge();
      // if ('clientInvoice' in changes)
      //   if (this.clientInvoice) {
      //     this.now.next(this.clientInvoice.createdAt!);
      //
      //     if (this.sentMode) {
      //       this.now.next(this.currentInvoiceVersion.clientSentAt!);
      //       this.uidData = this.currentInvoiceVersion.clientUid!;
      //     }
      //   }
      
      if (this.sentMode)
        this.dateSubject.next(this.currentInvoiceVersion.clientSentAt!);

      if (this.invoice.canceledFromPayment)
        this.badge = 'canceled';
    }
  }

  setBadge() {
    if (!this.currentInvoiceVersion.opened && this.currentInvoiceVersion.clientStatus !== 'canceled' && this.currentInvoiceVersion.clientStatus !== 'declined')
      this.badge = 'not-opened';
    else {
      if (this.currentInvoiceVersion.clientStatus === 'pending_incomplete')
        this.badge = 'pending-incomplete';
      else
        this.badge = this.currentInvoiceVersion.clientStatus;
    }
  }

  calculatePreviewSummary() {
    this.subtotal = 0;
    this.discount = 0;
    this.salesTax = 0;
    this.total = 0;
    this.paid = 0;
    this.balanceDue = 0;

    this.currentInvoiceVersion.items?.forEach(item => {
      let discounted = 0;
      let taxed = 0;

      this.subtotal += (item.quantity ?? 0) * item.price;
      this.discount += (item.discountAmount ?? 0) * (item.quantity ?? 0);

      discounted = item.price - (item.discountAmount ?? 0);

      taxed = !item.taxable
        ? discounted
        : discounted + (discounted * (this.currentInvoiceVersion.clientSalesTaxPercentage ?? 0) / 100);

      if (item.quantity && item.taxable && item.price)
        this.salesTax += parseFloat(((taxed - discounted) * item.quantity).toFixed(2));

      if (item.quantity)
        this.total += parseFloat((taxed * item.quantity).toFixed(2));
    });

    this.paid = this.invoice.sPaidAmount;
    this.balanceDue = this.sentMode
      ? this.invoice.sBalanceDue
      : this.total - this.invoice.sPaidAmount!;
  }
  
  nextSubUid() {
    return Math.max(...this.invoice.versions.map(v => v.subUid ?? 0)) + 1;
  }

  getItemTaxAmount(price: number, taxable: boolean, discountAmount: number | null): number {
    if (!taxable)
      return 0;

    let discountedPrice = price;
    if (discountAmount)
      discountedPrice -= discountAmount;
    return discountedPrice*this.currentInvoiceVersion.clientSalesTaxPercentage!/100;
  }

  getItemTotal(price: number, taxable: boolean, discountAmount: number | null, quantity: number | null): number {
    const discountedPrice = discountAmount
      ? (price - discountAmount)
      : price;
    const taxed = taxable
      ? discountedPrice + (discountedPrice*this.currentInvoiceVersion.clientSalesTaxPercentage!/100)
      : discountedPrice;

    return quantity ? quantity*taxed : taxed;
  }

  getBadgeCopy(badgeId: string): string | null {
    switch (badgeId) {
      case 'preview':
        return 'Preview';
      case 'not-opened':
        return 'Not Opened';
      case 'pending':
        return 'Pending';
      case 'pending-incomplete':
        return 'Pending';
      case 'paid':
        return 'Paid';
      case 'canceled':
        return 'Canceled';
      default:
        return null;
    }
  }

  protected readonly rounder = rounder;
}
