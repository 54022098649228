<div class="select-business-button" [class.open]="isMenuOpen"
     #menuTrigger="matMenuTrigger" [hideMenuOnScreenSizeChange]="menuTrigger"
     [matMenuTriggerFor]="menu"  (menuOpened)="isMenuOpen = true"
     (menuClosed)="isMenuOpen = false">
    <div class="d-flex align-items-center gap--8">
        <p>{{ buttonText$ | async }}</p>
        <div class="red-dot" *ngIf="hasNotifications$ | async"></div>
    </div>
  <app-icon *ngIf="largeScreen$ | async" class="se-icon-button no-bg no-hover" src="cheveron_down"></app-icon>
</div>

<mat-menu #menu class="select-business-menu-wrapper" xPosition="before">
    <div (click)="onMenuClick($event)" class="select-business-menu" *ngIf="selectedBusiness$ | async let selectedBusiness">

        <div class="d-flex flex-column gap--10">
            <app-business-button *ngFor="let business of businesses$ | async"
                [businessName]="businessName(business.businessData, true)"
                [selected]="selectedBusiness.businessId === business.businessData.businessId"
                [user]="business.userData"
                [notificationsCount]="(unseenChatsCountByBusiness$ | async)?.get(business.businessData.businessId) ?? 0"
                (click)="onButtonClicked(business)"
            ></app-business-button>
        </div>
        <div class="icon-button add-business-button" (click)="addBusiness()">
            <app-icon class="se-icon-button" src="plus"></app-icon>
            <p>New Account</p>
        </div>

    </div>
</mat-menu>