import {
  Directive,
  ElementRef,
  Input, OnChanges,
  Renderer2, SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appHideText]'
})
export class HideTextDirective implements OnChanges {

  @Input() appHideText = true;
  savedValue = '';

  constructor(private el: ElementRef<HTMLInputElement>, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appHideText) {
      if (this.appHideText) {
        this.savedValue = this.el.nativeElement.value;
        this.el.nativeElement.value = '';
      } else {
        this.el.nativeElement.value = this.savedValue;
        this.savedValue = '';
      }
    }
  }
}
