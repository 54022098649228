<div class="dialog">
    <div class="content" *ngIf="newFile else other">
        <div class="header d-flex justify-content-between align-items-center">
            <h3>Save</h3>
            <app-icon class="se-icon-button no-bg" src="close" (click)="close()"></app-icon>
        </div>
    
        <div *ngIf="!error else errorTemplate"></div>

        <div class="upload-section d-flex justify-content-center align-items-stretch">
            <img [src]="newSrc">
        </div>
    
        <div class="footer">
            <loader-button [loading]="loading" text="Save" (onClick)="save()"></loader-button>
            <button mat-stroked-button (click)="newFile = undefined">Delete</button>
        </div>
    
    </div>
    
    <ng-template #other>
    
        <div class="content" *ngIf="(!change && (src$ | async)) let src else upload">
            <div class="header d-flex justify-content-between align-items-center">
                <h3>Edit</h3>
                <app-icon class="se-icon-button no-bg close-button" src="close" (click)="close()"></app-icon>
            </div>
    
            <div *ngIf="!error else errorTemplate"></div>
        
            <div class="upload-section d-flex justify-content-center align-items-stretch">
                <img class="flex-fill" [src]="src">
            </div>
        
            <div class="footer">
                <button mat-flat-button color="primary" (click)="change = true">Change</button>
                <button mat-stroked-button (click)="deleteLogo()">Delete</button>
            </div>
        
        </div>
    
        <ng-template #upload>
            <div class="content">
                <div class="header d-flex justify-content-between align-items-center">
                    <h3>Upload</h3>
                    <app-icon class="se-icon-button no-bg close-button" src="close" (click)="close()"></app-icon>
                </div>
    
                <div *ngIf="!error else errorTemplate"></div>
            
                <div class="upload-section without-image d-flex flex-column justify-content-center align-items-center" appDragNDrop (fileDropped)="fileChosen($event)">
                    <app-icon src="upload" class="mb--20"></app-icon>
                    <h3>Drag & Drop or<br class="only-mobile"> Choose a file to upload</h3>
                    <p>JPG, GIF or PNG files</p>
            
                    <div *ngIf="!isMobile" class="separator">
                        <div></div>
                        <h4>Or</h4>
                        <div></div>
                    </div>
            
                    <button mat-flat-button color="primary" (click)="onChooseFileClick(fileInput)">Choose file</button>
                    <input #fileInput type="file" style="display: none" accept="image/gif, image/jpeg, image/png" (change)="fileChosen(fileFromEvent($event))">
                </div>
            
                <div class="footer justify-content-center align-items-center text-center">
                    <p>It will appear on<br class="only-mobile"> Proposals and Invoices</p>
                </div>
            
            </div>
            
        </ng-template>
    
    </ng-template>
    
</div>


<ng-template #errorTemplate>
    <div class="error">
        <h4 *ngIf="error === 'size'">File size exceeds 2MB.<br class="only-mobile"> Try a smaller file</h4>
        <h4 *ngIf="error === 'format'">Unsupported format.<br class="only-mobile"> Try another format</h4>
        <h4 *ngIf="error === 'internet' || error === 'unknown'">Upload failed. Check your<br class="only-mobile"> internet connection</h4>
    </div>
</ng-template>
