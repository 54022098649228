<mat-form-field class="textarea assign-to-select"
                (click)="assignAutocompleteOpen ? assignTrigger.closePanel() : assignTrigger.openPanel()"
                *ngIf="!(singleUser$ | async); else disabledInput">
    <div class="d-flex justify-content-between align-items-center">
        <input
                matInput
                readonly
                [value]="'Choose a user'"
                class="assign-to-input"
                #assignInput
                #assignTrigger="matAutocompleteTrigger"
                [matAutocomplete]="assignAutocomplete"
        >
        <mat-icon class="suffix rotate" [class.do]="assignAutocompleteOpen">expand_more</mat-icon>
    </div>
    <mat-autocomplete
        #assignAutocomplete="matAutocomplete"
        (opened)="assignAutocompleteOpen = true"
        (closed)="assignAutocompleteOpen = false"
    >
        <mat-option *ngIf="showAllAccounts" value="All Accounts" (click)="usersSelectedChanged('All Accounts')">
            All Accounts
        </mat-option>
        <!-- <mat-divider></mat-divider> -->
        <ng-container *ngFor="let user of users$ | async">
            <mat-option [value]="userFullName(user)" (click)="usersSelectedChanged(user)">
                {{ userFullName(user) }}
            </mat-option>
            <!-- <mat-divider></mat-divider> -->
        </ng-container>
    </mat-autocomplete>
</mat-form-field>

<ng-template #disabledInput>
    <mat-form-field class="textarea assign-to-select" disabled>
        <div class="d-flex justify-content-between align-items-center">
            <input
                    matInput
                    [value]="'Choose a user'"
                    class="assign-to-input"
                    disabled
            >
        </div>
    </mat-form-field>
</ng-template>
