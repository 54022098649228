import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[replaceCommaWithDot]'
})
export class ReplaceCommaWithDotDirective {
  @HostListener('input', ['$event']) onInput(event: any) {
    const [start, end] = [event.target.selectionStart, event.target.selectionEnd];
    event.target.value = event.target.value?.replaceAll(',', '.');
    event.target.selectionStart = start;
    event.target.selectionEnd = end;
  }

  constructor() { }

}
