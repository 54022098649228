import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {
  DotsSpinnerComponent,
  LoaderButtonComponent
} from './components';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ForScreenSizeDirective } from './directives';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SkeletonImgComponent } from './components/skeleton-img/skeleton-img.component';
import { HttpClientModule } from '@angular/common/http';
import { IconComponent } from './components/icon/icon.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FadeInDirective } from './directives/fade-in.directive';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { MaterialModule } from './material.module';
import { MaskImageSrcDirective } from './directives/mask-image-src.directive';
import { TabsComponent } from './components/tabs/tabs.component';
import { TextToggleComponent } from './components/text-toggle/text-toggle.component';
import { SectionDividerComponent } from './components/section-divider/section-divider.component';
import { ConfirmationDialog } from './modals/confirmation-dialog/confirmation-dialog.component';
import { PlaceOptionClickDirective } from './directives/place-option-click.directive';
import { AddressPipe } from './pipes/address.pipe';
import { HideMenuOnScreenSizeChangeDirective } from './directives/hide-menu-on-screen-size-change.directive';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { FormFieldFilledDirective } from './directives/form-field-filled.directive';
import { SupportChatComponent } from './components/support-chat/support-chat.component';
import { ChatOverlayComponent } from './components/chat-overlay/chat-overlay.component';
import { MessagesIndicatorComponent } from './components/messages-indicator/messages-indicator.component';
import { MessageComponent } from './components/message/message.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ButtonComponent } from './components/image-viewer/button/button.component';
import { ChatDateHeaderComponent } from './components/chat-date-header/chat-date-header.component';
import { ChatMessageDirective } from "./directives/chat-message.directive";
import { AnchorAutocompleteOnScrollDirective } from './directives/anchor-autocomplete-on-scroll.directive';
import { ReplaceCommaWithDotDirective } from './directives/replace-comma-with-dot.directive';


@NgModule({
  declarations: [
    DotsSpinnerComponent,
    LoaderButtonComponent,
    ForScreenSizeDirective,
    SkeletonImgComponent,
    IconComponent,
    SnackbarComponent,
    FadeInDirective,
    DateRangeComponent,
    MaskImageSrcDirective,
    TabsComponent,
    TextToggleComponent,
    SectionDividerComponent,
    ConfirmationDialog,
    PlaceOptionClickDirective,
    AddressPipe,
    HideMenuOnScreenSizeChangeDirective,
    SendMessageComponent,
    FormFieldFilledDirective,
    SupportChatComponent,
    ChatOverlayComponent,
    MessagesIndicatorComponent,
    MessageComponent,
    ImageViewerComponent,
    ButtonComponent,
    ChatDateHeaderComponent,
    ChatMessageDirective,
    AnchorAutocompleteOnScrollDirective,
    ReplaceCommaWithDotDirective
  ],
  exports: [
    DotsSpinnerComponent,
    LoaderButtonComponent,
    ForScreenSizeDirective,
    ForScreenSizeDirective,
    SkeletonImgComponent,
    IconComponent,
    SnackbarComponent,
    DateRangeComponent,
    MaterialModule,
    MaskImageSrcDirective,
    TabsComponent,
    TextToggleComponent,
    SectionDividerComponent,
    ConfirmationDialog,
    PlaceOptionClickDirective,
    AddressPipe,
    HideMenuOnScreenSizeChangeDirective,
    SendMessageComponent,
    FormFieldFilledDirective,
    SendMessageComponent,
    SupportChatComponent,
    ChatOverlayComponent,
    MessagesIndicatorComponent,
    MessageComponent,
    ImageViewerComponent,
    ButtonComponent,
    ChatDateHeaderComponent,
    ChatMessageDirective,
    FadeInDirective,
    AnchorAutocompleteOnScrollDirective,
    ReplaceCommaWithDotDirective
  ],
  imports: [
    RouterModule,
    AngularCommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
})
export class CommonModule {
}
