import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LobbyComponent } from './pages/lobby/lobby.component';
import { ProposalsComponent } from './pages/proposals/proposals.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { canOpenItemsPage, ItemsComponent } from "./pages/items/items.component";
import { EventInfoComponent } from './pages/event-info/event-info.component';
import { ProposalComponent } from "./pages/proposal/proposal.component";
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { CreateBusinessComponent } from './pages/create-business/create-business.component';
import { InvoiceComponent } from "./pages/invoice/invoice.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { ReportsPaymentsComponent } from "./pages/reports-payments/reports-payments.component";
import { ReportsCommissionsComponent } from "./pages/reports-commissions/reports-commissions.component";
import { ReportsTechPartsComponent } from "./pages/reports-tech-parts/reports-tech-parts.component";
import { ReportsOverlookComponent } from "./pages/reports-overlook/reports-overlook.component";
import { ReportsCompareComponent } from "./pages/reports-compare/reports-compare.component";
import { CustomerListComponent } from "./pages/customer-list/customer-list.component";
import { canViewItemGuard } from './services/navigation.service';
import { AssignedComponent } from './pages/assigned/assigned.component';
import { AssignmentComponent } from './pages/assignment/assignment.component';
import { canOpenAssignmentsPage } from './services/assignment.service';
import { canOpenAssignedPage } from './services/assigned.service';
import { SuspendedBusinessComponent } from './pages/suspended-business/suspended-business.component';
import { BusinessService } from './services/business.service';
import { AuthService } from './services/auth.service';
import { take } from 'rxjs/operators';
import { ResetComponent } from "./pages/reset/reset.component";
import { GoogleAuthResultComponent } from "./pages/google-auth-result/google-auth-result.component";
import { GoogleAuthComponent } from "./pages/google-auth/google-auth.component";
import { JoinComponent } from "./pages/join/join.component";
import { LeadsComponent } from "./pages/leads/leads.component";
import { EstimatesComponent } from "./pages/estimates/estimates.component";
import { JobsComponent } from "./pages/jobs/jobs.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { canOpenSingleUserPage } from "./services/view-as.service";
import { AvailabilityComponent } from "./pages/availability/availability.component";
import { TechPartsReportItemsComponent } from "./components/tech-parts-report-items/tech-parts-report-items.component";
import { PaymentsReportItemsComponent } from "./components/payments-report-items/payments-report-items.component";
import {
  CommissionsReportItemsComponent
} from "./components/commissions-report-items/commissions-report-items.component";
import { AllScheduleComponent } from "./pages/all-schedule/all-schedule.component";
import { PoIListComponent } from './pages/poi-list/poi-list.component';
import { canViewBusiness, canViewSuspendedPage } from "./guards/business.guard";
import { AfterLoginComponent } from "./components/after-login/after-login.component";

export const singleUserPages = ['settings', 'items', 'assigned', 'assignment'];

const redirectToLobby = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = await authService.user$.pipe(take(1)).toPromise();

  if(user) {
    return router.createUrlTree(['/lobby']);
  }
  return true;

};
const redirectToJoin = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = await authService.user$.pipe(take(1)).toPromise();

  if(user && route.url.length > 1) {
    const id = route.url[1].path;
    return router.createUrlTree(['/join', id]);
  }
  return true;

};
const joinWithoutId = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const businessService = inject(BusinessService);
  const router = inject(Router);
  const user = await authService.user$.pipe(take(1)).toPromise();

  const id = businessService.getInvitationFromStorage();

  if(id) {
    businessService.removeInvitationFromStorage();
    return router.createUrlTree(['/join', id]);
  }
  return router.createUrlTree(['/lobby']);

};

const redirectUnauthorized = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const businessService = inject(BusinessService);
  const user = await authService.user$.pipe(take(1)).toPromise();
  if(!user) {
    return router.createUrlTree(['/login']);
  }
  const availableBusiness = await businessService.availableBusinesses$.pipe(take(1)).toPromise();
  if(availableBusiness.length === 0) {
    return router.createUrlTree(['/create']);
  }
  return true;
};

const routes: Routes = [
  { path: '', redirectTo: 'lobby', pathMatch: 'full' },

  { path: 'login', component: LoginComponent, canActivate: [redirectToLobby] },
  { path: 'signup', component: SignupComponent, canActivate: [redirectToJoin] },
  { path: 'signup/:id', component: SignupComponent, canActivate: [redirectToJoin] },
  { path: 'join/:id', component: JoinComponent },
  { path: 'join', component: JoinComponent, canActivate: [joinWithoutId] },
  { path: 'al', component: AfterLoginComponent },
  { path: 'reset-password', component: ForgotPasswordComponent, canActivate: [redirectToLobby] },
  { path: 'change-password', component: ResetComponent, canActivate: [redirectUnauthorized] },

  { path: 'gauth', component: GoogleAuthComponent },
  { path: 'gauthres', component: GoogleAuthResultComponent },

  {path: 'settings', component: ProfileComponent, canActivate: [redirectUnauthorized, canViewBusiness, canOpenSingleUserPage]},
  {
    path: 'reports', component: ReportsComponent, canActivate: [redirectUnauthorized, canViewBusiness], children: [
      {path: '', redirectTo: 'payments', pathMatch: 'full'},
      {
        path: 'payments', component: ReportsPaymentsComponent, children: [
          {path: '', redirectTo: 'all', pathMatch: 'full'},
          {path: 'all', component: PaymentsReportItemsComponent},
          {path: 'paid', component: PaymentsReportItemsComponent},
          {path: 'pending', component: PaymentsReportItemsComponent},
        ]
      },
      {
        path: 'commissions', component: ReportsCommissionsComponent, children: [
          {path: '', redirectTo: 'pending', pathMatch: 'full'},
          {path: 'pending', component: CommissionsReportItemsComponent},
          {path: 'paid', component: CommissionsReportItemsComponent}
        ]
      },
      {path: 'tech-parts', component: ReportsTechPartsComponent, children: [
          {path: '', redirectTo: 'pending', pathMatch: 'full'},
          {path: 'pending', component: TechPartsReportItemsComponent},
          {path: 'paid', component: TechPartsReportItemsComponent}
        ]
      },
      {path: 'overlook', component: ReportsOverlookComponent},
      {path: 'compare', component: ReportsCompareComponent},
    ]
  },

  { path: 'create', component: CreateBusinessComponent },
  { path: 'customer-list', component: CustomerListComponent, canActivate: [redirectUnauthorized, canViewBusiness] },

  {
    path: 'lobby', component: LobbyComponent, canActivate: [redirectUnauthorized, canViewBusiness], children: [
      { path: '', redirectTo: 'schedule', pathMatch: 'full' },
      { path: 'assigned', component: AssignedComponent, canActivate: [canOpenAssignedPage] },
      { path: 'assignment', component: AssignmentComponent, canActivate: [canOpenAssignmentsPage] },
      {
        path: 'schedule', component: ScheduleComponent, children: [
          { path: '', redirectTo: 'leads', pathMatch: 'full' },
          { path: 'leads', component: LeadsComponent },
          { path: 'all', component: AllScheduleComponent },
          { path: 'estimates', component: EstimatesComponent },
          { path: 'jobs', component: JobsComponent },
          { path: 'unavailable', component: AvailabilityComponent },
        ]
      },
      {
        path: 'proposals', component: ProposalsComponent, children: [
          { path: '', redirectTo: 'pending', pathMatch: 'full' },
          { path: 'pending', component: PoIListComponent, data: { type: 'Proposal', tab: 'Pending' } },
          { path: 'won', component: PoIListComponent, data: { type: 'Proposal', tab: 'Won' } },
          { path: 'declined', component: PoIListComponent, data: { type: 'Proposal', tab: 'Declined' } },
          { path: 'canceled', component: PoIListComponent, data: { type: 'Proposal', tab: 'Canceled' } },
          { path: 'drafts', component: PoIListComponent, data: { type: 'Proposal', tab: 'Drafts' } },
        ]
      },
      {
        path: 'invoices', component: InvoicesComponent, children: [
          { path: '', redirectTo: 'pending', pathMatch: 'full' },
          { path: 'pending', component: PoIListComponent, data: { type: 'Invoice', tab: 'Pending' } },
          { path: 'paid', component: PoIListComponent, data: { type: 'Invoice', tab: 'Paid' } },
          { path: 'canceled', component: PoIListComponent, data: { type: 'Invoice', tab: 'Canceled' } },
          { path: 'drafts', component: PoIListComponent, data: { type: 'Invoice', tab: 'Drafts' } }
        ]
      },
    ]
  },

  { path: 'estimates/:workflowId/:requestId', component: EventInfoComponent, canActivate: [redirectUnauthorized, canViewItemGuard, canViewBusiness] },
  { path: 'jobs/:workflowId/:requestId', component: EventInfoComponent, canActivate: [redirectUnauthorized, canViewItemGuard, canViewBusiness] },

  { path: 'proposals/:workflowId/:proposalId', component: ProposalComponent, canActivate: [redirectUnauthorized, canViewItemGuard, canViewBusiness] },
  { path: 'invoices/:workflowId/:invoiceId', component: InvoiceComponent, canActivate: [redirectUnauthorized, canViewItemGuard, canViewBusiness] },
  { path: 'payments/:workflowId/:paymentId', component: PaymentComponent, canActivate: [redirectUnauthorized, canViewItemGuard, canViewBusiness] },

  { path: 'items', component: ItemsComponent, canActivate: [redirectUnauthorized, canViewBusiness, canOpenItemsPage] },

  { path: 'suspended', component: SuspendedBusinessComponent, canActivate: [redirectUnauthorized, canViewSuspendedPage] },

  { path: 'loading', component: LoadingComponent },

  { path: '**', redirectTo: 'lobby', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
