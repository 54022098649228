<div class="tile-wrapper" (click)="tileClick.emit(document)">
  <app-base-tile
      [data]="data"
      [hideOnSmallScreens]="false"
  >
      <div class="info" [class.with-label]="showOpenedLabel || (showCreator$ | async)" trailing>
          <app-label class="label" [color]="document.opened ? 'green' : 'grey'" *ngIf="showOpenedLabel">{{ document.opened ? 'Opened' : 'Not Opened' }}</app-label>
          <small *ngIf="showCreator$ | async" class="user px--12 py--4 fw-600">{{ document.creatorFirstName }}&nbsp;{{ document.creatorLastName[0].toUpperCase() }}.</small>
      </div>
  </app-base-tile>
</div>