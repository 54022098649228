import moment from "moment";

export class DateFilter {
    
    readonly from: Date;
    readonly to: Date;

    constructor(
        from: Date,
        to: Date,
    ) {
        this.from = moment(from).startOf('day').toDate();
        this.to = moment(to).endOf('day').toDate();
    }

    static from(dates: [Date, Date]): DateFilter {
        return new DateFilter(dates[0], dates[1]);
    }
    
}