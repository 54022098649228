import { Injectable, inject } from '@angular/core';
import { Page, Tab, pagePathToName } from '../models/navigation.model';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter, map, startWith, distinctUntilChanged, shareReplay, take, tap } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { ViewAsService } from "./view-as.service";
import { SupabaseService } from "./supabase.service";
import { UsersService } from './users.service';
import { AuthService } from './auth.service';
import { UtilsService } from 'projects/common/src/public-api';

type ProperType = 'leads' | 'estimates' | 'jobs' | 'proposals' | 'invoices';
type StateQueryParam = { businessId: string, users: number[] | null };

function isProperType(type: string): type is ProperType {
  return ['leads', 'estimates', 'jobs', 'proposals', 'invoices', 'payments'].includes(type);
}

function findProperType(segments: string[]) {
    for(let i = 0; i < segments.length; i++) {
        if(isProperType(segments[i]))
            return i;
    }
    return -1;
}

export const canViewItemGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const businessService = inject(BusinessService);
  const supabaseService = inject(SupabaseService);
  const viewAsService = inject(ViewAsService);
  const navigationService = inject(NavigationService);
  const router = inject(Router);

  await navigationService.setState(route.queryParams.state);

  const selectedUsers = await viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
  if(!selectedUsers)
    return true;

  const segments = state.url.split('?')[0].split('/');

  const typeIndex = findProperType(segments);
  if(typeIndex === -1)
    return false;

  const itemType = segments[typeIndex];
  const itemId = segments[typeIndex+2];

  const business = await businessService.selectedBusiness$.pipe(take(1)).toPromise();
  const type = itemType.endsWith('s') ? itemType.substring(0, itemType.length - 1) : itemType;

  const canView = await supabaseService.rpcFunc<boolean>(business!.businessId, 'can_open_item', {
    inItemType: type,
    inItemId: +itemId,
    inUserIds: selectedUsers
  });

  if(!canView)
    return router.createUrlTree(['/lobby']);

  return true;
};

@Injectable({
  providedIn: 'root'
})
export class NavigationService<P = Page, T = Tab> {

  url$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(_ => this.router.url),
    startWith(this.router.url),
    distinctUntilChanged(),
    map(url => {
      const route = url.split('?')[0];
      const routeParts = route.split('/');
      if (['lobby', 'reports', 'customer-list'].includes(routeParts[1]))
        sessionStorage.setItem('back-route', route);
      return routeParts;
    }),
    shareReplay(1)
  );

  scope$ = this.url$.pipe(
      map(([_, scope, page, tab]) => {
        if(!scope) {
          console.warn('scope is undefined', scope, page, tab);
        }
        return scope;
      }),
      distinctUntilChanged(),
      shareReplay(1)
  );

  page$ = this.url$.pipe(
    map(([_, scope, page, tab]) => {
      if(!page) {
        console.warn('page is undefined', scope, page, tab);
        return page as P;
      }
      return pagePathToName(page) as P;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  tab$ = this.url$.pipe(
    map(([scope, lobby, page, tab]) => {
      if(!tab) {
        if(scope === 'lobby' || scope === 'reports')
          console.warn('tab is undefined', lobby, page, tab);
        return '' as T;
      }
      return pagePathToName(tab) as T;
    }),
    distinctUntilChanged(),
  );

  isSingleUserPage$ = this.url$.pipe(
    map(([_, base, tab]) => {
      if(base === 'settings')
        return true;
      if(base === 'lobby' && (['assignments', 'assigned'].includes(tab)))
        return true;
      return false;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private businessService: BusinessService,
    private viewAsService: ViewAsService,
    private usersService: UsersService,
    private authService: AuthService,
    private utilsService: UtilsService,
  ) {
    // Manual init of url$ is needed because router.events initializes and starts observing after first subscription
    this.url$.pipe(take(1)).toPromise();
  }

  async setStateQueryParam() {
    if (this.route.snapshot.queryParams.state)
      return;
    const businessId = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
    const users = (await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise());
    const stateObj: StateQueryParam = { businessId, users };
    const state = await this.utilsService.encrypt(JSON.stringify(stateObj));
    await this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: { state },
        queryParamsHandling: 'merge',
        replaceUrl: true
      }
    );
  }

  async setState(state?: string) {
    if (!state)
      return;
    const loggedIn = !!(await this.authService.user$.pipe(take(1)).toPromise());
    if (!loggedIn)
      return;

    const currentBusiness = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
    const currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    try {
      const decrypted = await this.utilsService.decrypt(state);
      const { businessId, users } = JSON.parse(decrypted) as StateQueryParam;
      if (businessId && currentBusiness !== businessId) {
        await this.businessService.selectBusiness(businessId, false);
        return;
      }
      if (['owner', 'admin'].includes(currentUser.role)) {
        const userProfiles = users 
          ? (await this.usersService.activeUsers$.pipe(take(1)).toPromise())!.filter(u => users.includes(u.id)) 
          : 'All';
        this.viewAsService.changePrep$.next(userProfiles);
        this.viewAsService.setUsers(users ?? 'All');
      }
    } catch(e) {
      console.error('Error processing "state" query param');
      this.router.navigate(['/']);
    }
  }

  refreshPage(state?: object) {
    const url = this.router.url;
    this.router.navigateByUrl('/loading', {skipLocationChange: true}).then(() => {
        this.router.navigate([`/${url}`], { state });
    });
  }

  async goBack() {
    const route = sessionStorage.getItem('back-route');
    if (route && route.split('/').length > 1) {
      this.router.navigate([route]);
      return;
    }
    const currentUrl = await this.url$.pipe(take(1)).toPromise();
    let backUrl = '/';
    if (currentUrl[1] === 'proposals')
      backUrl = '/lobby/proposals/won';
    else if (currentUrl[1] === 'invoices')
      backUrl = '/lobby/invoices/paid';
    this.router.navigate([backUrl]);
  }

  get fromReports() {
    return sessionStorage.getItem('back-route')?.split('/')[1] === 'reports';
  }

}
