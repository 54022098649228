import { Component, OnInit } from '@angular/core';
import { BusinessAndUserResult } from "../../services/business.service";
import { SupabaseService } from "../../services/supabase.service";
import { AuthService } from "../../services/auth.service";
import { take } from "rxjs/operators";
import { Router } from "@angular/router";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-after-login',
  templateUrl: './after-login.component.html',
  styleUrls: ['./after-login.component.scss']
})
export class AfterLoginComponent implements OnInit {

  constructor(
      private supabaseService: SupabaseService,
      private authService: AuthService,
      private router: Router,
      private snackbar: MatSnackBar,
  ) {}

  async ngOnInit() {
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    const businesses = await this.supabaseService.rpcFunc<BusinessAndUserResult>('app_data', 'get_business_and_user_data', { in_user_uuid: user!.uid });
    if(!businesses || !businesses.data || businesses.data.length === 0) {
      this.authService.logout().then(() => {
        showSnackbar(this.snackbar, {
          message: "This user does not exist",
          duration: 10000
        })
      });
    } else {
      this.router.navigate(['/lobby']);
    }
  }

}
