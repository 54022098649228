<button #button [style.width]="width" *ngIf="stroked else flat" class="loader" [class.loading]="loading" mat-stroked-button type="submit" (click)="click($event)">
    <mat-spinner class="m-auto" [diameter]="diameter ?? 20" *ngIf="loading else textTemplate"></mat-spinner>
</button>

<ng-template #flat>
    <button #button [style.width]="width" class="loader" mat-flat-button [color]="color ?? 'primary'" type="submit" (click)="click($event)">
        <mat-spinner class="m-auto" [diameter]="diameter ?? 20" *ngIf="loading else textTemplate"></mat-spinner>
    </button>
</ng-template>

<ng-template #textTemplate>
    {{ text }}
</ng-template>