import { Injectable } from '@angular/core';
import 'babel-polyfill';
import Pushy from 'pushy-sdk-web';
import { environment } from "../../environments/environment";
import { SupabaseService } from "./supabase.service";

const TOKEN_KEY = 'PUSHY_TOKEN';
const DONT_SHOW_AGAIN_KEY = 'DONT_SHOW_NOTIFICATIONS_PROMPT';

@Injectable({
  providedIn: 'root'
})
export class PushyService {

    constructor(
        private supabaseService: SupabaseService
    ) {}

    async register() {
        try {
            const token = await Pushy.register({ appId: environment.pushy.appId });
            localStorage.setItem(TOKEN_KEY, token);
            this.supabaseService.rpcFunc('app_data', 'add_notification_token', { token });
        } catch (e: any) {
            console.error('Registration failed: ' + e.message);
        }
    }

    isRegistered() {
        const token = localStorage.getItem(TOKEN_KEY);
        return token !== null
    }

    canShowPrompt() {
        return localStorage.getItem(DONT_SHOW_AGAIN_KEY) !== 'TRUE';
    }

    dontShowAgain() {
        localStorage.setItem(DONT_SHOW_AGAIN_KEY, 'TRUE');
    }

    unsetDontShowAgain() {
        localStorage.removeItem(DONT_SHOW_AGAIN_KEY);
    }

    async unregister() {
        const token = localStorage.getItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_KEY);
        if(token) {
            return this.supabaseService.rpcFunc('app_data', 'delete_notification_token', { token });
        }
    }
}
