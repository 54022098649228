import { filter, map, startWith } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ViewAsService } from "../services/view-as.service";
import { combineLatest } from "rxjs";

const MENU_ITEMS = ['/settings', '/reports', '/items', '/customers'] as const;
export type MenuItem = {
    title: string,
    link: typeof MENU_ITEMS[number],
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private _route$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.router.url),
        map((event) => {
            const url = (typeof event === 'string') ? event : (event as NavigationEnd).url;
            const [_, page] = url.split('/');
            return page;
        }),
    );

    menuItems$ = combineLatest([
        this.viewAsService.selectedUsers$.pipe(filter(user => !!user)),
        this._route$
    ]).pipe(
        map(([selectedUsers, path]) => {
            const items: MenuItem[] = [
                {
                    title: 'Settings',
                    link: '/settings'
                },
                {
                    title: 'Reports',
                    link: '/reports'
                }
            ];
            if (
                selectedUsers === 'All'
                || selectedUsers.some(user => user!.role === 'admin' || user!.role === 'owner' || user!.permissions.includes('edit_items'))
            ) {
                items.push({
                    title: 'Items',
                    link: '/items'
                });
            }
            
            return items;
        })
    );

    constructor(
        private viewAsService: ViewAsService,
        private router: Router,
    ) {}

}
