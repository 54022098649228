import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Availability, AvailabilityTileData } from "../../models/availability.model";
import { AvailabilityTileComponent } from "../../components/availability-tile/availability-tile.component";
import {
    DynamicListComponent,
    DynamicListEvent,
    PaginationListItem
} from "../../components/dynamic-list/dynamic-list.component";
import { Pagination, PaginationOptions } from "../../services/pagination.service";
import { BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { BusinessService } from "../../services/business.service";
import { SupabaseService } from "../../services/supabase.service";
import { LobbyService } from "../../services/lobby.service";
import { ViewAsService } from "../../services/view-as.service";
import { limitStep } from "../../services/items.service";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { dateString } from "../../../../../common/src/lib/services";
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { ScheduleService, ScheduleTileData } from '../../services/schedule.service';
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { TimetableDialogComponent } from "../../modals/timetable-dialog/timetable-dialog.component";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

function availabilityToPaginationList(availability: AvailabilityTileData[]) {
    return availability.map(entry => ({
        componentClass: AvailabilityTileComponent,
        header: dateString(entry.startTime, entry.endTime),
        args: { document: {...entry, docType: 'Personal'}}
    }));
}

@Component({
    selector: 'app-availability',
    templateUrl: './availability.component.html',
    styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit, AfterViewInit {

    @ViewChild('list') dynamicList!: DynamicListComponent<AvailabilityTileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<AvailabilityTileComponent>;

    pagination!: Pagination<AvailabilityTileData>;
    dateFilterPagination!: Pagination<AvailabilityTileData>;
    loading$ = new BehaviorSubject<boolean>(true);

    dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
        switchMap(async range => {
            if(!range) 
                return null;
            const inLimit = limitStep;
            const options: PaginationOptions = {
                inLimit, 
                inOffset: 0, 
                inStartDate: frontToServerTranslation(range.from), 
                inEndDate: frontToServerTranslation(range.to), 
            };
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if(userIds)
                options.inUserIds = userIds;
            
            const [past, future] = await Promise.all([
                this.dateFilterPagination.get({...options, in_direction: 'past'}),
                this.dateFilterPagination.get({...options, in_direction: 'future'})
            ]);
            this.filterFirstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
            this.initialFilterTopItems = availabilityToPaginationList(past);
            this.initialFilterBottomItems = availabilityToPaginationList(future);
            this.initialFilterCanLoadMoreBottom = future.length >= inLimit;
            this.initialFilterCanLoadMoreTop = past.length >= inLimit;
            return range;
        }),
        shareReplay({ bufferSize: 1, refCount: true})
    );

    initialTopItems!: PaginationListItem<AvailabilityTileComponent>[];
    initialBottomItems!: PaginationListItem<AvailabilityTileComponent>[];
    initialFilterTopItems!: PaginationListItem<AvailabilityTileComponent>[];
    initialFilterBottomItems!: PaginationListItem<AvailabilityTileComponent>[];
    initialFilterCanLoadMoreTop = true;
    initialFilterCanLoadMoreBottom = true;
    firstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    firstHeader$ = this.firstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    filterFirstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    filterFirstHeader$ = this.filterFirstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));

    constructor(
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private lobbyService: LobbyService,
        private scheduleService: ScheduleService,
        private viewAsService: ViewAsService,
        private modalsService: ModalsService,
    ) {}

    async ngOnInit() {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<AvailabilityTileData, Availability>(this.supabaseService, 'get_unavailability', business.businessId, 'unavailability')
            .on<Availability>('*', 'unavailability', this.handleUnavailabilityChanges.bind(this));

        this.dateFilterPagination = new Pagination<AvailabilityTileData, Availability>(this.supabaseService, 'get_unavailability', business.businessId, 'filtered_unavailability')
            .on<Availability>('*', 'unavailability', this.handleDateFilterUnavailabilityChanges.bind(this));

        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0 };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const [past, future] = await Promise.all([
            this.pagination.get({...options, in_direction: 'past'}),
            this.pagination.get({...options, in_direction: 'future'})
        ]);
        this.initialTopItems = availabilityToPaginationList(past);
        this.initialBottomItems = availabilityToPaginationList(future);
        this.firstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
        this.setInitialCanLoadMore();
        this.loading$.next(false);
    }

    ngAfterViewInit(): void {
        this.setInitialCanLoadMore();
    }
    
    ngOnDestroy(): void {
        this.pagination.destroy();
        this.dateFilterPagination.destroy();
    }

    setInitialCanLoadMore() {
        if (!this.initialTopItems || !this.initialBottomItems || !this.dynamicList)
            return;
        if(this.initialTopItems.length < limitStep)
            this.dynamicList.canLoadMoreTop = false;
        if(this.initialBottomItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset, inDirection: at === 'top' ? 'past' : 'future' };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const availability = await this.pagination.get(options);
        if(at === 'top') {
            if(availability.length < inLimit)
                this.dynamicList.canLoadMoreTop = false;
            this.dynamicList.addAtTop(
                availabilityToPaginationList(availability)
            );
        } else {
            if(availability.length < inLimit)
                this.dynamicList.canLoadMoreBottom = false;
            this.dynamicList.addAtBottom(
                availabilityToPaginationList(availability)
            );
        }
    }

    async loadDateFilterItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = {
            inLimit,
            inOffset,
            inDirection: at === 'top' ? 'past' : 'future',
            inStartDate: frontToServerTranslation(range!.from),
            inEndDate: frontToServerTranslation(range!.to),
        };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const availability = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;

        if(at === 'top') {
            if(availability.length < inLimit)
                this.dateFilterList.canLoadMoreTop = false;
            this.dateFilterList.addAtTop(
                availabilityToPaginationList(availability)
            );
        } else {
            if(availability.length < inLimit)
                this.dateFilterList.canLoadMoreBottom = false;
            this.dateFilterList.addAtBottom(
                availabilityToPaginationList(availability)
            );
        }
    }

    handleUnavailabilityChanges(payload: RealtimePostgresChangesPayload<Availability>) {
        this.scheduleService.handleUnavailabilityChanges(this.dynamicList, payload, this.firstHeaderRangeSubject);
    }

    handleDateFilterUnavailabilityChanges(payload: RealtimePostgresChangesPayload<Availability>) {
        this.scheduleService.handleDateFilterUnavailabilityChanges(this.dateFilterList, payload, this.filterFirstHeaderRangeSubject);
    }

    onItemEvent(event: DynamicListEvent<AvailabilityTileData>) {
        this.scheduleService.onItemEvent(event as DynamicListEvent<ScheduleTileData>)
    }
}