<div (mouseenter)="hoverSubject.next(true)" (mouseleave)="hoverSubject.next(false)" [class.focus]="focus"
     (click)="onClick()" class="note-tile" [class.withImage]="imageUrl">
  <div 
    #wrapper 
    class="wrapper d-flex" 
    [class.hover]="hoverSubject | async" 
    *ngIf="imageUrl; else textAreaWrapper"
    [ngClass]="{ 'done': note.status === 'Done',
                              'in-progress': note.status === 'In Progress',
                              'unmark': note.status === 'Unmark' }"
  >
    <skeleton-img class="image" [src]="thumbnailUrl" (click)="imageClick($event)"></skeleton-img>
    <ng-container *ngTemplateOutlet="textAreaWrapper"></ng-container>
  </div>
  <app-icon *ngIf="note.text!.length > 0 || note.image || note.imageName" class="delete se-icon-button no-bg" [class.withImage]="imageUrl"
            (click)="deleteClick($event)" src="close"></app-icon>
</div>

<ng-template #textAreaWrapper>
  <div class="textAreaWrapper">
    <form>
<!--      <textarea cdkTextareaAutosize cdkAutosizeMinRows="1"-->
<!--                [formControl]="formControl" #textArea #autosize="cdkTextareaAutosize"-->
<!--                placeholder="Write note..."-->
<!--                (click)="onTextFieldClick($event)"-->
<!--                (focus)="onFocus()" (focusout)="onUnfocus()"-->
<!--                [ngClass]="{ 'done': notesService.isNoteMarked(note, 'Done') && !note.image,-->
<!--                              'in-progress': notesService.isNoteMarked(note, 'In Progress') && !note.image,-->
<!--                              'unmark': notesService.isNoteMarked(note, 'Unmark') && !note.image }"-->
<!--                (input)="checkNoteIsEmpty()"-->
<!--                [class.hover]="hoverSubject | async">{{ note.text }}-->

<!--      </textarea>-->
      <textarea cdkTextareaAutosize cdkAutosizeMinRows="1"
                [formControl]="formControl" #textArea #autosize="cdkTextareaAutosize"
                placeholder="Write note..."
                (click)="onTextFieldClick($event)"
                (focus)="onFocus()"
                (focusout)="onUnfocus()"
                [ngClass]="{ 'done': note.status === 'Done' && !note.image,
                              'in-progress': note.status === 'In Progress' && !note.image,
                              'unmark': note.status === 'Unmark' && !note.image }"
                [class.hover]="hoverSubject | async"
                [readonly]="!!note.inUseBy && note.inUseBy !== currentUser.id"
        >{{ note.text }}

      </textarea>
    </form>
  </div>
  <!-- <app-icon class="delete not-mobile se-icon-button no-bg" [class.withImage]="note.hasImage" *ngIf="showDeleteButton$ | async" (click)="deleteClick($event)" src="close"></app-icon> -->
</ng-template>
