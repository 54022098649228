import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from "../../services/users.service";
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { Subscription } from "rxjs";
import {
  CHAT_MESSAGE_FORMAT,
  USER_MENTION_CLASS
} from "../../../../../common/src/lib/directives/chat-message.directive";
import { DOCUMENT } from "@angular/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { removeInvalidSpans, SCOPE_CLOSE_CHAR, SCOPE_OPEN_CHAR } from "./format";

function nextNode(range: Range) {
  const parent = range.startContainer.parentNode!;
  for (let i = 0; i < parent.childNodes.length; i++) {
    if(parent.childNodes[i] === range.startContainer) {
      return parent.childNodes[i + 2];
    }
  }
  return null;
}

function previousNode(range: Range) {
  const parent = range.startContainer.parentNode!;
  for (let i = 0; i < parent.childNodes.length; i++) {
    if(parent.childNodes[i] === range.startContainer) {
      return parent.childNodes[i - 2];
    }
  }
  return null;
}

@Component({
  selector: 'app-chat-message-input',
  templateUrl: './chat-message-input.component.html',
  styleUrls: ['./chat-message-input.component.scss']
})
export class ChatMessageInputComponent implements OnInit, OnDestroy {

  @ViewChild('input') input!: ElementRef<HTMLDivElement>;

  @Input() placeholder = '';
  @Input() messageControl!: FormControl<string>;

  normalText = '';
  _formattedText = '';
  get formattedText() { return this._formattedText }
  set formattedText(value: string) {
    // value = this.checkControlChar(value);
    this._formattedText = value;
    this.innerHTML = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  innerHTML: SafeHtml = '';

  users!: UserProfile[];
  usersSub!: Subscription;
  textSub!: Subscription;

  nodesCounter = 0;
  focused = false;

  constructor(
     private usersService: UsersService,
     private sanitizer: DomSanitizer,
     @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    // this.document.addEventListener('selectionchange', this.onSelectionChange.bind(this));
    this.usersSub = this.usersService.users$.subscribe(users => {
      this.users = users!;
    });
    this.textSub = this.messageControl.valueChanges.subscribe(text => {
      if(text !== this.normalText) {
        this.normalText = text;
        this.formattedText = this.formatToLocal(text);
      }
    });
    this.document.addEventListener('selectionchange', this.onSelectionChange.bind(this));
  }

  fieldFocus(focus: boolean) {
    this.focused = focus;
  }

  async onChange() {
    // Skip if text is the value is the same
    if(this.input.nativeElement.innerHTML === this.formattedText) {
      if(this.input.nativeElement.innerHTML !== this.messageControl.value) {
        this.normalText = this.formatToIds(this.input.nativeElement.innerHTML);
        this.messageControl.setValue(this.normalText);
      }
      return;
    }

    const selection = this.document.getSelection();
    let lastNode = selection!.getRangeAt(selection!.rangeCount - 1).startContainer;

    let value = removeInvalidSpans(this.input.nativeElement.innerHTML);

    // Format all @{#} to localFormat
    const formattedText = this.formatToLocal(value);

    // Set the value locally only if the value was changed during the formatting
    // Otherwise the selection will jump around
    if(formattedText !== this.input.nativeElement.innerHTML) {
      this.formattedText = formattedText
          .replace(/<div>/g, '<br>')
          .replace(/<\/div>/g, '');
    }
    this.normalText = this.formatToIds(formattedText);
    this.messageControl.setValue(this.normalText);
    setTimeout(() => {

      const selection = this.document.getSelection();
      if(selection) {
        const newRange = selection!.getRangeAt(selection!.rangeCount - 1);
        if(newRange.startContainer === this.input.nativeElement) {
          const node = this.input.nativeElement.childNodes[this.input.nativeElement.childNodes.length - 1];
          if(selection.rangeCount)
            selection.removeAllRanges();
          newRange.setStartAfter(node);
          selection.addRange(newRange);
        } else if(lastNode !== newRange.startContainer) {
          if(selection.rangeCount)
            selection.removeAllRanges();
          newRange.setStartAfter(newRange.startContainer);
          selection.addRange(newRange);
        }
      }
    }, 2);
  }

  formatToLocal(value: string) {
    let lastNode: number | null = null;
    const result = value
        .replace(CHAT_MESSAGE_FORMAT, (match) => {
      const idMatch = match.match(/(\d+)/);
      const id = +idMatch![0];
      const user = this.users.find(u => u.id === id)!;
      const replacement = `${user.firstName} ${user.lastName}`;
      lastNode = this.nodesCounter++;
      return `${SCOPE_OPEN_CHAR}<span class="${USER_MENTION_CLASS}" data-user-id="${id}" data-nc="${lastNode}" contenteditable="false">${replacement}</span>${SCOPE_CLOSE_CHAR}`;
    });

    if(lastNode !== null) {
      setTimeout(() => {
        const selection = this.document.getSelection();
        if(selection) {
          let node!: Node;
          for(let i = 0; i < this.input.nativeElement.childNodes.length; i++) {
            const n = this.input.nativeElement.childNodes[i];
            if(n.nodeType === Node.ELEMENT_NODE) {
              const nc = (n as HTMLElement).getAttribute('data-nc');
              if(nc === `${lastNode}`) {
                node = this.input.nativeElement.childNodes[i];
                break;
              }
            }
          }

          const range = selection.getRangeAt(selection.rangeCount - 1);
          if(node) {
            selection.removeAllRanges();
            range.setStartBefore(node);
            range.setEndBefore(node);
            selection.addRange(range);
          }
        }
      }, 0);
    }

    return result;
  }

  formatToIds(value: string) {
    return value
      .replace(/<br>/g, '\n')
      .replace(/<(\w+)(?:\s+[^>]*)?>([\s\S]*?)<\/\1>/g, (match) => {
        let start = match.indexOf('data-user-id="');
        if(start === -1)
          return match;
        start += 14;
        const end = match.indexOf('"', start);
        if(end === -1)
          return match;
        
        return `@{${match.substring(start, end)}}`;
      });
  }

  onSelectionChange() {
    const selection = this.document.getSelection();
    try {
      if(selection) {
        const range = selection.getRangeAt(selection.rangeCount - 1);
        if(range.startContainer.textContent?.startsWith(SCOPE_CLOSE_CHAR) && range.startOffset === 0) {
          const node = previousNode(range);
          if(node) {
            selection.removeAllRanges();
            range.setStart(node, (node as Text).length -1 );
            range.setEnd(node, (node as Text).length -1 );
            selection.addRange(range);
          }
        } else if (range.startContainer.textContent?.endsWith(SCOPE_OPEN_CHAR) && range.startOffset === (range.startContainer as Text).length) {
          const node = nextNode(range);
          if(node) {
            selection.removeAllRanges();
            range.setStart(node, 1);
            range.setEnd(node, 1);
            selection.addRange(range);
          }
        }
      }
    } catch {}
  }

  async onPaste(event: ClipboardEvent) {
    if(event.clipboardData) {
      event.preventDefault();
      event.stopImmediatePropagation();
      const text = event.clipboardData.getData('text/plain');
      this.document.execCommand("insertHTML", false, this.sanitizer.sanitize(SecurityContext.HTML, text)!.toString());
    }
  }

  ngOnDestroy() {
    // this.document.removeEventListener('selectionchange', this.onSelectionChange);
    this.usersSub?.unsubscribe();
    this.textSub?.unsubscribe();
  }

}
