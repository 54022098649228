<div class="sales-container">
    <div class="sales big-screen" *ngIf="data" [class.no-border]="loading$ | async">
        <div class="skeleton" *ngIf="loading$ | async"></div>
        <ng-container *ngFor="let tabLabel of infoToFill[selectedTab]; let last = last">
            <div class="total-sales" [class.p-paid-paid]="tabLabel.id === 'p.paid.paid'">
                <ng-container *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !== 'c.pending.jobs' && tabLabel.id !== 'c.paid.jobs'"
                        [class.overpay]="redAmount(tabLabel.id, data)"
                        class="currency"
                        [attr.data-id]="tabLabel.id"
                        #tooltip="matTooltip"
                        [matTooltip]="''"
                        [hideTooltipOnScroll]="tooltip"
                        [shortNumber]="data[tabLabel.property] ?? 0 | currency"
                        [shortNumberTooltip]="tooltip"
                        (click)="tooltip.show()"
                    ></h3>
                    <h3 *ngIf="tabLabel.id === 'p.paid.avg_j_d'"
                        #tooltip="matTooltip"
                        [matTooltip]="''"
                        [shortNumber]="round(data[tabLabel.property])"
                        [shortNumberTooltip]="tooltip"
                        [hideTooltipOnScroll]="tooltip"
                    ></h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc' || tabLabel.id === 'c.pending.jobs' || tabLabel.id === 'c.paid.jobs'"
                        #tooltip="matTooltip"
                        [matTooltip]="''"
                        [shortNumber]="data[tabLabel.property] ?? 0"
                        [shortNumberDecimals]="0"
                        [shortNumberTooltip]="tooltip"
                        [hideTooltipOnScroll]="tooltip"
                    ></h3>
                </ng-container>
                <small>{{ tabLabel.text }}</small>
            </div>
            <div class="divider-line" *ngIf="!last"></div>
        </ng-container>
    </div>
    <div class="sales small-screen" [class.expand-animation]="showAll" *ngIf="data">
        <div class="skeleton" *ngIf="loading$ | async"></div>
        <ng-container *ngFor="let tabLabel of infoToFill[selectedTab]; let last = last; let i = index">
            <div class="total-sales" [class.p-paid-paid]="tabLabel.id === 'p.paid.paid'">
                <small>{{ tabLabel.text }}</small>
                <div class="w-100 overflow-hidden d-flex justify-content-end" *ngIf="data">
                    <h3 *ngIf="tabLabel.id !== 'p.paid.avg_j_d' && tabLabel.id !== 'p.pending.nc' && tabLabel.id !== 'c.pending.jobs' && tabLabel.id !== 'c.paid.jobs'"
                        [class.overpay]="redAmount(tabLabel.id, data)"
                        class="currency"
                        [attr.data-id]="tabLabel.id"
                        #tooltip="matTooltip"
                        [matTooltip]="''"
                        [hideTooltipOnScroll]="tooltip"
                        [shortNumber]="data[tabLabel.property] ?? 0 | currency"
                        [shortNumberTooltip]="tooltip"
                        (click)="tooltip.show()"
                    ></h3>
                    <h3 *ngIf="tabLabel.id === 'p.paid.avg_j_d'"
                        #tooltip="matTooltip"
                        [matTooltip]="''"
                        [shortNumber]="round(data[tabLabel.property])"
                        [shortNumberTooltip]="tooltip"
                        [hideTooltipOnScroll]="tooltip"
                    ></h3>
                    <h3 *ngIf="tabLabel.id === 'p.pending.nc' || tabLabel.id === 'c.pending.jobs' || tabLabel.id === 'c.paid.jobs'"
                        #tooltip="matTooltip"
                        [matTooltip]="''"
                        [shortNumber]="data[tabLabel.property] ?? 0"
                        [shortNumberDecimals]="0"
                        [shortNumberTooltip]="tooltip"
                        [hideTooltipOnScroll]="tooltip"
                    ></h3>
                </div>
            </div>
        </ng-container>
        <app-icon class="se-icon-button no-bg no-hover" width="24" height="24" [class.transform]="showAll" src="cheveron_down" (click)="toggleShowAll()"></app-icon>
    </div>
</div>
