import { SupabaseService } from "../../services/supabase.service";
import { TechPartsReport, TechPartsReportItem } from "../../models/reports.model";
import { limitStep } from "../../services/reports-service-extras";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { workflowChangesHandler } from "../../services/reports.service";

const techPartsTables = [
  'workflow_user',
  'user',
  'other',
  'payment',
  'invoice',
  'invoice_version',
  'invoice_version_client',
  'proposal',
  'proposal_version',
  'proposal_version_client'
];

export function techPartsPendingTotalSales(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_pending_total_sales',
      schema: businessId,
      fn: 'tech_parts_pending_total_sales',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function techPartsPendingPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_pending_tech_parts_pending',
      schema: businessId,
      fn: 'tech_parts_pending_tech_parts_pending',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function techPartsPendingList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<TechPartsReportItem[]>({
      cName: 'tech_parts_pending_report',
      schema: businessId,
      fn: 'tech_parts_pending_report',
      tables: [...techPartsTables, 'workflow'],
      options: {
        row_limit: limitStep,
        offset_count: 0,
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    workflowChangesHandler,
    'report'
  );
}

export function techPartsPendingListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_pending_report_count',
      schema: businessId,
      fn: 'tech_parts_pending_report_count',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function techPartsPendingAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_pending_report_ids',
      schema: businessId,
      fn: 'tech_parts_pending_report_ids',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initTechPartsPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    techPartsPendingTotalSales(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    techPartsPendingPending(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    techPartsPendingListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    techPartsPendingAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([totalSales, pending, count, allIds]) => {
      return {
        type: 'pending',
        totalSales: totalSales as unknown as number,
        pending: pending as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as TechPartsReport;
    })
  );
}

export function techPartsPaidTotalSales(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_paid_total_sales',
      schema: businessId,
      fn: 'tech_parts_paid_total_sales',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function techPartsPaidPaid(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_paid_tech_parts_paid',
      schema: businessId,
      fn: 'tech_parts_paid_tech_parts_paid',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function techPartsPaidList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<TechPartsReportItem[]>({
      cName: 'tech_parts_paid_report',
      schema: businessId,
      fn: 'tech_parts_paid_report',
      tables: [...techPartsTables, 'workflow'],
      options: {
        row_limit: limitStep,
        offset_count: 0,
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    workflowChangesHandler,
    'report'
  );
}

export function techPartsPaidListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_paid_report_count',
      schema: businessId,
      fn: 'tech_parts_paid_report_count',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function techPartsPaidAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'tech_parts_paid_report_ids',
      schema: businessId,
      fn: 'tech_parts_paid_report_ids',
      tables: techPartsTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initTechPartsPaid(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    techPartsPaidTotalSales(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    techPartsPaidPaid(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    techPartsPaidListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    techPartsPaidAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([totalSales, paid, count, allIds]) => {
      return {
        type: 'paid',
        totalSales: totalSales as unknown as number,
        paid: paid as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as TechPartsReport;
    })
  );
}
