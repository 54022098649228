<div (click)="openPayment(item.wId, item.id)" class="p-item clickable" [class.p-all]="kind === 'All'">
  <mat-checkbox class="checkbox" [color]="'primary'" [checked]="checked"
                (change)="check($event.checked, item.wId)" (click)="$event.stopPropagation()"
  ></mat-checkbox>

  <div class="p-item-text">
    <div class="p-item-left">
      <div class="p-item-name">{{ item.name }}</div>
      <div class="p-item-jt">{{ item.jobType ?? 'N/A' }}</div>
    </div>
    <div class="p-item-amount">
      <span 
        #tooltip="matTooltip"
        [matTooltip]="''"
        [shortNumber]="item.paidSum | currency"
        [shortNumberTooltip]="tooltip"
        [hideTooltipOnScroll]="tooltip"
      ></span>/<span 
        #tooltip2="matTooltip"
        [matTooltip]="''"
        [shortNumber]="item.total | currency"
        [shortNumberTooltip]="tooltip2"
        [hideTooltipOnScroll]="tooltip2"
      ></span>
    </div>
    <div class="p-item-status"
         [class.paid]="item.paidSum === item.total"
         [class.pending]="item.paidSum !== item.total"
    >
      {{ item.paidSum === item.total ? 'Paid' : 'Pending' }}
    </div>
  </div>
</div>

<div *ngIf="dividerNeed" class="p-item-divider"></div>
