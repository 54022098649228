import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../services/business.service';
import { UsersService } from '../../services/users.service';
import { map, take } from 'rxjs/operators';
import { Router } from "@angular/router";
import { UtilsService } from 'projects/common/src/public-api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile$ = this.businessService.selectedBusiness$;
  user$ = this.usersService.currentUser$.pipe(
    map(user => {
      if(!['owner', 'admin'].includes(user.role) && this.selectedTab === 'Users')
        this.onTabChange('Personal');
      return user;
    })
  );

  selectedTab = 'Personal';
  tabs$ = this.user$.pipe(
    map(currentUser => {
      if(currentUser && ['owner', 'admin'].includes(currentUser.role)) {
        return ['Personal', 'Business', 'Users', 'Help'];
      }
      return ['Personal', 'Business', 'Help'];
    })
  );

  tabletScreen$ = this.utilsService.onScreenBreakpointChange('sm');

  tabIndex = 0;

  constructor(
    private usersService: UsersService,
    private businessService: BusinessService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  async ngOnInit() {
    const initTab = localStorage.getItem('Settings_Tab');
    if(initTab) {
      const availableTabs = await this.tabs$.pipe(take(1)).toPromise();
      if(availableTabs.includes(initTab)) {
        this.tabIndex = availableTabs.indexOf(initTab);
        this.selectedTab = initTab;
      }
    }
    localStorage.removeItem('Settings_Tab');
  }

  backClick() {
    this.router.navigate(['lobby']);
  }

  onTabChange(tab: string) {
    this.selectedTab = tab;
  }
  
}
