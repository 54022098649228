import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Pagination, PaginationOptions } from "../../services/pagination.service";
import { Client } from "../../../../../common/src/lib/models";
import { BehaviorSubject } from "rxjs";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import {
    DynamicListComponent,
    DynamicListEvent,
    PaginationListItem
} from "../../components/dynamic-list/dynamic-list.component";
import { limitStep } from "../../services/items.service";
import { shareReplay, switchMap, take } from "rxjs/operators";
import { BusinessService } from "../../services/business.service";
import { LeadTileComponent } from "../../components/lead-tile/lead-tile.component";
import { SupabaseService } from "../../services/supabase.service";
import { LobbyService } from "../../services/lobby.service";
import { ViewAsService } from "../../services/view-as.service";
import { LeadRestore, LeadsService, LeadUpdate } from "../../services/leads.service";
import { Lead } from "../../../../../common/src/lib/models/lead.model";
import { Router } from "@angular/router";
import moment from 'moment';
import { UsersService } from '../../services/users.service';
import { Workflow } from '../../models/workflow.model';
import {
    RequestInvitationModal,
} from "../../modals/request-invitation/request-invitation.component";
import { ScheduleService } from "../../services/schedule.service";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar } from "@angular/material/legacy-snack-bar";
import {
    ConfirmationDialog
} from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { clientRestoreFromDocument } from "../../../../../common/src/lib/models/client-transform";
import { NotesService } from "../../services/notes.service";
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';
import {
    RequestInvitationFormData,
    RequestInvitationModalData
} from "../../modals/request-invitation/request-invitation.model";

function leadsToPaginationList(leads: Lead[]) {
    return leads.map(lead => {
        lead.docType = 'Lead';
        return {
            componentClass: LeadTileComponent,
            header: 'Leads',
            args: { lead }
        }
    });
}

@Component({
    selector: 'app-leads',
    templateUrl: './leads.component.html',
    styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('list') dynamicList!: DynamicListComponent<LeadTileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<LeadTileComponent>;

    pagination!: Pagination<Lead, Client | Workflow>;
    dateFilterPagination!: Pagination<Lead, Client | Workflow>;
    loading$ = new BehaviorSubject<boolean>(true);
    initialItems!: PaginationListItem<LeadTileComponent>[];
    initialFilterItems!: PaginationListItem<LeadTileComponent>[];
    initialFilterCanLoadMoreBottom = true;

    dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
        switchMap(async range => {
            if(!range) 
                return null;
            const inLimit = limitStep;
            const options: PaginationOptions = {
                inLimit, 
                inOffset: 0, 
                inStartDate: frontToServerTranslation(range.from), 
                inEndDate: frontToServerTranslation(range.to), 
            };
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if(userIds)
                options.inUserIds = userIds;
            
            const leads = await this.pagination.get(options);
            this.initialFilterItems = leadsToPaginationList(leads);
            this.initialFilterCanLoadMoreBottom = leads.length >= inLimit;
            return range;
        }),
        shareReplay({ bufferSize: 1, refCount: true})
    );

    constructor(
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private lobbyService: LobbyService,
        private viewAsService: ViewAsService,
        private leadsService: LeadsService,
        private usersService: UsersService,
        private modalsService: ModalsService,
        private notesService: NotesService,
        private scheduleService: ScheduleService,
        private snackbar: MatLegacySnackBar,
        private router: Router,
    ) {}

    async ngOnInit() {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<Lead, Client | Workflow>(this.supabaseService, 'get_leads', business.businessId, 'leads')
            .on<Lead>('*', 'lead', this.handleLeadChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'lead_client', this.handleClientChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleWorkflowChanges.bind(this));

        this.dateFilterPagination = new Pagination<Lead, Client | Workflow>(this.supabaseService, 'get_leads', business.businessId, 'filtered_leads')
            .on<Lead>('*', 'lead', this.handleDateFilterLeadChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'lead_client', this.handleDateFilterClientChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleDateFilterWorkflowChanges.bind(this));

        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0 };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const leads = await this.pagination.get(options);
        if(leads.length === 0) {
            this.router.navigate(['/lobby', 'schedule', 'all']);
            return;
        }
        this.initialItems = leadsToPaginationList(leads);
        this.setInitialCanLoadMore();
        this.loading$.next(false);
    }

    ngAfterViewInit(): void {
        this.setInitialCanLoadMore();
    }

    setInitialCanLoadMore() {
        if (!this.initialItems || !this.dynamicList)
            return;

        if(this.initialItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(inOffset = 0) {
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const leads = await this.pagination.get(options);
        if(inOffset === 0 && leads.length === 0) {
            this.router.navigate(['/lobby', 'schedule', 'all']);
            return;
        }

        if(leads.length < inLimit)
            this.dynamicList.canLoadMoreBottom = false;

        this.dynamicList.addAtBottom(
            leadsToPaginationList(leads)
        );

    }

    async loadDateFilterItems(inOffset = 0) {
        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = { inLimit, inOffset, inStartDate: frontToServerTranslation(range!.from), inEndDate: frontToServerTranslation(range!.to) };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const leads = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;
        if(leads.length < inLimit)
            this.dateFilterList.canLoadMoreBottom = false;

        this.dateFilterList.addAtBottom(
            leadsToPaginationList(leads)
        );
    }

    async commonHandleUpdate(data: any, idProp: string, handleDateFilter = false) {
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const list = handleDateFilter ? this.dateFilterList : this.dynamicList;
        list.updateItems(component => {
            const lead = component.instance.lead;
            if (lead.id === data[idProp]) {
                if ('ownerId' in data && lead.ownerId !== data['ownerId']) {
                    const owner = users?.find(user => user.id === data['ownerId']);
                    if (owner) {
                        lead.ownerFirstName = owner.firstName;
                        lead.ownerLastName = owner.lastName;
                    }
                }
                for (const prop in lead) {
                    if (prop === 'type' && !['business', 'personal'].includes(data[prop]))
                        continue;
                    if (prop in data && !(['id', 'createdAt'].includes(prop)))
                        (lead as any)[prop] = data[prop];
                }
                component.instance.refreshData();
                return true;
            }
            return false;
        });
    }

    async insertLead(list: DynamicListComponent<LeadTileComponent, any>, id: number) {
        const lead = await this.leadsService.getLead(id);
        lead.docType = 'Lead';
        list.insertItem(
            (prev, current) => {
                if (!prev && lead.createdAt < current.instance.lead.createdAt)
                    return true;
                return prev ? moment(lead.createdAt).isBetween(prev.instance.lead.createdAt, current.instance.lead.createdAt, undefined, '[]') : false;
            },
            {
                componentClass: LeadTileComponent,
                header: 'Leads',
                args: { lead }
            },
            'bottom',
            !list.canLoadMoreBottom
        );
    }

    async commonHandleLeadChanges(changes: RealtimePostgresChangesPayload<Lead>, handleDateFilter = false) {
        const list = handleDateFilter ? this.dateFilterList : this.dynamicList;
        const lastComponent = list.getLastComponent();
        if (changes.eventType === 'INSERT' || changes.eventType === 'UPDATE') {
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if (changes.eventType === 'INSERT') {
                if (userIds && !userIds.includes(changes.new.ownerId))
                    return;

                if (list.canLoadMoreBottom && lastComponent && changes.new.createdAt > lastComponent.instance.lead.createdAt)
                    return;
                
                await this.insertLead(list, changes.new.id);
            } else {
                if (userIds) {
                    if (userIds.includes(changes.new.ownerId) && !list.includesItem((prev, item) => item?.instance.lead.id === changes.new.id)) {
                        await this.insertLead(list, changes.new.id);
                        return;
                    } else if (!userIds.includes(changes.new.ownerId) && list.includesItem((prev, item) => item?.instance.lead.id === changes.new.id)) {
                        list.removeItem('bottom', component => component.instance.lead.id === changes.new.id);
                        return;
                    }
                }
                this.commonHandleUpdate(changes.new, 'id', handleDateFilter);
            }
        } else {
            list.removeItem('bottom', component => component.instance.lead.id === changes.old.id);
            if((list.getTopCount + list.getBottomCount) === 0)
                this.router.navigate(['/lobby', 'schedule', 'all'])
        }
    }

    handleLeadChanges(changes: RealtimePostgresChangesPayload<Lead>) {
        this.commonHandleLeadChanges(changes);
    }

    handleClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.commonHandleUpdate(changes.new, 'leadId');
    }

    handleWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.commonHandleUpdate(changes.new, 'leadId');
    }

    async handleDateFilterLeadChanges(changes: RealtimePostgresChangesPayload<Lead>) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter || changes.eventType !== 'DELETE' && !moment((changes.new as any).createdAt).isBetween(dateFilter.from, dateFilter.to, undefined, '[]'))
            return;
        this.commonHandleLeadChanges(changes, true);
    }

    async handleDateFilterClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleUpdate(changes.new, 'leadId', true);
    }

    async handleDateFilterWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        const dateFilter = await this.dateFilter$.pipe(take(1)).toPromise();
        if (!dateFilter)
            return;
        this.commonHandleUpdate(changes.new, 'leadId', true);
    }

    onItemEvent(event: DynamicListEvent<Lead>) {
        if(event.eventEmitterName === 'tileClick') {
            this.openRequestInvitationForLeadId(event.value);
        } else if(event.eventEmitterName === 'deleteClick') {
            this.openDeleteLeadDialog(event.value);
        }
    }

    async openRequestInvitationForLeadId(lead: Lead) {
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        const router = this.router;
        const data: RequestInvitationModalData = {
            initialData: {
                id: lead.id,
                workflowId: lead.workflowId,
                dialogType: "Lead",
                jobType: lead.jobType,
                client: {
                    businessName: lead.businessName ?? undefined,
                    firstName: lead.firstName,
                    lastName: lead.lastName,
                    phoneNumber: lead.phoneNumber,
                    extNumber: lead.extNumber ?? undefined,
                    address: lead.address,
                    unit: lead.unit ?? undefined,
                    email: lead.email,
                    type: lead.type,
                },
                assignees: [users!.find(user => user.id === lead.ownerId)!],
            },
            showDialogTypeChoice: true,
            onSubmit: ((data: Required<RequestInvitationFormData>) => this.updateLeadOrCreateDocument(lead, data)).bind(this),
        };

        await this.modalsService.open(RequestInvitationModal, {
            data,
            behavior: ModalBehavior.Auto,
            breakpoint: RequestInvitationModal.BREAKPOINT,
        });
    }

    async openDeleteLeadDialog(lead: Lead) {
        const notes = await this.notesService.notesObservable(lead.workflowId).pipe(take(1)).toPromise();
        const clientRestore = await clientRestoreFromDocument(lead);
        const restoreData: LeadRestore = {
            inId: lead.id,
            inClient: clientRestore,
            inCreatedAt: lead.createdAt,
            inJobType: lead.jobType,
            inNotes: notes!,
            inOwnerId: lead.ownerId,
            inWorkflowId: lead.workflowId
        };
        const router = this.router;

        this.modalsService.open(ConfirmationDialog, {
            data: {
                title: 'Delete',
                message: 'Are you sure you want to delete this lead?',
                actionTitle: 'Delete',
                action: async () => {
                    await this.leadsService.deleteLead(lead.id);
                    showSnackbar(this.snackbar, {
                        message: 'Lead deleted',
                        duration: 10000,
                        actionText: 'Undo',
                        action: async () => {
                            await this.leadsService.restoreLead(restoreData);
                            router.navigate(['/lobby', 'schedule', 'leads']);
                        }
                    });
                }
            },
            behavior: ModalBehavior.Dialog,
            breakpoint: RequestInvitationModal.BREAKPOINT,
        });
    }

    async updateLeadOrCreateDocument(original: Lead, data: Required<RequestInvitationFormData>) {
        if(data.dialogType !== 'Lead') {
            await Promise.all([
                this.scheduleService.createDocument(data, original.workflowId),
                this.leadsService.deleteLead(original.id)
            ]);
        } else {
            const updateData: LeadUpdate = {
                inId: original.id,
                inClient: data.client as Client,
                inJobType: data.jobType,
            };
            if(data.assignees)
                updateData.inOwnerId = data.assignees[0].id;
            await this.leadsService.updateLead(updateData);
        }
        return null;
    }

    ngOnDestroy() {
        this.pagination?.destroy();
        this.dateFilterPagination?.destroy();
    }
}
