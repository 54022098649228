<div class="current-invoice-version-block" *ngIf="currentInvoiceVersion">
  <div class="invoice-note" *ngIf="(currentInvoiceVersion.items ?? []).length > 0">
    <mat-form-field appearance="outline" class="textarea w-100" appFormFieldFilled>
            <textarea matInput cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      placeholder="Invoice Note"
                      (focus)="this.noteFocused = true; this.currentNote = currentInvoiceVersion.note ?? ''; editableInFocus.emit(true)"
                      (focusout)="noteFocusout(currentInvoiceVersion.id, currentInvoiceVersion.note); editableInFocus.emit(false)"
                      [(ngModel)]="currentInvoiceVersion.note"
            ></textarea>
    </mat-form-field>
    <div class="note-border-bottom" [class.focused]="noteFocused"></div>
  </div>
  <app-invoice-items-list [invoiceVersion]="currentInvoiceVersion"
                          [invoice]="invoice"
                          [scrollPosition]="scrollPosition"
                          (itemsListFocus)="editableInFocus.emit($event)"
  ></app-invoice-items-list>
</div>
