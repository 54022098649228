import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { DateFilter } from '../models/date-filter.model';

type Constructor<T = {}> = new (...args: any[]) => T;

export function DateFilterMixin<T extends Constructor<{}>>(Base: T) {

  return class extends Base {

    private _selectedDateRange$ = new BehaviorSubject<DateFilter | null>(null);
    selectedDateRange$ = this._selectedDateRange$.pipe(
        distinctUntilChanged((a, b) => {
          if(a === null && b === null)
            return true;
          if(a === null || b === null)
            return false;
          return a.from.getTime() === b.from.getTime() && a.to.getTime() === b.to.getTime();
        }),
    );
    selectedDateRangeForEvent$ = this.selectedDateRange$.pipe(
      map(dates => {
        return dates ? [dates.from, dates.to] as [Date, Date] : null
      }),
    );

    setSelectedDateRange(dates: DateFilter | null) {
      this._selectedDateRange$.next(dates);
    }

    private _selectedDateRangeCompareLeft$ = new BehaviorSubject<DateFilter | null>(null);
    selectedDateRangeCompareLeft$ = this._selectedDateRangeCompareLeft$.asObservable();
    selectedDateRangeForEventCompareLeft$ = this.selectedDateRangeCompareLeft$.pipe(
      map(dates => dates ? [dates.from, dates.to] as [Date, Date] : null),
      distinctUntilChanged(),
      shareReplay(1)
    );

    setSelectedDateRangeCompareLeft(dates: DateFilter | null) {
      this._selectedDateRangeCompareLeft$.next(dates);
    }

    private _selectedDateRangeCompareRight$ = new BehaviorSubject<DateFilter | null>(null);
    selectedDateRangeCompareRight$ = this._selectedDateRangeCompareRight$.asObservable();
    selectedDateRangeForEventCompareRight$ = this.selectedDateRangeCompareRight$.pipe(
      map(dates => dates ? [dates.from, dates.to] as [Date, Date] : null),
      distinctUntilChanged(),
      shareReplay(1)
    );

    setSelectedDateRangeCompareRight(dates: DateFilter | null) {
      this._selectedDateRangeCompareRight$.next(dates);
    }

  };
}

@Injectable({
  providedIn: 'root'
})
export class DateFilterService extends DateFilterMixin(class { }) { }
