import { Directive, Output, EventEmitter } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { BusinessAddressComponents, PlacesService } from '../services/places.service';
import { AddressComponents } from 'projects/common/src/lib/models/address-comments.model';

@Directive({
  selector: '[placeOptionClick]'
})
export class PlaceOptionClickDirective<T extends AddressComponents | BusinessAddressComponents> {

  @Output() placeOptionClick = new EventEmitter<T>();

  constructor(
    private matOption: MatOption,
    private placesService: PlacesService,
  ) { }

  ngAfterViewInit() {
    this.matOption._getHostElement().addEventListener('click', async () => {
      const prediction = this.matOption.value;
      const res = await this.placesService.getPlaceDetails<T>(prediction.place_id, prediction.sessionToken, prediction.type);
      this.placeOptionClick.emit(res);
    });
  }

}
