<div #itemInfo class="main-item-info" *ngIf="show$ | async let show"
     [class.show]="show"
     [ngStyle]="{'top.px': top}"
     id="item-info"
     [class.hide]="scrolling"
>
  <div class="header">
    <h3>Item</h3>
    <div class="edited" *ngIf="edited">Edited</div>
    <div class="edited not-existing" *ngIf="notExisting">Warning!</div>
  </div>

  <div class="px--20 pt--20 pb--28">
    <div class="section">
      <div class="s-title">Item Name</div>
      <div class="s-text">{{poIItem.name}}</div>
    </div>
    <div class="info-section-divider"></div>
    <div class="section mt--12">
      <div class="s-title">Item Description</div>
      <div class="s-text">{{poIItem.description}}</div>
    </div>
    <div class="d-flex justify-content-between mt--24 align-items-center">
      <div class="d-flex gap--10">
        <div class="section width--96">
          <div class="s-title">Price</div>
          <div class="s-text d-flex">
            <div>$</div>
            <div class="fw-600">{{roundAmount(poIItem.price!)}}</div>
          </div>
        </div>
        <div class="section width--96">
          <div class="s-title">Cost</div>
          <div class="s-text d-flex">
            <div>$</div>
            <div class="fw-600">{{roundAmount(poIItem.cost!)}}</div>
          </div>
        </div>
      </div>
      <div class="markup" [class.negative]="+getMarkup(poIItem.price!, poIItem.cost!) < 0">
        <div>Markup</div>
        <div>{{getMarkup(poIItem.price!, poIItem.cost!)}}%</div>
      </div>
    </div>
    <div class="d-flex mt--18 gap--8" *ngIf="poIItem.discountPercent !== 0  || poIItem.discountAmount !== 0">
      <div class="s-title">Discount</div>
      <div class="s-title" style="color: #1B1B1B" *ngIf="poIItem.discountType === 'percent'">
        {{poIItem.discountPercent}}%
      </div>
      <div class="s-title" style="color: #1B1B1B" *ngIf="poIItem.discountType === 'amount'">
        ${{poIItem.discountAmount?.toFixed(2)}}
      </div>
    </div>
    <div class="d-flex mt--16 gap--8" *ngIf="poIItem.taxable">
      <div class="s-title">Sales Tax</div>
      <div class="s-title" style="color: #1B1B1B">{{salesTax}}%</div>
    </div>
    <div class="divider"></div>
    <div class="total">
      <h4>Total</h4>
      <h4>${{getTotal(poIItem)}}</h4>
    </div>
  </div>

</div>
