import { Estimate } from "../../../../business-slick-estimates/src/app/models/estimate.model";
import { Job } from "projects/business-slick-estimates/src/app/models/jobs.model";
import { MakeOptional } from "../services";
import { InvoiceVersion } from "../../../../business-slick-estimates/src/app/models/invoice.model";
import { Client } from "./client.model";
import { ProposalVersion } from "../../../../business-slick-estimates/src/app/models/proposal.model";
import { Lead } from "./lead.model";
import { AddressComponents } from "./address-comments.model";

export interface BaseClientRestore {
  id: number,
  createdAt: Date,
  type: 'personal' | 'business',
  firstName: string,
  lastName: string,
  businessName: string | null,
  phoneNumber: string,
  email: string | null,
  address: AddressComponents,
  unit: string | null,
  extNumber: string | null,
  salesTaxPercentage: number
}

export type LeadClientRestore = BaseClientRestore & {
  leadId: number,
}

export type EstimateClientRestore = BaseClientRestore & {
  estimateId: number,
}


export type JobClientRestore = BaseClientRestore & {
  jobId: number,
}

export type RestoreTypeForDocument<T extends Lead | Estimate | Job> = {
  Lead: LeadClientRestore,
  Estimate: EstimateClientRestore,
  Job: JobClientRestore,
};


export function clientFromDocument(document: Lead): MakeOptional<Client, 'email'>;
export function clientFromDocument(document: Estimate | Job): Client;
export function clientFromDocument<T extends Lead | Estimate | Job>(document: T) {
  if(document.docType === 'Lead')
    return clientFromLead(document);

  if(document.docType === 'Estimate')
    return clientFromEstimate(document);

  return clientFromJob(document);
}

export function clientFromLead(lead: Lead) {
  const client: MakeOptional<Client, 'email'> = {
    firstName: lead.firstName,
    lastName: lead.lastName,
    phoneNumber: lead.phoneNumber,
    extNumber: lead.extNumber,
    address: lead.address,
    unit: lead.unit,
    email: lead.email,
    businessName: lead.businessName,
    type: lead.type,
    salesTaxPercentage: lead.salesTaxPercentage,
  };
  return client;
}


export function clientFromEstimate(estimate: Estimate) {
  const client: Client = {
    firstName: estimate.firstName,
    lastName: estimate.lastName,
    phoneNumber: estimate.phoneNumber,
    extNumber: estimate.extNumber,
    address: estimate.address,
    unit: estimate.unit,
    email: estimate.email,
    businessName: estimate.businessName,
    type: estimate.type,
    salesTaxPercentage: estimate.salesTaxPercentage,
  };
  return client;
}

export function clientFromJob(job: Job) {
  const client: Client = {
      firstName: job.firstName,
      lastName: job.lastName,
      phoneNumber: job.phoneNumber,
      extNumber: job.extNumber,
      address: job.address,
      unit: job.unit,
      email: job.email,
      businessName: job.businessName,
      type: job.type,
      salesTaxPercentage: job.salesTaxPercentage,
  };
  return client;
}

export function clientFromProposalVersion(version: ProposalVersion) {
  const client: MakeOptional<Client, 'salesTaxPercentage'> = {
    firstName: version.clientFirstName,
    lastName: version.clientLastName,
    phoneNumber: version.clientPhoneNumber,
    extNumber: version.clientExtNumber,
    address: version.clientAddress,
    unit: version.clientUnit,
    email: version.clientEmail,
    businessName: version.clientBusinessName,
    type: version.clientType,
  };
  if(version.clientSalesTaxPercentage)
    client.salesTaxPercentage = version.clientSalesTaxPercentage;
  return client;
}

export function clientFromInvoiceVersion(version: InvoiceVersion) {
  const client: Client = {
    firstName: version.clientFirstName,
    lastName: version.clientLastName,
    phoneNumber: version.clientPhoneNumber,
    extNumber: version.clientExtNumber,
    address: version.clientAddress,
    unit: version.clientUnit,
    email: version.clientEmail,
    businessName: version.clientBusinessName,
    type: version.clientType,
    salesTaxPercentage: version.clientSalesTaxPercentage,
  };
  if(version.clientSalesTaxPercentage)
    client.salesTaxPercentage = version.clientSalesTaxPercentage;
  return client;
}

type ClientRestoreTypes = {
  'Lead': LeadClientRestore,
  'Estimate': EstimateClientRestore,
  'Job': JobClientRestore,
};

export async function clientRestoreFromDocument<T extends Lead | Estimate | Job>(document: T) {
  const data: BaseClientRestore = {
    id: document.clientId,
    createdAt: document.clientCreatedAt,
    type: document.type,
    firstName: document.firstName,
    lastName: document.lastName,
    businessName: document.businessName,
    phoneNumber: document.phoneNumber,
    email: document.email,
    address: document.address,
    unit: document.unit,
    extNumber: document.extNumber,
    salesTaxPercentage: document.salesTaxPercentage
  };

  switch (document.docType) {
    case "Lead":
      return { ...data, leadId: document.id } as ClientRestoreTypes[T['docType']];
    case "Estimate":
      return { ...data, estimateId: document.id } as ClientRestoreTypes[T['docType']];
    case "Job":
      return { ...data, jobId: document.id } as ClientRestoreTypes[T['docType']];
  }
}