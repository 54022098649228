import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { UtilsService } from 'projects/common/src/public-api';
import { Observable, Subscription } from 'rxjs';
import { PlaceResult, PlacesService } from '../../../../../common/src/lib/services/places.service';
import { AddressComponents } from 'projects/common/src/lib/models/address-comments.model';

@Component({
  selector: 'app-profile-field',
  templateUrl: './profile-field.component.html',
  styleUrls: ['./profile-field.component.scss']
})
/**
 * Mask and Autocomplete cannot be used together
 */
export class ProfileFieldComponent<T> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @ViewChild('input', {read: NgControl}) addressInputDirective!: NgControl;

  @Input() value?: T;
  @Input() fieldTitle!: string;
  @Input() tooltipMessage?: string;
  @Input() tooltipPosition?: any;
  @Input() tooltipClass?: any;
  @Input() mask?: string;
  @Input() disableEdit?: boolean;
  @Input() alternativeEdit?: boolean;
  @Input() useContent?: boolean;
  @Input() inputmode?: string;
  @Input() initialError?: string;
  @Input() largeField?: boolean;
  @Input() checkValue?: boolean;
  @Input() objectToString?: (value: T | undefined) => string;
  @Input() addressType?: 'address' | 'establishment';

  @Output() changeVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();

  focused = false;
  formGroup = this.formBuilder.group({
    value: this.value
  });
  formSub?: Subscription;


  get valueControl() {
    return this.formGroup.get('value') as FormControl<T>;
  }

  editMode = false;
  loading = false;
  error: string | null = null;

  @Input() onSave?: (value: T) => Promise<string | null>;
  @Input() onCancel?: () => void;

  @Output() onChange = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  visibility: any;

  lastSelectedAddress: AddressComponents | null  = null;
  autocompleteOptions$: Observable<google.maps.places.AutocompletePrediction[] | null> | undefined;

  get placeholder() {
    return this.addressType === 'address' ? 'Enter at least 7 characters for autocomplete' : '';
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private placesService: PlacesService,
  ) {}

  getValue() {
    return this.objectToString ? this.objectToString(this.value) : this.value as string;
  }

  ngOnInit(): void {
    this.formSub = this.valueControl.valueChanges.subscribe(value => {
      this.lastSelectedAddress = null;
      this.onChange.emit(value);
    });
  }

  async ngAfterViewInit() {
    if(this.editMode && this.addressType)
      this.autocompleteOptions$ = (await this.placesService.getPlaceAutocomplete(
        this.input!,
        this.addressType
      ));
  }

  onPlacesOptionSelected(place: PlaceResult) {
    this.addressInputDirective.valueAccessor?.writeValue(this.objectToString!(place as any));
    this.valueControl.setValue(place as T, { emitModelToViewChange: false });

    this.lastSelectedAddress = place as any;
  }

  async save() {
    if (this.onSave) {
      this.loading = true;
      try {
        const value: T = this.valueControl.value;
        this.error = await this.onSave(value);
        if (this.error) {
          this.input?.nativeElement.blur();
          this.valueControl.setErrors({
            error: this.error
          });
        } else {
          this.setEditMode(false);
        }
      } catch (e) {

        console.log(`profile-field.component.ts > ProfileFieldComponent > save()`, e);
      }
      this.loading = false;
    }
  }

  cancel() {
    this.setEditMode(false);
    if (this.onCancel) {
      this.onCancel();
    }
  }

  async setEditMode(edit: boolean) {

    if (this.disableEdit) {
      return;
    }
    if (this.alternativeEdit) {
      this.onEdit.emit();
      return;
    }
    this.onEdit.emit();

    this.editMode = edit;
    if (edit) {
      if(this.addressType) {
        setTimeout(async () => {
          this.autocompleteOptions$ = await (this.placesService.getPlaceAutocomplete(
            this.input!,
            this.addressType!,
          ));
        }, 5);
      }

      if(this.value) {
        if(this.objectToString) {
          setTimeout(() => {
            this.addressInputDirective.valueAccessor?.writeValue(this.objectToString!(this.value));
            this.valueControl.setValue(this.value!, { emitModelToViewChange: false });
          });
        } else {
          this.valueControl.setValue(this.value, {emitEvent: false});
        }
      }

      await this.utilsService.delay(50);
      this.input.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
  }
}
