<div class="item-upload" *ngIf="_modalState$ | async let modalState">

  <div class="header">
    <h3>{{modalState.title}}</h3>
    <app-icon class="se-icon-button" src="close" (click)="close()" *ngIf="modalState.status !== 'loading'"></app-icon>
  </div>

  <div class="init-content" [class.unsupported]="modalState.unsupportedFormat"
       *ngIf="modalState.status === 'init'"
  >
    <h4 *ngIf="modalState.unsupportedFormat" class="unsupported-format">Format not supported</h4>
    <div class="drag-and-drop-area" appDragNDrop (fileDropped)="fileUploaded($event)"
         (dragover)="fileOverDrag = true" (dragleave)="fileOverDrag = false" [class.dragover]="fileOverDrag"
    >
      <h3 class="not-mobile fw-600">Drag & Drop your table here</h3>
      <p class="mobile fw-600">Upload your table here</p>
      <div class="support-text">We support XLS & CSV</div>
      <div class="divider">
        <div class="line"></div>
        <div>Or</div>
        <div class="line"></div>
      </div>
      <div class="choose-button" (click)="fileInput.click()">Choose file</div>
      <input #fileInput type="file" class="hide" accept=".xls,.xlsx,.csv"
             (change)="fileUploaded($any($any($event.target).files)[0])"
      />
    </div>
  </div>

  <div class="success-content" *ngIf="modalState.status === 'success'">
    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="text">Uploaded successfully {{modalState.createdItemsCount}} items</div>
    </div>
    <div class="button-block">
      <div></div>
      <div class="close" (click)="close()">Close</div>
    </div>
  </div>

  <div class="fail-content" *ngIf="modalState.status === 'fail'">
    <div class="issue">{{modalState.error}}</div>
    <div class="caption">(Make sure the table filled properly)</div>
    <div class="example-button" (click)="openExample()">See example</div>
  </div>

  <div class="loading-content" *ngIf="modalState.status === 'loading'">
    <app-dots-spinner size="small"></app-dots-spinner>
  </div>

</div>
