import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Injectable,
  OnInit, Optional,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { map, pairwise, shareReplay, startWith, take } from 'rxjs/operators';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { UtilsService } from 'projects/common/src/public-api';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import moment, { Moment } from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TimetableService } from '../../services/timetable.service';
import { AvailabilityItem, UserTimetable } from '../../models/user-timetable.model';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
  MatCalendarCellClassFunction
} from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { AvailabilityService } from '../../services/availability.service';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

const MY_DATE_FORMATS = {
  display: {
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const TIMETABLE_DIALOG_TYPES = ['Available', 'Unavailable'] as const;
export type TimetableDialogTab = typeof TIMETABLE_DIALOG_TYPES[number];
export interface TimetableDialogData {
  setUnavailableTab?: boolean;
  selectedDate?: Date
}


@Injectable()
class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

@Component({
  selector: 'app-timetable-dialog',
  templateUrl: './timetable-dialog.component.html',
  styleUrls: ['./timetable-dialog.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
})
export class TimetableDialogComponent implements OnInit, AfterViewInit {
  tabs = Array.from(TIMETABLE_DIALOG_TYPES);
  namesOfDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  expandStates = this.namesOfDays.map(() => false);
  saving = false;
  formWorkingHours = this.formBuilder.group({});
  formAvailability = this.formBuilder.group({items: this.formBuilder.array([])});
  formAvailabilityItems = this.formAvailability.get('items') as FormArray<FormGroup>;
  selectedDate: Date | null = null;
  currentTimetable: UserTimetable | null = null;
  minDate = new Date();
  currentMonthUnavailable: AvailabilityItem[] = [];
  startDate = moment(this.data?.selectedDate ?? new Date()).startOf('day').toDate();

  tabletScreen$ = this.utilsService.onScreenBreakpointChange('sm');
  selectedTabSubject = new BehaviorSubject<string>(this.tabs[0]);
  initialTimetable$ = this.timetableService.currentUserTimetable$.pipe(
    startWith(null),
    pairwise(),
    map(([prev, curr]) => {
      if (!prev && curr)
        setTimeout(() => this.initListeners());
      return curr;
    }),
    shareReplay()
  );

  @ViewChild('calendar') calendar!: MatCalendar<DateRange<Date>>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalsService: ModalsService,
    private utilsService: UtilsService,
    private timetableService: TimetableService,
    private formsService: FormsService,
    private changeDetector: ChangeDetectorRef,
    private snackbar: MatLegacySnackBar,
    private availabilityService: AvailabilityService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: TimetableDialogData,
  ) {
  }


  initInputData() {
    if (this.data && this.data.setUnavailableTab && this.data.selectedDate) {
      this.selectedTabSubject = new BehaviorSubject<string>(this.tabs[1]);
    }
    this.onSelectedChange(this.startDate);
  }

  async ngOnInit() {
    for (let day in this.namesOfDays) {
      this.formWorkingHours.addControl(`${day}`, this.formBuilder.group({
        ranges: this.formBuilder.array([]),
        checkbox: new FormControl(false)
      }));
    }
    await this.initForms();
    this.currentTimetable = await this.initialTimetable$.pipe(take(1)).toPromise();
    this.changeDetector.detectChanges();
  }

  async ngAfterViewInit() {
    await this.updateCurrentMonthUnavailability(this.startDate);
    this.initInputData();
    this.calendar?.updateTodaysDate();
  }

  async updateCurrentMonthUnavailability(date?: Date) {
    const periodElement = document.querySelector('.mat-calendar-period-button > .mdc-button__label');
    if (!periodElement?.textContent && !date)
      return;

    const start = date ? moment(date).startOf('month') : moment(Date.parse(periodElement!.textContent!));
    const end = moment(date ?? start).endOf('month');
    this.currentMonthUnavailable = await this.availabilityService.getUnavailabilityForRange(start, end);
    this.calendar?.updateTodaysDate();
  }

  initListeners() {
    document.querySelector('.mat-calendar-previous-button')?.addEventListener('click', () => this.updateCurrentMonthUnavailability());
    document.querySelector('.mat-calendar-next-button')?.addEventListener('click', () => this.updateCurrentMonthUnavailability());
  }

  onSelectedChange(date: Date | null): void {
    if (!date)
      return;

    this.selectedDate = moment(date).toDate();
    this.setAvailabilityForm();
  }

  async setAvailabilityForm() {
    if (!this.selectedDate) {
      return;
    }
    this.formAvailabilityItems.clear({emitEvent: true});
    
    const entries = this.currentMonthUnavailable.filter(entry => moment(entry.startTime).startOf('day').isSame(moment(this.selectedDate).startOf('day')));
    if (entries.length) {
      for (let entry of entries) {
        const rangeGroup = this.formBuilder.group({
          start: new UntypedFormControl(new Date(new Date(0).setHours(entry.startTime.getHours(), entry.startTime.getMinutes()))),
          end: new UntypedFormControl(new Date(new Date(0).setHours(entry.endTime.getHours(), entry.endTime.getMinutes()))),
          description: new UntypedFormControl(entry.description),
          itemRef: new UntypedFormControl()
        });
        this.formAvailabilityItems.push(rangeGroup);
      }
    } else {
      this.newAvailabilityItem();
    }
    setTimeout(() => {
      document.querySelector('.availability-items')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    });
  }


  get availabilityControls() {
    return this.formAvailabilityItems?.controls;
  }

  newAvailabilityItem(index?: number) {
    const rangeGroup = this.formBuilder.group({
      start: new UntypedFormControl(),
      end: new UntypedFormControl(),
      description: new UntypedFormControl(),
      itemRef: new UntypedFormControl([]),
    });
    index !==undefined ? this.formAvailabilityItems.insert(index + 1, rangeGroup) : this.formAvailabilityItems.push(rangeGroup);
    if (index !== undefined) {
      setTimeout(() => {
        const formBottom = (document.querySelector('.availability-form') as HTMLElement).getBoundingClientRect().bottom;
        const item = document.querySelectorAll('.availability-item').item(index + 1) as HTMLElement;
        const itemBottom = item.getBoundingClientRect().bottom;
        if (itemBottom > formBottom) {
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          });
        }
      });
    }
    return rangeGroup;
  }

  deleteAvailabilityItemClick(index: number) {
    if (!this.selectedDate) {
      return;
    }
    const controls = this.formAvailabilityItems.at(index).controls;
    if (this.formAvailabilityItems.length === 1) {
      if (!controls.start.value && !controls.end.value) {
        this.selectedDate = null;
      } else {
        controls.start.setValue(null);
        controls.end.setValue(null);
        controls.description?.setValue('');
      }
      return;
    }
    this.formAvailabilityItems.removeAt(index);
    this.calendar.updateTodaysDate();
  }

  isDateAvailable(date: Date | Moment) {
    return !!this.formWorkingHours.get([`${moment(date).day()}`, 'checkbox'])?.value;
  }

  hasDateItems(date: Date | Moment) {
    return !!this.currentMonthUnavailable.find((item) => moment(date).isSame(moment(item.startTime).startOf('day')));
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view !== 'month') {
      return '';
    }
    const date = cellDate instanceof Date ? moment(cellDate) : cellDate;

    if (date.isBefore(moment().startOf('day'))) {
      return '';
    }
    if (this.hasDateItems(date)) {
      return 'has-items';
    }
    return this.isDateAvailable(date) ? 'available' : 'unavailable';
  }

  setAvailableRange(day: number | string, index: number | null, range?: TimeRange) {
    const rangeGroup = this.formBuilder.group({
      start: new UntypedFormControl(range?.startTime),
      end: new UntypedFormControl(range?.endTime),
    });
    const ranges = (this.formWorkingHours.get([day, 'ranges']) as FormArray<UntypedFormGroup>)!;
    index !== null ? ranges.insert(index + 1, rangeGroup) : ranges.push(rangeGroup);
    if (index !== null) {
      setTimeout(() => {
        const formBottom = (document.querySelector('.working-hours-form') as HTMLElement).getBoundingClientRect().bottom;
        const item = document.querySelectorAll('.working-hours-form .tile').item(+day).querySelectorAll('.range-item').item(index + 1);
        const itemBottom = item.getBoundingClientRect().bottom;
        if (itemBottom > formBottom) {
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          });
        }
      });
    }
    this.formWorkingHours.get([day, 'checkbox'])!.setValue(true);
  }

  deleteAvailableRange(day: number, index: number) {
    (this.formWorkingHours.get([day, 'ranges']) as FormArray<UntypedFormGroup>)!.controls.splice(index, 1);
  }

  getAvailableRanges(day: number) {
    return (this.formWorkingHours.get([day, 'ranges']) as FormArray<UntypedFormGroup>).controls;
  }

  async initForms() {
    const timetable = await this.initialTimetable$.pipe(take(1)).toPromise();
    if (!timetable) {
      return;
    }
    for (let day in timetable.ranges) {
      if (timetable.ranges[day]) {
        for (let range of timetable.ranges[day]!) {
          this.setAvailableRange(day, null, range);
        }
      }
    }
  }

  availableCheckboxChange(checkbox: MatCheckboxChange, day: number) {
    this.calendar.updateTodaysDate();
    if (!checkbox.checked || this.getAvailableRanges(day).length > 0) {
      if (checkbox.checked) {
        this.setExpand(day, false);
        this.toggleExpand(day);
      }
      return;
    }
    this.setAvailableRange(day, null, { startTime: this.timetableService.defaultAvailableTimetableStart, endTime: this.timetableService.defaultAvailableTimetableEnd });
    if (checkbox.checked) {
      this.toggleExpand(day);
    }
  }

  hasMultipleAvailableRanges() {
    for (let day in this.namesOfDays) {
      if ((this.formWorkingHours.get([day, 'ranges']) as FormArray).controls.length > 1) {
        return true;
      }
    }
    return false;
  }

  noTimeRangesOverlap(ranges: AbstractControl[]) {
    if (ranges.length === 1) {
      return true;
    }
    let valid = true;
    for (let range of ranges) {
      const startControl = range.get('start')!;
      const endControl = range.get('end')!;
      const start = startControl.value as Date;
      const end = endControl.value as Date;
      const isOverlap = ranges.some((currentRange) => {
        const currentStart = currentRange.get('start')!.value as Date;
        const currentEnd = currentRange.get('end')?.value as Date;
        return range !== currentRange && !(start >= currentEnd || end <= currentStart);
      });
      if (isOverlap) {
        startControl.setErrors({rangeOverlap: true});
        endControl.setErrors({rangeOverlap: true});
        valid = false;
      }
    }
    return valid;
  }

  isWorkingHoursFormValid() {
    this.formWorkingHours.markAllAsTouched();
    let valid = true;
    for (let day in this.namesOfDays) {
      const dayTimetable = this.formWorkingHours.get(day);
      const available = dayTimetable?.get('checkbox')!.value as boolean;
      const ranges = (dayTimetable?.get('ranges') as FormArray).controls;
      if (!available || !ranges.length) {
        continue;
      }
      for (let range of ranges) {
        const startControl = range.get('start')!;
        const endControl = range.get('end')!;
        this.formsService.resetErrors(startControl);
        this.formsService.resetErrors(endControl);
        if (!this.formsService.validateRequired(startControl, endControl) || !this.isTimeRangeValid(startControl, endControl)) {
          valid = false;
          this.setExpand(+day, true);
          continue;
        }
      }
      // if (valid && !this.noTimeRangesOverlap(ranges)) {
      //   valid = false;
      //   this.setExpand(+day, true);
      // }
    }
    if (!valid && this.selectedTabSubject.value !== this.tabs[0]) {
      this.selectedTabSubject.next(this.tabs[0]);
    }

    return valid;
  }

  isAvailabilityFormValid() {
    let valid = true;
    this.formAvailability.markAllAsTouched();
    if (this.isAvailabilityFormEmpty()) {
      return true;
    }
    for (let i in this.formAvailabilityItems.controls) {
      const startControl = this.formAvailabilityItems.at(+i).get('start')!;
      const endControl = this.formAvailabilityItems.at(+i).get('end')!;
      this.formsService.resetErrors(startControl);
      this.formsService.resetErrors(endControl);
      const startValid = this.formsService.validateRequired(startControl);
      const endValid = this.formsService.validateRequired(endControl);
      if (!startValid || !endValid) {
        valid = false;
        continue;
      }

      const now = new Date();
      const date = this.selectedDate!;
      // No need to check past since we don't allow to select past
      const isToday = date.getDate() === now.getDate() &&
          date.getMonth() === now.getMonth() &&
          date.getFullYear() === now.getFullYear()
      if (!this.isTimeRangeValid(startControl, endControl, isToday)) {
        valid = false;
        continue;
      }
    }
    if (valid && !this.noTimeRangesOverlap(this.formAvailabilityItems.controls)) {
      valid = false;
    }
    if (!valid && this.selectedTabSubject.value !== this.tabs[1]) {
      this.selectedTabSubject.next(this.tabs[1]);
    }

    return valid;
  }

  isTimeRangeValid(startControl: AbstractControl, endControl: AbstractControl, checkCurrentTime = false) {
    const start = startControl.value as Date;
    startControl.setErrors(null);
    endControl.setErrors(null);
    if(checkCurrentTime && start < new Date()) {
      startControl.setErrors({ laterThankCurrentTime: true });
      return false;
    }
    const end = endControl.value as Date;
    const valid = start < end;
    if (!valid) {
      startControl.setErrors({laterTime: true});
      endControl.setErrors({laterTime: true});
    } else {
      this.formsService.resetErrors(startControl);
      this.formsService.resetErrors(endControl);
    }
    return valid;
  }

  isAvailabilityFormEmpty() {
    return this.formAvailabilityItems.controls.length === 1
      && !this.formAvailabilityItems.at(0).get('start')!.value
      && !this.formAvailabilityItems.at(0).get('end')!.value
      && !this.formAvailabilityItems.at(0).get('description')!.value;
  }

  close() {
    this.modalsService.close();
  }

  copyTimetable(timetable: UserTimetable) {
    const copiedTimetable: UserTimetable = this.timetableService.emptyTimetable;
    copiedTimetable.userId = timetable.userId;
    copiedTimetable.ids = timetable.ids;
    for (let day in timetable.ranges) {
      const ranges = timetable.ranges[day];
      if (ranges) {
        for (let range of ranges) {
          const newRange = {startTime: new Date(range.startTime), endTime: new Date(range.endTime)};
          copiedTimetable.ranges[day] ? copiedTimetable.ranges[day]?.push(newRange) : copiedTimetable.ranges[day] = [newRange];
        }
      } else {
        copiedTimetable.ranges[day] = null;
      }
    }
    return copiedTimetable;
  }

  async apply(initialTimetable: UserTimetable) {
    const newTimetable: UserTimetable = this.copyTimetable(initialTimetable);
    if (!newTimetable || !(this.isWorkingHoursFormValid() && this.isAvailabilityFormValid())) {
      return;
    }

    for (let day in this.namesOfDays) {
      const dayTimetable = this.formWorkingHours.get(day);
      const isAvailable = dayTimetable?.get('checkbox')!.value as boolean;
      const ranges = (dayTimetable?.get('ranges') as FormArray).controls;
      if (!isAvailable || !ranges.length) {
        newTimetable.ranges[day] = null;
        continue;
      }
      newTimetable.ranges[day] = [];
      for (let range of ranges) {
        const start = range.get('start')!.value as Date;
        const end = range.get('end')!.value as Date;
        newTimetable.ranges[day]?.push({startTime: start, endTime: end});
      }
    }

    // Delete items from timetable if a day of a week has been changed from available to unavailable or backwards
    // for (let day in this.namesOfDays) {
    //   if (!!initialTimetable.ranges[day] !== !!newTimetable!.ranges[day]) {
    //     for (let index = 0; index < newTimetable!.availability.length; index += 1) {
    //       const item = newTimetable!.availability[index];
    //       if (item.startTime.getDay() === +day && !this.datesFromSelectedRange.find((date) => moment(item.startTime).startOf('day').isSame(date))) {
    //         newTimetable!.availability.splice(+index, 1);
    //       }
    //     }
    //   }
    // }

    let availabilityValid = true;
    if (this.formAvailabilityItems?.controls?.length && this.selectedDate !== null) {
      if (!this.isAvailabilityFormEmpty()) {
        const date = moment(this.selectedDate);
        const userEvents = await this.availabilityService.getUserEvents(date);

        for (let i = 0; i < this.formAvailabilityItems.controls.length; i += 1) {
          const startControl = this.formAvailabilityItems.at(i).get('start')!;
          const endControl = this.formAvailabilityItems.at(i).get('end')!;
          let description = this.formAvailabilityItems.at(i).get('description')!.value;
          if (typeof description === 'string') {
            description = description.trim();
            this.formAvailabilityItems.at(i).get('description')?.setValue(description);
          }
          const item: AvailabilityItem = {
            startTime: new Date((new Date(startControl.value)).setFullYear(date.year(), date.month(), date.date())),
            endTime: new Date((new Date(endControl.value)).setFullYear(date.year(), date.month(), date.date())),
            description: description,
            available: !this.isDateAvailable(this.selectedDate!)
          };
  
          if (!item.available && !userEvents.every((event) => event.startTime >= item.endTime || event.endTime <= item.startTime)) {
            availabilityValid = false;
            startControl?.setErrors({timetableOverlap: true});
            endControl?.setErrors({timetableOverlap: true});
          }
        }
      }
    }

    if (!availabilityValid) {
      if (this.selectedTabSubject.value !== this.tabs[1]) {
        this.selectedTabSubject.next(this.tabs[1]);
      }
      return;
    }
    this.saving = true;

    try {
      await Promise.all([this.timetableService.updateTimetable(newTimetable), this.applyUnavailability()]);
      showSnackbar(this.snackbar, {
        message: 'Applied successfully',
        duration: 3000,
      });
    } catch (error) {
      console.log(error, 'Timetable not saved to db!');
    }
    await this.updateCurrentMonthUnavailability();
    this.saving = false;
  }

  async applyUnavailability() {
    if (!this.selectedDate)
      return;

    const entries: AvailabilityItem[] = [];
    if (this.formAvailabilityItems?.controls?.length && !this.isAvailabilityFormEmpty()) {
      for (const control of this.formAvailabilityItems.controls) {
        const date = moment(this.selectedDate);
        const startTime = new Date(control.get('start')!.value);
        const endTime = new Date(control.get('end')!.value);
        startTime.setFullYear(date.year(), date.month(), date.date());
        endTime.setFullYear(date.year(), date.month(), date.date());
        const entry: AvailabilityItem = {
          startTime,
          endTime,
          description: control.get('description')!.value,
          available: !this.isDateAvailable(startTime)
        };
        entries.push(entry);
      }
    }
    await this.availabilityService.setUnavailability(this.selectedDate, entries);
  }

  async toggleExpand(day: number) {
    const tabletScreen = await this.tabletScreen$.pipe(take(1)).toPromise();
    if (!tabletScreen) {
      this.expandStates[day] = !this.expandStates[day];
      if (this.expandStates[day]) {
        setTimeout(() => {
          const item = document.querySelectorAll('.working-hours-form .tile').item(day);
          const itemTop = item.getBoundingClientRect().top;
          const itemBottom = item.getBoundingClientRect().bottom;
          const itemHeight = item.getBoundingClientRect().height;
          const form = document.querySelector('.working-hours-form') as HTMLElement;
          const formTop = form.getBoundingClientRect().top;
          const formBottom = form.getBoundingClientRect().bottom;
          const formHeight = form.getBoundingClientRect().height;
          if (itemTop < formTop || itemHeight > formHeight) {
            item.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          } else if (itemBottom > formBottom) {
            item.scrollIntoView({
              behavior: 'smooth',
              block: 'end'
            });
          }
        })
      }
    }
  }

  async setExpand(day: number, value: boolean) {
    const tabletScreen = await this.tabletScreen$.pipe(take(1)).toPromise();
    if (!tabletScreen) {
      this.expandStates[day] = value;
    }
  }

  refreshCalendar() {
    //Fixing of month name showing bug (lowercase first letter) on iOS
    const element = document.querySelector('.calendar .mdc-button__label');
    element?.classList.add('d-none');
    element?.classList.remove('d-none');
  }

}
