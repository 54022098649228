import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProposalsService, ProposalVersionCreate } from "../../services/proposals.service";
import { debounce, distinctUntilChanged, map, pairwise, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { BehaviorSubject, combineLatest, timer } from "rxjs";
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { Client } from "../../../../../common/src/lib/models";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { ConfirmationDialog } from "../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component";
import { UsersService } from "../../services/users.service";
import { BusinessService } from "../../services/business.service";
import { UtilsService } from 'projects/common/src/public-api';
import { ChatService, unseenIndicatorDebounceMs } from '../../services/chat.service';
import { ChatComponent } from "../../components/chat/chat.component";
import { NavigationService } from '../../services/navigation.service';

const tabs = ['Proposal', 'Notes', 'Chat'] as const;
const sideTabs = tabs.slice(1);
type MainTab = typeof tabs[number];
type SideTab = Exclude<MainTab, 'Proposal'>;

// noinspection ES6BindWithArrowFunction
@Component({
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class ProposalComponent implements OnDestroy {

  @ViewChild('chat') chat!: ChatComponent;

  businessId$ = this.businessService.selectedBusiness$.pipe(
      map(business => business!.businessId)
  );

  currentUser$ = this.usersService.currentUser$;

  differentInFocus$ = new BehaviorSubject<boolean>(false);
  currentProposalVersionItemsLength$ = new BehaviorSubject(0);
  user$ = this.authService.user$;
  currentProposalVersionId$ = new BehaviorSubject(1);
  scrollPosition: number = 0;
  preview: boolean = false;
  sendLoading: boolean = false;
  actions = ['New', 'Duplicate'];
  addedNewVersion = false;

  params$ = this.route.params.pipe(
    map(params => {
      return {
        proposalId: +params['proposalId'],
        workflowId: +params['workflowId']
      }
    })
  );

  proposal$ = this.params$.pipe(
    switchMap(params => {
      return this.proposalsService.proposalObservable(params.proposalId, params.workflowId).pipe(
        startWith(null),
        distinctUntilChanged(),
        pairwise(),
        map(([prevProposal, currProposal], index) => {
          if (!currProposal) {
            if (prevProposal) {
              this.router.navigate(['/lobby/proposals/won']).then();
            }
            return null;
          }
          if (index === 0) {
            const versionId = this.proposalsService.findHighestVersionId(currProposal!.versions);
            this.currentProposalVersionId$.next(versionId);
          }

          return currProposal;
        })
      );
    }),
    shareReplay(1),
  );

  proposalVersions$ = this.proposal$.pipe(
      map(proposal => proposal!.versions),
      startWith([] as ProposalVersion[]),
      pairwise(),
      map(([_, versions]) => versions)
  );

  currentProposalVersion$ = this.currentProposalVersionId$.pipe(
    switchMap(currentProposalVersionId => {
      return this.proposalVersions$.pipe(
        map(versions => {
          if (this.addedNewVersion) {
            this.addedNewVersion = false;
            const id = this.proposalsService.findHighestVersionId(versions);
            this.currentProposalVersionId$.next(id);
          }
          const currentProposalVersion = versions.find(proposalVersion => proposalVersion.id === currentProposalVersionId);
          if (currentProposalVersion?.items)
            this.currentProposalVersionItemsLength$.next(currentProposalVersion!.items.length);

          return currentProposalVersion;
        })
      );
    })
  );

  largeScreen$ = this.utilsService.onScreenBreakpointChange('lg');
  selectedTabSubject = new BehaviorSubject<MainTab>('Proposal');
  selectedSideTabSubject = new BehaviorSubject<SideTab>('Notes');
  selectedTab$ = combineLatest([this.largeScreen$, this.selectedTabSubject, this.selectedSideTabSubject]).pipe(
    map(([largeScreen, mainTab, sideTab]) => largeScreen ? sideTab : mainTab)
  );
  
  showInfo$ = combineLatest([this.largeScreen$, this.selectedTab$]).pipe(
    map(([largeScreen, selectedTab]) => {
      return largeScreen || selectedTab === 'Proposal';
    })
  );

  showNotes$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Notes')
  );

  showChat$ = this.selectedTab$.pipe(
    map((selectedTab) => selectedTab === 'Chat')
  );

  tabs$ = this.largeScreen$.pipe(
    map(largeScreen => (largeScreen ? sideTabs : tabs) as string[])
  );

  chat$ = this.params$.pipe(
    map(params => params.workflowId),
    switchMap(workflowId => this.chatService.chatObservable(workflowId, true)),
    shareReplay(1)
  );

  tabsRedDots$ = combineLatest([
    this.chat$.pipe(
      map(chat => !!chat?.hasUnseenMessages),
      startWith(false),
      debounce(hasUnseen => timer(hasUnseen ? unseenIndicatorDebounceMs : 0))
    ),
    this.tabs$
  ]).pipe(map(([chatHasUnseenMessages, tabs]) => tabs.map(tab => tab === 'Chat' && chatHasUnseenMessages)));

  chatSubject$ = combineLatest([this.chat$, this.currentProposalVersion$]).pipe(
    map(([chat, version]) => chat ? chat.subject : `CN: ${version?.clientFirstName} ${version?.clientLastName}`)
  );

  async setActiveTab(tab: string) {
    const largeScreen = await this.largeScreen$.pipe(take(1)).toPromise();
    largeScreen ? this.selectedSideTabSubject.next(tab as SideTab) : this.selectedTabSubject.next(tab as MainTab);
  }

  clientUpdateFunction = async (currentProposalVersionId: number, form: Client): Promise<any> => {
    await this.proposalsService.updateProposalVersionClient(
      currentProposalVersionId,
      form
    )
  }

  constructor(
    private authService: AuthService,
    private proposalsService: ProposalsService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private utilsService: UtilsService,
    private chatService: ChatService,
    private navigationService: NavigationService,
  ) {
    this.navigationService.setStateQueryParam();
  }

  async ngOnDestroy() {
    const modalComponent = await this.modalsService.modalComponent$.pipe(take(1)).toPromise();
    if (!modalComponent)
      this.params$.pipe(take(1)).toPromise()
        .then(params => this.proposalsService.cleanUpProposal(params.proposalId));
  }

  async back() {
    this.navigationService.goBack();
  }

  get backButtonCaption() {
    return this.navigationService.fromReports ? 'Back' : 'Close';
  }

  async handleVersionPickerAction(action: typeof this.actions[number]) {
    switch (action) {
      case 'New':
        await this.createProposalVersion();
        break;
      case 'Duplicate':
        await this.duplicateProposalVersion();
        break;
      default:
        break;
    }
  }

  setCurrentVersion(proposalVersion: ProposalVersion) {
    this.preview = false;
    this.currentProposalVersionId$.next(proposalVersion.id);
    this.currentProposalVersionItemsLength$.next(proposalVersion.items?.length ?? 0);
  }

  async createProposalVersion() {
    const proposal = await this.proposal$.pipe(take(1)).toPromise();
    const currentProposalVersionId = await this.currentProposalVersionId$.pipe(take(1)).toPromise();
    try {
      const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
      const proposalVersion = proposal!.versions.find(version => version.id === currentProposalVersionId)!
      const data: ProposalVersionCreate = {
        inClient: {
          address: proposalVersion.clientAddress,
          businessName: proposalVersion.clientBusinessName,
          email: proposalVersion.clientEmail,
          extNumber: proposalVersion.clientExtNumber,
          firstName: proposalVersion.clientFirstName,
          lastName: proposalVersion.clientLastName,
          phoneNumber: proposalVersion.clientPhoneNumber,
          salesTaxPercentage: proposalVersion.clientSalesTaxPercentage!,
          type: proposalVersion.clientType,
          unit: proposalVersion.clientUnit,
        },
        inCreatedBy: user.id,
        inProposalId: proposal!.id,
        inWorkflowId: proposal!.workflowId,
      };
      await this.proposalsService.createProposalVersion(data);
      this.addedNewVersion = true;
      showSnackbar(this.snackBar, {
        message: "Proposal created",
        duration: 2000,
      });
    } catch (e) {
      console.log(e)
    }
  }

  async duplicateProposalVersion() {
    const proposal = await this.proposal$.pipe(take(1)).toPromise();
    const currentProposalVersion = await this.currentProposalVersion$.pipe(take(1)).toPromise();
    await this.proposalsService.duplicateProposalVersion(proposal!, currentProposalVersion!);
    this.addedNewVersion = true;
    showSnackbar(this.snackBar, {
      message: "Proposal duplicated",
      duration: 2000,
    });
  }

  async sendProposalVersion(
    proposal: Proposal,
    currentProposalVersion: ProposalVersion
  ) {
    this.sendLoading = true;
    let sent = false;

    proposal.versions.forEach(version => {
      if (version.status === 'sent' || version.status === 'accepted')
        sent = true;
    });

    if (!sent) {
      await this.proposalsService.sendProposal(currentProposalVersion.id);
      this.preview = false;
      showSnackbar(this.snackBar, {
        message: "Proposal sent",
        duration: 2000,
      });
    } else {
      await this.modalsService.open(ConfirmationDialog, {
        behavior: ModalBehavior.Dialog,
        disableClose: true,
        data: {
          title: 'New Version',
          message: 'By sending a new version, you are cancelling the last version. The payment balance will appear in the new proposal. Are you sure you want to proceed?',
          actionTitle: 'Yes',
          actionColor: 'warn',
          cancelTitle: 'No',
          action: async () => {
            await this.proposalsService.sendProposal(currentProposalVersion.id);
            this.preview = false;
            showSnackbar(this.snackBar, {
              message: "Proposal sent",
              duration: 2000,
            });
          }
        }
      });
    }
    this.sendLoading = false;
  }

  sidePanelClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.chat?.onMenuClick(event);
  }

  detectChanges() {
    this.changeDetector.detectChanges();
  }
}
