import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../models/menu-items-config';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NavigationService } from '../../services/navigation.service';
import { Location } from "@angular/common";
import { ScheduleService } from '../../services/schedule.service';
import { LobbyService } from '../../services/lobby.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() type?: 'login' | 'signup' | 'menu' | 'only-menu';
  @Input() hideLobby?: boolean;
  @Input() leftAction?: string;

  @Output() backClick = new EventEmitter<void>();

  searchControl = new FormControl<string>('') as FormControl<string>;
  menuItems$ = this.menuService.menuItems$;

  showLobbyOption = true;
  showSearchField = false;
  show = false;

  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private navigationService: NavigationService,
    private scheduleService: ScheduleService,
    private lobbyService: LobbyService,
    private router: Router,
    private window: Window,
    private element: ElementRef,
    private location: Location,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.show = getComputedStyle(this.element.nativeElement).display === 'block';
  }

  async logoClick() {
    this.scheduleService.showCalendarSubject.next(false);
    this.lobbyService.setSelectedDateRange(null);
    const loggedIn = !!(await this.authService.user$.pipe(take(1)).toPromise());
    if (this.type === 'menu' || this.type === 'login' && loggedIn)
      this.router.navigate(['/']);
    else
      this.window.location.href = 'https://slickestimates.com';
  }

  onBackClick() {
    this.scheduleService.showCalendarSubject.next(false);
    if(this.backClick.observers.length > 0) {
      this.backClick.emit();
    } else {
      this.location.back();
    }
  }

  async ngOnInit()  {
    this.show = getComputedStyle(this.element.nativeElement).display === 'block';
    const [ _, page, name ] = await this.navigationService.url$.pipe(take(1)).toPromise();
    if(page === 'customer-list') {
      const [ firstName, lastName ] = decodeURIComponent(name).split('+');
      if(firstName && lastName) {
        this.searchControl.setValue(firstName + ' ' + lastName, {emitEvent: false});
      }
    }
    
    if (this.hideLobby === true)
      this.showLobbyOption = false;
    else
      this.showLobbyOption = this.router.url.split('/')[1] !== 'lobby';
    this.showSearchField = this.router.url.split('/')[1] === 'lobby'
      || this.router.url.split('/')[1] === 'customer-list'
  }

  onSearchSuggestionClick(suggestion: any) {
    const name = suggestion ? suggestion.name : null;
    this.searchControl.setValue(name, {emitEvent: false});
    // this.searchService.next(name);
  }

  logout() {
    this.authService.logout();
  }

}
