import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {

  @Input() icon?: 'checkmark';
  @Input() color?: 'green' | 'red' | 'yellow' | 'grey';

  constructor() { }

}
