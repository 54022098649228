
import { Inject, Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { distinctUntilChanged, switchMap, map, shareReplay, startWith } from 'rxjs/operators';
import moment from 'moment';
import { Moment } from 'moment';
import { SCREEN_BREAKPOINTS } from '../directives/SCREEN_BREAKPOINTS';
import NoSleep from 'nosleep.js';
import { DOCUMENT } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { NgxMaskService } from "ngx-mask";
import { TimeRange } from '../models/time-range.model';

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type SeparatedItems<T> = { [date: string]: T[] };

export type Breakpoint = number | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';

export type MapOf<T> = {
  [id: string]: T
}

export function mapFrom<T>(items: T[], idParam: string, forEach?: (item: T) => void) {
  const map: MapOf<T> = {};
  for (const item of items) {
    if(forEach) {
      forEach(item);
    }
    map[(item as any)[idParam]] = item;
  }
  return map;
}

export function isValidEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9]+(?:[\.\-_+][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[\.\-_+][a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(?:[\.\-_+][a-zA-Z0-9]+)*)+$/;
  return emailRegex.test(email);
}

export function capitalizeFirstChar(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function lowerFirstChar(text: string) {
  return text.charAt(0).toLowerCase() + text.slice(1)
}

export function fileExtension(file: File) {

  const split = file.name.split('.');
  if(split.length > 1)
    return split[split.length - 1];

  if(file.type.startsWith('image/')) {
    switch (file.type) {
      case 'image/jpeg':
        return 'jpg';
      case 'image/png':
        return 'png';
      case 'image/svg':
      case 'image/svg+xml':
        return 'svg';
      case 'image/webp':
        return 'webp';
    }
  }
  throw new Error("Unsupported format");
}

export class Completer<T> {
  public readonly promise: Promise<T>;

  public complete!: (value: (PromiseLike<T> | T)) => void;
  private reject!: (reason?: any) => void;

  public constructor() {
      this.promise = new Promise<T>((resolve, reject) => {
          this.complete = resolve;
          this.reject = reject;
      });
  }
}
type ReplaceValue<T, R, W> = T extends R ? W : T extends object ? { [K in keyof T]: ReplaceValue<T[K], R, W> } : T;

export function deepReplace<T, R, W>(data: T, replace: R, replaceWith: W): ReplaceValue<T, R, W>;
export function deepReplace<T>(data: T, replace: any, replaceWith?: any) {
  if(typeof data === 'object') {
    for(const key in data) {
        if(data[key] === replace) {
          if(replaceWith) {
            data[key] = replaceWith;
          } else {
            delete data[key];
          }

        } else if(typeof data[key] === 'object')
          data[key] = deepDeleteUndefined(data[key], replaceWith);
    }
  }
  return data;
}

export function deepDeleteUndefined(data: any, replaceWith?: any) {
  return deepReplace(data, undefined, replaceWith);
}

export function containsUndefined(data: any) {
  return true;
}

export function timestampsToDate<T>(obj: T): T {
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return (obj as any).map((item: any) => timestampsToDate(item));
    } else {
      if(typeof (obj as any)?.toDate === 'function') {
        return (obj as any).toDate();
      }

      for (const key in obj) {
        obj[key] = timestampsToDate(obj[key]);
      }
      return obj;
    }
  }

  return obj;
}

export function changeTimestampsToDate<T>() {
  return function (source$: Observable<T[]>): Observable<T[]> {
    return source$.pipe(
      map(items => items.map(timestampsToDate))
    );
  }
}

export function separateItemsByDate<T>(dateForItem: (item: T) => string) {
    return function (source$: Observable<T[] | null>): Observable<SeparatedItems<T>> {
        return source$.pipe(
            map(items => {
              const separated: SeparatedItems<T> = {};
                if (!items) {
                  return separated;
                }
                for (const item of items) {
                    // const useDateParam = parseDateParam(dateParam, item);
                    // const date = useDateParam ? dateString((item as any)[useDateParam] ?? null) : 'Not set';
                    const date = dateForItem(item);
                    if (!separated[date]) {
                        separated[date] = [];
                    }
                    separated[date].push(item);
                }

                return separated;
            })
        );
    }
}

export function dateString(startDate: Date | null, endDate?: Date | null, onlyStart?: boolean) {
  if(!startDate) {
    return 'Not set';
  }

  if (endDate && !onlyStart) {
    const formattedStartDate = moment(startDate).format('ddd, MMM D, YYYY');
    const formattedEndDate = moment(endDate).format('ddd, MMM D, YYYY');

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (formattedStartDate === formattedEndDate) {
        if (formattedStartDate === moment().format('ddd, MMM D, YYYY')) {
            return 'Today';
        }
        if (formattedStartDate === moment(yesterday).format('ddd, MMM D, YYYY')) {
            return 'Yesterday';
        }
        if (formattedStartDate === moment(tomorrow).format('ddd, MMM D, YYYY')) {
            return 'Tomorrow';
        }
    }
    return formattedStartDate;
  } else {
    const createdDate = moment(startDate).format('ddd, MMM D, YYYY');
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (createdDate === moment().format('ddd, MMM D, YYYY'))
      return 'Today';

    if (createdDate === moment(yesterday).format('ddd, MMM D, YYYY'))
      return 'Yesterday';

    return createdDate;
  }
}

export function sortDateHeaders(reversed: boolean, notSetFirst = true) {
  return (a: string, b: string) => {

    if (a === 'Not set')
      return notSetFirst ? 1 : -1;
    if (b === 'Not set')
      return notSetFirst ? -1 : 1;

    if (a === 'Today')
      return -1;
    if (b === 'Today')
      return 1;


    if (a === 'Yesterday')
      return -1;
    if (b === 'Yesterday')
      return 1;

    if (a === 'Tomorrow')
      return -1;
    if (b === 'Tomorrow')
      return 1;

    if (reversed) {
      return (new Date(b)).getTime() - (new Date(a)).getTime();
    }
    return (new Date(a)).getTime() - (new Date(b)).getTime();
  };
}

export function dateToISOStringWithTimezone(date: Date) {
  return moment(date).toISOString(true);
}

export function camelCaseToSnake(str: string) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function snakeCaseToCamel(str: string) {
  return str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
    );
}

export function formatDuration(duration: number | moment.Duration): string {
  const durationMoment = moment.duration(duration);
  const days = durationMoment.days();
  const hours = durationMoment.hours();
  const minutes = durationMoment.minutes();
  const seconds = durationMoment.seconds();

  const formattedMinutes = minutes < 10 ? `0${minutes}m` : `${minutes}m`;
  let formattedSeconds = '';
  if (hours === 0)
    formattedSeconds = seconds < 10 ? `:0${seconds}s` : `:${seconds}s`;

  const formattedDuration = `${days ? days + 'd:' : ''}${hours ? hours + 'h:' : ''}${formattedMinutes}${formattedSeconds}`;

  return formattedDuration;
}

export function formatDurationRange(start: Date | Moment, end: Date | Moment): string {
  return formatDuration(moment.duration(moment(end).diff(start)));
}

export function formatTimeSinceRef(refTime: Date | moment.Moment): string {
  return formatDuration(moment.duration(moment().diff(refTime)));
}

function isScrollable(node: Element) {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return false;
  }
  const style = getComputedStyle(node);
  return ['overflow', 'overflow-y'].some((propertyName) => {
    const value = style.getPropertyValue(propertyName);
    return value === 'auto' || value === 'scroll';
  });
}

export function getScrollParent(node: Element): Element {
  let currentParent = node.parentElement;
  while (currentParent) {
    if (isScrollable(currentParent)) {
      return currentParent;
    }
    currentParent = currentParent.parentElement;
  }
  return document.scrollingElement || document.documentElement;
}

export function hasNonWhitespaceChars(text: string): boolean {
  return text.replace(/<div>\n<\/div>|&nbsp;/g, '').trim().length > 0;
}

export function trimHtmlString(value: string): string {
  return value.replace(/<div>\n<\/div>/g, '\n').trim();
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private initViewportSize?: number;

  viewportWidth$ = fromEvent(this.window!.visualViewport!, 'resize').pipe(
    map(event => {
      const visualViewport = event.target as VisualViewport;
      return visualViewport.width;
    }),
    startWith(this.window!.visualViewport!.width),
    distinctUntilChanged(),
    shareReplay(1)
  );

  virtualKeyboardIsOpen$ = fromEvent(this.window!.visualViewport!, 'resize').pipe(
    switchMap(async event => {
      const visualViewport = event.target as VisualViewport;
      if(this.isAndroid()) {
        return this.initViewportSize && visualViewport.width + visualViewport.height < this.initViewportSize;
      } else if(this.isIOS() && visualViewport.offsetTop !== 0) {
        await new Promise((resolve) => {
          setTimeout(resolve, 500)
        });
      }
      return visualViewport.offsetTop !== 0;
    }),
    distinctUntilChanged()
  );

  browserFocus$ = fromEvent(this.document, 'visibilitychange').pipe(
    map(_ => !this.document.hidden)
  );
  isOnline$ = this.genereateWindowEventStateObservable('online', 'offline');

  noSleep = new NoSleep();

  constructor(
    private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private maskService: NgxMaskService,
  ) {
    this.initViewportSize = this.window!.visualViewport!.width + this.window!.visualViewport!.height;
  }

  onScreenBreakpointChange(size: Breakpoint) {
    const useSize = typeof size === 'string' ? (SCREEN_BREAKPOINTS as any)[size] as number : size;
    return this.viewportWidth$.pipe(
      map(width => {
        return width >= useSize;
      }),
      distinctUntilChanged(),
    );
  }

  genereateWindowEventStateObservable(pos: string, neg: string) {
    return merge<boolean>(
      fromEvent(this.window, pos).pipe(map(() => true)),
      fromEvent(this.window, neg).pipe(map(() => false)),
    ).pipe(distinctUntilChanged(), shareReplay(1));
  }

  hasInternetConnection() {
    return this.window.navigator.onLine;
  }

  delay(milliseconds: number) {
    return new Promise<void>((resolve) => {
      setTimeout((_: any) => {
        resolve();
      }, milliseconds);
    });
  }

  keyboardObservableWithDefault(def: boolean) {
    return this.isMobile() ? this.virtualKeyboardIsOpen$ : of(def);
  }

  isAndroid() {
    return navigator.userAgent.match(/Android/i);
  }

  isIOS() {
    const toMatch = [
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /Macintosh/i,
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  isIPad() {
    const toMatch = [
      /iPad/i,
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  public isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  isChrome() {
    return navigator.userAgent.indexOf("Chrome") > -1;
  }

  isSafari() {
    if(this.isChrome()) {
      return false;
    }
    return navigator.userAgent.indexOf("Safari") > -1;
  }

  getGoogleCalendarLink(title: string, description: string, start: Date, end: Date) {
    const timeOffset = start.getTimezoneOffset();
    const startDate = new Date((new Date(start)).setMinutes(start.getMinutes() - timeOffset)).toISOString().replace(/-|:|\.\d\d\d/g,"");
    const endDate = new Date((new Date(end)).setMinutes(end.getMinutes() - timeOffset)).toISOString().replace(/-|:|\.\d\d\d/g,"");
    return `http://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${description}&location=&trp=false&sprop=&sprop=name:`;
  }

  parseDate(data: {date?: Moment, time?: string, ampm: 'AM' | 'PM'}) {

    if(!data.date || !data.time)
      return null;

    const dateFormat = 'DD/MM/YYYY';
    const strDate = data.date.format(dateFormat);

    return moment(`${strDate} ${data.time} ${data.ampm}`, `${dateFormat} hh:mm A`).toDate();
  }

  maskInput(input: HTMLInputElement, control: AbstractControl, mask: string) {
    let value = input.value.slice(0, mask.length);
    let cursorPos = input.selectionStart || 0;
    const cursorPosAtEnd = cursorPos >= value.length;
    value = this.maskService.applyMask(value, mask);
    control.setValue(value);
    input.value = value;
    if (!cursorPosAtEnd && !value[cursorPos].match(/\d/)) {
      cursorPos += 1;
    }
    input.selectionStart = cursorPosAtEnd ? value.length : cursorPos;
    input.selectionEnd = input.selectionStart;
  }

  checkAndFixTime(timeControl: AbstractControl, ampmControl: AbstractControl) {
    let time = timeControl.value;

    if (!(time && time.length > 0)) {
      return false;
    }

    let hours, minutes;

    if (time.includes(':')) {
      const split = time.split(':');
      hours = +split[0];
      minutes = +split[1];
    } else if (time.length < 3) {
      hours = +time;
      minutes = 0;
    } else if (time.length === 3) {
      hours = +time[0];
      minutes = +(time[1] + time[2]);
    } else {
      hours = +(time[0] + time[1]);
      minutes = +(time[2] + time[3]);
    }

    if (hours === 0) {
      hours = 12;
      ampmControl.setValue('AM', {emitEvent: false});
    }

    if (hours > 12) {
      if (hours > 24) {
        hours = +moment().format('hh');
      } else {
        hours -= 12;
        ampmControl.setValue('PM', {emitEvent: false});
      }
    }

    if (minutes > 59) {
      minutes = +moment().format('mm');
    }

    const hoursStr = `${hours < 10 ? '0' + hours : hours}`;
    const minutesStr = `${minutes < 10 ? '0' + minutes : minutes}`;

    const checkTime = new RegExp('^(0?[1-9]|1[012]):[0-5][0-9]$');
    if (!checkTime.test(`${hoursStr}:${minutesStr}`)) {
      timeControl.setErrors({
        time: true
      });
      return false;
    }

    timeControl.setValue(`${hoursStr}:${minutesStr}`);
    return true;
  }

  isTimeRangeCovered(range: TimeRange, listOfRanges: TimeRange[]) {
    // Sort the list of ranges by their start times
    const sortedRanges = listOfRanges.sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));

    let currentStart = moment(range.startTime).set({ year: 0, month: 0, date: 0});
    const rangeEnd = moment(range.endTime).set({ year: 0, month: 0, date: 0});

    for (const item of sortedRanges) {
        const itemStart = moment(item.startTime).set({ year: 0, month: 0, date: 0});
        const itemEnd = moment(item.endTime).set({ year: 0, month: 0, date: 0});

        // If the current range start is covered by the current item
        if (itemStart.isSameOrBefore(currentStart) && itemEnd.isAfter(currentStart)) {
            // Move the current start to the end of the current item
            currentStart = itemEnd;

            // If the current start is past or equal to the range end, the range is fully covered
            if (currentStart.isSameOrAfter(rangeEnd)) {
                return true;
            }
        }
    }

    // If we finish the loop without covering the entire range, return false
    return false;
  }
  
  randomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  getImageDimensions(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const errorMsg = 'Could not load image';
        const img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = () => reject(errorMsg);
        if (!event.target?.result) {
          reject(errorMsg);
          return;
        }
        img.src = event.target.result.toString();
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  getVideoDimensions(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => resolve({ width: video.videoWidth, height: video.videoHeight });
      video.onerror = () => reject('Could not load video');
      video.src = URL.createObjectURL(file);
    });
  }

  getAudioDurationMs(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const audio = document.createElement('audio');
      audio.preload = 'auto'; // Change preload to 'auto' to force more data to be loaded

      const attemptToGetDuration = () => {
        if (audio.duration !== Infinity) {
          resolve(audio.duration * 1000);
        } else {
          // Load more data
          audio.currentTime = 1e101; // Large time to force loading more data
        }
      };

      audio.onloadedmetadata = attemptToGetDuration;
      audio.onerror = () => reject('Could not load audio file');
      audio.ontimeupdate = attemptToGetDuration;
      audio.src = URL.createObjectURL(file);
    });
  }

  async encrypt(text: string, password = ''): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const keyMaterial = await window.crypto.subtle.importKey(
      'raw',
      encoder.encode(password),
      'PBKDF2',
      false,
      ['deriveKey']
    );
    const key = await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: encoder.encode(password),
        iterations: 1,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-CTR', length: 256 },
      false,
      ['encrypt']
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const encryptedData = await window.crypto.subtle.encrypt(
      { name: 'AES-CTR', counter: iv, length: 64 },
      key,
      data
    );
    const combinedArray = new Uint8Array(iv.length + encryptedData.byteLength);
    combinedArray.set(iv);
    combinedArray.set(new Uint8Array(encryptedData), iv.length);
    return btoa(String.fromCharCode(...combinedArray));
  }

  async decrypt(encryptedText: string, password = ''): Promise<string> {
    const combinedArray = Uint8Array.from(atob(encryptedText), c => c.charCodeAt(0));
    const iv = combinedArray.slice(0, 16);
    const data = combinedArray.slice(16);
    const encoder = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
      'raw',
      encoder.encode(password),
      'PBKDF2',
      false,
      ['deriveKey']
    );
    const key = await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: encoder.encode(password),
        iterations: 1,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-CTR', length: 256 },
      false,
      ['decrypt']
    );
    const decryptedData = await window.crypto.subtle.decrypt(
      { name: 'AES-CTR', counter: iv, length: 64 },
      key,
      data
    );
    return new TextDecoder().decode(decryptedData);
  }

}
