import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SupportChatService } from "../../../../../../common/src/lib/services/support-chat.service";
import { Ticket } from "../../../../../../common/src/lib/models/support-chat.model";
import { UsersService } from "../../../services/users.service";
import { UserProfile } from "../../../../../../common/src/lib/models/user-profile.model";
import { take } from "rxjs/operators";
import { SupportChatComponent } from "projects/common/src/lib/components/support-chat/support-chat.component";
import { BusinessProfile } from "../../../../../../common/src/lib/models/business-profile.model";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit{

  @Input() businessProfile!: BusinessProfile;

  @ViewChild(SupportChatComponent) chatComponent!: SupportChatComponent;

  ticket$!: Observable<Ticket | null>;

  currentUser!: UserProfile;
  sentMessage!: string;

  constructor(
    private usersService: UsersService,
    private chatService: SupportChatService,
  ) { }

  async ngOnInit() {
    this.currentUser = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    this.ticket$ = this.chatService.currentTicketObservable(this.businessProfile.businessId!, this.currentUser.id);
  }

  async sendMessage(message: string) {
    this.sentMessage = message;
    await this.chatService.sendMessage(this.businessProfile.businessId, this.currentUser.id, this.currentUser.id, message);
  }

  async onFileChange(file: File) {
    await this.chatComponent.onFileChange(file);
  }
}
