import { Component, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { UtilsService } from 'projects/common/src/public-api';
import { BusinessService } from '../../services/business.service';

@Component({
  selector: 'app-profile-logo-modal',
  templateUrl: './profile-logo.component.html',
  styleUrls: ['./profile-logo.component.scss']
})
export class ProfileLogoModal implements OnInit {

  isMobile!: boolean;

  change = false;
  loading = false;

  error?: string;

  src$ = this.businessService.selectedBusiness$.pipe(
    map(profile => {
      if(profile?.logo) {
        return profile.logo;
      }
      return null;
    })
  );

  newSrc?: string;
  newFile?: File;

  constructor(
    private utilsService: UtilsService,
    private businessService: BusinessService,
    private modalsService: ModalsService,
  ) {
    this.isMobile = this.utilsService.isMobile();
  }

  ngOnInit(): void {
    
  }

  onChooseFileClick(fileInput: any) {
    fileInput.click();
  }

  fileChosen(file: File) {
    if(file) {
      if(!this.businessService.fileSizeValid(file)) {
        this.error = 'size';
        return;
      }
      if (!this.businessService.fileFormatValid(file)) {
        this.error = 'format';
        return;
      }

      this.error = undefined;

      this.newFile = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const url = event?.target?.result;
        this.newSrc = !url ? undefined : url.toString();
      }
    }
  }

  fileFromEvent(event: any) {
    return event.target.files[0];
  }

  async save() {

    if(!this.utilsService.hasInternetConnection()) {
      this.error = "internet";
      return;
    }

    this.loading = true;
    try {
      await this.businessService.saveLogo(this.newFile!);
      this.error = undefined;
    } catch (e: any) {
      this.error = "unknown";
      console.log(`profile-logo.component.ts > ProfileLogoModal > save()`, e);
    }
    this.loading = false;
    this.modalsService.close();
  }

  async deleteLogo() {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    this.businessService.saveLogo(null);
    this.modalsService.close();
  }

  close() {
    this.modalsService.close();
  }

}
