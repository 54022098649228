<div class="rating-container d-flex flex-column justify-content-center align-items-center h-100">
    <div class="header">
        <h3>Rate support</h3>
    </div>
    <div class="content">
        <p>Resolved in {{ ts }}</p>
        <p>Let us know how was the support you received?</p>
        <div class="stars d-flex" (mouseleave)="hover = false">
            <app-icon *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                      (mouseenter)="hoverSelect(i + 1)"
                      class="star-icon"
                      src="star_empty"
                      [class.selected]="i < selectedRating"
                      [class.hovered]="hover && i < hoveredIndex && !ratingSent"
                      [class.disabled]="ratingSent"
                      (click)="select(i + 1)"

            >
            </app-icon>
        </div>
        <div class="button-container">
            <loader-button *ngIf="selectedRating && !ratingSent" class="button" text="Submit" (onClick)="submitRating()"></loader-button>
            <p *ngIf="ratingSent">Thank You for Your Feedback!</p>
        </div>
    </div>
</div>

