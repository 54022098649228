import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProposalVersion } from "../../models/proposal.model";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ProposalsService } from "../../services/proposals.service";

@Component({
  selector: 'app-proposal-editable',
  templateUrl: './proposal-editable.component.html',
  styleUrls: ['./proposal-editable.component.scss']
})
export class ProposalEditableComponent {

  @Input() proposal: any;
  @Input() currentProposalVersion!: ProposalVersion;
  @Input() scrollPosition: any;

  @Output() editableInFocus = new EventEmitter<boolean>();

  noteFocused: boolean = false;
  currentNote: string = '';

  constructor(
    private snackBar: MatSnackBar,
    private proposalsService: ProposalsService,
  ) { }

  async noteFocusout(currentProposalVersionId: number, note: string | null) {
    this.noteFocused = false;
    if (note !== this.currentNote) {
      try {
        await this.proposalsService.updateProposalNote(
          currentProposalVersionId,
          note ?? ''
        );
        showSnackbar(this.snackBar, {
          message: "Note updated",
          duration: 2000
        });
      } catch (e) {
        alert('Something went wrong')
      }
    }
  }
}
