<div class="page main d-flex flex-column justify-content-start" *ngIf="user$ | async" fadeIn="500" [fadeInDelay]="200">

  <app-toolbar type="menu" class="above-tab" [leftAction]="backButtonCaption" (backClick)="back()">
    <h3>Invoice</h3>
  </app-toolbar>

  <app-mobile-toolbar class="under-tab" [showMenu]="true" [leftAction]="backButtonCaption" (backClick)="back()"></app-mobile-toolbar>

  <ng-container *ngIf="!(largeScreen$ | async)" [ngTemplateOutlet]="tabs"></ng-container>

  <div class="d-flex h-100 container main-layout" *ngIf="invoice$ | async let invoice">

    <div class="invoice-main flex-fill" scroll (scrollPosition)="scrollPosition = $event"
         [class.hide-tab]="!(showInfo$ | async)"
    >
      <div style="min-height: unset">
        <div class="close-and-workflow-bar">
          <app-progress-bar [workflowId]="invoice.workflowId" active="invoice"></app-progress-bar>
        </div>

        <app-version-picker
            [versions]="(invoiceVersions$ | async)!"
            [selectedVersionId]="(currentInvoiceVersionId$ | async)!"
            (versionSelected)="setCurrentVersion($any($event))"
            [actions]="actions"
            (actionClick)="handleVersionPickerAction($event)"
            type="Invoice"
        ></app-version-picker>

        <ng-container *ngIf="currentInvoiceVersion$ | async let currentInvoiceVersion">
          <app-workflow-prepared [workflow]="invoice"
                                 [currentWorkflowVersion]="currentInvoiceVersion"
                                 *ngIf="currentInvoiceVersion.status === 'created' && !preview"
                                 [updateSnackbarMessage]="'Invoice updated'"
                                 [updateFunction]="clientUpdateFunction"
          ></app-workflow-prepared>

          <app-invoice-editable [scrollPosition]="scrollPosition"
                                 [invoice]="invoice"
                                 [currentInvoiceVersion]="currentInvoiceVersion"
                                 *ngIf="currentInvoiceVersion.status === 'created' && !preview"
                                 (editableInFocus)="differentInFocus$.next($event)"
          ></app-invoice-editable>

          <app-invoice-preview-and-send *ngIf="preview" [invoice]="invoice"
                                         [currentInvoiceVersion]="currentInvoiceVersion"
          ></app-invoice-preview-and-send>

          <app-invoice-preview-and-send *ngIf="currentInvoiceVersion.status !== 'created' && !preview"
                                         [invoice]="invoice" [currentInvoiceVersion]="currentInvoiceVersion"
                                         [sentMode]="true"
          ></app-invoice-preview-and-send>
        </ng-container>
      </div>

      <ng-container *ngIf="currentInvoiceVersion$ | async let currentInvoiceVersion">
        <ng-container *ngIf="{ value: differentInFocus$ | async } as differentInFocus">
          <app-invoice-actions [invoice]="invoice"
                               [currentInvoiceVersion]="currentInvoiceVersion"
                               (setCurrentInvoiceVersionId)="currentInvoiceVersionId$.next($event);"
                               (previewEvent)="preview = $event"
                               [preview]="preview"
                               (sendEvent)="sendInvoiceVersion(invoice, currentInvoiceVersion)"
                               [sendLoading]="sendLoading"
                               *ngIf="currentInvoiceVersion?.items?.length! > 0"
                               [editableFocused]="differentInFocus.value ?? false"
          ></app-invoice-actions>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="(largeScreen$ | async) || !(showInfo$ | async)" class="side-panel-wrap" (click)="sidePanelClick($event)">
      <ng-container *ngIf="largeScreen$ | async" [ngTemplateOutlet]="tabs"></ng-container>
      
      <div class="notes-wrap" [class.d-none]="!(showNotes$ | async)">
        <app-notes
          class="notes"
          [workflowId]="(params$ | async)!.workflowId"
          [businessId]="(businessId$ | async)!"
          [currentUser]="(currentUser$ | async)!"
          [isModal]="false"
          [showJobType]="true"
          [showOverlayImageViewer]="true"
          from="Invoice"
        ></app-notes>
      </div>

      <div class="chat-wrap h-100" *ngIf="showChat$ | async">
        <app-chat #chat
          [chat]="chat$ | async"
          [workflowId]="(params$ | async)!.workflowId"
          [subject]="(chatSubject$ | async)!"
        ></app-chat>
      </div>
    </div>
    
  </div>

  <ng-template #tabs>
    <app-tabs
      [class.app-tabs]="!(largeScreen$ | async)"
      linePosition="bottom"
      alignment="center"
      [tabs]="(tabs$ | async)!"
      [activeTab]="(selectedTab$ | async)!"
      (onTabSelect)="setActiveTab($event)"
      [redDots]="(tabsRedDots$ | async)!"
    ></app-tabs>
  </ng-template>

</div>
