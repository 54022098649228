import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class IconsService {

    sources: { [src: string]: string } = {};

    constructor() {}

    async getSource(src: string) {
        if(!this.sources[src]) {
            const response = await fetch(`assets/icons/${src}.svg`);
            this.sources[src] = await response.text();
        }
        return this.sources[src];

    }

}