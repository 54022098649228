import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'image-viewer-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() src!: 'zoom-in' | 'zoom-out' | 'rotate' | 'download' | 'close';

  constructor() { }

  ngOnInit(): void {
  }

}
