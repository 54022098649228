import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { PaymentReportItem } from "../../models/reports.model";
import { currencify } from "../../helpers/common";
import { Router } from "@angular/router";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: 'app-payments-report-tile',
  templateUrl: './payments-report-tile.component.html',
  styleUrls: ['./payments-report-tile.component.scss']
})
export class PaymentsReportTileComponent implements OnInit {

  protected readonly currencify = currencify;

  @Input() item!: PaymentReportItem;
  @Input() kind!: 'All' | 'Pending' | 'Paid';

  @HostBinding('attr.id') id!: string;

  get dividerNeed() {
    return this.element.nativeElement.nextSibling?.nodeName === 'APP-PAYMENTS-REPORT-TILE';
  }

  get checked() {
    return this.reportsService.selectedPaymentIds.has(this.item.wId);
  }

  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private element: ElementRef
  ) { }

  ngOnInit() {
    this.id = `${this.item.wId}_${this.item.id}`;
  }

  async openPayment(wId: number, pId: number) {
    await this.router.navigate([`/payments/${wId}/${pId}`]);
  }

  check(checked: boolean, itemId: any) {
    if (checked && !this.reportsService.selectedPaymentIds.has(itemId))
      this.reportsService.selectedPaymentIds.add(itemId);

    if (!checked && this.reportsService.selectedPaymentIds.has(itemId))
      this.reportsService.selectedPaymentIds.delete(itemId);
  }
}
