import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { LobbyService } from "../../services/lobby.service";
import { DynamicListComponent, DynamicListEvent, PaginationListItem } from "../../components/dynamic-list/dynamic-list.component";
import { EstimateTileComponent } from '../../components/estimate-tile/estimate-tile.component';
import { JobTileComponent } from '../../components/job-tile/job-tile.component';
import { AvailabilityTileComponent } from '../../components/availability-tile/availability-tile.component';
import { Estimate, EstimateStatus, EstimateTileData, EstimateTimeRange } from '../../models/estimate.model';
import { Job, JobStatus, JobTileData, JobTimeRange, JobUser } from '../../models/jobs.model';
import { Availability } from '../../models/availability.model';
import { Pagination, PaginationOptions } from '../../services/pagination.service';
import { BehaviorSubject } from 'rxjs';
import { TimeRange } from 'projects/common/src/lib/models/time-range.model';
import { Client, dateString } from 'projects/common/src/public-api';
import { limitStep } from '../../services/items.service';
import { SupabaseService } from '../../services/supabase.service';
import { ViewAsService } from '../../services/view-as.service';
import { BusinessService } from '../../services/business.service';
import { Workflow } from '../../models/workflow.model';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { ScheduleService, ScheduleTileData } from '../../services/schedule.service';
import { ScheduleItem } from '../../models/schedule.model';
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';

export type ScheduleTileComponent = EstimateTileComponent | JobTileComponent | AvailabilityTileComponent;

function scheduleToPaginationList(schedule: ScheduleItem[]) {
    return schedule.map(item => {
        let componentClass: typeof EstimateTileComponent | typeof JobTileComponent | typeof AvailabilityTileComponent;
        let document: Partial<EstimateTileData> | Partial<JobTileData> | Availability;
        if (item.itemType === 'estimate') {
            componentClass = EstimateTileComponent;
            document = {
                docType: 'Estimate',
                id: item.id,
                businessName: item.businessName,
                firstName: item.firstName,
                lastName: item.lastName,
                startTime :item.startTime,
                endTime: item.endTime,
                jobType: item.jobType,
                ownerFirstName: item.ownerFirstName,
                ownerLastName: item.ownerLastName,
                rangeId: item.rangeId,
                phoneNumber: item.phoneNumber,
                workflowId: item.workflowId,
                status: item.status as EstimateStatus,
            };
        } else if (item.itemType === 'job') {
            componentClass = JobTileComponent;
            document = {
                docType: 'Job',
                id: item.id,
                businessName: item.businessName,
                firstName: item.firstName,
                lastName: item.lastName,
                startTime: item.startTime,
                endTime: item.endTime,
                jobType: item.jobType,
                rangeId: item.rangeId,
                phoneNumber: item.phoneNumber,
                workflowId: item.workflowId,
                status: item.status as JobStatus,
                users: item.users,
            };
        } else {
            componentClass = AvailabilityTileComponent;
            document = {
                docType: 'Personal',
                id: item.id,
                userId: item.userId,
                firstName: item.userFirstName,
                lastName: item.userLastName,
                startTime: item.startTime,
                endTime: item.endTime,
                available: item.available,
                description: item.description,
            };
        }
        return {
            componentClass,
            header: dateString(item.startTime, item.endTime),
            args: { document, showDocType: true }
        };
    });
}

@Component({
  selector: 'app-all-schedule',
  templateUrl: './all-schedule.component.html',
  styleUrls: ['./all-schedule.component.scss']
})
export class AllScheduleComponent implements OnInit, OnDestroy {

    @ViewChild('list') dynamicList!: DynamicListComponent<ScheduleTileComponent>;
    @ViewChild('dateFilterList') dateFilterList!: DynamicListComponent<ScheduleTileComponent>;

    pagination!: Pagination<ScheduleItem>;
    dateFilterPagination!: Pagination<ScheduleItem>;
    loading$ = new BehaviorSubject<boolean>(true);

    firstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    firstHeader$ = this.firstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    filterFirstHeaderRangeSubject = new BehaviorSubject<TimeRange | null>(null);
    filterFirstHeader$ = this.filterFirstHeaderRangeSubject.pipe(map(range => range ? dateString(range?.startTime, range?.endTime): null));
    initialTopItems!: PaginationListItem<ScheduleTileComponent>[];
    initialBottomItems!: PaginationListItem<ScheduleTileComponent>[];
    initialFilterTopItems!: PaginationListItem<ScheduleTileComponent>[];
    initialFilterBottomItems!: PaginationListItem<ScheduleTileComponent>[];
    initialFilterCanLoadMoreTop = true;
    initialFilterCanLoadMoreBottom = true;
    
    dateFilter$ = this.lobbyService.selectedDateRange$.pipe(
        switchMap(async range => {
            if(!range) 
                return null;
            const inLimit = limitStep;
            const options: PaginationOptions = {
                inLimit, 
                inOffset: 0, 
                inStartDate: frontToServerTranslation(range.from), 
                inEndDate: frontToServerTranslation(range.to), 
            };
            const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
            if(userIds)
                options.inUserIds = userIds;
            
            const [past, future] = await Promise.all([
                this.dateFilterPagination.get({...options, in_direction: 'past'}),
                this.dateFilterPagination.get({...options, in_direction: 'future'})
            ]);
            this.filterFirstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
            this.initialFilterTopItems = scheduleToPaginationList(past);
            this.initialFilterBottomItems = scheduleToPaginationList(future);
            this.initialFilterCanLoadMoreBottom = future.length >= inLimit;
            this.initialFilterCanLoadMoreTop = past.length >= inLimit;
            return range;
        }),
        shareReplay({ bufferSize: 1, refCount: true})
    );

    constructor(
        private lobbyService: LobbyService,
        private viewAsService: ViewAsService,
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private scheduleService: ScheduleService,
    ) {}

    async ngOnInit() {
        const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
        this.pagination = new Pagination<ScheduleItem, Estimate | Job | Client | EstimateTimeRange | JobTimeRange | Workflow | JobUser | Availability>(this.supabaseService, 'get_schedule', business.businessId, 'schedule')
            .on<Estimate, 'UPDATE'>('UPDATE', 'estimate', this.handleEstimateChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'estimate_client', this.handleEstimateClientChanges.bind(this))
            .on<EstimateTimeRange>('*', 'estimate_range', this.handleEstimateRangeChanges.bind(this))
            .on<Job, 'UPDATE'>('UPDATE', 'job', this.handleJobChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'job_client', this.handleJobClientChanges.bind(this))
            .on<JobTimeRange>('*', 'job_range', this.handleJobRangeChanges.bind(this))
            .on<JobUser>('*', 'job_user', this.handleJobUserChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleWorkflowChanges.bind(this))
            .on<Availability>('*', 'unavailability', this.handleUnavailabilityChanges.bind(this));

        this.dateFilterPagination = new Pagination<ScheduleItem, Estimate | Job | Client | EstimateTimeRange | JobTimeRange | Workflow | JobUser | Availability>(this.supabaseService, 'get_schedule', business.businessId, 'filtered_schedule')
            .on<Estimate, 'UPDATE'>('UPDATE', 'estimate', this.handleDateFilterEstimateChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'estimate_client', this.handleDateFilterEstimateClientChanges.bind(this))
            .on<EstimateTimeRange>('*', 'estimate_range', this.handleDateFilterEstimateRangeChanges.bind(this))
            .on<Job, 'UPDATE'>('UPDATE', 'job', this.handleDateFilterJobChanges.bind(this))
            .on<Client, 'UPDATE'>('UPDATE', 'job_client', this.handleDateFilterJobClientChanges.bind(this))
            .on<JobTimeRange>('*', 'job_range', this.handleDateFilterJobRangeChanges.bind(this))
            .on<JobUser>('*', 'job_user', this.handleDateFilterJobUserChanges.bind(this))
            .on<Workflow, 'UPDATE'>('UPDATE', 'workflow', this.handleDateFilterWorkflowChanges.bind(this))
            .on<Availability>('*', 'unavailability', this.handleDateFilterUnavailabilityChanges.bind(this));
        
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset: 0 };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const [past, future] = await Promise.all([
            this.pagination.get({...options, in_direction: 'past'}),
            this.pagination.get({...options, in_direction: 'future'})
        ]);

        this.firstHeaderRangeSubject.next(future.length ? { startTime: future[0].startTime, endTime: future[0].endTime } : null);
        this.initialTopItems = scheduleToPaginationList(past);
        this.initialBottomItems = scheduleToPaginationList(future);
        this.setInitialCanLoadMore();
        this.loading$.next(false);
    }

    ngOnDestroy(): void {
        this.pagination.destroy();
        this.dateFilterPagination.destroy();
    }

    setInitialCanLoadMore() {
        if (!this.initialTopItems || !this.initialBottomItems || !this.dynamicList)
            return;

        if(this.initialTopItems.length < limitStep)
            this.dynamicList.canLoadMoreTop = false;
        if(this.initialBottomItems.length < limitStep)
            this.dynamicList.canLoadMoreBottom = false;
    }

    async loadItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const options: PaginationOptions = { inLimit, inOffset, inDirection: at === 'top' ? 'past' : 'future' };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const items = await this.pagination.get(options);
        if(at === 'top') {
            if(items.length < inLimit)
                this.dynamicList.canLoadMoreTop = false;
            this.dynamicList.addAtTop(
                scheduleToPaginationList(items)
            );
        } else {
            if(items.length < inLimit)
                this.dynamicList.canLoadMoreBottom = false;
            this.dynamicList.addAtBottom(
                scheduleToPaginationList(items)
            );
        }
    }
    
    async loadDateFilterItems(at: 'top' | 'bottom', inOffset = 0) {
        const inLimit = limitStep;
        const range = await this.lobbyService.selectedDateRange$.pipe(take(1)).toPromise();
        const options: PaginationOptions = {
            inLimit,
            inOffset,
            inDirection: at === 'top' ? 'past' : 'future',
            inStartDate: frontToServerTranslation(range!.from),
            inEndDate: frontToServerTranslation(range!.to),
        };
        const userIds = await this.viewAsService.selectedUsersIds$.pipe(take(1)).toPromise();
        if(userIds)
            options.inUserIds = userIds;

        const items = await this.dateFilterPagination.get(options);
        if(!this.dateFilterList)
            return;

        if(at === 'top') {
            if(items.length < inLimit)
                this.dateFilterList.canLoadMoreTop = false;
            this.dateFilterList.addAtTop(
                scheduleToPaginationList(items)
            );
        } else {
            if(items.length < inLimit)
                this.dateFilterList.canLoadMoreBottom = false;
            this.dateFilterList.addAtBottom(
                scheduleToPaginationList(items)
            );
        }
    }

    onItemEvent(event: DynamicListEvent<ScheduleTileData>) {
        this.scheduleService.onItemEvent(event, true);
    }

    handleEstimateChanges(changes: RealtimePostgresChangesPayload<Estimate>) {
        this.scheduleService.handleEstimateChanges(this.dynamicList as DynamicListComponent<EstimateTileComponent>, changes, this.firstHeaderRangeSubject, 'All', true);
    }

    handleEstimateClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleEstimateClientChanges(this.dynamicList as DynamicListComponent<EstimateTileComponent>, changes);
    }

    handleEstimateRangeChanges(changes: RealtimePostgresChangesPayload<EstimateTimeRange>) {
        this.scheduleService.handleEstimateRangeChanges(this.dynamicList as DynamicListComponent<EstimateTileComponent>, changes, this.firstHeaderRangeSubject, 'All', true);
    }
    
    handleWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleWorkflowChanges(this.dynamicList  as DynamicListComponent<EstimateTileComponent | JobTileComponent>, changes);
    }

    handleDateFilterEstimateChanges(changes: RealtimePostgresChangesPayload<Estimate>) {
        this.scheduleService.handleDateFilterEstimateChanges(this.dateFilterList as DynamicListComponent<EstimateTileComponent>, changes, this.filterFirstHeaderRangeSubject, 'All', true);
    }

    handleDateFilterEstimateClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleDateFilterEstimateClientChanges(this.dateFilterList as DynamicListComponent<EstimateTileComponent>, changes);
    }

    handleDateFilterEstimateRangeChanges(changes: RealtimePostgresChangesPayload<EstimateTimeRange>) {
        this.scheduleService.handleDateFilterEstimateRangeChanges(this.dateFilterList as DynamicListComponent<EstimateTileComponent>, changes, this.filterFirstHeaderRangeSubject, 'All', true);
    }

    handleJobChanges(changes: RealtimePostgresChangesPayload<Job>) {
        this.scheduleService.handleJobChanges(this.dynamicList as DynamicListComponent<JobTileComponent>, changes, this.firstHeaderRangeSubject, 'All', true);
    }

    handleJobClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleJobClientChanges(this.dynamicList as DynamicListComponent<JobTileComponent>, changes);
    }

    handleJobRangeChanges(changes: RealtimePostgresChangesPayload<JobTimeRange>) {
        this.scheduleService.handleJobRangeChanges(this.dynamicList as DynamicListComponent<JobTileComponent>, changes, this.firstHeaderRangeSubject, 'All', true);
    }

    handleJobUserChanges(changes: RealtimePostgresChangesPayload<JobUser>) {
        this.scheduleService.handleJobUserChanges(this.dynamicList as DynamicListComponent<JobTileComponent>, changes, this.firstHeaderRangeSubject, 'All', true);
    }

    handleDateFilterJobChanges(changes: RealtimePostgresChangesPayload<Job>) {
        this.scheduleService.handleDateFilterJobChanges(this.dateFilterList as DynamicListComponent<JobTileComponent>, changes, this.filterFirstHeaderRangeSubject, 'All', true);
    }

    handleDateFilterJobClientChanges(changes: RealtimePostgresChangesPayload<Client>) {
        this.scheduleService.handleDateFilterJobClientChanges(this.dateFilterList as DynamicListComponent<JobTileComponent>, changes);
    }

    handleDateFilterJobRangeChanges(changes: RealtimePostgresChangesPayload<JobTimeRange>) {
        this.scheduleService.handleDateFilterJobRangeChanges(this.dateFilterList as DynamicListComponent<JobTileComponent>, changes, this.filterFirstHeaderRangeSubject, 'All', true);
    }

    handleDateFilterWorkflowChanges(changes: RealtimePostgresChangesPayload<Workflow>) {
        this.scheduleService.handleDateFilterWorkflowChanges(this.dateFilterList as DynamicListComponent<JobTileComponent | EstimateTileComponent>, changes);
    }
    
    handleDateFilterJobUserChanges(changes: RealtimePostgresChangesPayload<JobUser>) {
        this.scheduleService.handleDateFilterJobUserChanges(this.dateFilterList as DynamicListComponent<JobTileComponent>, changes, this.filterFirstHeaderRangeSubject, 'All', true);
    }

    handleUnavailabilityChanges(payload: RealtimePostgresChangesPayload<Availability>) {
        this.scheduleService.handleUnavailabilityChanges(this.dynamicList as DynamicListComponent<AvailabilityTileComponent>, payload, this.firstHeaderRangeSubject, true);
    }

    handleDateFilterUnavailabilityChanges(payload: RealtimePostgresChangesPayload<Availability>) {
        this.scheduleService.handleDateFilterUnavailabilityChanges(this.dateFilterList as DynamicListComponent<AvailabilityTileComponent>, payload, this.filterFirstHeaderRangeSubject, true);
    }
}
