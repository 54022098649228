import { Injectable } from '@angular/core';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { UserPersonalEvent } from 'projects/common/src/public-api';
import { BusinessService } from './business.service';
import { SupabaseService } from "./supabase.service";
import { TimeRange } from "../../../../common/src/lib/models/time-range.model";
import { Availability, AvailabilityTileData } from '../models/availability.model';
import { PaginationOptions } from "./pagination.service";
import moment from 'moment';
import { UsersService } from './users.service';
import { Job, JobTileData } from '../models/jobs.model';
import { Estimate, EstimateTileData } from '../models/estimate.model';
import { AvailabilityItem } from '../models/user-timetable.model';
import { DateFilter } from "../models/date-filter.model";
import { ScheduleItem } from "../models/schedule.model";

export type UserEvent = TimeRange | UserPersonalEvent;

export interface UsersEvents {
  [userId: string]: UserEvent[]
}
// export interface UnavailabilityCreate {
//   inUserId: number;
//   inStartTime: Date;
//   inEndTime: Date;
//   inDescription?: string;
//   inAvailable?: boolean;
// }

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  constructor(
    private businessService: BusinessService,
    private supabaseService: SupabaseService,
    private usersService: UsersService
  ) { }

  unavailabilityObservable(options: PaginationOptions, pref = '') {
    return this.businessService.selectedBusiness$.pipe(
        switchMap(business => {
          return this.supabaseService.rpc<Availability[]>({
            cName: pref + '_unavailability',
            schema: business!.businessId,
            fn: 'get_unavailability',
            tables: [
              {table: 'unavailability'},
            ],
            options
          });
        }),
        map(availability => {
          if (!availability)
            return [];
          for (const item of availability)
            item.docType = 'Personal';
          return availability;
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  async setUnavailability(dateStart: Date, entries: AvailabilityItem[]) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const inUserId = (await this.usersService.currentUser$.pipe(take(1)).toPromise()).id;

    await this.supabaseService.rpcFunc(business!.businessId, 'set_unavailability', { inUserId, inDateStart: moment(dateStart).startOf('day').toDate(), inEntries: entries });
  }

  async getUnavailability(id: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const unavailable = (await this.supabaseService.rpcFunc<Availability[]>(business!.businessId, 'get_unavailability_by_id', { inId: id }))[0];
    unavailable.docType = 'Personal';
    return unavailable;
  }

  async getUserEvents(date: Date | moment.Moment) {
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
    const userId = (await this.usersService.currentUser$.pipe(take(1)).toPromise()).id;

    const options: PaginationOptions = {
      inStartDate: moment(date).startOf('day').toDate(),
      inEndDate: moment(date).endOf('day').toDate(),
      inUserIds: [userId]
    };

    const promises: Promise<Job[] | Estimate[]>[] = [];

    promises.push(this.supabaseService.rpcFunc<Job[]>(
      business.businessId,
      'get_jobs',
      options
    ));

    promises.push(this.supabaseService.rpcFunc<Estimate[]>(
      business.businessId,
      'get_estimates',
      options
    ));

    return (await Promise.all(promises)).flat().map((event: any) => ({ startTime: event.startTime, endTime: event.endTime }));
  }

  async getUnavailabilityForRange(start: Date | moment.Moment, end?: Date | moment.Moment, userIds?: number[]) {
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;
    const inUserIds = userIds ?? [(await this.usersService.currentUser$.pipe(take(1)).toPromise()).id];

    const options: PaginationOptions = {
      inStartDate: moment(start).startOf('day').toDate(),
      inEndDate: moment(end ?? start).endOf('day').toDate(),
      inUserIds
    };

    return this.supabaseService.rpcFunc<Availability[]>(
      business.businessId,
      'get_unavailability',
      options
    );
  }

  async getScheduleForUsers(ids: number[], dateRange?: DateFilter, noLimit = false) {
    const business = (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!;

    const options: PaginationOptions = {
      inDirection: 'future',
      inUserIds: ids,
      inLimit: noLimit ? undefined : 20
    };
    if(dateRange) {
      options.inStartDate = dateRange.from;
      options.inEndDate = dateRange.to;
    }

    return this.supabaseService.rpcFunc<ScheduleItem[]>(
      business.businessId,
      'get_schedule',
      options
    );
  }
}
