import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { UtilsService } from 'projects/common/src/public-api';

const shortNumberThreshold = 10000;

@Directive({
  selector: '[shortNumber]'
})
export class ShortNumberDirective implements OnChanges {
  @Input() shortNumber!: string | number | null;
  @Input('shortNumberDecimals') decimalsCount = 2;
  @Input('shortNumberTooltip') tooltip!: MatTooltip;

  constructor(
    private el: ElementRef,
    private utilService: UtilsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shortNumber.currentValue === changes.shortNumber.previousValue)
      return;
    const value = changes.shortNumber.currentValue;
    if (value === null) {
      this.el.nativeElement.innerText = '';
      this.tooltip.message = '';
      this.tooltip.disabled = true;
      return;
    }
    this.el.nativeElement.innerText = typeof value === 'number'
      ? this.shortenNumber(value)
      : this.shortenString(value);
    if (this.tooltip) {
      const numValue = typeof value === 'string' ? this.parseValue(value)?.value : value;
      if (!numValue || numValue < shortNumberThreshold) {
        this.tooltip.message = '';
        this.tooltip.disabled = true;
        return;
      }
      this.tooltip.disabled = false;
      this.tooltip.message = value;
      this.tooltip.showDelay = this.utilService.isMobile() ? 0 : 500;
    }
  }

  shortenNumber(value: number): string {
    let result = '';
    const absValue = Math.abs(value);
    if (absValue < shortNumberThreshold) {
      result = value.toFixed(this.decimalsCount);
    } else if (absValue < 1e6) {
      result = (value / 1000).toFixed(this.decimalsCount) + 'K';
    } else if (absValue < 1e9) {
      result = (value / 1e6).toFixed(this.decimalsCount) + 'M';
    } else {
      result = (value / 1e9).toFixed(this.decimalsCount) + 'B';
    }
    return result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  shortenString(value: string): string {
    const parsed = this.parseValue(value);
    if (!parsed)
      return value;
    return value.slice(0, parsed.numStart) + this.shortenNumber(parsed.value) + value.slice(parsed.numEnd);
  }

  parseValue(value: string) {
    const valueArr = value.split('');
    const numStart = valueArr.findIndex(char => !isNaN(+char));
    if (numStart === -1)
      return null;
    let numEnd = valueArr.slice(numStart).findIndex(char => char !== ',' && char !== '.' && isNaN(+char));
    if (numEnd === -1)
      numEnd = valueArr.length;
    return {
      value: +valueArr.slice(numStart, numEnd).filter(char => char !== ',').join(''),
      numStart,
      numEnd
    };
  }

}