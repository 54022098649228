import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, timer } from "rxjs";
import { debounce, take, tap } from "rxjs/operators";
import { ItemsService } from "../../services/items.service";
import { CommonPoIItem } from "../../models/poi.model";

@Component({
  selector: 'app-workflow-item-info',
  templateUrl: './workflow-item-info.component.html',
  styleUrls: ['./workflow-item-info.component.scss']
})
export class WorkflowItemInfoComponent implements OnChanges {

  @Input() poIItem!: CommonPoIItem;
  @Input() index!: number;
  @Input() hoveredItemIndex!: number | null;
  @Input() distanceFromTop!: number;
  @Input() salesTaxPercentage!: number;
  @Input() businessId!: string;

  @ViewChild('itemInfo') itemInfo: any;

  edited: any;
  notExisting: any = true;

  scrolling = false;

  top: any = -302;

  _show$ =  new BehaviorSubject<boolean>(false);
  show$ = this._show$.asObservable().pipe(
    debounce(show => {
      return show
        ? timer(200)
        : timer(0);
    }),
    tap( () => {
      setTimeout((() => {
        if (this.itemInfo)
          if (this.itemInfo.nativeElement.clientHeight) {
            const dist = window.innerHeight - this.distanceFromTop;
            if (dist < 180)
              this.top = 14 - this.itemInfo.nativeElement.clientHeight;
          }
      }), 0);
    })
  );

  get salesTax() {
    return this.salesTaxPercentage;
  }

  constructor(
    private itemsService: ItemsService
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if ('hoveredItemIndex' in changes) {
      this._show$.next(this.hoveredItemIndex === this.index)
      setTimeout((() => {
        this.scrolling = false;
      }).bind(this), 100);
    }
    if ('distanceFromTop' in changes) {
      this.scrolling = true;
      if (this.distanceFromTop < 400)
        this.top = -this.distanceFromTop + 100;
      else
        this.top = -330;
    }

    if ('poIItem' in changes) {
      const item = await this.itemsService.itemByName(this.poIItem.name);
      if (!item) {
        this.notExisting = true;
        this.edited = false;
      } else {
        this.notExisting = false;
        this.edited = item.description !== this.poIItem.description
          || item.price !== this.poIItem.price
          || item.cost !== this.poIItem.cost
          || item.taxable !== this.poIItem.taxable;
      }
    }
  }

  roundAmount(amount: number): string {
    return (Math.round(amount * 100) / 100).toFixed(2);
  }

  getMarkup(price: number, cost: number): string {
    if (!cost || cost === 0)
      return '100';

    const markup = ((price-cost)/cost)*100;
    return (Math.round(markup * 100) / 100).toFixed(0);
  }

  getTotal(workflowItem: CommonPoIItem): string {
    let total = workflowItem.quantity!*workflowItem.price!;
    if (workflowItem.taxable) {
      total += total*this.salesTaxPercentage/100;
    }

    if (workflowItem.discountAmount || workflowItem.discountPercent) {
      if (workflowItem.discountType === 'percent') {
        total -= total*workflowItem.discountPercent!/100;
      } else {
        total -= workflowItem.discountAmount!;
      }
    }

    return (Math.round(total * 100) / 100).toFixed(2).toString();
  }
}
