<div class="form-field search-field" [class.show-search-icon]="showSearchIcon" appFormFieldFilled [class.show-clear-button]="!showSearchIcon && (showCloseButtonAlways || (control.value && control.value.length > 0))"
     [class.full-width]="fullWidth" [class.focused]="focus" [class.filled]="control.value" (click)="onClick($event)">

    <input
        #input
        matInput
        class="flex-fill"
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
        placeholder="Search by name"
        [formControl]="control"
        (focus)="focus = true; focusChange.emit(true)"
        (focusout)="focus = false; focusChange.emit(false)"
    />

    <div class="wrapper flex-fill">
        <img *ngIf="showSearchIcon" class="prefix" src="../../../assets/icons/search.svg">
        <app-icon
                width="20px" height="20px"
                display="inline-flex"
                src="close" style="scale: 1.2" class="se-icon-button no-bg close-button" (click)="clear($event)"
                *ngIf="showCloseButtonAlways || (control.value && control.value.length > 0)"></app-icon>
    </div>


    <mat-autocomplete
        #auto="matAutocomplete"
        class="search-autocomplete"
    >
        <ng-container *ngIf="!(loading$ | async) else loading">
            <mat-option
                        *ngFor="let option of options$ | async"
                        [value]="option"
                        (click)="optionClicked(option)"
            >
                <div class="customer-info">
                    <h4 class="customer_name">{{ option.outFirstName + ' ' + option.outLastName }}</h4>
                    <div class="customer-info__count">
                        <h4>Estimates <h4 class="count">{{ option.outEstimatesCount }}</h4></h4>
                        <h4>Jobs <h4 class="count">{{ option.outJobsCount }}</h4></h4>
                        <h4>Proposals <h4 class="count">{{ option.outProposalsCount }}</h4></h4>
                        <h4>Invoices <h4 class="count">{{ option.outInvoicesCount }}</h4></h4>
                    </div>
                </div>
            </mat-option>
        </ng-container>

        <ng-template #loading>
            <mat-option class="no-results-option" disabled>
                Searching...
            </mat-option>
        </ng-template>

        <ng-template #noResults>
            <mat-option class="no-results-option" *ngIf="searchedOnce && control.value && control.value.length > 0"
                        (click)="trigger.openPanel()" disabled>No results
            </mat-option>
        </ng-template>
    </mat-autocomplete>

</div>
