import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import { Invoice, InvoiceVersion } from "../../models/invoice.model";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { InvoicesService } from "../../services/invoices.service";
import { debounce, filter, map, tap } from "rxjs/operators";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import {
  CreateEditWorkflowItemComponent
} from "../../modals/create-edit-workflow-item/create-edit-workflow-item.component";
import { WorkflowItemInfoMobileComponent } from "../workflow-item-info-mobile/workflow-item-info-mobile.component";
import { CommonPoIItem } from "../../models/poi.model";
import { digitsOnly, onIntegerPaste } from "../../helpers/forms";
import { UsersService } from "../../services/users.service";
import { BusinessService } from "../../services/business.service";
import {
  CreateEditWorkflowItemRestrictedComponent
} from "../../modals/create-edit-workflow-item-restricted/create-edit-workflow-item-restricted.component";
import { ComponentType } from "@angular/cdk/overlay";

@Component({
  selector: 'app-invoice-items-list',
  templateUrl: './invoice-items-list.component.html',
  styleUrls: ['./invoice-items-list.component.scss']
})
export class InvoiceItemsListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  mobileView = false;
  @HostListener('window:resize')
  onResize() {
    this.mobileView = window.innerWidth < 576;
  }

  protected readonly digitsOnly = digitsOnly;
  protected readonly onPaste = onIntegerPaste;

  @Input() invoice!: Invoice;
  @Input() invoiceVersion!: InvoiceVersion;
  @Input() scrollPosition = 0;

  @Output() itemsListFocus = new EventEmitter<boolean>();

  dragging: boolean = false;

  hoveredItemIndex: number | null = null;
  infoIconDistancesFromTop: number[] = [];

  afterViewInit = false;

  qtyFocus$ = new Subject<boolean>();
  qtyFocusSub!: Subscription;

  fullItemAccess$: Observable<boolean> = this.usersService.currentUser$.pipe(
    filter(value => !!value),
    map(userProfile => userProfile.role !== 'user' || userProfile.permissions.includes('edit_items'))
  );

  businessId$ = this.businessService.selectedBusiness$.pipe(
    filter(businessProfile => !!businessProfile),
    map(businessProfile => businessProfile!.businessId)
  );

  constructor(
    private invoicesService: InvoicesService,
    private modalsService: ModalsService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private usersService: UsersService,
    private businessService: BusinessService
  ) {
    this.mobileView = window.innerWidth < 576;
  }

  ngOnInit(){
    this.qtyFocusSub = this.qtyFocus$.pipe(
      tap(focus => {
        this.itemsListFocus.emit(focus);
        if (!focus && this.invoiceVersion.items)
          this.invoiceVersion.items.forEach(item => {
            if (item.quantity === 0 || !item.quantity)
              item.quantity = 1;
          });
      }),
      debounce(focus => {
        return focus ? timer(20) : timer(0);
      })
    ).subscribe(async focus => {
      if (!focus)
        await this.saveInvoiceVersion();
    });
  }

  ngOnDestroy() {
    this.qtyFocusSub.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this.mobileView) {
      if(this.invoiceVersion.items)
        for (let i = 0; i < this.invoiceVersion.items.length; i++)
          this.infoIconDistancesFromTop.push(
            document.getElementById('info-icon-'+i)!.getBoundingClientRect().top
          );
      this.cdr.detectChanges();
      this.afterViewInit = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.invoiceVersion.items)
      return;

    if ('invoiceVersion' in changes) {
      for (let i = 0; i < this.invoiceVersion.items.length; i++) {
        this.reCalcQtyPricePadding('qty-price-'+i);
      }
    }

    if (this.afterViewInit) {
      for (let i = 0; i < this.invoiceVersion.items.length; i++) {
        const top = document.getElementById('info-icon-' + i)?.getBoundingClientRect().top;
        if(top)
          this.infoIconDistancesFromTop[i] = top;
      }
    }
  }

  reCalcQtyPricePadding(elementId: string){
    if (!this.mobileView) {
      setTimeout(() => {
        try {
          const textLength = document.getElementById(elementId)
            ?.getElementsByClassName('item-price')[0].textContent?.length!-4
          const qtyPriceBlock = document.getElementById(elementId);
          if (textLength > 8 && textLength < 10) {
            qtyPriceBlock!.classList.add('sp-s');
          } else if (textLength >= 10 && textLength < 12) {
            qtyPriceBlock!.classList.add('sp-m');
          } else if (textLength >= 12) {
            qtyPriceBlock!.classList.add('sp-l');
          } else {
            qtyPriceBlock!.classList.remove('sp-l');
            qtyPriceBlock!.classList.remove('sp-m');
            qtyPriceBlock!.classList.remove('sp-s');
          }
        } catch (e) {
          console.log('Error during calculation!')
        }
      }, 0);
    }
  }

  getItemPrice(invoiceItem: CommonPoIItem): string {
    if (!invoiceItem.quantity || invoiceItem.quantity === 0) {
      return '0';
    } else {
      let total = invoiceItem.price!;

      if (invoiceItem.discountAmount || invoiceItem.discountPercent) {
        if (invoiceItem.discountType === 'percent') {
          total -= total*invoiceItem.discountPercent!/100;
        } else {
          total -= invoiceItem.discountAmount!;
        }
      }

      if (invoiceItem.taxable) {
        total += total*this.invoiceVersion.clientSalesTaxPercentage!/100;
      }

      total = invoiceItem.quantity!*total;

      return (Math.round(total * 100) / 100).toFixed(2).toString();
    }
  }

  async qtyFieldInput(event: any, invoiceItem: CommonPoIItem, mobile?: boolean, elementId?: string) {
    event.target.value = event.target.value?.replace(/\D/, ''); // Prevent input non-digit chars by codes using ALT key
    setTimeout(async () => {
      invoiceItem.quantity = +event.target.value;
      if (!mobile)
        this.reCalcQtyPricePadding(elementId!);
    }, 10);
  }

  async dropAction(event: any) {
    try {
      moveItemInArray(this.invoiceVersion.items!, event.previousIndex, event.currentIndex);
      this.fixItemsIndexes();
      await this.saveInvoiceVersion();
    } catch (e) {
      alert('Something went wrong during invoice update!');
    }
  }

  async openAddInvoiceItemModal(fullAccess: boolean) {
    await this.modalsService.open(
      (fullAccess ? CreateEditWorkflowItemComponent : CreateEditWorkflowItemRestrictedComponent) as ComponentType<any>,
      {
        behavior: ModalBehavior.Dialog,
        disableClose: true,
        data: {
          salesTaxPercentage: this.invoiceVersion.clientSalesTaxPercentage,
          workflow: this.invoice,
          workflowVersion: this.invoiceVersion,
          mode: 'add',
          updateFunction: (async (invoiceId: number, updateData: Partial<InvoiceVersion>) =>
            this.invoicesService.updateInvoiceVersion(invoiceId, updateData)).bind(this)
        }
      }
    );
  }

  async editItem(item: CommonPoIItem, fullAccess: boolean) {
    await this.modalsService.open(
      (fullAccess ? CreateEditWorkflowItemComponent : CreateEditWorkflowItemRestrictedComponent) as ComponentType<any>,
      {
        behavior: ModalBehavior.Dialog,
        disableClose: true,
        data: {
          salesTaxPercentage: this.invoiceVersion.clientSalesTaxPercentage,
          item: item,
          workflow: this.invoice,
          workflowVersion: this.invoiceVersion,
          mode: 'edit',
          updateFunction: (async (invoiceId: number, updateData: Partial<InvoiceVersion>) =>
            this.invoicesService.updateInvoiceVersion(invoiceId, updateData)).bind(this)
        }
      }
    );
  }
  
  fixItemsIndexes() {
    for (let i = 0; i < this.invoiceVersion.items!.length; i++) {
      this.invoiceVersion.items![i].index = i;
    }
  }

  async duplicateItem(item: CommonPoIItem, index: number) {
    const newItem = { ... item };
    this.invoiceVersion.items?.splice(index + 1, 0, newItem);
    if (index !== this.invoiceVersion.items!.length - 1)
      this.fixItemsIndexes();
    await this.saveInvoiceVersion();
    showSnackbar(this.snackBar, {
      message: 'Item Duplicated',
      duration: 2000
    });
  }

  async deleteItem(index: number) {
    const currentItemsState = JSON.parse(JSON.stringify(this.invoiceVersion.items));
    this.invoiceVersion.items!.splice(index, 1);
    if (index !== this.invoiceVersion.items!.length)
      this.fixItemsIndexes();
    await this.saveInvoiceVersion();
    showSnackbar(this.snackBar, {
      message: 'Item deleted',
      duration: 10000,
      actionText: 'Undo',
      action: (async () => {
        this.invoiceVersion.items = currentItemsState;
        await this.saveInvoiceVersion();
      }).bind(this),
    });
  }

  private async saveInvoiceVersion() {
    await this.invoicesService.updateInvoiceVersion(this.invoiceVersion.id, { items: this.invoiceVersion.items } );
  }

  async openItemInfoMobile(invoiceItem: CommonPoIItem) {
    await this.modalsService.open(WorkflowItemInfoMobileComponent, {
      data: {
        salesTaxPercentage: this.invoiceVersion.clientSalesTaxPercentage,
        proposalItem: invoiceItem
      }
    });
  }
}
