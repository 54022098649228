import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { DateFilter } from '../../models/date-filter.model';
import { Router } from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { pageNameToPath, ReportsPages, reportsPages } from "../../models/navigation.model";
import { currencify } from "../../helpers/common";
import { BehaviorSubject, Observable } from "rxjs";
import { CompareReport } from "../../models/reports.model";
import { filter, map, pairwise, shareReplay, startWith } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';

@Component({
  selector: 'app-reports-compare',
  templateUrl: './reports-compare.component.html',
  styleUrls: ['./reports-compare.component.scss']
})
export class ReportsCompareComponent {

  protected readonly currencify = currencify;

  datePickerLeftIsEmpty$ =  new BehaviorSubject<boolean>(true);
  datePickerRightIsEmpty$ =  new BehaviorSubject<boolean>(true);

  dateFilterLeft$ = this.reportsService.selectedDateRangeForEventCompareLeft$ ;
  dateFilterRight$ = this.reportsService.selectedDateRangeForEventCompareRight$ ;
  pages = Array.from(reportsPages);
  page$ = this.navigationService.page$;

  loading$ = (this.reportsService.report$ as Observable<CompareReport | null>).pipe(
    map(report => !report)
  );

  report$ = (this.reportsService.report$ as Observable<CompareReport | null>).pipe(
    filter(report => !!report),
    startWith(null),
    pairwise(),
    map(([prevReport, curReport]) => {
      if (!curReport)
        return null;
      const report = {...curReport};
      const checkIfEmpty = (side: keyof CompareReport) => {
        if (report[side] && Object.values(report[side]!).every(v => !v)) {
          const anotherSide: keyof CompareReport = side === 'left' ? 'right' : 'left';
          report[side] = null;
          if (!curReport?.[anotherSide] && !prevReport?.[anotherSide] || 
            curReport[anotherSide] && prevReport?.[anotherSide] && 
            Object.keys(curReport[anotherSide]!).every(key => (prevReport[anotherSide] as any)[key] === (curReport[anotherSide] as any)[key])
          )
            showSnackbar(this.snackbar, { message: 'No available info', duration: 1000 });
        }
      }
      checkIfEmpty('left');
      checkIfEmpty('right');
      return report;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private reportsService: ReportsService,
    private navigationService: NavigationService,
    private router: Router,
    private snackbar: MatSnackBar
  ) { }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.router.navigateByUrl('reports/' + [chosenPage]);
  }

  calculateColumnHeight(mainValue: number, secondValue: number): string {
    mainValue = Math.abs(mainValue);
    secondValue = Math.abs(secondValue);
    const maxValue = Math.max(mainValue, secondValue);
    if (maxValue === 0)
      return '1px';

    const maxHeight = window.innerWidth < 768
      ? 184
      : window.innerWidth > 991
        ? 276
        : 196;

    const height = (mainValue / maxValue) * maxHeight;
    return `${height > 1 ? height : 1}px`;
  }

  calculatePercentageDifference(mainValue: number, secondValue: number): { percent: string, isPositive: boolean } {
    if (mainValue === 0 && secondValue === 0)
      return { percent: '', isPositive: false };

    const calcDifference = () => (mainValue - secondValue) / Math.max(mainValue, secondValue) * 100;
    let difference: number;
    if (mainValue <= 0 && secondValue <= 0) {
      mainValue = Math.abs(mainValue);
      secondValue = Math.abs(secondValue);
      difference = -calcDifference();
    } else {
      difference = calcDifference();
    }
    const isPositive = difference >= 0;
    const percent = `${isPositive ? '+' : ''}${difference.toFixed(2)}%`;
    return { percent, isPositive };
  }

  selectedRangeChange(event: [Date, Date] | null, side: string) {
    if (side === 'left') {
      this.datePickerLeftIsEmpty$.next(!event);
      this.reportsService.setSelectedDateRangeCompareLeft(event ? DateFilter.from(event) : null);
    } else {
      this.datePickerRightIsEmpty$.next(!event);
      this.reportsService.setSelectedDateRangeCompareRight(event ? DateFilter.from(event) : null);
    }
  }
}
