import { Component, Input, ViewChild } from '@angular/core';
import { BusinessProfile } from '../../../../../../common/src/lib/models/business-profile.model';
import { UserProfile } from '../../../../../../common/src/lib/models/user-profile.model';
import { BusinessAddressComponents, PlacesService } from '../../../../../../common/src/lib/services/places.service';
import { UsersService } from '../../../services/users.service';
import { BusinessService } from '../../../services/business.service';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProfileFieldComponent } from '../../../components/profile-field/profile-field.component';
import { capitalizeFirstChar, deepDeleteUndefined } from 'projects/common/src/public-api';
import { AddressComponents, addressComponentsEmpty } from 'projects/common/src/lib/models/address-comments.model';
import { ConfirmationDialog } from '../../../../../../common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import { ModalsService } from 'projects/common/src/lib/services/modals.service';
import { componentsToString } from 'projects/common/src/lib/pipes/address.pipe';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent {

  @ViewChild('businessNameInputField') businessNameInputField!: ProfileFieldComponent<string>;
  
  @Input() profile!: BusinessProfile;
  @Input() user!: UserProfile;

  phoneNumberSubject = new BehaviorSubject('');

  disableFieldEdit$ = this.usersService.currentUser$.pipe(
    map(user => user?.role !== 'owner' && user?.role !== 'admin'),
  );

  phoneNumberError?: string;

  lastSelectedAddress: AddressComponents | null  = null;

  constructor(
    private usersService: UsersService,
    private businessService: BusinessService,
    private modalsService: ModalsService,
  ) { }

  capitalizeFirstChar = capitalizeFirstChar;

  async onBusinessPhoneNumberEditClick() {
    const editDisabled = await this.disableFieldEdit$.pipe(take(1)).toPromise();
    if(editDisabled)
      this.phoneNumberError = 'Business phone number can only be edit by an admin';
  }

  businessNameToString(value?: string | BusinessAddressComponents) {
    if(!value) return '';
    return typeof value === 'string' ? value : value.name;
  }

  addressToString(value?: AddressComponents) {
    if(!value) return '';
    return componentsToString(value);
  }

  async saveBusinessName(value: string | BusinessAddressComponents) {
    const businessName = (typeof value === 'string' ? value : value.name).trim();
    if(businessName === this.profile.businessName) {
      return null;
    }
    if(businessName.length === 0) {
      return 'Business name is required';
    }

    if(typeof value === 'object' && !addressComponentsEmpty(value))
      this.modalsService.open(ConfirmationDialog, {
        data: {
          title: `Set address`,
          message: `Would you also like to set the address ${componentsToString(value)} for your business?`,
          actionTitle: 'Yes',
          cancelTitle: 'No',
          action: async () => {
            this.saveAddress(value);
          },
          actionColor: 'primary',
        }
      });

    const data: any = { businessName: businessName };
    await this.businessService.updateBusiness(data);
    return null;
  }

  async saveAddress(value?: AddressComponents) {
    if(!value) {
      return 'Address is required';
    }
    if(typeof value === 'string') {
      return 'Select a full address option from the dropdown';
    }
    if(addressComponentsEmpty(value)) {
      return 'Select a full address option from the dropdown';
    }
    
    await this.businessService.updateBusiness({
      address: deepDeleteUndefined(value)
    });
    return null;
  }

  async saveBusinessPhoneNumber(value: string) {
    if(value === this.profile.phoneNumber) {
      return null;
    }

    if(value === '') {
      return 'Business phone number is required';
    } else if (value.length !== 0 && value.length !== 10) {
      return 'Invalid business phone number. Re-enter';
    }

    const data: any = { phoneNumber: value };
    await this.businessService.updateBusiness(data);
    return null;
  }

}
