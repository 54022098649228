import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef, ElementRef, EventEmitter,
  HostListener,
  Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
  Type, ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";
import { SectionDividerComponent } from "projects/common/src/lib/components/section-divider/section-divider.component";
import { debounceTime, filter } from "rxjs/operators";
import { UtilsService } from "../../../../../common/src/lib/services";

export interface PaginationListItem<T> {
  componentClass: Type<T>;
  header: string,
  args: { [key: string]: any };
}

export interface DynamicListEvent<E> {
  eventEmitterName: string,
  eventEmitter?: EventEmitter<E>,
  value: E
}

type FullPredicate<T> = (prev: ComponentRef<T> | undefined, value: ComponentRef<T>, next: ComponentRef<T> | undefined, index: number) => boolean;
export type Predicate<T> = (value: ComponentRef<T>, index: number) => boolean;

/**
 * @returns True to indicate that the process should finish and false to continue
 * */
type IterableHandler<T> = (value: ComponentRef<T>, index: number) => boolean;

function refIsSectionDivider(ref: ComponentRef<any>): ref is ComponentRef<SectionDividerComponent> {
  return ref.componentType === SectionDividerComponent;
}

function isSectionDividerText(ref: ComponentRef<any>, text: string) {
  return refIsSectionDivider(ref) && ref.instance.text === text;
}

let iter = 0;

@Component({
  selector: 'dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss']
})
export class DynamicListComponent<C = Component, E = any> implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('container') container!: ElementRef<HTMLDivElement>;
  @ViewChild('container', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;
  @ViewChild('emptySpace') emptySpace!: ElementRef<HTMLDivElement>;

  @Input() initialTopItems?: PaginationListItem<C>[];
  @Input() initialBottomItems: PaginationListItem<C>[] = [];
  @Input() firstHeader?: string | null;
  @Input() canLoadMoreTop = true;
  @Input() canLoadMoreBottom = true;
  @Input() threshold = 500;
  @Input() hasDateFilter = false;
  @Input() stayOnTop = false;

  @Output() loadMoreTop = new EventEmitter<number>();
  @Output() loadMoreBottom = new EventEmitter<number>();
  @Output() itemEvent = new EventEmitter<DynamicListEvent<E>>();

  @HostListener('window:resize', ['$event']) onResize() {
    this.setEmptySpace();
  }

  private static _scrolling = new BehaviorSubject(false);
  static scrolling$ = DynamicListComponent._scrolling.asObservable();

  private components: ComponentRef<C | SectionDividerComponent>[] = [];

  private debouncerTop = new Subject<void>();
  private debouncerBottom = new Subject<void>();
  private loadingTop = false;
  private loadingBottom = false;

  protected _topCount = new BehaviorSubject(0);
  topCount$ = this._topCount.asObservable();
  protected _bottomCount = new BehaviorSubject(0);
  bottomCount$ = this._bottomCount.asObservable();

  loading = false;
  initializing = true;
  scrollingEmitDisabled = true;

  get getTopCount() {
    return this._topCount.value;
  }

  get getBottomCount() {
    return this._bottomCount.value;
  }

  private prevScroll = 0;

  private iter = iter++;

  constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private utilsService: UtilsService,
      private element: ElementRef<HTMLElement>
  ) {
    this.debouncerTop
      .pipe(debounceTime(100), filter(_ => !this.loadingTop))
      .subscribe((value) => {
        this.loadingTop = true;
        this.loadMoreTop.emit(this._topCount.value);
      });
    this.debouncerBottom
      .pipe(debounceTime(100), filter(_ => {
        return !this.loadingBottom;
      }))
      .subscribe((value) => {
        this.loadingBottom = true;
        this.loadMoreBottom.emit(this._bottomCount.value);
      });
  }

  getFirstComponent<CT extends C>(ofType?: Type<CT>) {
    if (ofType)
      return this.components.find(c => c.componentType === ofType) as ComponentRef<CT>;
    return this.components.length ? this.components[1] as ComponentRef<C> : null;
  }

  getLastComponent<CT extends C>(ofType?: Type<CT>) {
    if (ofType)
      return ((this.components as any).toReversed() as typeof this.components).find(c => c.componentType === ofType) as ComponentRef<CT>;
    return this.components.length ? this.components[this.components.length - 1] as ComponentRef<C> : null;
  }

  ngOnInit() {
    const scrollElement = this.element.nativeElement!;
    scrollElement.parentElement!.style.overflow = 'hidden';
    scrollElement.style.overflow = 'auto';
    scrollElement.style.opacity = '0';
    scrollElement.style.transition = 'all 500ms ease-in-out';
    scrollElement.classList.add('sticky-header');
    scrollElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  protected onScroll(event: Event) {
    if (this.scrollingEmitDisabled)
      this.scrollingEmitDisabled = false;
    else
      DynamicListComponent._scrolling.next(true);
    const scrollElement = this.element.nativeElement!;
    const scroll = scrollElement.scrollTop;
    const height = scrollElement.clientHeight;
    const scrollHeight = scrollElement.scrollHeight;

    scrollHeight - (height + scroll) < 20 && !this.emptySpace ? scrollElement.classList.remove('sticky-header') : scrollElement.classList.add('sticky-header');
    DynamicListComponent._scrolling.next(false);

    if (this.loading || !this.canLoadMoreTop && !this.canLoadMoreBottom) 
      return;
    
    if (this.canLoadMoreTop && this.loadMoreTop.observers.length > 0 && scroll < this.prevScroll && scroll < this.threshold) {
      this.debouncerTop.next();
    } else if (this.canLoadMoreBottom && this.loadMoreBottom.observers.length > 0 && scroll > this.prevScroll && scroll + height > scrollHeight - this.threshold) {
      this.debouncerBottom.next();
    }
    this.prevScroll = scroll;
    if (scroll < 10)
      scrollElement.scrollTo({ top: 10 });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.firstHeader)
      this.setEmptySpace();

    if (!changes.initialBottomItems?.currentValue || !this.viewContainerRef)
      return;

    this.loading = true;
    if (changes.initialBottomItems) {
      const bottom = changes.initialBottomItems?.currentValue ?? this.initialBottomItems;
      if (this.components.length > 0)
        this.clear();
      if (bottom && bottom.length > 0)
        await this.addAtBottom(bottom);
      const top = changes.initialTopItems?.currentValue ?? this.initialTopItems;
      if (top && top.length > 0)
        await this.addAtTop(top);
      this.changeDetectorRef.detectChanges();
      this.setEmptySpace();
      this.processScrollChanges();
    }
    this.loading = false;
  }

  async ngAfterViewInit() {
    this.loading = true;
    if (this.initialBottomItems) {
      const bottom = this.initialBottomItems;
      if (this.components.length > 0)
        this.clear();
      if (bottom && bottom.length > 0)
        await this.addAtBottom(bottom);
      const top = this.initialTopItems;
      if (top && top.length > 0)
        await this.addAtTop(top);
      this.changeDetectorRef.detectChanges();
      this.setEmptySpace();
      this.processScrollChanges();
    }
    this.loading = false;

    const config = { attributes: true, attributeOldValue: true, attributeFilter: ['style'] };
    const observer = new MutationObserver((mutationList: MutationRecord[], _observer: MutationObserver) => {
        for (const mutation of mutationList) {
          const element = this.element.nativeElement!;
          if (mutation.oldValue?.includes('display: none') && getComputedStyle(element).display !== 'none') {
            this.setEmptySpace();
            this.processScrollChanges();
          }
        }
      }
    );
    observer.observe(this.element.nativeElement, config);
  }

  private processScrollChanges() {
    const setVisible = () => {
      this.initializing = false;
      scrollElement.style.opacity = '1';
      this.changeDetectorRef.detectChanges();
      return;
    };

    const handleInitScrolling = () => {
      if (!this.initializing)
        return;

      if (scrollElement.clientHeight + 1 >= scrollElement.scrollHeight || this._topCount.value === 0) {
        setVisible();
        return;
      }

      if(this.utilsService.isSafari()) {
        setTimeout(() => {
          this.initializing = false;
          scrollElement.style.opacity = '1';
        }, 200);
      } else {
        const scrollCallback = () => {
          this.initializing = false;
          scrollElement.style.opacity = '1';
          scrollElement.removeEventListener('scrollend', scrollCallback);
        }
        scrollElement.addEventListener('scrollend', scrollCallback);
      }
    }

    const scrollElement = this.element.nativeElement;
    if (this.container)
      this.container.nativeElement.remove();
    if (!this.firstHeader || this.components.length === 0) {
      if (this.stayOnTop) {
        setVisible();
        return;
      }
      handleInitScrolling();
      scrollElement.scrollTo({top: scrollElement.scrollHeight});
      return;
    }
    const component = this.components.find(c => isSectionDividerText(c, this.firstHeader!));
    if (!component)
      return;
    const element = component!.location.nativeElement as HTMLElement;
    this.scrollingEmitDisabled = true;
    element.scrollIntoView({block: 'start'});
    this.initializing = false;
    scrollElement.style.opacity = '1';
    this.changeDetectorRef.detectChanges();
  }

  private setEmptySpace() {
    if (!this.emptySpace)
      return;

    this.scrollingEmitDisabled = true;
    if (!this.firstHeader || !this.components.length) {
      this.emptySpace.nativeElement.style.height = '0';
      return;
    }

    const headerElement = this.components.find(c => isSectionDividerText(c, this.firstHeader!))?.location.nativeElement as HTMLElement;
    const lastElement = this.getLastComponent()?.location.nativeElement as HTMLElement;
    if (!headerElement || !lastElement)
      return;

    const contentHeight = lastElement.getBoundingClientRect().bottom - headerElement.getBoundingClientRect().top;
    const scrollElement = this.element.nativeElement;
    this.emptySpace.nativeElement.style.height = scrollElement.clientHeight < contentHeight ? '0' : `${scrollElement.clientHeight - contentHeight}px`;
  }

  private _checkHeader(header: string, at: 'top' | 'bottom', sd?: ComponentRef<SectionDividerComponent>): ComponentRef<SectionDividerComponent> {
    if (!!sd && sd.instance.text === header)
      return sd;

    if (this.components.length === 0) {
      return this._createHeader(header, 0);
    }

    if (!sd)
      sd = (at === 'top')
          ? this.components[0]
          : (this.components as any).findLast((c: any) => c.componentType === SectionDividerComponent);

    if (sd!.instance.text !== header) {
      const index = at === 'top' ? 0 : this.components.length;
      sd = this._createHeader(header, index);
    }
    return sd!;
  }

  async addAtTop(items: PaginationListItem<C>[]) {
    let sectionDivider;
    const scrollFromBottom = this.element.nativeElement.scrollHeight - this.element.nativeElement.scrollTop;
    for (const item of items) {
      sectionDivider = this._checkHeader(item.header, 'top', sectionDivider);
      this._createComponent(item, 1);
    }
    this._topCount.next(this._topCount.value + items.length);
    this.changeDetectorRef.detectChanges();
    if (!this.initializing) {
      const scrollElement = this.element.nativeElement;
      const scrollFunc = () => this.element.nativeElement.scrollTo({ top: this.element.nativeElement.scrollHeight - scrollFromBottom });
      if (this.utilsService.isIOS()) {
        scrollElement.style.overflow = 'hidden';
        scrollFunc();
        scrollElement.style.overflow = 'auto';
      } else {
        scrollFunc();
      }
    }
    await this.utilsService.delay(100);
    this.loadingTop = false;
  }

  async addAtBottom(items: PaginationListItem<C>[]) {
    let sectionDivider;
    for (const item of items) {
      sectionDivider = this._checkHeader(item.header, 'bottom', sectionDivider);
      this._createComponent(item, this.components.length);
    }
    this._bottomCount.next(this._bottomCount.value + items.length);
    this.setEmptySpace();
    this.changeDetectorRef.detectChanges();
    await this.utilsService.delay(100);
    this.loadingBottom = false;
  }

  private _findIndex(predicate: FullPredicate<C>) {
    const index = this.components.findIndex((v, i) => {
      if (v.componentType === SectionDividerComponent)
        return false;

      let prevIndex = i - 1;
      let prev = this.components[prevIndex];
      while (prev !== undefined && refIsSectionDivider(prev)) {
        prevIndex--;
        prev = this.components[prevIndex];
      }

      let nextIndex = i + 1;
      let next = this.components[nextIndex];
      while (next !== undefined && refIsSectionDivider(next)) {
        nextIndex--;
        next = this.components[nextIndex];
      }
      return predicate(prev as ComponentRef<C>, v as ComponentRef<C>, next as ComponentRef<C>, i);
    });
    return index;
  }

  includesItem(predicate: FullPredicate<C>) {
    return this._findIndex(predicate) !== -1;
  }

  insertItem(
      predicate: number | FullPredicate<C>,
      item: PaginationListItem<C>,
      at: 'top' | 'bottom',
      insertLastIfNotFound = false
  ) {
    let index = typeof predicate === 'number' ? predicate : this._findIndex(predicate);
    if (index === -1) {
      if (this.components.length > 0) {
        if (!insertLastIfNotFound && at === 'bottom')
          return;
        
        index = this.components.length;
        let searchIndex = index === this.components.length ? (this.components.length - 1) : index;
        while (!refIsSectionDivider(this.components[searchIndex])) {
          searchIndex--;
        }
        const sd = this.components[searchIndex].instance as SectionDividerComponent;
        if (sd.text !== item.header) {
          this._createHeader(item.header, index);
          index++;
        }
      } else {
        this._createHeader(item.header, 0);
        index = 1;
      }
    } else {
      if (index === 1 && at === 'top' && !insertLastIfNotFound)
        return;

      let searchIndex = index === this.components.length ? (this.components.length - 1) : index;
      if (searchIndex === 1) {
        if ((this.components[searchIndex - 1].instance as SectionDividerComponent).text !== item.header)
          this._createHeader(item.header, 0);
        } else {
        if (refIsSectionDivider(this.components[searchIndex - 1]) && (this.components[searchIndex - 1].instance as SectionDividerComponent).text !== item.header) {
          searchIndex--;
          index--;
        }
        searchIndex--;
        while (!refIsSectionDivider(this.components[searchIndex]))
          searchIndex--;

        const sd = this.components[searchIndex].instance as SectionDividerComponent;
        if (sd.text !== item.header) {
          this._createHeader(item.header, index);
          index++;
        }
      }
    }

    const component = this._createComponent(item, index);

    for (const key in item.args) {
      (component.instance as any)[key] = item.args[key];
    }
    if (at === 'top') {
      this._topCount.next(this._topCount.value + 1);
    } else {
      this._bottomCount.next(this._bottomCount.value + 1);
    }
    this.changeDetectorRef.detectChanges();
    this.setEmptySpace();
  }

  /**
   * @param {IterableHandler} iter An iteration function in which the component data should be updated.
   * */
  updateItems(iter: IterableHandler<C>) {
    let index = 0;
    for (const component of this.components) {
      if (refIsSectionDivider(component))
        continue;
      const res = iter(component as ComponentRef<C>, index);
      component.changeDetectorRef.detectChanges();
      if (res)
        return;
      index++;
    }
    // this.changeDetectorRef.detectChanges();
  }

  getItem(predicate: Predicate<C>) {
    return this.components.find((v, i, o) => {
      if (refIsSectionDivider(v))
        return false;
      return predicate(v as ComponentRef<C>, i);
    })?.instance;
  }

  removeItems(at: 'top' | 'bottom' | 'auto', predicate: Predicate<C>) {
    let found = false;
    do {
      found = this.removeItem(at, predicate);
    } while (found);
  }

  removeItem(at: 'top' | 'bottom' | 'auto', predicate: Predicate<C>) {
    const items = this.components.filter(c => !refIsSectionDivider(c));
    const indexInItems = items.findIndex((v, i) => predicate(v as ComponentRef<C>, i));
    const index = this.components.findIndex((v, i, o) => {
      if (refIsSectionDivider(v))
        return false;
      return predicate(v as ComponentRef<C>, i);
    });

    if (index === -1)
      return false;

    if (this.components[index - 1].componentType === SectionDividerComponent && this.components[index + 1] && this.components[index + 1].componentType !== SectionDividerComponent) {
      (this.components[index + 1].location.nativeElement as HTMLElement).getElementsByClassName('tile-wrapper')[0].classList.remove('top-border');
    }
    const component = this.components.splice(index, 1)[0];
    component?.destroy();

    if (at === 'top')
      this._topCount.next(this._topCount.value - 1);
    else if (at === 'bottom')
      this._bottomCount.next(this._bottomCount.value - 1);
    else {
      const counter = indexInItems < this._topCount.value ? this._topCount : this._bottomCount;
      counter.next(counter.value - 1);
    }

    const currentIsDivider = this.components[index]?.componentType === SectionDividerComponent;
    const prevIsDivider = this.components[index - 1]?.componentType === SectionDividerComponent;

    if (prevIsDivider && (!this.components[index] || currentIsDivider)) {
      this.components[index - 1].destroy();
      this.components.splice(index - 1, 1);
    }

    const height = this.element.nativeElement!.clientHeight;
    const scrollHeight = this.element.nativeElement!.scrollHeight;
    const scroll = this.element.nativeElement!.scrollTop;
    if (this.canLoadMoreBottom && this.loadMoreBottom.observers.length > 0 && scroll + height > scrollHeight - this.threshold) {
      this.debouncerBottom.next();
    }
    this.changeDetectorRef.detectChanges();
    this.setEmptySpace();
    return true;
  }

  private _createHeader(text: string, index: number) {
    const sd = this.viewContainerRef.createComponent(SectionDividerComponent, {index})
    this.components.splice(index, 0, sd);
    if (refIsSectionDivider(sd)) {
      sd.instance.text = text;
      (sd.location.nativeElement.firstElementChild as HTMLElement).style.marginTop = '10px';
      (sd.location.nativeElement.firstElementChild as HTMLElement).style.marginBottom = '14px';
    }
    return sd;
  }

  private _createComponent(item: PaginationListItem<C>, index: number) {
    const component = this.viewContainerRef.createComponent(item.componentClass, {index});
    for (const key in component.instance) {
      if ((component.instance[key] as any)['__proto__'] && (component.instance[key] as any)['__proto__'] === EventEmitter.prototype)
        (component.instance[key] as EventEmitter<E>).subscribe(e => this.itemEvent.emit({
          eventEmitterName: key,
          eventEmitter: component.instance[key] as EventEmitter<E>,
          value: e
        }))
    }
    for (const key in item.args) {
      (component.instance as any)[key] = item.args[key];
    }

    this.components.splice(index, 0, component);

    if (this.components[index - 1] && !refIsSectionDivider(this.components[index - 1])) {
      (this.components[index].location.nativeElement as HTMLElement).getElementsByClassName('tile-wrapper')[0]?.classList.add('top-border');
    }
    if (this.components[index + 1] && !refIsSectionDivider(this.components[index + 1])) {
      (this.components[index + 1].location.nativeElement as HTMLElement).getElementsByClassName('tile-wrapper')[0].classList.add('top-border');
    }
    return component;
  }

  clear() {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.components = [];
    this._topCount.next(0);
    this._bottomCount.next(0);
    this.prevScroll = 0;
    this.canLoadMoreTop = true;
    this.canLoadMoreBottom = true;
    this.loadingTop = false;
    this.loadingBottom = false;
  }

  ngOnDestroy() {
    this.element.nativeElement?.removeEventListener('scroll', this.onScroll.bind(this));
  }

}
