<div *ngIf="enabled && (enabled$ | async) else disabled" class="wrapper enabled" (mouseenter)="hover = true" (mouseleave)="hover = false" (click)="openModal()">

    <div class="profile-logo" [class.no-border]="noBorder">

        <div class="img" *ngIf="(src$ | async) let src else noSrc" [class.no-border]="noBorder">
            <skeleton-img [src]="src"></skeleton-img>
            <div class="edit-overlay" *ngIf="hover"></div>
            <div class="edit-overlay-content" *ngIf="hover">
                <!-- <ng-content *ngTemplateOutlet="editIcon"></ng-content> -->
                <img src="../../../assets/icons/edit.svg">
                <h4>Edit</h4>
            </div>
        </div>

        <ng-template #noSrc>
            <div class="no-logo">
                <app-icon class="no-logo-icon" *ngIf="action$ | async let action" [src]="hover ? 'upload' : 'plus'"></app-icon>
                <!-- <skeleton-img *ngIf="action$ | async let action" [src]="'../../../assets/icons/' + (hover ? 'upload' : 'plus') + '.svg'"></skeleton-img> -->
                <h4>{{ hover ? 'Upload' : 'Add logo'}}</h4>
            </div>
        </ng-template>

    </div>
</div>

<ng-template #disabled>
    <div class="wrapper">
        <div class="profile-logo" [class.no-border]="noBorder">
            <div class="img" *ngIf="(src$ | async) let src else noSrc">
                <skeleton-img [src]="src"></skeleton-img>
            </div>

            <ng-template #noSrc>
                <div class="no-logo disabled" (click)="setShowReminder()">
                    <h4>No logo</h4>
                </div>
            </ng-template>
        </div>
        <h4 class="reminder" [class.hide]="!showReminder">Remind admin</h4>
    </div>
</ng-template>