import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormsService } from 'projects/common/src/lib/services/forms.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

  step = 1;

  canResend = true;
  loading = false;

  form = this.fromBuilder.group({
    email: new UntypedFormControl('', [Validators.email])
  });

  @ViewChild("stepper") stepper!: MatStepper;
  @ViewChild("emailInput") emailInput!: ElementRef;
  
  constructor(
    private authService: AuthService,
    private fromBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private formsService: FormsService,
  ) { }

  ngOnInit(): void {
    const email = this.route.snapshot.queryParams['e'];
    if(email) {
      this.form.get('email')!.setValue(email);
    }
  }

  ngAfterViewInit(): void {
    const email = this.route.snapshot.queryParams['e'];
    if(!email) {
      this.emailInput.nativeElement.focus();
    }
  }

  async submit() {
    this.loading = true;
    if(this.valid()) {
      try {
        await this.authService.sendPasswordResetEmail(this.form.get('email')!.value);
        this.step = 2;
        this.stepper.next();
      } catch (error: any) {
        console.log(`forgot-password.component.ts > ForgotPasswordComponent > submit()`, error);
        
        if(error.code === 'auth/user-not-found') {
          this.form.get('email')!.setErrors({
            emailNotFound: true
          });
        } else if(error.code === 'auth/invalid-email') {
          this.form.get('email')!.setErrors({
            email: true
          });
        } else if(error.code === 'auth/too-many-requests') {
          this.form.get('email')!.setErrors({
            tooManyRequests: true
          });
        }
      }
      this.emailInput.nativeElement.blur();
    } else {
      this.form.get('email')!.markAllAsTouched();
      this.emailInput.nativeElement.blur();
    }
    this.loading = false;
  }

  valid(): boolean {

    const valid = this.formsService.validateRequired(this.form.get('email')!);

    if(!this.form.valid) {
      return false;
    }

    return valid;
  }

  back() {
    if(this.step == 1) {
      this.router.navigate(['/login']);
    } else {
      this.step = 1;
      this.stepper.previous();
    }
  }

}
