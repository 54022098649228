import { ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { ItemsService } from "../../services/items.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from "rxjs/operators";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs"
import { Item } from "../../models/item.model";
import { showSnackbar } from "../../../../../common/src/lib/components/snackbar/snackbar.component";
import { fadeIn } from "../../helpers/animations";
import { isEmpty, decimalOnly, onDecimalPaste } from "../../helpers/forms";
import { MatLegacyAutocomplete } from "@angular/material/legacy-autocomplete";

type ModalStates =
  'add'
  | 'addOnlyHere'
  | 'createInTheItemList'
  | 'addHereAndToTheItemList'
  | 'addHereAndUpdateInTheItemList'
  | 'update'
  | 'updateOnlyHere'
  | 'updateInTheItemList'
  | 'updateHereAndInTheItemList'
  | 'updateHereAndCreateInTheItemList';

type Focus = 'unfocused' | 'name' | 'description' | 'price' | 'cost' | 'discount';

@Component({
  selector: 'app-create-edit-workflow-item',
  templateUrl: './create-edit-workflow-item.component.html',
  styleUrls: ['./create-edit-workflow-item.component.scss'],
  animations: [ fadeIn ]
})
export class CreateEditWorkflowItemComponent implements OnDestroy, AfterViewChecked, OnInit {

  @ViewChild('autocomplete') autocomplete!: MatLegacyAutocomplete;
  @ViewChild('nameField') nameField!: any;
  @ViewChild('descriptionField') descriptionField!: any;

  protected readonly decimalOnly = decimalOnly;
  protected readonly onPaste = onDecimalPaste;

  showingDiscountSnackbar = false;

  edited: boolean = false;

  notExisting: boolean = false;

  scrolledToTop = false;

  modalState$ = new BehaviorSubject<ModalStates[]>([]);
  focus$ = new BehaviorSubject<Focus>('unfocused');

  form = this.formBuilder.group({
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    cost: new UntypedFormControl(''),
    discountPercent: new UntypedFormControl(''),
    discountAmount: new UntypedFormControl(''),
    discountType: new UntypedFormControl(''),
    taxable: new UntypedFormControl(false),
  });

  fromSuggestion = false;

  loading1 = false;
  loading2 = false;
  initialized = false;

  priceValueChanges!: Subscription;

  discountPercentValueChanges!: Subscription;
  discountAmountValueChanges!: Subscription;
  discountTypeValueChanges!: Subscription;
  temporaryDiscountValue!: number;

  discountChangedSubject = new BehaviorSubject(false);

  discountAboveAmount = false;
  emptyNameError = false;
  emptyPriceError = false;
  usedNameError = false;

  connectedItem!: Item | undefined;

  nameFieldHeight = 0;

  nameSuggestions$ = this.form.get('name')?.valueChanges.pipe(
    debounceTime(50),
    tap(() => {
      this.nameFieldHeight = this.nameField?.nativeElement?.offsetHeight;
    }),
    debounceTime(350),
    map(name => {
      if (name.length < 1 || this.usedNameError)
        return [];

      const nameSuggestions: { name: string, price: string }[] = [];
      this.items?.forEach(item => {
        item.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) && item.name !== ''
          ? nameSuggestions.push({ name: item.name, price: ' ($' + item.price.toFixed(2) +')' })
          : null;
      });

      return nameSuggestions;
    })
  );

  formHandlerSubscription!: Subscription;

  items!: Item[] | null;
  itemsSub!: Subscription;
  selectedItem!: Item;

  get data() {
    return this.modalsService.data;
  }

  get taxPercentage() {
    return this.modalsService.data.salesTaxPercentage;
  }
  
  get title() {
    return this.data?.mode === 'edit' ? 'Edit Item' : 'Add Item';
  };

  constructor(
    private modalsService: ModalsService,
    private formBuilder: UntypedFormBuilder,
    private itemsService: ItemsService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data?.mode === 'edit') {
      if (this.data.item.discountAmount === 0)
        delete this.data.item.discountAmount;
      if (this.data.item.discountPercent === 0)
        delete this.data.item.discountPercent;

      this.form.patchValue(this.data.item);
    }

    this.itemsSub = this.itemsService.items$.pipe(
      filter(items => !!items)
    ).subscribe(items => {
      this.items = items;
      if (this.data?.mode === 'edit') {
        this.connectedItem = this.items?.find(item => item.name === this.data.item.name);
        if (this.connectedItem) {
          if (
            this.connectedItem.description !== this.data.item.description
            || this.connectedItem.price !== this.data.item.price
            || this.connectedItem.cost !== this.data.item.cost
            || this.connectedItem.taxable !== this.data.item.taxable
          )
            this.edited = true;
        } else {
          this.notExisting = true;
        }
      }

      if (!this.initialized) {
        this.handleDiscount();
        this.handlePrice();
        this.handleForm();
        this.initialized = true;
      }
    });
  }

  ngAfterViewChecked() {
    if (!this.scrolledToTop && this.nameField && this.descriptionField) {
      this.nameField.nativeElement.scrollTop = 0;
      this.descriptionField.nativeElement.scrollTop = 0;
    }
  }

  ngOnDestroy() {
    this.unsubscribes();
  }

  private unsubscribes() {
    this.formHandlerSubscription?.unsubscribe();
    this.priceValueChanges?.unsubscribe();
    this.discountPercentValueChanges?.unsubscribe();
    this.discountAmountValueChanges?.unsubscribe();
    this.discountTypeValueChanges?.unsubscribe();
    this.itemsSub?.unsubscribe();
  }

  async close() {
    await this.modalsService.close();
  }

  toggleDiscountType() {
    this.form.get('discountType')?.setValue(
      this.form.get('discountType')?.value === 'percent'
        ? 'amount'
        : 'percent'
    );
  }

  private handleForm() {
    this.formHandlerSubscription = combineLatest([
      this.form.valueChanges.pipe(startWith(this.data?.item ?? {})),
      this.focus$.asObservable(),
      this.discountChangedSubject
    ]).subscribe(async ([form, focus, discountChanged]) => {
      if (this.data?.mode === 'edit') {
        this.usedNameError = false;
        const sameName = this.data?.item.name === form.name;
        const sameContent =
          this.data?.item.description === form.description
          && +this.data?.item.price === +form.price
          && (
            this.data?.item.cost
              ? +this.data?.item.cost === +form.cost
              : (isEmpty(form.cost) && isEmpty(this.data?.item.cost))
          )
          && this.data?.item.taxable === form.taxable;

        if (sameName && sameContent && discountChanged) {
          this.modalState$.next(['update']);
        } else if (!this.connectedItem) {
          if (sameName && sameContent) {
            this.modalState$.next(['createInTheItemList']);
            this.edited = false;
            this.notExisting = true;
          } else {
            this.modalState$.next(['updateHereAndCreateInTheItemList']);
          }
        } else {
          if (sameName && !sameContent) {
            this.edited = true;
            if (
              this.connectedItem.description !== form.description
              || this.connectedItem.price !== +form.price
              || this.connectedItem.cost !== +form.cost
              || this.connectedItem.taxable !== form.taxable
            ) {
              this.modalState$.next(['updateOnlyHere', 'updateHereAndInTheItemList']);
            } else {
              this.modalState$.next(['updateOnlyHere']);
            }
          } else if (!sameName) {
            if (this.itemPresentInList(this.items, form.name)) {
              if (focus !== 'name') {
                this.usedNameError = true;
                this.emptyNameError = false;
                this.modalState$.next([]);
              }
            } else {
              this.modalState$.next(['updateHereAndCreateInTheItemList']);
            }
          } else {
            this.edited = this.connectedItem.description !== form.description
              || this.connectedItem.price !== +form.price
              || (
                this.connectedItem.cost
                  ? this.connectedItem.cost !== +form.cost
                  : !isEmpty(form.cost)
              )
              || this.connectedItem.taxable !== form.taxable;
            this.modalState$.next(this.edited ? ['updateInTheItemList'] : []);
          }
        }
      } else {
        this.usedNameError = false;
        this.edited = false;
        this.emptyNameError = false;
        if (this.fromSuggestion) {
          const sameName = this.selectedItem.name === form.name;
          const sameContent =
            this.selectedItem.description === form.description
            && this.selectedItem.price === +form.price
            && (
              this.selectedItem.cost
                ? this.selectedItem.cost === +form.cost
                : (isEmpty(form.cost) && isEmpty(this.selectedItem.cost))
            )
            && this.selectedItem.taxable === form.taxable;
          if (sameName && sameContent) {
            this.modalState$.next(['add']);
          }
          if (sameName && !sameContent) {
            this.edited = true;
            this.modalState$.next(['addOnlyHere', 'addHereAndUpdateInTheItemList']);
          }
          if (!sameName) {
            if (this.itemPresentInList(this.items, form.name)) {
              if (focus !== 'name') {
                this.usedNameError = true;
                this.emptyNameError = false;
              }
              this.modalState$.next([]);
            } else {
              this.modalState$.next(['addHereAndToTheItemList']);
            }
          }
        } else {
          if (
            !isEmpty(form.name)
            || !isEmpty(form.description)
            || !isEmpty(form.price)
            || !isEmpty(form.cost)
          ) {
            this.modalState$.next(['addHereAndToTheItemList']);
            if (this.itemPresentInList(this.items, form.name)) {
              if (focus !== 'name') {
                this.usedNameError = true;
                this.emptyNameError = false;
                this.modalState$.next([]);
              }
            }
          } else
            this.modalState$.next([]);
        }
      }
      if (
        isEmpty(form.name)
        && isEmpty(form.description)
        && isEmpty(form.price)
        && isEmpty(form.cost)
      ) {
        this.modalState$.next([]);
        this.usedNameError = false;
        this.fromSuggestion = false;
        this.edited = false;
        this.emptyNameError = false;
        this.usedNameError = false;
        this.emptyPriceError = false;
      }
    });
  }

  private itemPresentInList(itemList: Item[] | null, name: string): boolean {
    let present = false;
    itemList?.forEach(item => {
      if (item.name === name)
        present = true;
    });
    return present;
  }

  private handlePrice() {
    this.priceValueChanges = this.form.controls['price'].valueChanges.subscribe(() => {
      this.form.get('discountPercent')?.setValue('');
      this.form.get('discountAmount')?.setValue('');
    });
  }

  private handleDiscount() {
    if (this.data?.mode === 'edit') {
      this.temporaryDiscountValue = this.form.get('discountType')?.value === 'percent'
        ? this.form.get('discountAmount')?.value
        : this.form.get('discountPercent')?.value;
      if (this.form.get('price')?.value
        && (this.form.get('discountAmount')?.value || this.form.get('discountPercent')?.value)
      ) {
        this.discountAboveAmount = (
          +(this.form.get('discountPercent')?.value) > 100
          || +(this.form.get('discountAmount')?.value) > +(this.form.get('price')?.value)
        );
      } else {
        this.discountAboveAmount = false;
      }
    } else {
      this.form.get('discountType')?.setValue('percent');
    }

    this.discountAmountValueChanges = this.form.controls['discountAmount'].valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(discountAmount => {
      if (this.form.value.discountType !== 'amount')
        return;
      const percent = (discountAmount / this.form.get('price')?.value) * 100;
      this.temporaryDiscountValue = +percent.toFixed(2);
      this.discountAboveAmount = +discountAmount > +(this.form.get('price')?.value);
      this.discountChangedSubject.next(this.checkDiscountChanged());
    });

    this.discountPercentValueChanges = this.form.controls['discountPercent'].valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(discountPercent => {
      if (this.form.value.discountType !== 'percent')
        return;
      const amount = (this.form.get('price')?.value * discountPercent) / 100;
      this.temporaryDiscountValue = +amount.toFixed(2);
      this.discountAboveAmount = +discountPercent > 100;
      this.discountChangedSubject.next(this.checkDiscountChanged());
    });

    this.discountTypeValueChanges = this.form.controls['discountType'].valueChanges
      .subscribe(discountType => {
        if (discountType === 'percent') {
          const tmp = this.form.get('discountAmount')?.value;
          this.form.get('discountPercent')?.setValue(tmp === '' ? '' : this.temporaryDiscountValue);
          this.temporaryDiscountValue = tmp;
        } else {
          const tmp = this.form.get('discountPercent')?.value;
          this.form.get('discountAmount')?.setValue(tmp === '' ? '' : this.temporaryDiscountValue);
          this.temporaryDiscountValue = tmp;
        }
        this.discountChangedSubject.next(this.checkDiscountChanged());
      });
  }

  getMarkupValue(): string {
    const diff = this.getDiscountedPrice()-this.form.get('cost')?.value;
    const markup = (diff / this.form.get('cost')?.value) * 100;
    return (Math.round(markup * 100) / 100).toFixed(2);
  }

  getSalesTaxValue(): string {
    const tax = (this.getDiscountedPrice()*this.taxPercentage) / 100;
    return tax < 0 ? '0' : (Math.round(tax * 100) / 100).toFixed(2);
  }

  getTotalValue(): string {
    const taxed = this.form.get('taxable')?.value === false
      ? this.getDiscountedPrice()
      : ((this.getDiscountedPrice() * this.taxPercentage) / 100) + this.getDiscountedPrice();
    return taxed < 0 ? '0' : (Math.round(taxed * 100) / 100).toFixed(2);
  }

  private getDiscountedPrice(): number {
    return this.form.get('discountType')?.value === 'amount'
      ? this.form.get('price')?.value - this.form.get('discountAmount')?.value
      : this.form.get('price')?.value - ((this.form.get('price')?.value * this.form.get('discountPercent')?.value) / 100);
  }

  nameSelected(event: any) {
    this.selectedItem = this.items?.find(item => item.name === event.option.value)!;
    this.emptyPriceError = false;
    this.form.patchValue(this.selectedItem);
    this.fromSuggestion = true;
    this.modalState$.next(['add']);
  }

  private validForm(): boolean {
    if (!this.form.get('name')?.value || this.form.get('name')?.value?.trim() === '')
      this.emptyNameError = true;
    if (!+this.form.get('price')?.value)
      this.emptyPriceError = true;

    if (this.emptyNameError || this.emptyPriceError)
      return false;

    if (this.usedNameError)
      return false;

    this.emptyPriceError = false;
    this.emptyNameError = false;
    return true;
  }

  private async saveWorkflowVersion(workflowVersion: any) {
    await this.data.updateFunction(workflowVersion.id, { items: workflowVersion.items });
  }

  async createInTheItemList() {
    if (this.validForm() && !this.loading1) {
      this.loading1 = true;
      await this.createInItemListFunc();

      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Created in item list',
        duration: 2000,
      });
      this.loading1 = false;
    }
  }

  async updateInTheItemList() {
    if (this.validForm() && !this.loading1) {
      this.loading1 = true;
      await this.updateInItemListFunc();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Updated in item list',
        duration: 2000,
      });
      this.loading1 = false;
    }
  }

  async addOnlyHere() {
    this.loading1 = true;
    if (this.validForm()) {
      await this.createInWorkflowItemListFunc();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item added',
        duration: 2000,
      });
    }
    this.loading1 = false;
  }

  async addHereAndToTheItemList() {
    this.loading2 = true;
    if (this.validForm()) {
      await this.createInWorkflowItemListFunc();
      await this.createInItemListFunc();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item added & created',
        duration: 2000,
      });
    }
    this.loading2 = false;
  }

  async addHereAndUpdateInTheItemList() {
    this.loading2 = true;
    if (this.validForm()) {
      await this.createInWorkflowItemListFunc();
      await this.updateInItemListFunc();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item added & updated',
        duration: 2000,
      });
    }
    this.loading2 = false;
  }

  async update() {
    this.loading1 = true;
    if (this.validForm()) {
      await this.saveDiscount();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item updated',
        duration: 2000,
      });
    }
    this.loading1 = false;
  }

  async updateOnlyHere() {
    this.loading1 = true;
    if (this.validForm()) {
      await this.updateInWorkflowItemListFunc();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item updated',
        duration: 2000,
      });
    }
    this.loading1 = false;
  }

  async updateHereAndInTheItemList() {
    this.loading2 = true;
    if (this.validForm()) {
      await this.updateInWorkflowItemListFunc();
      await this.updateInItemListFunc();
      await this.modalsService.close();
      showSnackbar(this.snackbar, {
        message: 'Item updated',
        duration: 2000,
      });
    }
    this.loading2 = false;
  }

  async updateHereAndCreateInTheItemList() {
    this.loading2 = true;
    if (this.validForm()) {
      if (!this.itemPresentInList(this.items, this.form.get('name')?.value)) {
        await this.createInItemListFunc();
        await this.updateInWorkflowItemListFunc();
        await this.modalsService.close();
        showSnackbar(this.snackbar, {
          message: 'Item updated & created',
          duration: 2000,
        });
      } else {
        this.usedNameError = true;
      }
    }
    this.loading2 = false;
  }

  private setDiscountInItem(item: any) {
    item.discountType = this.form.get('discountType')?.value;
    if (!this.form.get('discountPercent')?.value && !this.form.get('discountAmount')?.value) {
      item.discountPercent = 0;
      item.discountAmount = 0;
    } else {
      if (this.form.get('discountType')?.value === 'percent') {
        item.discountPercent = +(this.form.get('discountPercent')?.value ?? 0);
        item.discountAmount = +(this.form.get('price')?.value * item.discountPercent / 100).toFixed(2);
      } else {
        item.discountAmount = +(this.form.get('discountAmount')?.value ?? 0);
        item.discountPercent = +(item.discountAmount / this.form.get('price')?.value * 100).toFixed(2);
      }
    }
  }

  private async createInWorkflowItemListFunc() {
    const workflowVersion = this.data.workflowVersion;
    const item = {
      ...this.form.value
    };
    if (!item.description || item.description === '')
      item.description = null;
    if (!item.cost || item.cost === '')
      item.cost = null;
    else
      item.cost = +item.cost;
    item.price = +item.price;
    item.name = item.name?.trim();
    item.quantity = 1;
    item.index = workflowVersion.items?.length ?? 0;

    this.setDiscountInItem(item);

    if (!workflowVersion.items)
      workflowVersion.items = [];
    workflowVersion.items.push(item);
    await this.saveWorkflowVersion(workflowVersion);
  }

  private async updateInWorkflowItemListFunc() {
    const item = this.data.item;
    this.data.workflowVersion.items.splice(item.index, 1);
    const newItem = { ...item };
    newItem.name = this.form.get('name')?.value;
    newItem.description = this.form.get('description')?.value;
    newItem.price = +this.form.get('price')?.value;
    newItem.cost =
      this.form.get('cost')?.value && this.form.get('cost')?.value !== ''
        ? +this.form.get('cost')?.value
        : null;
    newItem.taxable = this.form.get('taxable')?.value;

    this.setDiscountInItem(newItem);

    newItem.name = newItem.name?.trim();
    this.data.workflowVersion.items.splice(item.index, 0, newItem);
    await this.saveWorkflowVersion(this.data.workflowVersion);
  }

  private async createInItemListFunc() {
    const item = {
      name: this.form.value.name?.trim(),
      description: !this.form.value.description || this.form.value.description === "" ? null : this.form.value.description,
      price: +this.form.value.price,
      cost: !this.form.value.cost || +this.form.value.cost === 0 ? null : +this.form.value.cost,
      taxable: this.form.value.taxable,
    };
    await this.itemsService.createItem(item);
  }

  private async updateInItemListFunc() {
    const updatedItem = this.selectedItem ? {
      ...(this.form.get('name')?.value != this.selectedItem.name && { name: this.form.get('name')?.value }),
      ...(this.form.get('description')?.value != this.selectedItem.description && { description: this.form.get('description')?.value }),
      ...(this.form.get('cost')?.value != this.selectedItem.cost?.toString() && { cost: this.form.get('cost')?.value }),
      ...(this.form.get('price')?.value != this.selectedItem.price?.toString() && { price: this.form.get('price')?.value }),
      ...(this.form.get('taxable')?.value != this.selectedItem.taxable?.toString() && { taxable: this.form.get('taxable')?.value }),
    } : {
      description: this.form.get('description')?.value,
      cost: this.form.get('cost')?.value,
      price: this.form.get('price')?.value,
      taxable: this.form.get('taxable')?.value
    };

    this.selectedItem ?
      await this.itemsService.updateItem(this.selectedItem.id, updatedItem)
      : await this.itemsService.updateItem(
        this.items?.find(item => item.name === this.data.item.name)!.id!,
        updatedItem
      );
  }

  private checkDiscountChanged() {
    const form = this.form.value;
    const zeroFormPercent = !+form.discountPercent;
    const zeroDataPercent = !this.data?.item?.discountPercent;
    const zeroFormAmount = !+form.discountAmount;
    const zeroDataAmount = !this.data?.item?.discountAmount;

    if (form.discountType !== this.data?.item?.discountType)
      return !(
        form.discountType === 'percent' && zeroFormPercent && zeroDataPercent
        || form.discountType === 'amount' && zeroFormAmount && zeroDataAmount
      )

    if (form.discountType === 'percent') {
      const samePercent = (zeroDataPercent && zeroFormPercent)
        || +this.data?.item?.discountPercent === +form.discountPercent;
      return !samePercent;
    }

    const sameAmount = (zeroDataAmount && zeroFormAmount)
      || +this.data?.item?.discountAmount === +form.discountAmount;
    return !sameAmount;
  }

  async saveDiscount() {
    if (!this.checkDiscount())
      return;

    this.setDiscountInItem(this.data.item);
    await this.saveWorkflowVersion(this.data.workflowVersion);
  }

  tabHandle(event: any, where: string, nameField: any) {
    if (event.key === 'Tab') {
      if (where == 'cost') {
        if (!this.form.get('price')?.value || !(this.form.get('price')?.value > 0)) {
          event.preventDefault();
          nameField.focus();
        }
      } else if (where === 'discount') {
        event.preventDefault();
        nameField.focus();
      }
    }
  }

  checkDiscount(): boolean {
    if (this.form.get('discountType')?.value === 'percent') {
      return this.form.get('discountPercent')?.value <= 100;
    } else {
      return +this.form.get('discountAmount')?.value <= +this.form.get('price')?.value;
    }
  }
}
