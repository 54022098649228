import { Component, Input } from '@angular/core';
import { UserProfile, UserRole } from '../../../../../../common/src/lib/models/user-profile.model';
import { capitalizeFirstChar } from 'projects/common/src/public-api';

@Component({
  selector: 'app-business-button',
  templateUrl: './business-button.component.html',
  styleUrls: ['./business-button.component.scss']
})
export class BusinessButtonComponent {

  @Input() businessName!: string;
  @Input() selected!: boolean;
  @Input() user!: UserProfile;
  @Input() notificationsCount!: number;

  capitalizeFirstChar = capitalizeFirstChar;
}
