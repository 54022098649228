<mat-form-field class="date-picker">
  <mat-date-range-input [min]="min" [max]="max" [rangePicker]="picker">
    <input matStartDate [formControl]="dateFilterFromControl">
    <input matEndDate [formControl]="dateFilterToControl">
  </mat-date-range-input>
  <mat-date-range-picker
    #picker
    [restoreFocus]="false"
    (opened)="datePickerOpen = true; status.emit(datePickerOpen)"
    (closed)="datePickerOpen = false; status.emit(datePickerOpen)"
  ></mat-date-range-picker>
</mat-form-field>
<div class="date-filter" 
  [class.set]="(dateFilterText$ | async) !== placeholder"
  [class.open]="datePickerOpen" 
  (click)="picker.open()"
>
  <div class="calendar"
    maskImageSrc="../../../../../../assets/icons/calendar.svg"
  ></div>
  <h4>{{ dateFilterText$ | async }}</h4>
  <app-icon 
    class="se-icon-button no-bg mr--4"
    *ngIf="showDateFilterClearButton$ | async" 
    src="close_md"
    (click)="clearDateFilterEvent($event)"
  ></app-icon>
</div>
