import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { combineLatest, of, BehaviorSubject } from 'rxjs';
import { BusinessProfile } from 'projects/common/src/lib/models/business-profile.model';
import { map, switchMap, shareReplay, take, distinctUntilChanged, filter } from 'rxjs/operators';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { UserProfile } from '../../../../common/src/lib/models/user-profile.model';
import { rpcFilter, SupabaseService } from "./supabase.service";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { serverToFrontTranslation } from 'projects/common/src/lib/services/supabase.service';

export interface CreateBusiness extends Omit<BusinessProfile, 'businessId' | 'logo' | 'salesTax' | 'createdAt' | 'id'> {
  userUuid: string,
  firstName: string,
  lastName: string,
  email: string,
}

export interface BusinessAndUserResult {
  data: { userData: UserProfile, businessData: BusinessProfile }[],
  lastLoggedIn: string | null
}

function BUSINESS_AND_USER_CHANGES_HANDLER(changes: RealtimePostgresChangesPayload<any>, data: BusinessAndUserResult) {
  if(changes.schema === 'app_data')
    return null;

  if(changes.eventType === 'UPDATE') {
    if (changes.table === 'business') {
      const index = data.data.findIndex(e => e.businessData.businessId === changes.schema);
      data.data[index].businessData = serverToFrontTranslation(changes.new);
    } else if (changes.table === 'user') {
      const index = data.data.findIndex(e => e.userData.id === (changes.old as UserProfile).id);
      data.data[index].userData = serverToFrontTranslation(changes.new);
    }
  }

  return data;
}

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  businessesAndUsers$ = this.authService.user$.pipe(
      switchMap((user) => {
        if(!user)
          return of(null);

        const tablesGenerator = (res: any) => {
          const tables: any[] = [
            { schema: 'app_data', table: 'user_business_ids' }
          ];
          for(const data of res.data) {
            tables.push({ schema: data.businessData.businessId, table: 'business' });
            tables.push({ schema: data.businessData.businessId, table: 'user', filter: rpcFilter('id', 'eq', data.userData.id) });
          }
          return tables;
        };

        return this.supabaseService.multiSchemaRPC<BusinessAndUserResult>(
          {
            cName: 'businessAndUser',
            fn: 'get_business_and_user_data',
            schema: 'app_data',
            options: { in_user_uuid: user.uid }
          },
          tablesGenerator,
          BUSINESS_AND_USER_CHANGES_HANDLER,
        );
      }),
      map((results: BusinessAndUserResult | null) => {
        if(!results)
          return null;
        const businesses = results.data;
        if(!businesses || businesses.length === 0) {
          if(!this.router.url.startsWith('/join'))
            this.router.navigate(['/create']);
          return {} as BusinessAndUserResult;
        }
        if(this._selectedBusinessSubject.value === null) {
          this._selectedBusinessSubject.next(results.lastLoggedIn);
        }

        return results;
      }),
      shareReplay(1)
  );

  availableBusinesses$ = this.businessesAndUsers$.pipe(
    filter(res => !!res),
    map(res => {
      if(!res?.data) {
        return [];
      }
      return res!.data.map(i => i.businessData);
    }),
    shareReplay(1)
  );

  private _selectedBusinessSubject = new BehaviorSubject<string | null>(null);
  selectedBusiness$ = this.authService.user$.pipe(
      switchMap(user => {
        if(!user) {
          this._selectedBusinessSubject.next(null);
          return of(null);
        }
        return combineLatest([this.businessesAndUsers$, this._selectedBusinessSubject]).pipe(
            filter(([businessesAndUsers, selectedBusiness]) => !!businessesAndUsers?.lastLoggedIn || !!selectedBusiness),
            distinctUntilChanged(),
            switchMap(([businessesAndUsers, selectedBusiness]) => {
              return this.supabaseService.rpc<BusinessProfile>({
                cName: 'business_'+selectedBusiness,
                fn: 'get_business_profile',
                schema: selectedBusiness ?? businessesAndUsers!.lastLoggedIn!,
                tables: ['business']
              });
            }),
        );
      }),
      shareReplay(1)
  );

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private supabaseService: SupabaseService,
    private window: Window,
  ) {}

  businessData(id: string) {
    return this.supabaseService.rpc<BusinessProfile>({
      cName: 'businessData',
      fn: 'get_business_profile',
      schema: id,
      tables: ['business']
    });
  }

  async selectBusiness(businessId: string, navigateToLobby: boolean = true) {
    const ab = await this.availableBusinesses$.pipe(take(1)).toPromise();
    const availableBusinesses = ab.map(b => b.businessId);
    if (availableBusinesses.length > 0 && !availableBusinesses.includes(businessId)) {

      this.router.navigate(['/']);
      return;
    }
    await this.saveSelectedBusiness(businessId, navigateToLobby);
  }

  async saveSelectedBusiness(businessId: string, navigateToLobby: boolean = false) {
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    await this.supabaseService.update(
        'app_data',
        'user_business_ids',
        { key: 'user_id', value: user!.uid },
        { last_logged_in: businessId }
    );
    if (navigateToLobby)
      setTimeout(async () => {
        await this.router.navigate(['/lobby']);
        this.window.location.reload();
      }, 200);
    else
      this.window.location.reload();
  }

  async createBusiness(data: CreateBusiness): Promise<string | null> {
    try {
      const res = await this.supabaseService.edgeFunc<{
        message: string,
        newUserId: number,
        businessId: string
      }>('create-business', data);
      const businessId = res.businessId;
      this.selectBusiness(businessId);
      return businessId;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updateBusiness(profile: Partial<BusinessProfile>, showProfileUpdateSnackbar: boolean = true) {
    const business = await this.selectedBusiness$.pipe(take(1)).toPromise();
    const res = await this.supabaseService.update(
      business!.businessId,
      'business',
      { key: 'business_id', value: business!.businessId},
      profile
    );
    if (showProfileUpdateSnackbar) {
      showSnackbar(this.snackBar, {
        message: "Profile updated",
      });
    }
    return res;
  }

  async acceptInvitation(businessId: string, userId: number) {
    try {
      await this.supabaseService.rpcFunc('app_data', 'accept_invitation', {
        inBusinessId: businessId,
        inUserRecordId: userId
      });
      this.removeInvitationFromStorage();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  fileSizeValid(file: File) {
    return file.size <= 1024 * 1024 * 2;
  }

  fileFormatValid(file: File) {
    const type = file.type.split('/').pop()!.toLowerCase();
    return !(type != "jpeg" && type != "jpg" && type != "png" && type != "gif");
  }

  async saveLogo(file: File | null) {
    const business = (await this.selectedBusiness$.pipe(take(1)).toPromise())!;
    if (file) {
      const splitFileName = file.name.split('.');
      const fileName = business.businessId + '.' + splitFileName[splitFileName.length - 1] + `?v=${Date.now()}`;
      const result = await this.supabaseService.uploadFile('logos', fileName, file);
      await this.updateBusiness({ logo: result.data.publicUrl }, true);

      showSnackbar(this.snackBar, {
        message: "Profile updated",
      });

    } else {
      if(!business.logo)
        return;

      const splitFileName = business.logo!.split('.');
      const fileName = business.businessId + '.' + splitFileName[splitFileName.length - 1];
      const previousFile = await this.supabaseService.downloadFile('logos', fileName);
      if(!previousFile) {
        await this.updateBusiness({ logo: null }, true);
        return;
      }

      await Promise.all([
        this.supabaseService.deleteFile('logos', previousFile!.name),
        this.updateBusiness({ logo: null }, true)
      ]);

      showSnackbar(this.snackBar, {
        message: "Logo Deleted",
        duration: 10000,
        actionText: 'Undo',
        action: async () => {
          if (previousFile) {
            const result = await this.supabaseService.uploadFile('logos', previousFile.name, previousFile);
            await this.updateBusiness({ logo: result.data.publicUrl }, true);
          }
        }
      });

    }
  }

  saveInvitation(invitationsLink: string) {
    localStorage.setItem('invitation_link', invitationsLink);
  }

  getInvitationFromStorage(): string | null {
    return localStorage.getItem('invitation_link');
  }

  removeInvitationFromStorage() {
    localStorage.removeItem('invitation_link');
  }

}
