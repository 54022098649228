import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseTileData } from "../base-tile/base-tile.component";
import { NgxMaskService } from "ngx-mask";
import { timer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { EstimateTileData } from "../../models/estimate.model";
import { ScheduleService } from '../../services/schedule.service';
import { ViewAsService } from '../../services/view-as.service';

@Component({
  selector: 'app-estimate-tile',
  templateUrl: './estimate-tile.component.html',
  styleUrls: ['./estimate-tile.component.scss']
})
export class EstimateTileComponent implements OnInit {

  @Input() document!: EstimateTileData;
  @Input() showDocType = false;

  @Output() tileClick = new EventEmitter<EstimateTileData>();
  @Output() deleteEstimate = new EventEmitter<EstimateTileData>();
  data!: BaseTileData;

  reverseTimeOrder$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.endTime.getTime() < Date.now();
      })
  );
  startTitle$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.startTime.getTime() < Date.now() ? 'Started' : 'Starts';
      })
  );
  endTitle$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return this.document.endTime.getTime() < Date.now() ? 'Ended' : 'Ends';
      })
  );

  showOwner$ = this.viewAsService.selectedUsers$.pipe(map(users => users === 'All' || users.length > 1));

  currentStatusFilter$ = this.scheduleService.filterEstimateStatus$;

  readonly timeFormat = 'hh:mm a';

  constructor(
      private maskService: NgxMaskService,
      private scheduleService: ScheduleService,
      private viewAsService: ViewAsService,
  ) {}

  refreshData() {
    const phoneNumber = this.maskService.applyMask(this.document.phoneNumber, '(000) 000-0000');
    this.data = {
      title: this.document.firstName + ' ' + this.document.lastName,
      subtitle: phoneNumber,
      description: this.document.jobType
    };
  }

  ngOnInit() {
    this.refreshData();
  }

  async onDeleteClick(event: Event) {
    event.stopPropagation();
    this.deleteEstimate.emit(this.document);
  }
}
