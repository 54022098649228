import { CommonPoIItem } from "../models/poi.model";
import * as moment from 'moment'

export function currencify(value: number | null, thousandSeparation = false): string {
  if (typeof value !== 'number' || !value)
    return '$0.00';

  let result = value.toFixed(2);
  if (thousandSeparation)
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${value < 0 ? '-' : ''}$${result}`;
}

export function round(value: number | null, decimals = 2): string {
  if (!value || value === 0)
    return '0';

  return `${value.toFixed(decimals)}`;
}

export function formatMinutes(durationInMinutes: number): string {
  if (!durationInMinutes || isNaN(durationInMinutes))
    return '0';

  const duration = moment.duration(durationInMinutes, 'minutes'); 
  const parts: string[] = [];
  if (durationInMinutes < 60) {
      const minutes = Math.floor(durationInMinutes);
      const seconds = Math.floor((durationInMinutes - minutes) * 60);
      if (minutes > 0) parts.push(`${minutes}m`);
      if (seconds > 0) parts.push(`${seconds}s`);
  } else if (durationInMinutes < 1440) {
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());
      if (hours > 0) parts.push(`${hours}h`);
      if (minutes > 0) parts.push(`${minutes}m`);
  } else if (durationInMinutes < 43200) { // 30 days
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.hours());
      if (days > 0) parts.push(`${days}d`);
      if (hours > 0) parts.push(`${hours}h`);
  } else {
      const months = Math.floor(duration.asMonths());
      const days = Math.floor(duration.days());
      if (months > 0) parts.push(`${months}mo`);
      if (days > 0) parts.push(`${days}d`);
  }

  return parts.join(' ');
}

export function capitalize(text: string): string {
  if (!text)
    return '';

  return text[0].toUpperCase() + text.substring(1, text.length);
}

export function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

export function getItemValues(items: CommonPoIItem[], taxPercentage: number) {
  let total = 0;
  let discount = 0;
  let tax = 0;
  let cost = 0;

  items.forEach(item => {
    const discounted = !item.discountAmount
      ? item.price
      : item.price - item.discountAmount;

    const taxed = !item.taxable
      ? discounted
      : discounted + (discounted*taxPercentage/100);

    if (item.cost && item.quantity)
      cost += item.cost*item.quantity;
    discount += item.quantity && item.discountAmount ? (item.discountAmount * item.quantity) : 0;
    tax += item.taxable && item.quantity && item.price ? (taxed - discounted)*item.quantity : 0;
    total += item.quantity ? taxed*item.quantity : 0;
  });

  return {
    total: total,
    discount: discount,
    tax: tax,
    cost: cost
  };
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2)
    return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null)
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length)
    return false;

  for (const key of keys1)
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
      return false;

  return true;
}
