import { Component, Input } from '@angular/core';
import { UserRole, UserStatus } from '../../../../../../../common/src/lib/models/user-profile.model';
import { capitalizeFirstChar } from 'projects/common/src/public-api';

@Component({
  selector: 'app-role-label',
  templateUrl: './role-label.component.html',
  styleUrls: ['./role-label.component.scss']
})
export class RoleLabelComponent {

  @Input() role!: UserRole;
  @Input() status!: UserStatus;

  capitalizeFirstChar = capitalizeFirstChar;

}
