<div class="providers">
  <div class="provider w-100 d-flex justify-content-center align-items-center" (click)="googleSignIn()"
       data-cy="googleSignInButton">
    <img *ngIf="!signingWithGoogle else loading" src="../../../assets/google.svg"/>
    <p>{{ title }}</p>
  </div>
  <div *ngIf="error" class="providers-error d-flex justify-content-center align-items-center">
    <h4>{{ error }}</h4>
  </div>
</div>
<ng-template #loading>
  <mat-spinner class="mr--16" diameter="23"></mat-spinner>
</ng-template>
