import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseTileData } from '../base-tile/base-tile.component';
import { ViewAsService } from '../../services/view-as.service';
import { map } from 'rxjs/operators';
import { NgxMaskService } from 'ngx-mask';
import { PoITileData } from '../../models/poi-list.model';

@Component({
  selector: 'app-poi-tile',
  templateUrl: './poi-tile.component.html',
  styleUrls: ['./poi-tile.component.scss']
})
export class PoITileComponent implements OnInit {

  @Input() document!: PoITileData;
  @Input() showOpenedLabel = false;

  @Output() tileClick = new EventEmitter<PoITileData>();

  data!: BaseTileData;

  showCreator$ = this.viewAsService.selectedUsers$.pipe(map(users => users === 'All' || users.length > 1));

  refreshData() {
    const phoneNumber = this.maskService.applyMask(this.document.phoneNumber, '(000) 000-0000');
    this.data = {
      title: this.document.firstName + ' ' + this.document.lastName,
      subtitle: phoneNumber,
      description: this.document.jobType
    };
  }

  constructor(
    private maskService: NgxMaskService,
    private viewAsService: ViewAsService
  ) {}
  
  ngOnInit() {
    this.refreshData();
  }
}
