<div class="main-payment-actions">
  <div class="content">
    <app-icon class="se-icon-button" src="plus"
              [matMenuTriggerFor]="addPaymentMenu"
              *ngIf="!payment.paymentSummary.canceled"
    ></app-icon>
    <div *ngIf="payment.paymentSummary.canceled"></div>
    <div class="cancel"
         [class.hide-cancel]="!cancelVisible"
         (click)="handleJob()"
    >
      {{ !payment.paymentSummary.canceled ? 'Cancel' : 'Restore' }} Job
      <div class="cover" *ngIf="!(showCancelElement$ | async)"></div>
    </div>
  </div>
</div>

<mat-menu #addPaymentMenu xPosition="before" class="no-margin">
  <ng-template matMenuContent>
    <mat-option (click)="addPayment('payment')">Payment</mat-option>
    <mat-option (click)="addPayment('expense')" *ngIf="expenseAccess">Expenses</mat-option>
    <mat-option (click)="addPayment('tech_part')">Tech parts</mat-option>
  </ng-template>
</mat-menu>
