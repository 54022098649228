import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UserProfile } from '../../../../common/src/lib/models/user-profile.model';
import { UsersService } from './users.service';
import { take, distinctUntilChanged, map, filter, shareReplay } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from './auth.service';
import { singleUserPages } from '../app-routing.module';

export const canOpenSingleUserPage = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
  const viewAsService = inject(ViewAsService);
  const usersService = inject(UsersService);
  const selectedUsers = await viewAsService.selectedUsers$.pipe(take(1)).toPromise();
  const currentUsers = await usersService.currentUser$.pipe(take(1)).toPromise();
  if(Array.isArray(selectedUsers) && selectedUsers.length === 1 && selectedUsers[0].id === currentUsers.id)
    return true;

  viewAsService.viewAsButtonComponent?.onNavigationToSingleUserPage();
  return false;
}

export interface ViewAsComponent {
  onNavigationToSingleUserPage: () => void
}

@Injectable({
  providedIn: 'root'
})
export class ViewAsService {

  private _selectedUserSubject = new BehaviorSubject<number[] | 'All'>([]);
  changePrep$ = new BehaviorSubject<UserProfile[] | "All">([]);
  selectedUsers$ = combineLatest([
    this._selectedUserSubject.pipe(
        distinctUntilChanged((a, b) => {
          if(a === 'All' && b === 'All')
            return true;
          if(a === 'All' || b === 'All')
            return false;
          if(a.length !== b.length)
            return false;
          for(const v of a)
            if(!b.includes(v))
              return false;
          return true;
        }),
    ),
    this.usersService.users$.pipe(filter(users => !!users))
  ]).pipe(
    map(([selectedUserIds, users]) => {
      if(selectedUserIds === 'All') {
        return 'All';
      }
      return users!.filter(user => selectedUserIds.find(uid => uid === user.id));
    }),
    filter(users => users === 'All' || users.length > 0),
    shareReplay(1)
  );

  selectedUsersIds$ = this.selectedUsers$.pipe(
    map(users => {
      if (users === 'All')
        return null;

      return users.map(user => user.id);
    })
  );

  hasChanges$ = combineLatest([
    this.selectedUsers$,
    this.changePrep$
  ]).pipe(
      map(([users, selected]) => {
        if(users === 'All' && selected === 'All')
          return false;

        if(users === 'All' || selected === 'All')
          return true;

        if(users.length !== selected.length)
          return true;

        for (const user of users) {
          if(!selected.find(u => u.id === user.id)) {
            return true;
          }
        }
        return false;
      }),
  );

  viewAsButtonComponent?: ViewAsComponent;

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.usersService.currentUser$
      .pipe(distinctUntilChanged((x: UserProfile, y: UserProfile) => {
        if (x.id !== y.id)
          this.clearSavedState();
        return x.id === y.id;
      }))
      .subscribe(() => this.restoreState());

    this.authService.user$
      .pipe(
        distinctUntilChanged(), 
        filter(user => !user)
      )
      .subscribe(() => this._setCurrentUser());
  }

  private async _setCurrentUser() {
    const user = await this.usersService.currentUser$.pipe(take(1)).toPromise();
    this.changePrep$.next([user]);
    this.setUsers([user!]);
  }

  private async restoreState() {
    const path = location.pathname.split('/').pop();
    if (path && !singleUserPages.includes(path)) {
      try {
        const savedListStr = localStorage.getItem('view-as');
        if (savedListStr) {
          const savedList = JSON.parse(savedListStr) as number[] | 'All';
          if (savedList === 'All') {
            this.changePrep$.next('All');
            this.setUsers('All');
            return;
          }
          const users = (await this.usersService.users$.pipe(take(1)).toPromise())?.filter(user => savedList.includes(user.id));
          if (users?.length) {
            this.changePrep$.next(users);
            this.setUsers(users);
            return;
          }
        }
      } catch {}
    }
    this._setCurrentUser();
  }

  private saveState() {
    localStorage.setItem('view-as', JSON.stringify(this._selectedUserSubject.value));
  }

  clearSavedState() {
    localStorage.removeItem('view-as');
  }

  setUsers(users: UserProfile[] | number[] | 'All') {
    this._selectedUserSubject.next(users === 'All' ? 'All' : users.map(user => typeof user === 'number' ? user : user.id));
    this.saveState();
  }

  addUser(user: UserProfile) {
    let value = this._selectedUserSubject.value;
    if(value === 'All') {
      value = [user.id];
    } else {
      if(!!value.find(uid => uid === user.id))
        return;  
      value.push(user.id);
    }
    this._selectedUserSubject.next(value);
  }

  removeUser(user: UserProfile) {
    const value = this._selectedUserSubject.value;
    if(value === 'All') {
      return;
    }
    const index = value.findIndex(uid => uid === user.id);
    if (index > -1) {
      value.splice(index, 1);
    }
    this._selectedUserSubject.next(value);
  }

  async applyChanges() {
    let users = this.changePrep$.value === 'All' ? this.changePrep$.value : Array.from(this.changePrep$.value);
    this.setUsers(users);
    this.router.navigateByUrl('/loading', {skipLocationChange: true})
      .then(() => this.router.navigate(['/']));
    return true;
  }

  async clearChanges() {
    let users = await this.selectedUsers$.pipe(take(1)).toPromise();
    if(users !== 'All')
      users = Array.from(users);
    this.changePrep$.next(users);
  }
}
