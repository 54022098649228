import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  FormArray, FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  UntypedFormControl
} from "@angular/forms";
import { AddressComponents, addressComponentsEmpty } from "../../../../../common/src/lib/models/address-comments.model";
import { FormsService } from "../../../../../common/src/lib/services/forms.service";
import { capitalizeFirstChar, isValidEmail, UtilsService } from "../../../../../common/src/lib/services";
import { componentsToString } from "../../../../../common/src/lib/pipes/address.pipe";
import { UserSelectComponent } from "../user-select/user-select.component";
import { UserSearchBarComponent } from "../user-search-bar/user-search-bar.component";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { PlacesService } from '../../../../../common/src/lib/services/places.service';
import { UserProfile } from "../../../../../common/src/lib/models/user-profile.model";
import { TimeRange } from "../../../../../common/src/lib/models/time-range.model";
import { UsersService } from "../../services/users.service";
import moment from "moment";
import { AvailabilityService } from "../../services/availability.service";
import { map, take } from "rxjs/operators";
import { capitalize } from '../../helpers/common';
import { TimetableService } from '../../services/timetable.service';
import { ClientSuggestionsService } from "../../services/client-suggestion";
import { ClientsService } from "../../services/clients.service";
import { applyPhoneNumberMask, removePhoneNumberMask } from "../../../../../common/src/lib/utils/phone-number-mask";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import {
  MultiformErrors,
  RequestInvitationFormData,
  RequestInvitationModalInitialData
} from "../../modals/request-invitation/request-invitation.model";

function formError<E, T extends keyof E>(errors: E, formControlName: T) {
  if(!errors[formControlName]) {
    return null;
  }
  const res: any = {};
  (res as any)[formControlName] = true;
  return res;
}

// function equalDates(a: Date, b: Date) {
//   return a.getFullYear() === b.getFullYear()
//     && a.getMonth() === b.getMonth()
//     && a.getDate() === b.getDate()
//     && a.getHours() === b.getHours()
//     && a.getMinutes() === b.getMinutes()
// }

@Component({
  selector: 'app-request-invitation-form',
  templateUrl: './request-invitation-form.component.html',
  styleUrls: ['./request-invitation-form.component.scss']
})
export class RequestInvitationFormComponent implements AfterViewInit, OnDestroy, OnChanges {
  static readonly BREAKPOINT = 'sm';

  @ViewChild('jobTypeInput') jobTypeInput!: ElementRef;
  @ViewChild('businessNameInput') businessNameInput!: ElementRef;
  @ViewChild('firstNameInput') firstNameInput!: ElementRef;
  @ViewChild('lastNameInput') lastNameInput!: ElementRef;
  @ViewChild('phoneNumberInput') phoneNumberInput!: ElementRef;
  @ViewChild('extNumberInput') extNumberInput!: ElementRef;
  @ViewChild('addressInput') addressInput!: ElementRef<HTMLInputElement>;
  @ViewChild('unitInput') unitInput!: ElementRef;
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('footer') footer!: ElementRef;
  @ViewChild('userSelect') userSelect!: UserSelectComponent;
  @ViewChildren('ranges') rangesInputs!:  QueryList<ElementRef>;
  @ViewChild('userSearch') userSearch?: UserSearchBarComponent;

  @ViewChild('addressInput', {read: NgControl})
  addressInputDirective!: NgControl;

  @Input() data?: RequestInvitationFormData;
  @Input() initialData?: RequestInvitationModalInitialData;

  @Input() showDetails!:  boolean | null;
  @Input() showNotes!:  boolean | null;

  @Input() tabletScreen!:  boolean | null;
  @Input() isIOS?:  boolean | null;
  @Input() middleScreen!: boolean | null;

  @Input() isEdit?: boolean;
  @Input() isReschedule?: boolean;

  // @Input() assignedUsers: UserProfile[] = [];

  @Input() emailOptional!: boolean;
  @Input() assigneesOptional!: boolean;
  @Input() scheduleOptional!: boolean;

  @Input() isProposalDialog?: boolean;
  @Input() isJobDialog? : boolean;
  @Input() isEstimateDialog? : boolean;
  @Input() isLeadDialog?: boolean;

  // @Input() isClientBusiness?: boolean;

  @Output() openPreviewModal = new EventEmitter<void>();
  @Output() deleteRequestConfirm = new EventEmitter<void>();
  @Output() availabilityModalVisibilityChanged = new EventEmitter<boolean>();
  @Output() selectedTabSubjectValue = new EventEmitter<string>();
  @Output() datePickerStateChange = new EventEmitter<boolean>();
  // @Output() selectedDialogType = new EventEmitter<string>();

  singleAssigneeInList!: boolean;
  user?: any;

  checkFormFilled?: boolean

  jobTypeFocused = false;
  firstNameFocused = false;
  businessNameFocused = false;
  lastNameFocused = false;
  phoneNumberFocused = false;
  extNumberFocused = false;
  addressFocused = false;
  unitFocused = false;
  emailFocused = false;

  @Input('availabilityModalVisible') availabilityModalVisible = false;
  selectedTabSubject = new BehaviorSubject<string>('Details');
  currentAssigneeSubject = new BehaviorSubject<UserProfile | null>(null);

  readonly defaultEstimateDuration = 15;

  currentUser$ = this.usersService.currentUser$;

  form = this.formBuilder.group({
    dialogType: new FormControl<'Lead' | 'Estimate' | 'Proposal' | 'Job'>('Lead', { nonNullable: true }),
    jobType: new FormControl('', { nonNullable: true }),
    client: this.formBuilder.group({
      businessName: new FormControl(''),
      firstName: new FormControl('', { nonNullable: true }),
      lastName: new FormControl('', { nonNullable: true }),
      phoneNumber: new FormControl('', { nonNullable: true }),
      extNumber: new FormControl(''),
      address: new FormControl<AddressComponents | null>(null),
      unit: new FormControl(''),
      email: new FormControl(''),
      type: new FormControl<'Personal' | 'Business'>('Personal', { nonNullable: true }),
    }, { nonNullable: true }),
    assignees: new FormControl<UserProfile[]>([], { nonNullable: true }),
    ranges: this.formBuilder.array([this.createNewRange()]),
  });

  isBusinessClientType$ = this.form.controls.client.controls.type.valueChanges.pipe(
    map(clientType => {
      return clientType === 'Business';
    })
  );

  unassignedUsers = new Set<number>();
  assigneesSub?: Subscription;

  clientSuggestionsService = new ClientSuggestionsService(this.clientsService, this.form.controls.client);

  constructor(
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private utilsService: UtilsService,
    private placesService: PlacesService,
    private usersService: UsersService,
    private availabilityService: AvailabilityService,
    private timetableService: TimetableService,
    private clientsService: ClientsService
  ) { }
  jobTypeControl = this.form.get('jobType')!;
  clientControl = this.form.get('client')!;
  rangesArray = this.form.get('ranges')! as FormArray<FormGroup>;

  addressPredictions$: Observable<google.maps.places.AutocompletePrediction[] | null> | undefined;

  async ngAfterViewInit() {
    this.addressPredictions$ = await this.placesService.getPlaceAutocomplete(this.addressInput, 'address');
    if(this.data) {
      const data: any = this.data;
      if(this.data.assignees) {
        const users = await this.usersService.users$.pipe(take(1)).toPromise();
        data.assignees = this.data.assignees.map(user => users!.find(u => u.id === user.id)!);
      }
      if(this.futureRanges?.length) {
          for(const range of this.futureRanges) {
            this.rangesArray.push(this.createNewRange(
              new Date(range.startTime.getFullYear(), range.startTime.getMonth(), range.startTime.getDate()),
              range.startTime,
              range.endTime
            ));
          }
          this.rangesArray.removeAt(0);
      }
      if(this.data.client?.type)
        data.client.type = capitalize(data.client.type);

      if(data.client?.phoneNumber) {
        data.client.phoneNumber = applyPhoneNumberMask(data.client.phoneNumber);
      }
      this.form.patchValue(data);
      if(!!this.data.client?.address && typeof this.data.client.address === 'object') {
        this.onPlacesOptionSelected(this.data.client.address);
      }
    }
    
    this.assigneesSub = this.form.controls.assignees.valueChanges.subscribe(() => {
      for (let i = 0; i < this.rangesArray.controls.length; i++) {
        this.rangesArray.controls[i].get('overlap')?.setValue([]);
        this.rangeOverlap(i);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (['isLeadDialog', 'isJobDialog', 'isEstimateDialog', 'isProposalDialog'].some(v => Object.keys(changes).includes(v))) {
      this.resetAllErrors();
    }
  }

  datePickerChange(state: boolean) {
    this.datePickerStateChange.emit(state);
  }
  
  unfocusClientSuggestionField() {
    this.clientSuggestionsService.focusedField = null;
  }

  onClientSuggestionSelected(event: MatAutocompleteSelectedEvent) {
    this.clientSuggestionsService.activeOption = null;
    this.clientSuggestionsService.hoveredOption = null;
    if(!event.option)
      return;
    const value = event.option.value;
    value.phoneNumber = applyPhoneNumberMask(value.phoneNumber);
    value.type = capitalizeFirstChar(value.type);
    const address = value.address;
    delete value.address;
    const self = this;
    setTimeout(() => {
      self.form.controls.client.patchValue(value, { emitEvent: true });
      if(address)
        this.onPlacesOptionSelected(address);
    }, 10);
  }

  onPhoneNumberInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const maskedValue = applyPhoneNumberMask(value);
    this.form.controls.client.get('phoneNumber')!.setValue(maskedValue, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this.assigneesSub?.unsubscribe();
  }

  get pastRanges() {
    return this.isEdit ? (this.data?.ranges?.filter((range) => moment(range.startTime).isSameOrBefore(this.today())) ?? []) : [];
  }

  get futureRanges() {
    return this.isEdit ? (this.data?.ranges?.filter((range) => moment(range.startTime).isAfter(this.today())) ?? []) : this.data?.ranges;
  }

  updateDialogType(type: string) {
    this.form.get('dialogType')?.setValue(type as "Lead" | "Estimate" | "Proposal" | "Job");
  }

  changeAvailabilityVisibility(visible: boolean, event?: Event) {
    event?.preventDefault();
    event?.stopImmediatePropagation();
    this.availabilityModalVisibilityChanged.emit(visible);
    this.selectedTabSubjectValue.emit('Details');
  }

  getDate(index: number, type: 'Start' | 'End') {
    const range = this.rangesArray.at(index);
    const date = range.get('date')?.value ? moment(range.get('date')?.value) : null;
    let time = range.get(`time${type}`)?.value as Date | null | undefined;
    if (!(date && time)) {
      return null;
    }
    time = new Date(time);
    time.setFullYear(date.year(), date.month(), date.date());
    return time;
  }

  getRange(index: number) {
    const startTime = this.getDate(index, 'Start');
    const endTime = this.getDate(index, 'End');
    if (!(startTime && endTime)) {
      return null;
    }
    return {startTime, endTime};
  }

  hasRequiredError<T extends keyof MultiformErrors>(key: T, subKey?: string) {
    const controls = (subKey ? this.form.controls[key] : this.form.controls) as any;
    const useKey = subKey ?? key;
    const value = subKey ? controls.controls[useKey].value : controls[useKey].value;
    let hasRequiredError = false
    if (useKey === 'ranges') {
      if (value.length === 1) {
        const obj = value[0];
        Object.keys(obj).forEach(key => {
          if (key !== 'overlap' && obj[key] === '') {
            const rangeControl = this.rangesArray.controls[0].get(key);
            if (rangeControl) {
              rangeControl.setErrors({ required: true });
              hasRequiredError = true;
            }
          }
        });
      }
    }

    if (!value || value === '') {
      hasRequiredError = true;
      if (subKey)
        controls.controls[useKey].setErrors({required: true});
      else
        controls[useKey].setErrors({required: true});
    }

    return hasRequiredError;
  }


  hasPhoneNumberError() {
    if(this.hasRequiredError('client', 'phoneNumber'))
      return true;
    const control = this.form.controls['client'].controls['phoneNumber'];
    const value = removePhoneNumberMask(control.value);
    if(value.length < 10) {
      control.setErrors({ invalidNumber: true });
      return true;
    }
    return false;
  }

  hasEmailErrors() {
    if (!this.emailOptional && this.hasRequiredError('client', 'email'))
      return true;

    const control = this.form.controls['client'].get('email');
    if (control?.value && !isValidEmail(control!.value)) {
      control!.setErrors({
        invalidEmail: true
      });
      return true
    }
    return false;
  }

  hasAddressErrors() {
    if (this.hasRequiredError('client', 'address'))
      return true;
    const control = this.form.controls['client'].get('address');
    if (control!.hasError('missingComponents')) {
      return true;
    }
    const isAddressString = typeof control!.value === 'string';

    if (isAddressString) {
      control!.setErrors({
        addressIsString: true
      });
      return true;
    }
    return false;
  }

  hasRangesErrors() {
    let hasErrors = false;
    for (let i = 0; i < this.rangesArray.controls.length; i += 1) {
      const range = this.rangesArray.at(i);
      const controls = [range.get('date')!, range.get('timeStart')!, range.get('timeEnd')!];
      for (let control of controls) {
        const value = control.value;
        if(!value || (value === '')) {
          control.setErrors({ required: true });
          hasErrors = true;
        } else if (!control.valid) {
          hasErrors = true;
        }
      }
    }
    return hasErrors;
  }

  validRange(index: number) {
    const range = this.rangesArray.at(index);
    const controls = [range.get('date')!, range.get('timeStart')!, range.get('timeEnd')!];
    for (let control of controls) {
      this.formsService.resetErrors(control);
    }
    let validDate = this.validDate(index);
    let validTime = this.validTime(index);

    if (validTime && !range.get('date')?.value) {
      this.setDefaultDate(index);
      validDate = true;
    }

    if (!validDate || !validTime) {
      range.get('overlap')!.setValue([]);
      return false;
    }

    const {startTime, endTime} = this.getRange(index)!;
    const setErrors = (error: string) => {
      for (let control of controls) {
        control.touched ? control.setErrors({[error]: true}) : setTimeout(() => control.setErrors({[error]: true}));
      }
      range.markAllAsTouched();
      this.scrollToFirstError();
    }
    if (startTime.getTime() < Date.now()) {
      setErrors(moment(startTime).startOf('day').isSame(moment().startOf('day')) ? 'pastTime' : 'pastDate');
      range.get('overlap')!.setValue([]);
      return false;
    }
    if (endTime.getTime() <= startTime.getTime()) {
      setErrors('reversedDate');
      range.get('overlap')!.setValue([]);
      return false;
    }
    return true;
  }

  validDate(index: number) {
    const range = this.rangesArray.at(index);
    const dateControl = range.get('date')!;
    const timeStartControl = range.get(`timeStart`)!;
    const timeEndControl = range.get(`timeEnd`)!;
    let dateValue = dateControl.value;
    if (!dateValue) {
      return false;
    }

    const checkDate = new RegExp(/^\d\d\/\d\d\/\d\d\d\d$/);
    let month, day, year;
    if (typeof dateValue === 'string') {
      if (!checkDate.test(dateValue)) {
        dateControl.setErrors({
          date: true
        });
        return false;
      }
      [month, day, year] = dateValue.split('/').map(v => +v);
      month -= 1;
    } else {
      dateValue = moment(dateValue);
      month = dateValue.month();
      day = dateValue.date();
      year = dateValue.year();
    }

    if (month > 11 || month < 0) {
      month = moment().month();
    }
    if (year < moment().year()) {
      year = moment().year();
    }
    const maxDays = moment().year(year).month(month).daysInMonth();
    if (day > maxDays || day < 1) {
      day = maxDays;
    }

    const newValue = moment().year(year).month(month).date(day);
    dateControl.setValue(newValue, {emitEvent: false});

    if (index === 0 && !timeStartControl.value && !timeEndControl.value) {
      this.setDefaultTime(index);
    }

    return true;
  }

  validTime(index: number) {
    const range = this.rangesArray.at(index);
    const date = range?.get('date')?.value;
    const timeStart = range?.get('timeStart')?.value;
    const timeEnd = range?.get('timeEnd')?.value;
    if (index === 0 && timeStart && !timeEnd && !date) {
      this.setDefaultEndTime(index);
      return true;
    }
    return !!(timeStart && timeEnd);
  }

  rangeChanged(index: number) {
    if (!this.isEstimateDialog && !this.isJobDialog) {
      return false;
    }
    const range = this.getRange(index);
    const docType = this.initialData?.dialogType;
    if (!range) {
      return docType === 'Estimate' || docType === 'Job';
    }
    
    if (docType === 'Estimate' || docType === 'Job') {
      const prevRanges = this.initialData?.ranges ?? null;
      const { startTime, endTime } = range;
      return !this.isEdit || !prevRanges?.find((range) => moment(range.startTime).isSame(startTime) && moment(range.endTime).isSame(endTime));
    }
    return !!range;
  }

  setDefaultTime(index: number) {
    const time = new Date();
    time.setHours(time.getHours() + 1);
    time.setMinutes(0);
    const range = this.rangesArray.at(index);
    range.get(`timeStart`)?.setValue(time, {emitEvent: true});
    this.setDefaultEndTime(index);
  }

  setDefaultDate(index: number) {
    const range = this.rangesArray.at(index);
    const now = moment();
    const startTime = range.get('timeStart')?.value as Date;
    const date = moment().hours(startTime.getHours()).minutes(startTime.getMinutes());
    range?.get('date')?.setValue(date > now ? date.toDate() : date.add(1, 'day'));
  }

  setDefaultEndTime(index: number) {
    const range = this.rangesArray.at(index);
    if (!range.get(`timeStart`)?.value) {
      return;
    }
    const timeStart = moment(range.get(`timeStart`)?.value);
    let timeEnd = moment(timeStart).add(this.defaultEstimateDuration, 'minutes');
    if (timeEnd > timeStart.endOf('day')) {
      timeEnd = timeStart.endOf('day');
    }
    range.get(`timeEnd`)?.setValue(timeEnd, {emitEvent: true});
  }

  scrollToFirstError() {
    setTimeout(() => {
      const errorField = document.querySelector('.mat-form-field.ng-invalid') ?? document.querySelector('.time-input.ng-invalid');
      errorField?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    });
  }

  isFormValid() {
    this.form.markAllAsTouched();
    let hasErrors = false;
    const value = this.form.value;
    if (!this.isProposalDialog && this.hasRequiredError('jobType'))
      hasErrors = true;
    if(value.client.type === 'Business') {
      if (this.hasRequiredError('client', 'businessName'))
        hasErrors = true;
    } else {
      if (this.hasRequiredError('client', 'firstName'))
        hasErrors = true;
      if (this.hasRequiredError('client', 'lastName'))
        hasErrors = true;
    }
    if (this.hasEmailErrors())
      hasErrors = true;
    if (this.hasPhoneNumberError())
      hasErrors = true;
    if (this.hasAddressErrors())
      hasErrors = true;
    if ((this.isEstimateDialog || this.isJobDialog) && this.hasRangesErrors())
      hasErrors = true;
    if (hasErrors)
      this.scrollToFirstError();
    return !hasErrors;
  }

  setErrors(errors: MultiformErrors, formControls?: { [key: string]: AbstractControl }) {
    if (!formControls) {
      formControls = this.form.controls;
    }

    const keys = Object.keys(formControls) as (keyof MultiformErrors)[];

    for (const key of keys) {
      const control = formControls[key];

      if (control instanceof FormControl) {
        // Direct form controls
        const error = formError(errors, key);
        control.setErrors(error ? error : null);
      } else if (control instanceof FormArray) {
        // Array elements
        const arrayErrors = errors[key] as Array<any> | undefined;
        if (arrayErrors) {
          for (const subControl of control.controls) {
            const index = control.controls.indexOf(subControl);
            if (subControl instanceof FormGroup) {
              this.setErrors(arrayErrors[index] || {}, subControl.controls);
            } else if (subControl instanceof FormControl) {
              const subError = arrayErrors[index] ? formError(arrayErrors[index], key as any) : null; // Adjust the key type if needed
              subControl.setErrors(subError ? subError : null);
            }
          }
        } else {
          for (const subControl of control.controls) {
            if (subControl instanceof FormGroup) {
              this.setErrors({}, subControl.controls);
            } else if (subControl instanceof FormControl) {
              subControl.setErrors(null);
            }
          }
        }
      } else if (control instanceof FormGroup) {
        // Nested groups
        const groupErrors = errors[key] as { [key: string]: any } | undefined;
        this.setErrors(groupErrors || {}, control.controls);
      }
    }
  }

  today() {
    return moment();
  }

  get isClientBusiness() {
    return this.form.controls.client.controls.type.value === 'Business';
  }

  resetAllErrors() {
    for (let control of Object.keys(this.form.controls)) {
      this.form.get(control)!.setErrors(null);
    }
    for (let control of Object.keys((this.clientControl as FormGroup).controls)) {
      this.clientControl.get(control)!.setErrors(null);
    }
    for (let rangeGroup of this.rangesArray.controls) {
      for (let control in rangeGroup.controls) {
        rangeGroup.controls[control].setErrors(null);
      }
    }
  }

  createNewRange(date?: Date, timeStart?: Date, timeEnd?: Date) {
    return this.formBuilder.group({
      date: new UntypedFormControl({value: date ?? '', disabled: false}),
      timeStart: new UntypedFormControl({value: timeStart ?? '', disabled: false}),
      timeEnd: new UntypedFormControl({value: timeEnd ?? '', disabled: false}),
      overlap: new UntypedFormControl([])
    });
  }

  jobTypeShiftTabClick(event: Event) {
    if (this.isProposalDialog || this.isLeadDialog) {
      this.unitInput.nativeElement.focus();
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    const inputs = document.querySelectorAll('.time-input input');
    (inputs[inputs.length - 1] as HTMLInputElement)?.focus();
    event.preventDefault();
    event.stopPropagation();
  }

  onClientTypeChange() {
    this.formsService.resetErrors(this.clientControl.get('firstName')!);
    this.formsService.resetErrors(this.clientControl.get('lastName')!);
    this.formsService.resetErrors(this.clientControl.get('businessName')!);
  }

  onKeyPress(userId: number, event: KeyboardEvent, rangeIndex?: number, rangeInputName?: 'date' | 'timeStart' | 'timeEnd') {
    if (event.key === 'Enter') {
      this.nextField(userId, rangeIndex, rangeInputName);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  nextField(userId: number, rangeIndex?: number, rangeInputName?: 'date' | 'timeStart' | 'timeEnd') {
    if (!this.utilsService.isMobile()) {
      this.invite(userId);
      return;
    }

    if (rangeIndex !== undefined) {
      const rangeBlock = (this.rangesInputs.get(rangeIndex)?.nativeElement as HTMLElement);
      if (rangeInputName === 'date') {
        (rangeBlock.querySelector('.time-input-start input[formcontrolname="time"]') as HTMLInputElement).focus();
        return;
      }
      if (rangeInputName === 'timeStart') {
        (rangeBlock.querySelector('.time-input-start input[formcontrolname="time"]') as HTMLInputElement).blur();
        (rangeBlock.querySelector('.time-input-end input[formcontrolname="time"]') as HTMLInputElement).focus();
        return;
      }
      if (rangeInputName === 'timeEnd') {
        if (rangeIndex !== this.rangesInputs.length - 1) {
          ((this.rangesInputs.get(rangeIndex + 1)?.nativeElement as HTMLElement).querySelector('input[formcontrolname="date"]') as HTMLInputElement).focus();
          return;
        }
        (rangeBlock.querySelector('.time-input-end input[formcontrolname="time"]') as HTMLInputElement).blur();
        this.invite(userId);
        return;
      }
    }

    if (this.businessNameFocused) {
      this.firstNameInput.nativeElement.focus();
      return;
    }
    if (this.firstNameFocused) {
      this.lastNameInput.nativeElement.focus();
      return;
    }
    if (this.lastNameFocused) {
      this.phoneNumberInput.nativeElement.focus();
      return;
    }
    if (this.phoneNumberFocused) {
      this.extNumberInput.nativeElement.focus();
      return;
    }
    if (this.extNumberFocused) {
      this.emailInput.nativeElement.focus();
      return;
    }
    if (this.emailFocused) {
      this.addressInput.nativeElement.focus();
      return;
    }
    if (this.addressFocused) {
      this.unitInput.nativeElement.focus();
      return;
    }
    if (this.isLeadDialog || this.isProposalDialog) {
      if (this.unitFocused) {
        this.invite(userId);
        return;
      }
    }
    if (this.unitFocused) {
      ((this.rangesInputs.first.nativeElement as HTMLElement).querySelector('input[formcontrolname="date"]') as HTMLInputElement).focus();
      return;
    }
  }

  async invite(userId: number) {
    return
  }

  onPlacesOptionSelected(place: AddressComponents) {
    this.addressInputDirective.valueAccessor?.writeValue(componentsToString(place as any));
    this.clientControl.get('address')!.setValue(place, { emitModelToViewChange: false });

    if(addressComponentsEmpty(place)) {
      this.clientControl.get('address')!.setErrors({ missingComponents: true });
      this.clientControl.get('address')!.markAsTouched();
    } else {
      this.clientControl.get('address')!.setErrors(null);
    }
  }

  unitTabClick(event: Event) {
    if (this.isProposalDialog || this.isLeadDialog) {
      this.jobTypeInput.nativeElement.focus();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onAssigneesChange(users: UserProfile[]) {
    // const request = (this.data.request ?? this.data.initialRequest)!;
    // this.assignedUsers = users;
    // if (request.docType !== 'Job') {
    //   request['assignee'] = users[0]?.id ?? '';
    
    // } else {
    //   setTimeout((() => {
    //     // const isEdit = this.isEdit && this.dialogType === this.data.initialRequest?.docType;
    //     request['assignees'] = this.assignee;
    //   }).bind(this), 5);
    // }
  }

  handleClearButtonClick() {
    // const request = (this.data.request ?? this.data.initialRequest)!
    // request.keepInLoop = false ;
  }

  assignedUserFullName(user: UserProfile) {
    if (user)
      return user.firstName + ' ' + user.lastName.charAt(0) + '.';
    return ;
  }

  unAssignUser(assignedUser: UserProfile): void {
    this.unassignedUsers.add(assignedUser.id);
    this.form.controls.assignees.setValue(this.form.controls.assignees.value.filter(user => user.id !== assignedUser.id));
  }

  resetDateAndTimeErrors(range: FormGroup) {
    this.formsService.resetErrors(range.get('date')!);
    this.formsService.resetErrors(range.get('timeStart')!);
    this.formsService.resetErrors(range.get('timeEnd')!);
    range.get('overlap')?.setValue([]);
  }

  async getUnavailablesByTimetable(range: TimeRange, users: UserProfile[]) {
    const day = moment(range.startTime).day();
    const ids = users.map(u => u.id);
    const busyIds: number[] = [];
    const availability = await this.availabilityService.getUnavailabilityForRange(moment(range.startTime).startOf('day'), moment(range.startTime).endOf('day'), ids);
    for (const userId of ids) {
      const workingRanges = (await this.timetableService.userTimetableObservable(userId).pipe(take(1)).toPromise()).ranges[day];
      const availableRanges = workingRanges ?? availability.filter(event => event.userId === userId && event.available);
      if (!this.utilsService.isTimeRangeCovered(range, availableRanges))
        busyIds.push(userId);
    }
    return busyIds;
  }

  async getUnavailablesBySchedule(range: TimeRange, users: UserProfile[]) {
    const rangeFilter = { 
      from: moment(range.startTime).startOf('day').toDate(),
      to: moment(range.endTime).endOf('date').toDate() 
    };

    let events = await this.availabilityService.getScheduleForUsers(users.map(u => u.id), rangeFilter, true);
    if (this.isEdit && this.initialData) { 
      events = events.filter(event => !((this.isEstimateDialog && event.itemType === 'estimate' || this.isJobDialog && event.itemType === 'job') && event.id === this.initialData!.id));
    }

    return events
      .filter(event => !(event.endTime <= range.startTime || event.startTime >= range.endTime) && event.status !== 'canceled' && !event.available)
      .map(event => event.users?.length ? 
        event.users.filter(user => users.map(u => u.id).includes(user.userId) && ['accepted', 'pending'].includes(user.acceptanceStatus)).map(u => u.userId) : 
        (event.ownerId ?? event.userId)
      )
      .flat();
  }

  async rangeOverlap(index: number) {
    if (!this.isEstimateDialog && !this.isJobDialog)
      return false;

    this.rangesArray.at(index).get('date')!.clearValidators();
    if (!this.validRange(index) || !this.rangeChanged(index))
      return false;

    let assignees = this.form.controls.assignees?.value.length
      ? this.form.controls.assignees.value
      : [(await this.usersService.currentUser$.pipe(take(1)).toPromise())];

    const range = this.getRange(index)!;
    
    if (!assignees.length || this.today().isAfter(range.startTime))
      return false;

    let overlapUsersIds = await this.getUnavailablesByTimetable(range, assignees);
    if (overlapUsersIds.length < assignees.length)
      overlapUsersIds.push(...(await this.getUnavailablesBySchedule(range, assignees.filter(assingee => !overlapUsersIds.includes(assingee.id)))));
    
    this.rangesArray.at(index).get('overlap')?.setValue(assignees.filter(assingee => overlapUsersIds.includes(assingee.id)));
    return overlapUsersIds.length > 0;
  }

  async checkOverlapAndScrollToWarning(index: number, isTouched: boolean) {
    const isOverlap = await this.rangeOverlap(index);
    if (isOverlap && isTouched) {
      setTimeout(() => {
        const formBottom = (document.querySelector('.form-content') as HTMLElement)?.getBoundingClientRect().bottom;
        const item = document.querySelectorAll('.time-range-block').item(index)?.querySelector('.schedule-warning') as HTMLElement | null;
        if (!item || !formBottom) {
          return;
        }
        const itemBottom = item.getBoundingClientRect().bottom;
        if (itemBottom > formBottom) {
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          });
        }
      });
    }
  }

  maskInput(event: Event, formControl: string | AbstractControl, mask: string) {
    let control = typeof formControl === 'string' ? this.form.get(formControl)! : formControl;
    this.utilsService.maskInput(event.target as HTMLInputElement, control, mask);
  }

  timeEndTabClick(event: Event, lastRange: boolean) {
    if (lastRange) {
      this.jobTypeInput.nativeElement.focus();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  deleteRangeClick(index: number) {
    const range = this.rangesArray.at(index);
    if (this.rangesArray.length === 1) {
      range.get('date')!.setValue(undefined, {emitEvent: true});
      range.get('timeStart')!.setValue(undefined, {emitEvent: true});
      range.get('timeEnd')!.setValue(undefined, {emitEvent: true});
      this.resetDateAndTimeErrors(range);
      return;
    }
    this.rangesArray.removeAt(index);
  }

  addRangeClick(index: number) {
    const newTimeRange = this.createNewRange();
    this.rangesArray.insert(index + 1, newTimeRange);
    setTimeout(() => {
      const formBottom = (document.querySelector('.form-content') as HTMLElement).getBoundingClientRect().bottom;
      const item = document.querySelectorAll('.time-range-block').item(index + 1) as HTMLElement;
      const itemBottom = item.getBoundingClientRect().bottom;
      if (itemBottom > formBottom) {
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }
    });
  }

  getOverlapWarningMessage(currentUser: UserProfile, rangeIndex: number): string {
    const assingees = this.form.controls.assignees.value;
    const overlappedAssignees = this.rangesArray.at(rangeIndex).get('overlap')!.value as UserProfile[];
    let addText = '';
    if (!assingees.length || overlappedAssignees.length === 1 && overlappedAssignees[0].id === currentUser.id) {
      addText = 'your working hours';
    } else if (overlappedAssignees.length === 1) {
      addText = `${overlappedAssignees[0].firstName}${/s|z|x/.test(overlappedAssignees[0].firstName[overlappedAssignees[0].firstName.length - 1]) ? '\'' : '\'s'} working hours`;
    } else {
      addText = `${overlappedAssignees.length} users' working hours`;
    }

    return `Warning! This time is out of ${addText}`;
  }

  openPreviewModalClick() {
    this.openPreviewModal.emit();
  }

  async deleteRequestConfirmClick() {
    this.deleteRequestConfirm.emit();
  }

  protected readonly applyPhoneNumberMask = applyPhoneNumberMask;
}
