<div #sectionDivider class="section-divider d-flex align-items-center" [class.aligned-text]="alignedText" [class.danger]="type === 'danger'">
  <h4 *ngIf="!scheduleToday && !texts">{{ text }}</h4>
  <div *ngIf="!text && texts" class="d-flex justify-content-between w-100">
    <h4 *ngFor="let textTest of texts">{{ textTest }}</h4>
  </div>
  <div *ngIf="scheduleToday" class="d-flex">
    <h4 class="today-text" *ngIf="todayBefore !== ''">{{todayBefore}}&nbsp;</h4>
    <h4>{{headerDate}}</h4>
    <h4 class="today-text" *ngIf="todayAfter !== ''">&nbsp;{{todayAfter}}</h4>
  </div>
  <div class="flex-fill">
    <ng-content></ng-content>
  </div>
</div>
