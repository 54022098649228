<div class="d-flex flex-column">
    <app-profile-logo class="profile-logo"></app-profile-logo>

    <app-section-divider text="Info" [alignedText]="true"></app-section-divider>

    <app-profile-field
      class="info"
      fieldTitle="Role:"
      [value]="capitalizeFirstChar(user.role)"
      [largeField]="true"
      [disableEdit]="true"
    ></app-profile-field>

    <app-profile-field
      #businessNameInputField
      class="info"
      fieldTitle="Business name:"
      [value]="profile.businessName"
      [largeField]="true"
      [onSave]="saveBusinessName.bind(this)"
      addressType="establishment"
      [objectToString]="businessNameToString"
      [disableEdit]="(disableFieldEdit$ | async)!"
    ></app-profile-field>

    <app-profile-field
      [value]="profile.address"
      class="info"
      fieldTitle="Business address:"
      [largeField]="true"
      [onSave]="saveAddress.bind(this)"
      addressType="address"
      [objectToString]="addressToString"
      [disableEdit]="(disableFieldEdit$ | async)!"
    ></app-profile-field>

    <app-profile-field
      class="info"
      fieldTitle="Business phone number:"
      inputmode="tel"
      [value]="profile.phoneNumber"
      [largeField]="true"
      (onChange)="phoneNumberSubject.next($event)"
      [onSave]="saveBusinessPhoneNumber.bind(this)"
      mask="(000) 000-0000"
      [alternativeEdit]="(disableFieldEdit$ | async)!"
      (onEdit)="onBusinessPhoneNumberEditClick()"
      [initialError]="phoneNumberError"
      [disableEdit]="(disableFieldEdit$ | async)!"
    ></app-profile-field>
</div>