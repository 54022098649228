<div class="proposal-preview-and-sent" *ngIf="proposal && currentProposalVersion">

  <div class="d-flex gap--12 preview-and-sent-header">
    <div class="header-text">PROPOSAL</div>
    <div class="preview-and-sent-badge"
         *ngIf="badge"
         [class]="badge"
    >
      {{getBadgeCopy(badge)}}
    </div>
  </div>

  <div class="preview-and-sent-content">
    <div class="prepared-by" [style.transform]="'scale(' + (previewScaleFactor$ | async) + ')'">
      <div>
        <div class="text fs14 ln20 ls1">{{currentProposalVersion.creatorBusinessName}}</div>
        <div class="mt--6 d-flex flex-column gap--4">
          <div class="text">{{currentProposalVersion.creatorFirstName + ' ' + currentProposalVersion.creatorLastName}}</div>
          <div class="text" *ngIf="currentProposalVersion.creatorPhoneNumber">{{currentProposalVersion.creatorPhoneNumber | mask: '(000) 000-0000'}}</div>
          <div class="text" *ngIf="!sentMode">{{(business$ | async)!.address | address }}</div>
          <div class="text" *ngIf="sentMode">{{ currentProposalVersion.creatorAddress | address }}</div>
          <div class="text">{{currentProposalVersion.creatorEmail}}</div>
        </div>
      </div>
      <div [class.d-flex]="(business$ | async)!.logo"
           [class.justify-content-center]="(business$ | async)!.logo"
           [class.width--120]="(business$ | async)!.logo"
      >
        <app-profile-logo [enabled]="false" *ngIf="(business$ | async)!.logo" [noBorder]="true"></app-profile-logo>
        <ng-container *ngIf="!(business$ | async)!.logo" [ngTemplateOutlet]="uid"></ng-container>
      </div>
    </div>

    <div class="prepared-for" [style.transform]="'scale(' + (previewScaleFactor$ | async) + ')'">
      <div>
        <div class="text fs14 ln20 ls1 g700">PREPARED FOR:</div>
        <div class="mt--6 d-flex flex-column gap--4">
          <div class="text">{{currentProposalVersion.clientBusinessName}}</div>
          <div class="text">
            {{currentProposalVersion.clientFirstName + ' ' + currentProposalVersion.clientLastName}}
          </div>
          <div class="text">{{currentProposalVersion.clientPhoneNumber! | mask: '(000) 000-0000'}}</div>
          <div class="text">{{currentProposalVersion.clientAddress | address }}</div>
          <div class="text" *ngIf="currentProposalVersion.clientUnit">
            {{currentProposalVersion.clientUnit}}
          </div>
          <div class="text">{{currentProposalVersion.clientEmail}}</div>
        </div>
      </div>
      <ng-container *ngIf="(business$ | async)!.logo" [ngTemplateOutlet]="uid"></ng-container>
    </div>

    <div class="preview-and-sent-data" [style.transform]="'scale(' + (previewScaleFactor$ | async) + ')'">
      <div class="preview-and-sent-note"
           *ngIf="!isEmpty(currentProposalVersion.note)"
      >
        <div class="label">Proposal Note</div>
        <div class="note-text">{{currentProposalVersion.note}}</div>
      </div>

      <div class="d-flex flex-column gap--12 w-100" [class.mt--12]="!isEmpty(currentProposalVersion.note)">
        <div *ngFor="let proposalItem of currentProposalVersion.items" class="preview-and-sent-item">
          <div class="d-flex gap--22 justify-content-between">
            <div class="name-description">
              <div class="fs14 ln20 ls1 fw-600 cb">{{proposalItem.name}}</div>
              <div class="text fw-400" *ngIf="!isEmpty(proposalItem.description)">{{proposalItem.description}}</div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="d-flex justify-content-between">
            <div class="d-flex gap--48">
              <div class="item-data-block">
                <div class="label">Price</div>
                <div class="data">${{proposalItem.price.toFixed(2)}}</div>
              </div>
              <div class="item-data-block" *ngIf="proposalItem.discountAmount">
                <div class="label">Discount</div>
                <div class="data">-${{proposalItem.discountAmount.toFixed(2)}}</div>
              </div>
              <div class="item-data-block">
                <div class="label">Tax</div>
                <div class="data">
                  ${{ getItemTaxAmount(
                        proposalItem.price,
                        proposalItem.taxable,
                        proposalItem.discountAmount ?? null
                  ).toFixed(2) }}
                </div>
              </div>
              <div class="item-data-block">
                <div class="label">Qty</div>
                <div class="data tac">{{proposalItem.quantity}}</div>
              </div>
            </div>
            <div class="item-data-block">
              <div class="label">Amount</div>
              <div class="data tar">
                ${{ getItemTotal(
                      proposalItem.price,
                      proposalItem.taxable,
                      proposalItem.discountAmount ?? null,
                      proposalItem.quantity ?? null
                ).toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt--40 signature-text pl--16" *ngIf="currentProposalVersion.signature">Signature</div>
      <div [class.mt--40]="!currentProposalVersion.signature"
           [class.mt--8]="currentProposalVersion.signature"
           class="d-flex justify-content-between px--16"
      >
        <div>
          <div class="signature-block" *ngIf="currentProposalVersion.signature"
               [class.signed]="currentProposalVersion.signatureData?.status === 'signed'"
          >
            <div class="signature-text" *ngIf="currentProposalVersion.signatureData?.status !== 'signed'">Required</div>
            <ng-container *ngIf="currentProposalVersion.signatureData?.status === 'signed'">
              <img [src]="currentProposalVersion.signatureData?.signatureUrl" alt="signature" width="196px" height="92px">
            </ng-container>
          </div>
        </div>
        <div class="preview-and-sent-summary">
          <div class="preview-and-sent-summary-data">
            <div class="d-flex flex-column gap--8">
              <div class="d-flex justify-content-between">
                <div class="details">Subtotal</div>
                <div class="details">${{subtotal.toFixed(2)}}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="details">Discount</div>
                <div class="details">-${{discount.toFixed(2)}}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="details">Sales Tax</div>
                <div class="details">${{salesTax.toFixed(2)}}</div>
              </div>
              <div class="d-flex justify-content-between"
                   *ngIf="currentProposalVersion.depositAmount && currentProposalVersion.status !== 'canceled'"
              >
                <div class="details">Total</div>
                <div class="details">${{total.toFixed(2)}}</div>
              </div>
            </div>
            <div class="divider"></div>
            <div class="d-flex justify-content-between"
                 *ngIf="!currentProposalVersion.depositAmount || currentProposalVersion.status === 'canceled'"
            >
              <div class="total">Total</div>
              <div class="total">${{total.toFixed(2)}}</div>
            </div>
            <div class="d-flex justify-content-between"
                 *ngIf="currentProposalVersion.depositAmount && currentProposalVersion.status !== 'canceled'"
            >
              <div class="total">Deposit</div>
              <div class="total">${{(currentProposalVersion.depositAmount).toFixed(2)}}</div>
            </div>
            <ng-container *ngIf="currentProposalVersion.status !== 'canceled' && sentMode">
              <ng-container *ngIf="getDepositValues() let depositValues">
                <div class="d-flex justify-content-between" *ngIf="depositValues.paid > 0">
                  <div class="total paid">Paid</div>
                  <div class="total paid">${{depositValues.paid.toFixed(2)}}</div>
                </div>
                <div class="d-flex justify-content-between"
                     *ngIf="depositValues.pending > 0 && currentProposalVersion.status !== 'accepted'"
                >
                  <div class="total pending">Pending</div>
                  <div class="total pending">${{depositValues.pending.toFixed(2)}}</div>
                </div>
                <div class="d-flex justify-content-between" *ngIf="depositValues.pending < 0 && !proposal.invoiceId">
                  <div class="total refund">Refund</div>
                  <div class="total refund">-${{(-depositValues.pending).toFixed(2)}}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="paidAmount && !sentMode && currentProposalVersion.status !== 'canceled'">
              <div class="d-flex justify-content-between" *ngIf="paidAmount > 0">
                <div class="total paid">Paid</div>
                <div class="total paid">${{paidAmount.toFixed(2)}}</div>
              </div>
              <ng-container *ngIf="currentProposalVersion?.depositAmount">
                <ng-container *ngIf="(currentProposalVersion?.depositAmount! - paidAmount!) let pending">
                  <div class="d-flex justify-content-between" *ngIf="pending > 0">
                    <div class="total pending">Pending</div>
                    <div class="total pending">${{pending?.toFixed(2)}}</div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="currentProposalVersion.depositAmount">
                <ng-container *ngIf="(currentProposalVersion.depositAmount - paidAmount!) let refund">
                  <div class="d-flex justify-content-between" *ngIf="refund < 0">
                    <div class="total refund">Refund</div>
                    <div class="total refund">-${{(-refund)?.toFixed(2)}}</div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #uid>
  <div class="d-flex flex-column gap--4">
    <div class="d-flex">
      <div class="uid-label">PROPOSAL:&nbsp;</div>
      <div class="uid-data">{{ proposal.uid ? proposal.uid + ' - ' + (currentProposalVersion.subUid ?? nextSubUid()) : '' }}</div>
    </div>
    <ng-container *ngIf="date$ | async let date">
      <div class="d-flex">
        <div class="uid-label">DATE:&nbsp;</div>
        <div class="uid-data">{{date.createdDate}}</div>
      </div>
      <div class="d-flex">
        <div class="uid-label">TIME:&nbsp;</div>
        <div class="uid-data">{{date.createdTime}}</div>
      </div>
    </ng-container>
  </div>
</ng-template>
