import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { SupabaseService } from "../../services/supabase.service";
import { PaymentReport, PaymentReportItem } from "../../models/reports.model";
import { limitStep } from "../../services/reports-service-extras";
import { workflowChangesHandler } from "../../services/reports.service";

export const paymentTables = [
  'workflow_user',
  'user',
  'payment',
  'invoice',
  'invoice_version',
  'invoice_version_client',
  'proposal',
  'proposal_version',
  'proposal_version_client',
  'paid',
  'refunded'
];

export function paymentsAllPaid(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
    cName: 'payments_all_paid',
    schema: businessId,
    fn: 'payments_all_paid',
    tables: paymentTables,
    options: {
      start_time: startTime,
      end_time: endTime,
      ...(selectedUsers && { selected_users: selectedUsers })
    }
  },
    _ => null,
    'report'
  );
}

export function paymentsAllPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
    cName: 'payments_all_pending',
    schema: businessId,
    fn: 'payments_all_pending',
    tables: paymentTables,
    options: {
      start_time: startTime,
      end_time: endTime,
      ...(selectedUsers && { selected_users: selectedUsers })
    }
  },
    _ => null,
    'report'
  );
}

export function paymentsAllTotalSales(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
    cName: 'payments_all_total_sales',
    schema: businessId,
    fn: 'payments_all_total_sales',
    tables: paymentTables,
    options: {
      start_time: startTime,
      end_time: endTime,
      ...(selectedUsers && { selected_users: selectedUsers })
    }
  },
    _ => null,
    'report'
  );
}

export function paymentsAllList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<PaymentReportItem[]>({
    cName: 'payments_all_report',
    schema: businessId,
    fn: 'payments_all_report',
    tables: [...paymentTables, 'workflow'],
    options: {
      row_limit: limitStep,
      offset_count: 0,
      start_time: startTime,
      end_time: endTime,
      ...(selectedUsers && { selected_users: selectedUsers })
    }
  },
    workflowChangesHandler,
    'report'
  );
}

export function paymentsAllListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
    cName: 'payments_all_report_count',
    schema: businessId,
    fn: 'payments_all_report_count',
    tables: paymentTables,
    options: {
      start_time: startTime,
      end_time: endTime,
      ...(selectedUsers && { selected_users: selectedUsers })
    }
  },
    _ => null,
    'report'
  );
}

export function paymentsAllAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_all_report_ids',
      schema: businessId,
      fn: 'payments_all_report_ids',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initPaymentsAll(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    paymentsAllPaid(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsAllPending(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsAllTotalSales(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsAllListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsAllAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([paid, pending, totalSales, count, allIds]) => {
      return {
        type: 'all',
        paid: (paid as unknown as any)[0].paid,
        needsRefund: (paid as unknown as any)[0].needsRefund,
        pending: pending as unknown as number,
        totalSales: totalSales as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as PaymentReport;
    })
  );
}

export function paymentsPaidTotal(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_paid_total_paid',
      schema: businessId,
      fn: 'payments_paid_total_paid',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPaidAvgSalePerDay(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_paid_avg_sale_per_day',
      schema: businessId,
      fn: 'payments_paid_avg_sale_per_day',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPaidAvgSalePerJob(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_paid_avg_sale_per_jobs',
      schema: businessId,
      fn: 'payments_paid_avg_sale_per_jobs',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPaidAvgJobsPerDay(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_paid_avg_jobs_per_day',
      schema: businessId,
      fn: 'payments_paid_avg_jobs_per_day',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPaidList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<PaymentReportItem[]>({
      cName: 'payments_paid_report',
      schema: businessId,
      fn: 'payments_paid_report',
      tables: [...paymentTables, 'workflow'],
      options: {
        row_limit: limitStep,
        offset_count: 0,
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    workflowChangesHandler,
    'report'
  );
}

export function paymentsPaidListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_paid_report_count',
      schema: businessId,
      fn: 'payments_paid_report_count',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPaidAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_paid_report_ids',
      schema: businessId,
      fn: 'payments_paid_report_ids',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initPaymentsPaid(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    paymentsPaidTotal(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPaidAvgSalePerDay(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPaidAvgSalePerJob(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPaidAvgJobsPerDay(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPaidListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPaidAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([paid, avgSalePerDay, avgSalePerJob, avgJobsPerDay, count, allIds]) => {
      return {
        type: 'paid',
        paid: (paid as unknown as any)[0].paid,
        needsRefund: (paid as unknown as any)[0].needsRefund,
        avgSalePerDay: avgSalePerDay as unknown as number,
        avgSalePerJob: avgSalePerJob as unknown as number,
        avgJobsPerDay: avgJobsPerDay as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as PaymentReport;
    })
  );
}

export function paymentsPendingPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_pending_pending',
      schema: businessId,
      fn: 'payments_pending_pending',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPendingJobs(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_pending_jobs',
      schema: businessId,
      fn: 'payments_pending_jobs',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPendingList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<PaymentReportItem[]>({
      cName: 'payments_pending_report',
      schema: businessId,
      fn: 'payments_pending_report',
      tables: [...paymentTables, 'workflow'],
      options: {
        row_limit: limitStep,
        offset_count: 0,
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    workflowChangesHandler,
    'report'
  );
}

export function paymentsPendingListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_pending_report_count',
      schema: businessId,
      fn: 'payments_pending_report_count',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function paymentsPendingAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'payments_pending_report_ids',
      schema: businessId,
      fn: 'payments_pending_report_ids',
      tables: paymentTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initPaymentsPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    paymentsPendingPending(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPendingJobs(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPendingListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    paymentsPendingAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([pending, jobs, count, allIds]) => {
      return {
        type: 'pending',
        pending: pending as unknown as number,
        jobs: jobs as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as PaymentReport;
    })
  );
}
