import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SupportChatService } from "../../services/support-chat.service";
import { hasNonWhitespaceChars, trimHtmlString, UtilsService } from '../../services';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('attachmentInput') attachmentInput!: ElementRef<HTMLInputElement>;
  @ViewChild('sendButton') sendButton!: ElementRef<Element>;

  @Input() messageInput = this.chatService.messageInput;
  @Input() disabled = false;

  @Output() sendEvent = new EventEmitter<string>();
  @Output() addFileEvent = new EventEmitter<File>();

  hasNonWhitespaceChars = hasNonWhitespaceChars;

  constructor(
    private chatService: SupportChatService,
    private utilsService: UtilsService,

  ) { }

  ngOnInit(): void {
    if (this.disabled)
      this.chatService.messageInput.disable();
    else
      this.chatService.messageInput.enable();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.sendButton.nativeElement.classList.add('enable-transition'), 200);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('disabled' in changes) {
      if (changes.disabled.currentValue)
        this.chatService.messageInput.disable();
      else
        this.chatService.messageInput.enable();
    }
  }

  sendMessage() {
    if (!hasNonWhitespaceChars(this.messageInput.value))
      return;

    const content = trimHtmlString(this.messageInput.value);
    if (content) {
      this.sendEvent.emit(content);
      this.chatService.messageInput.setValue('');
    }
  }

  addFile() {
    const file = this.attachmentInput.nativeElement.files?.item(0);
    if (this.messageInput.value! !== '')
      this.chatService.messageInput.setValue(this.messageInput.value!);
    if(file)
      this.addFileEvent.emit(file);
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.utilsService.isMobile())
      return;
    if (!event.shiftKey && event.key === 'Enter') {
      this.sendMessage();
      event.preventDefault();
    }
  }
}
