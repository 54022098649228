<div class="main-invoice-summary" *ngIf="invoiceSummaryValues">

  <div class="values">
    <div class="section">
      <div class="text">Subtotal</div>
      <div class="d-flex">
        <div class="text">$</div>
        <div class="text fw-600">{{invoiceSummaryValues.subtotal.toFixed(2)}}</div>
      </div>
    </div>

    <div class="section"
         *ngIf="
         !isNaN(+invoiceSummaryValues.discountDisplay)
         && invoiceSummaryValues.discountDisplay !== '0'
         && invoiceSummaryValues.discountDisplay !== '0.00'"
    >
      <div class="d-flex align-items-center gap--10">
        <div class="text">Discount</div>
        <div class="discount-switcher">
          <div class="option" (click)="toggleDiscountType('percent')"
               [class.active]="invoiceVersion.discountType === 'percent'"
          >
            %
          </div>
          <div class="option" (click)="toggleDiscountType('amount')"
               [class.active]="invoiceVersion.discountType === 'amount'"
          >
            $
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <div class="text">-</div>
          <div class="text">{{currentDiscountType === 'amount' ? '$' : '&nbsp;'}}</div>
        </div>
        <div class="text fw-600">{{invoiceSummaryValues.discountDisplay}}</div>
        <div class="text" *ngIf="currentDiscountType === 'percent'">%</div>
      </div>
    </div>

    <div class="section">
      <div class="text">Sales Tax</div>
      <div class="d-flex">
        <div class="text">$</div>
        <div class="text fw-600">{{invoiceSummaryValues.salesTax.toFixed(2)}}</div>
      </div>
    </div>
  </div>

  <div class="total-line"></div>

  <div class="d-flex justify-content-between align-items-center">
    <div class="text">Total</div>
    <div class="text fw-600">${{invoiceSummaryValues.total.toFixed(2)}}</div>
  </div>

  <!--<ng-container *ngIf="balance$ | async let balance">
    <div class="d-flex justify-content-between align-items-center mt--24" *ngIf="balance.paid">
      <div class="text">Paid</div>
      <div class="text fw-600 paid-color">${{balance.paid.toFixed(2)}}</div>
    </div>

    <div class="total-line" *ngIf="balance.paid"></div>

    <ng-container *ngIf="balance.balanceDue >= 0">
      <div class="d-flex justify-content-between align-items-center" [class.mt--12]="!balance.paid">
        <div class="text fw-700">Balance Due</div>
        <div class="text fw-700">${{balance.balanceDue.toFixed(2)}}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="balance.balanceDue < 0">
      <div class="d-flex justify-content-between align-items-center" [class.mt--12]="!balance.paid">
        <div class="text fw-700">Balance Due</div>
        <div class="text fw-700">${{(0).toFixed(2)}}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt--8">
        <div class="text fw-700 refund-color">Refund</div>
        <div class="text fw-700 refund-color">-${{(-balance.balanceDue).toFixed(2)}}</div>
      </div>
    </ng-container>
  </ng-container>-->

  <div class="d-flex align-items-center justify-content-between mt--24">
    <div class="d-flex align-items-center gap--8">
      <div class="d-flex align-items-center gap--4">
        <div class="fw-600 text">Signature</div>
        <app-icon src="question-mark-tooltip"
                  matTooltip="Request a signature from your customer"
                  [matTooltipShowDelay]="300"
                  class="scale--85"
                  #tooltip="matTooltip"
                  (click)="tooltip.show()"
                  [hideTooltipOnScroll]="tooltip"
        ></app-icon>
      </div>
      <mat-slide-toggle [checked]="invoiceVersion.signature!"
                        (change)="toggleSignature($event.checked)"
                        class="slide-toggle"
      ></mat-slide-toggle>
    </div>
    <div class="text required" *ngIf="invoiceVersion.signature">Required</div>
  </div>

</div>
