<div class="created-actions"
     *ngIf="currentInvoiceVersion?.status === 'created' && !preview"
>
  <ng-container *ngIf="{ value: editableFocused$ | async } as ef">
    <button mat-flat-button (click)="deleteInvoice()" class="delete-button">Delete</button>
    <button mat-flat-button color="primary" class="width--116 tab-view"
            (click)="preview = true; previewEvent.emit(preview)"
            [disabled]="(currentInvoiceVersion.items ?? []).length === 0 || ef.value === true"
    >
      Preview
    </button>
  </ng-container>
</div>

<div *ngIf="currentInvoiceVersion?.status === 'created' && preview" class="preview-actions">
  <div class="button-block">
    <div class="preview-button back-button"
         (click)="preview = false; previewEvent.emit(preview)"
    >
      Back
    </div>
    <loader-button [adjustWidth]="false"
                   [loading]="sendLoading" text="Send"
                   (click)="sendEvent.emit()"
    ></loader-button>
  </div>
</div>

<div *ngIf="currentInvoiceVersion?.status !== 'created'" class="sent-actions">
  <div [matMenuTriggerFor]="actionsMenu"
       class="actions-button"
       [class.opened-actions]="openedActions"
       (menuOpened)="openedActions = true"
       (menuClosed)="openedActions = false"
       *ngIf="currentInvoiceVersion.status !== 'canceled' && currentInvoiceVersion.clientInvoiceId"
       [class.spinner-inside-actions]="downloading"
  >
    <ng-container *ngIf="!downloading">
      <div class="copy">Actions</div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
           [class.opened-menu]="openedActions"
           class="chevron"
      >
        <path d="M11.989 16.4993C11.7558 16.4998 11.5297 16.4184 11.3501 16.2693L5.36062 11.2693C5.15676 11.0996 5.02856 10.8556 5.00422 10.5912C4.97989 10.3268 5.06141 10.0635 5.23085 9.8593C5.40029 9.65508 5.64378 9.52666 5.90774 9.50228C6.1717 9.4779 6.43452 9.55956 6.63838 9.7293L11.989 14.2093L17.3397 9.8893C17.4418 9.80623 17.5593 9.7442 17.6854 9.70677C17.8115 9.66934 17.9438 9.65724 18.0746 9.67118C18.2054 9.68512 18.3321 9.72482 18.4475 9.78799C18.563 9.85117 18.6648 9.93657 18.7472 10.0393C18.8386 10.1421 18.9079 10.2627 18.9506 10.3936C18.9933 10.5245 19.0086 10.6628 18.9954 10.7999C18.9823 10.9369 18.9411 11.0698 18.8743 11.1902C18.8076 11.3105 18.7167 11.4158 18.6074 11.4993L12.6179 16.3293C12.4332 16.4548 12.2117 16.5147 11.989 16.4993Z" fill="white"/>
      </svg>
    </ng-container>
    <app-dots-spinner size="x-small" color="white" *ngIf="downloading"></app-dots-spinner>
  </div>
  <div class="actions-button"
      *ngIf="currentInvoiceVersion.status === 'canceled'"
       (click)="downloadPdf()" [class.spinner-inside-pdf]="downloading"
  >
    <ng-container *ngIf="!downloading">
      <app-icon src="download"></app-icon>
      <div class="copy">PDF</div>
    </ng-container>
    <app-dots-spinner size="x-small" color="white" *ngIf="downloading"></app-dots-spinner>
  </div>
</div>

<mat-menu #actionsMenu xPosition="before" class="no-margin">
  <ng-template matMenuContent>
    <mat-option (click)="resendInvoiceVersion()" *ngIf="!downloading">Resend Invoice</mat-option>
    <mat-option (click)="downloadPdf()" *ngIf="!downloading">Download Invoice</mat-option>
  </ng-template>
</mat-menu>
