<div class="page h-100 d-flex flex-column">
    <div class="header-wrapper">
        <div class="header container">
            <img class="logo" src="../../../assets/logo.svg" routerLink="/">
            <div class="d-flex justify-content-center align-items-center gap--16">
                <app-select-business-button></app-select-business-button>
                <div class="sign-out" (click)="signOut()">
                    Sign out
                </div>
            </div>
        </div>
    </div>
    <div class="flex-fill d-flex justify-content-center align-items-center">
        <p class="message">Your account is suspended on this business</p>
    </div>
</div>