<div class="workflow-item-info-mobile" *ngIf="this.data?.proposalItem && (itemStatus$ | async) let itemStatus">

    <div class="item-info-modal-header">
        <div class="d-flex gap--8 align-items-center">
            <div>Item</div>
            <div class="modal-badge edited" *ngIf="itemStatus === 'edited'" (click)="editedTooltip.show()">
                <div>Edited</div>
                <app-icon src="question-mark-tooltip"
                          matTooltip="This item was changed from its original state in the item list"
                          [matTooltipShowDelay]="300"
                          matTooltipClass="header-tooltip"
                          class="scale--85"
                          #editedTooltip="matTooltip"
                          [hideTooltipOnScroll]="editedTooltip"
                ></app-icon>
            </div>
            <div class="modal-badge warning" *ngIf="itemStatus === 'warning'" (click)="warningTooltip.show()">
                <div>Warning</div>
                <app-icon src="question-mark-tooltip"
                          matTooltip="Deleted from item list"
                          [matTooltipShowDelay]="300"
                          matTooltipClass="warning-tooltip"
                          class="scale--85"
                          #warningTooltip="matTooltip"
                          [hideTooltipOnScroll]="warningTooltip"
                ></app-icon>
            </div>
        </div>
        <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
    </div>

    <div class="item-info-modal-content">
        <div class="content-top">
            <div class="content-top-block">
                <div class="modal-label">Item Name</div>
                <div class="modal-data">{{this.data.proposalItem.name}}</div>
            </div>
            <div class="content-divider"></div>
            <div class="content-top-block">
                <div class="modal-label">Item Description</div>
                <div class="modal-data">{{this.data.proposalItem.description}}</div>
            </div>
        </div>

        <div>
            <div class="d-flex justify-content-between w-100 align-items-center justify-content-center gap--42">
                <div class="w-100 d-flex flex-column gap--8">
                    <div class="modal-label">Price</div>
                    <div class="d-flex">
                        <div class="modal-data fw-600">$</div>
                        <div class="modal-data fw-600">{{(+this.data.proposalItem.price).toFixed(2)}}</div>
                    </div>
                </div>
                <div class="w-100 d-flex flex-column gap--8">
                    <div class="modal-label">Cost</div>
                    <div class="d-flex">
                        <div class="modal-data fw-600">$</div>
                        <div class="modal-data fw-600">{{(+this.data.proposalItem.cost).toFixed(2)}}</div>
                    </div>
                </div>
            </div>
            <div class="item-markup" [class.negative]="getMarkup() < 0">
                <div>Markup</div>
                <div>{{getMarkup().toFixed(2)}}%</div>
            </div>
            <div class="d-flex justify-content-between mt--16"
                 *ngIf="this.data.proposalItem.discountPercent && this.data.proposalItem.discountAmount"
            >
                <div class="d-flex gap--8">
                    <div class="modal-label">Discount</div>
                    <div class="modal-data-small" *ngIf="this.data.proposalItem.discountType === 'percent'">
                        {{(+this.data.proposalItem.discountPercent).toFixed(2)}}%
                    </div>
                    <div class="modal-data-small" *ngIf="this.data.proposalItem.discountType === 'amount'">
                        ${{(+this.data.proposalItem.discountAmount).toFixed(2)}}
                    </div>
                </div>
                <div>
                    <div class="modal-data-small" *ngIf="this.data.proposalItem.discountType === 'percent'">
                        ${{(+this.data.proposalItem.discountAmount).toFixed(2)}}
                    </div>
                    <div class="modal-data-small" *ngIf="this.data.proposalItem.discountType === 'amount'">
                        {{(+this.data.proposalItem.discountPercent).toFixed(2)}}%
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between mt--16">
                <div class="d-flex gap--8">
                    <div class="modal-label">Sales Tax</div>
                    <div class="modal-data-small" *ngIf="!this.data.proposalItem.taxable">0%</div>
                    <div class="modal-data-small" *ngIf="this.data.proposalItem.taxable">
                        {{taxPercentage}}%
                    </div>
                </div>
                <div *ngIf="this.data.proposalItem.taxable">
                    <div class="modal-data-small">${{getTaxAmount().toFixed(2)}}</div>
                </div>
            </div>
            <div class="content-divider mt--12 mb--8"></div>
            <div class="d-flex justify-content-between">
                <div class="modal-data fw-600">Total</div>
                <div class="modal-data fw-600">${{getTotal().toFixed(2)}}</div>
            </div>
        </div>

    </div>

</div>