import { SupabaseService } from "../../services/supabase.service";
import { paymentTables } from "./payments";
import { combineLatest, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CompareReport, CompareReportSide } from "../../models/reports.model";

export function compare(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'compare',
      schema: businessId,
      fn: 'compare',
      tables: [...paymentTables, ...['commission', 'other', 'job']],
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initCompare(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  leftStartTime?: Date,
  leftEndTime?: Date,
  rightStartTime?: Date,
  rightEndTime?: Date
) {
  return combineLatest([
    leftStartTime && leftEndTime ? compare(
      supabaseService,
      businessId,
      selectedUsers,
      leftStartTime,
      leftEndTime
    ) : of(null),
    rightStartTime && rightEndTime ? compare(
      supabaseService,
      businessId,
      selectedUsers,
      rightStartTime,
      rightEndTime
    ) : of(null)
  ]).pipe(
    map(([left, right]) => {
      return {
        left: left?.[0] ?? null as unknown as CompareReportSide | null,
        right: right?.[0] ?? null as unknown as CompareReportSide | null,
      } as CompareReport;
    }),
    startWith(null)
  );
}
