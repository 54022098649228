import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from "rxjs";
import { Proposal, ProposalVersion } from "../../models/proposal.model";
import { EditClientComponent } from "../../modals/edit-client/edit-client.component";
import { Client } from "../../../../../common/src/lib/models";
import { ModalBehavior, ModalsService } from "../../../../../common/src/lib/services/modals.service";
import { Invoice, InvoiceVersion } from "../../models/invoice.model";
import { BusinessService } from "../../services/business.service";

@Component({
  selector: 'app-workflow-prepared',
  templateUrl: './workflow-prepared.component.html',
  styleUrls: ['./workflow-prepared.component.scss']
})
export class WorkflowPreparedComponent implements OnChanges {

  @Input() workflow!: Proposal | Invoice;
  @Input() currentWorkflowVersion!: ProposalVersion | InvoiceVersion | null | undefined;
  @Input() updateSnackbarMessage!: string;
  @Input() updateFunction!: (
    currentWorkflowVersionId: number,
    form: Client
  ) => Promise<any>;

  preparedForData: any;
  preparedSwitcherState = 'prepared-by';
  dataBlockOpened = false;

  business$ = this.businessService.selectedBusiness$;

  constructor(
    private modalsService: ModalsService,
    private businessService: BusinessService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('currentWorkflowVersion' in changes)
      this.preparedForData = this.getPreparedForData(this.currentWorkflowVersion);
  }

  async openClientEdit() {
    // TODO: uncomment below, and delete auto-add
    const client = {
      ...(this.preparedForData.firstName && { firstName: this.preparedForData.firstName }),
      ...(this.preparedForData.lastName && { lastName: this.preparedForData.lastName }),
      ...(this.preparedForData.address && { address: this.preparedForData.address }),
      ...(this.preparedForData.phoneNumber && { phoneNumber: this.preparedForData.phoneNumber }),
      ...(this.preparedForData.extNumber && { extNumber: this.preparedForData.extNumber }),
      ...(this.preparedForData.email && { email: this.preparedForData.email }),
      ...(this.preparedForData.unit && { unit: this.preparedForData.unit }),
      ...(this.preparedForData.type && { type: this.preparedForData.type }),
      ...(this.preparedForData.businessName && { businessName: this.preparedForData.businessName }),
    };
    await this.modalsService.open(EditClientComponent, {
      behavior: ModalBehavior.Auto,
      disableClose: true,
      data: {
        client: client as Client,
        currentWorkflowVersionId: this.currentWorkflowVersion!.id,
        workflow: this.workflow,
        snackbarMessage: this.updateSnackbarMessage,
        updateFunction: (async (currentWorkflowVersionId: any, form: Client) =>
          this.updateFunction!(
            currentWorkflowVersionId,
            form
          )).bind(this)
      }
    });
  }

  getPreparedForData(currentWorkflowVersion: ProposalVersion | InvoiceVersion | null | undefined) {
    let name!: string;
    if(currentWorkflowVersion)
      name = currentWorkflowVersion.clientFirstName + ' ' + currentWorkflowVersion.clientLastName;

    return {
      firstName: currentWorkflowVersion?.clientFirstName,
      lastName: currentWorkflowVersion?.clientLastName,
      name: name,
      phoneNumber: currentWorkflowVersion?.clientPhoneNumber,
      extNumber: currentWorkflowVersion?.clientExtNumber,
      address: currentWorkflowVersion?.clientAddress,
      email: currentWorkflowVersion?.clientEmail,
      unit: currentWorkflowVersion?.clientUnit,
      type: currentWorkflowVersion?.clientType,
      businessName: currentWorkflowVersion?.clientBusinessName,
    };
  }
}
