import { ElementRef, Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { BehaviorSubject, Observable, of } from "rxjs";
import { MatCheckbox } from "@angular/material/checkbox";
import { ReportsService } from "./reports.service";

@Injectable({
  providedIn: 'root'
})

export class ReportDownloadService {

  // private checkEvent = new BehaviorSubject<boolean>(false);
  //
  // onChangeCheck(data: boolean) {
  //   this.checkEvent.next(data);
  // }
  //
  // constructor(
  //   private reportsService: ReportsService
  // ) { }
  //
  // createExcelFile(): void {
  //   const currentDate = new Date();
  //   //replace fileName by dateStart-dateEnd
  //   const fileName = `report_${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}.xlsx`;
  //
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet('Sheet 1');
  //
  //   //if we choose more than 1 job to download report,
  //   // then in exel file will be as many tables with data as there are jobs we select
  //   const newData: Record<string, any>[] = [
  //     {
  //       'Transaction Type': 'Refund',
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id12',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Charge back',
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Charge back (Resolved)',
  //       'Amount': 100,
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Payment (CC)',//can be a different way to payment, or only one (Cash, Check, Cash App,Venmo,Zelle,Bank Transfer (offline))
  //       'Amount': 100,
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Payment (Cash)',//can be a different way to payment, or only one
  //       'Amount': 1000,
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Transaction fee',
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Costs (Items)',
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Expenses',
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Tech parts (Vlad S.)',//with name of workers like (Vlad S.) can be different person
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     },
  //     {
  //       'Transaction Type': 'Commission (Vlad S.)',//with name of workers like (Vlad S.), can be different person
  //       'Amount': -100, //always minus
  //       'Job ID': 'job id',
  //       'Client': 'Client 1',
  //       'Date': '2023-11-15'
  //     }
  //   ];
  //
  //   const netProfitSum = newData.reduce((sum, row) => sum + row.Amount, 0);
  //
  //   const netProfitRow = {
  //     'Transaction Type': 'Net Profit',
  //     'Amount': netProfitSum,
  //     'Job ID': 'job id',
  //     'Client': 'Client 11',
  //     'Date': '2023-11-15'
  //   };
  //   newData.push(netProfitRow);
  //
  //   const headerRow = worksheet.addRow(Object.keys(newData[0]));
  //   headerRow.eachCell((cell) => {
  //     cell.font = {
  //       bold: true,
  //     };
  //     cell.border = {
  //       top: { style: 'thin', color: { argb: '000000' } },
  //       left: { style: 'thin', color: { argb: '000000' } },
  //       bottom: { style: 'thin', color: { argb: '000000' } },
  //       right: { style: 'thin', color: { argb: '000000' } },
  //     };
  //     cell.alignment = {
  //       horizontal: 'center',
  //       vertical: 'middle',
  //     };
  //   });
  //
  //   newData.forEach((rowData) => {
  //     const row = worksheet.addRow(Object.values(rowData));
  //     row.eachCell((cell) => {
  //       cell.border = {
  //         top: { style: 'thin', color: { argb: '000000' } },
  //         left: { style: 'thin', color: { argb: '000000' } },
  //         bottom: { style: 'thin', color: { argb: '000000' } },
  //         right: { style: 'thin', color: { argb: '000000' } },
  //       };
  //     });
  //   });
  //
  //   worksheet.columns.forEach((column, colIndex) => {
  //     if (column.values) {
  //       const maxLen = column.values.reduce<number>((acc, val) => {
  //         const cellLen = String(val).length;
  //         return cellLen > acc ? cellLen : acc;
  //       }, 0);
  //
  //       if (typeof maxLen === 'number') {
  //         worksheet.getColumn(colIndex + 1).width = maxLen < 12 ? 12 : maxLen;
  //       }
  //     }
  //   });
  //
  //   workbook.xlsx.writeBuffer().then((buffer) => {
  //     this.saveAsExcelFile(buffer, fileName);
  //   });
  // }
  //
  // private saveAsExcelFile(buffer: ArrayBuffer, fileName: string): void {
  //   const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  //   const link: HTMLAnchorElement = document.createElement('a');
  //   link.href = window.URL.createObjectURL(data);
  //   link.download = fileName;
  //   link.click();
  // }
}
