import { Injectable, inject } from '@angular/core';
import { BusinessService } from './business.service';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, filter, map, shareReplay, take } from 'rxjs/operators';
import { UsersService } from './users.service';
import { BusinessProfile } from '../../../../common/src/lib/models/business-profile.model';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SupabaseService } from "./supabase.service";
import { Assignment } from "../models/assignment.model";

export const canOpenAssignmentsPage = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const assignmentService = inject(AssignmentService);
    const router = inject(Router);
    return assignmentService.items$.pipe(
        map(items => items.length > 0 ? true : router.parseUrl('/lobby')),
        take(1)
    ).toPromise();
}

@Injectable({
    providedIn: 'root'
})
export class AssignmentService {


    items$ = combineLatest([
        this.businessService.selectedBusiness$.pipe(filter(b => !!b)) as Observable<BusinessProfile>,
        this.usersService.currentUser$
    ]).pipe(
        switchMap(([business, currentUser]) => {
            return this.supabaseService.rpc<Assignment[]>({
                cName: 'assignment',
                schema: business.businessId,
                fn: 'get_assignment_items',
                tables: ['workflow', 'user', 'estimate', 'estimate_client', 'estimate_range', 'job', 'job_client', 'job_range', 'job_user'],
                options: { inUserId: currentUser.id }
            },
            (changes, items) => {
              if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
                return null;
              const data = changes.new as any;
              const item = items.find(item => data[`${item.type}Id`] === item.id);
              if (item)
                item.jobType = data.jobType;
              return items;
            });
        }),
        shareReplay(1),
    );

    constructor(
        private businessService: BusinessService,
        private supabaseService: SupabaseService,
        private usersService: UsersService,
    ) {
    }

}
