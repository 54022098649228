import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { SupportChatMessage, SupportChatSentMessage, SupportSenderType, Ticket } from "../../models/support-chat.model";
import { UserProfile } from "../../models/user-profile.model";
import { ChatMessage, MessageComponent } from "../message/message.component";
import { SupportChatService } from "../../services/support-chat.service";
import { BehaviorSubject, Subject } from "rxjs";
import { SystemUserProfile } from '../../../../../dashboard-slick-estimates/src/app/models/user-profile.model';
import { SafeUrl } from '@angular/platform-browser';
import { ComponentPortal } from '@angular/cdk/portal';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { Overlay } from '@angular/cdk/overlay';
import moment from 'moment';
import { ChatDateHeaderComponent } from '../chat-date-header/chat-date-header.component';
import { RealtimeChannel } from '@supabase/supabase-js';
import { trimHtmlString, UtilsService } from '../../services';
import { DOCUMENT } from '@angular/common';
import { showSnackbar } from '../snackbar/snackbar.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'lib-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.scss']
})
export class SupportChatComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('tempView') tempView!: ElementRef<HTMLDivElement>;
  @ViewChild('tempView', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;
  @ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('attachmentInput') attachmentInput!: ElementRef<HTMLInputElement>;

  @Input() ticket!: Ticket | null;
  @Input() currentUser!: UserProfile | SystemUserProfile;
  @Input() messageClass?: string;
  @Input() businessId?: string;
  @Input() showMessages = true;
  @Input() ticketsHistoryMode = false;
  @Input() sentMessage?: string;
  @Input() userType!: SupportSenderType;
  
  private messageComponents: ComponentRef<MessageComponent>[] = [];
  private selfMessages: (SupportChatMessage | SupportChatSentMessage)[] = [];
  private otherMessages: SupportChatMessage[] = [];
  private sentMessagesCount = 0;
  private hideDateHeaderDebouncer = new Subject<HTMLElement>();
  
  messages: (SupportChatMessage | SupportChatSentMessage)[] = [];
  unseenMessagesIds: number[] = [];
  hideIndicator = true;
  initializing = true;
  isViewInitialized = false;
  messagesChannel!: RealtimeChannel;
  scrollObserver: IntersectionObserver | null = null;
  attachmentSubject = new BehaviorSubject<File | null>(null);
  draggingAttachment = false;
  selectedFile?: File;
  selectedFileImage?: string;
  dialogMessageInput = this.chatService.messageInput;

  constructor(
      private changeDetectorRef: ChangeDetectorRef,
      private chatService: SupportChatService,
      private overlay: Overlay,
      private utilsService: UtilsService,
      private snackbar: MatLegacySnackBar,
      @Inject(DOCUMENT) private document: Document,
  ) {
    this.hideDateHeaderDebouncer
      .pipe(
        debounceTime(500),
      )
      .subscribe((header: HTMLElement) => {
        if (this.scrollContainer.nativeElement.scrollTop === 0)
          return;
        header.style.transition = 'opacity 500ms';
        header.style.opacity = '0';
      });
  }

  get messagesCount() {
    return this.selfMessages.length + this.otherMessages.length;
  }

  async ngAfterViewInit() {
    this.isViewInitialized = true;
    
    if (!this.ticketsHistoryMode) {
      const options = {
        root: this.scrollContainer.nativeElement,
        rootMargin: '0px',
        threshold: .8,
      };
      this.scrollObserver = new IntersectionObserver(this.onScrollObserverEvent.bind(this), options);
    }

    await this.init();
    
    this.onFocusIn = this.onFocusIn.bind(this);
    if (!this.ticketsHistoryMode)
      document.addEventListener('focusin', this.onFocusIn);

    this.updateHeadersVisibility = this.updateHeadersVisibility.bind(this);
    this.scrollContainer.nativeElement.addEventListener('scroll', this.updateHeadersVisibility);
  }

  ngOnDestroy() {
    if(this.messagesChannel)
      this.chatService.unsubscribe(this.messagesChannel);
    this.scrollContainer.nativeElement.removeEventListener('scroll', this.updateHeadersVisibility);
    if (!this.ticketsHistoryMode)
      document.removeEventListener('focusin', this.onFocusIn);
    this.hideDateHeaderDebouncer.unsubscribe();
  }

  async onScrollObserverEvent(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    if (!document.hasFocus()) {
      for (const entry of entries) {
        observer.unobserve(entry.target);
        observer.observe(entry.target);
      }
      return;
    }
    for (const entry of entries) {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        const id = +entry.target.id;
        this.chatService.markAsSeen(id);
        const index = this.unseenMessagesIds.indexOf(id);
        if (index > -1)
          this.unseenMessagesIds.splice(index, 1);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticket) {
      if (this.isViewInitialized && (!changes.ticket.currentValue || !changes.ticket.previousValue))
        this.init();
      else if (
        (changes.ticket.previousValue as Ticket)?.status === 'pending_close'
        && (changes.ticket.currentValue as Ticket).status === 'opened'
        && this.isCurrentUserBusiness()
      ) {
        this.changeDetectorRef.detectChanges();
        this.scrollContainer.nativeElement.scrollTo({
          top: this.scrollContainer.nativeElement.scrollHeight
        });
      }
    }
    if (changes.sentMessage?.currentValue)
      this.addSentMessage(changes.sentMessage.currentValue);
  }

  async init() {
    if (!this.ticket) {
      this.messages = [];
      this.selfMessages = [];
      this.otherMessages = [];
      this.unseenMessagesIds = [];
      this.messageComponents = [];
      this.viewContainerRef.clear();
      this.initializing = false;
      if(this.messagesChannel)
        this.chatService.unsubscribe(this.messagesChannel);
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.messagesChannel = await this.chatService.subscribeToMessages(
      this.ticket.id,
      (message) => this.addNewMessage(message),
      (message) => this.updateMessage(message),
    );

    if (this.tempView)
      this.tempView.nativeElement.remove();

    const messages = await this.chatService.getInitialMessages(this.ticket.id);
    for (const message of messages)
      this.addNewMessage(message, false);
    this.unseenMessagesIds = (this.messages.filter(message => message.id !== null && !this.isCurrentUserMessage(message) && !message.seen) as SupportChatMessage[]).map(m => m.id);

    this.changeDetectorRef.detectChanges();
    this.scrollToFirstOtherUnseen();

    if (this.scrollContainer.nativeElement.scrollHeight > this.scrollContainer.nativeElement.clientHeight) {
      setTimeout(() => this.hideIndicator = false);
    }
    this.initializing = false;
  }

  onFocusIn() {
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;
    if (scrollHeight > clientHeight)
      return;
    
    for (const message of this.otherMessages) {
      if (!message.seen)
        this.chatService.markAsSeen(message.id);
    }
  }

  updateHeadersVisibility() {
    const elements = this.document.getElementsByTagNameNS('http://www.w3.org/1999/xhtml', 'lib-chat-date-header');
    const contentRect = this.scrollContainer.nativeElement.getBoundingClientRect();
    let firstSet = false;
    for (let i = elements.length - 1; i > -1; i--) {
      const stick = elements[i].getBoundingClientRect().top === contentRect.top + parseInt(getComputedStyle(elements[i]).top);
      if(!stick) {
        elements[i].style.opacity = '1';
      } else if(!firstSet) {
        firstSet = true;
        elements[i].style.opacity = '1';
        this.hideDateHeaderDebouncer.next(elements[i]);
      } else {
        elements[i].style.transition = 'unset';
        elements[i].style.opacity = '0';
      }
    }
  }

  scrollToFirstOtherUnseen(smooth = false) {
    if(this.unseenMessagesIds.length > 0) {
      const message = this.otherMessages.find(message => !message.seen);
      if(message)
        document.getElementById(message.id + '')?.scrollIntoView({ block: 'start', behavior: smooth ? 'smooth' : 'auto' });
    } else {
      this.scrollContainer.nativeElement.scrollTo({
        top: this.scrollContainer.nativeElement.scrollHeight
      });
    }
    if (this.scrollContainer.nativeElement.scrollHeight <= this.scrollContainer.nativeElement.clientHeight && this.unseenMessagesIds.length === 0) {
      this.hideIndicator = false;
    }
  }

  addNewMessage(message: SupportChatMessage, scroll = true) {
    if (this.sentMessagesCount && this.isCurrentUserMessage(message)) {
      for (let index = this.messages.length - 1; index >= 0; index--) {
        const foundMessage = this.messages[index];
        if (foundMessage.id === null && foundMessage.content === message.content && foundMessage.attachment === message.attachment) {
          (foundMessage as any as SupportChatMessage).id = message.id;
          foundMessage.createdAt = message.createdAt;
          foundMessage.seen = message.seen;
          const messageComponent = this.messageComponents.find(component => 
            component.instance.message.id === null 
            && component.instance.message.content === message.content
            && (component.instance.message.attachment?.path ?? null) === message.attachment
          );
          if (messageComponent) {
            messageComponent.instance.message.status = 'delivered';
            messageComponent.instance.message.id = message.id;
            const element = messageComponent.location.nativeElement as HTMLElement;
            element.id = message.id + '';
            this.sentMessagesCount--;
          }
          return;
        }
      }
    }

    this.messages.push(message);
    const scrollFromBottom = this.scrollContainer.nativeElement.scrollTop + this.scrollContainer.nativeElement.clientHeight;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;
    const threshold = 60;

    this.addMessage(message);
    if (!this.isCurrentUserMessage(message) && !message.seen) {
      this.unseenMessagesIds.push(message.id);
    }

    if (this.isCurrentUserMessage(message) 
      || (document.hasFocus()
        && (scrollFromBottom + threshold >= scrollHeight && scrollHeight > clientHeight || scrollHeight <= clientHeight) 
      )
    ) {
      if (!this.isCurrentUserMessage(message))
        this.hideIndicator = true;

      if (scroll) {
        this.changeDetectorRef.detectChanges();
        this.scrollContainer.nativeElement.scrollTo({
          top: this.scrollContainer.nativeElement.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }

  async addSentMessage(content: string, path?: string, attachment?: File) {
    const message: SupportChatSentMessage = {
      id: null,
      seen: true,
      content,
      attachment: path ?? null,
      file: attachment,
      createdAt: new Date(),
      ticketId: this.ticket?.id,
      senderId: this.currentUser.id,
      senderType: this.userType
    };
    this.messages.push(message);
    this.addMessage(message);
    this.sentMessagesCount++;
    this.changeDetectorRef.detectChanges();
    this.scrollContainer.nativeElement.scrollTo({
      top: this.scrollContainer.nativeElement.scrollHeight,
      behavior: 'smooth'
    });
  }

  addMessage(message: SupportChatMessage | SupportChatSentMessage, force = false) {
    let component;
    const previousMessageIndex = this.isMessage(message) 
      ? this.messages.findIndex(m => m.id === message.id) - 1
      : this.messages.length - 2;
    if (previousMessageIndex >= -1 && (previousMessageIndex === -1 || !moment(this.messages[previousMessageIndex].createdAt).isSame(message.createdAt, 'date'))) {
      component = this.viewContainerRef.createComponent(ChatDateHeaderComponent);
      component.instance.date = message.createdAt;
    }

    component = this.viewContainerRef.createComponent(MessageComponent);
    this.messageComponents.push(component as ComponentRef<MessageComponent>);
    if (message.attachment)
      component.instance.imageClicked.subscribe(path => this.showImageViewer(path));

    if (this.scrollObserver && !this.isCurrentUserMessage(message) && !message.seen)
      this.scrollObserver.observe(component.location.nativeElement);

    component.instance.message = this.convertMessage(message);
    component.instance.supportChat = true;

    const element = component.location.nativeElement as HTMLElement;
    element.id = message.id + '';
    if (this.messageClass)
      element.classList.add(this.messageClass);

    const arr = this.isCurrentUserMessage(message) ? this.selfMessages : this.otherMessages;
    arr.push(message);

    if (this.isMessage(message) && message.seen)
      this.updateSeen(message);
  }

  updateMessage(message: SupportChatMessage) {
    const prevSelfIndex = this.selfMessages.findIndex(m => m.id === message.id);
    if (prevSelfIndex !== -1) {
      const prevSelf = this.selfMessages.splice(prevSelfIndex, 1, message)[0];
      if (this.isMessage(prevSelf))
        this.updateLastSeenMessage(message, prevSelf);
      return;
    }

    const prevOtherIndex = this.otherMessages.findIndex(m => m.id === message.id);
    if (prevOtherIndex === -1)
      return;

    const prevOther = this.otherMessages.splice(prevOtherIndex, 1, message)[0];
    if (!this.isCurrentUserMessage(message) && !prevOther.seen && message.seen) {
      const index = this.unseenMessagesIds.indexOf(message.id);
      if (index > -1)
        this.unseenMessagesIds.splice(index, 1);
      this.hideIndicator = false;
    }
  }

  updateLastSeenMessage(message: SupportChatMessage, prev: SupportChatMessage) {
    if(!this.isCurrentUserMessage(message))
      return;

    if(prev.seen)
      return;

    this.updateSeen(message);
  }

  updateSeen(message: SupportChatMessage) {
    if (!message.seen || !this.isCurrentUserMessage(message))
      return;

    const messageComponent = this.messageComponents.find(comp => comp.location.nativeElement.id === message.id + '');
    if (messageComponent) {
      messageComponent.instance.message.status = 'seen';
    }
    this.changeDetectorRef.detectChanges();
  }

  isCurrentUserSupport() {
    return this.userType === 'support';
  }

  isCurrentUserBusiness() {
    return this.userType === 'business';
  }

  isMessageBusiness(message: SupportChatMessage | SupportChatSentMessage) {
    return message.senderType === 'business';
  }

  isMessageSupport(message: SupportChatMessage | SupportChatSentMessage) {
    return message.senderType === 'support';
  }

  isCurrentUserMessage(message: SupportChatMessage | SupportChatSentMessage) {
    return message.senderId === this.currentUser.id && (
      message.senderType === 'support' && this.isCurrentUserSupport()
      || message.senderType === 'business' && this.isCurrentUserBusiness()
    );
  }

  isSentMessage(message: SupportChatMessage | SupportChatSentMessage): message is SupportChatSentMessage {
    return message.id === null;
  }

  isMessage(message: SupportChatMessage | SupportChatSentMessage): message is SupportChatMessage {
    return message.id !== null;
  }

  convertMessage(message: SupportChatMessage | SupportChatSentMessage): ChatMessage {
    const baseMessage = {
      createdAt: message.createdAt,
      attachment: message.attachment ? {
        bucket: 'support',
        path: message.attachment,
      } : null,
      content: message.content,
    };
    let convertedMessage: ChatMessage;
    if (this.isMessage(message) && (!this.isCurrentUserMessage(message) || this.ticketsHistoryMode && this.isMessageBusiness(message))) {
      const firstName = this.isMessageBusiness(message)? this.ticket?.businessUserFirstName : this.ticket?.systemUserFirstName;
      const lastName = this.isMessageBusiness(message)? this.ticket?.businessUserLastName : this.ticket?.systemUserLastName;
      convertedMessage = {
        ...baseMessage,
        id: message.id,
        status: 'foreign',
        firstName: firstName ?? '',
        lastName: lastName ?? ''
      };
    } else {
      convertedMessage = this.isSentMessage(message)
        ? {
          ...baseMessage,
          attachment: baseMessage.attachment && message.file
            ? {
              ...baseMessage.attachment,
              file: message.file
            }
            : null,
          id: null,
          status: 'sent'
        }
        : {
          ...baseMessage,
          id: message.id,
          status: message.seen ? 'seen' : 'delivered'
        }
    }
    return convertedMessage;
  }

  onDrag(dragging: boolean, event: DragEvent) {
    if (this.ticket && this.isCurrentUserSupport() && this.ticket.status !== 'opened')
      return;
    if(event.dataTransfer?.items.length) {
      const type = event.dataTransfer?.items[event.dataTransfer?.items.length - 1].type;
      if(type.startsWith('image')) {
        this.draggingAttachment = dragging;
      }
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.items[0]?.getAsFile();
    if(file)
      this.onFileChange(file);
  }

  async onFileChange(file: File) {
    this.draggingAttachment = false;
    this.selectedFile = file;
    const fileType = file.type;
    if (!fileType.startsWith('image/')) {
      showSnackbar(this.snackbar, {
        message: 'File type unsupported',
        duration: 3000
      });
      return;
    }

    if (file.size > 2097152) { // 2MB
      showSnackbar(this.snackbar, {
        message: 'File exceeds 2MB limit for images',
        duration: 3000
      });
      return;
    }
    this.selectedFileImage = await this.fileToString(this.selectedFile);
    this.dialog.nativeElement.showModal();
    this.dialogMessageInput.setValue(this.dialogMessageInput.value);
  }

  fileToString(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ((event: ProgressEvent<FileReader>) => {
        resolve(event.target!.result as string);
      }).bind(this);
      reader.readAsDataURL(file);
    })
  }

  closeImageDialog() {
    this.selectedFile = undefined;
    this.selectedFileImage = undefined;
    this.dialogMessageInput.setValue(this.dialogMessageInput.value);
    this.dialog.nativeElement.close();
  }

  onDialogKeyDown(event: KeyboardEvent) {
    if (this.utilsService.isMobile())
      return;
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      this.sendAttachmentMessage();
    }
  }

  async sendAttachmentMessage() {
    const splitName = this.selectedFile!.name.split('.');
    const fileName = this.utilsService.randomString(16) + '.' + splitName[splitName.length - 1];
    const content = trimHtmlString(this.dialogMessageInput.value);
    let ticket = this.ticket;
    if (!ticket) {
      if (!this.businessId)
        return;
      ticket = await this.chatService.createTicket(this.businessId, this.currentUser.id);
    }
    const path = `${ticket!.id}/${fileName}`;
    const pathWithData = await this.addMetaDataToPath(this.selectedFile!, path);
    this.addSentMessage(content, pathWithData, this.selectedFile);
    this.chatService.uploadImage(this.selectedFile!, path).then(_ => {
      this.chatService.sendMessage(this.ticket!.businessId, this.ticket!.businessUserId, this.currentUser.id, content, pathWithData);
    });
    this.dialogMessageInput.setValue('');
    this.closeImageDialog();
  }

  async addMetaDataToPath(file: File, path: string) {
    const type = file.type.split('/')[0];
    if (type !== 'image')
      return path;
    let pathAndType = `${path};${type}`;
    let dimensions;
    try {
      dimensions = await this.utilsService.getImageDimensions(file);
    } catch {
      return pathAndType;
    }
    return `${pathAndType};${dimensions.width}x${dimensions.height}`;
  }

  showImageViewer(imageUrl: SafeUrl | string) {
    const viewer = new ComponentPortal(ImageViewerComponent);
    const overlayRef = this.overlay.create(
      {
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        panelClass: 'image-viewer-overlay'
      },
    );
    const viewerRef = overlayRef.attach(viewer);
    viewerRef.setInput('imageUrl', imageUrl);
    viewerRef.setInput('showDownload', true);
    const subscription = viewerRef.instance.onClose.subscribe(() => {
      overlayRef.detach();
      viewer.viewContainerRef?.clear();
      subscription.unsubscribe();
    });
  }
}
