import { Directive, HostListener, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UtilsService } from "../services";

@Directive({
  selector: '[hideMenuOnScreenSizeChange]'
})
export class HideMenuOnScreenSizeChangeDirective {

  @Input() hideMenuOnScreenSizeChange?: MatMenuTrigger
  
  constructor(
      private utilsService: UtilsService
  ) { }

  @HostListener('window:resize')
  onScreenSizeChange() {
    if(!this.utilsService.isMobile() && this.hideMenuOnScreenSizeChange?.menuOpen) {
      this.hideMenuOnScreenSizeChange.closeMenu();
    }
  }

}
