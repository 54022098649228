import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from "../../services/navigation.service";
import { Assigned } from "../../models/assigned.model";
import { BaseTileData } from "../base-tile/base-tile.component";
import { TimeData } from "../base-tile/base-tile-time/base-tile-time.component";
import { capitalizeFirstChar } from "../../../../../common/src/lib/services";
import { Assignment } from "../../models/assignment.model";
import { AssignedDialogComponent } from "../../modals/assigned-dialog/assigned-dialog.component";
import { UsersService } from "../../services/users.service";
import { ModalBehavior, ModalsService } from 'projects/common/src/lib/services/modals.service';
import { EstimatesService } from "../../services/estimates.service";
import { JobsService } from "../../services/jobs.service";
import { AssignmentDialogComponent } from "../../modals/assignment-dialog/assignment-dialog.component";
import { NgxMaskService } from 'ngx-mask';

type itemTypes = {
  'Assigned': Assigned,
  'Assignment': Assignment
};

@Component({
  selector: 'app-assign-tile',
  templateUrl: './assign-tile.component.html',
  styleUrls: ['./assign-tile.component.scss']
})
export class AssignTileComponent<T extends 'Assigned' | 'Assignment'> implements OnInit {
  @Input() item!: itemTypes[T];
  @Input() tileType!: T;

  hover!: boolean;
  readonly dateFormat = 'MM/DD/YY';
  readonly timeFormat = 'hh:mm a';

  page$ = this.navigationService.page$;
  users$ = this.usersService.users$;
  currentUser$ = this.usersService.currentUser$;

  data!: BaseTileData;
  timeData!: TimeData;

  denied!: boolean;
  usersTag!: string;

  constructor(
    private navigationService: NavigationService,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private estimatesService: EstimatesService,
    private jobsService: JobsService,
    private maskService: NgxMaskService,
  ) {}

  isAssigned(item: any): item is Assigned {
    return this.tileType === 'Assigned';
  }

  isAssignment(item: any): item is Assignment {
    return this.tileType === 'Assignment';
  }

  async ngOnInit() {
    this.data = {
      title: this.item.firstName + ' ' + this.item.lastName,
      subtitle: this.maskService.applyMask(this.item.phoneNumber, '(000) 000-0000'),
      description: this.item.jobType,
    };
    this.timeData = {
      startTime: this.item.outStartTime,
      endTime: this.item.outEndTime,
    };
    this.initUsersTag();
    this.initDenied();
  }

  initDenied() {
    if(this.isAssigned(this.item)) {
      if(this.item.type === 'estimate') {
        this.denied = this.item.denied ?? false;
        return;
      } else {
        const userId = this.item.assignedToId!;
        const user = this.item.assignedToUsers!.find(user => user.userId === userId);
        this.denied = user?.status === 'denied';
      }
    }
  }

  initUsersTag() {

    if(this.isAssigned(this.item)) {
      this.usersTag = this.item.assignedToFirstName + ' ' + this.item.assignedToLastName;
    } else {
      this.usersTag = this.item.assignedByFirstName + ' ' + this.item.assignedByLastName;
    }
  }

  async onTileClick(event: Event) {
    event.stopImmediatePropagation();
    const document = await (this.item.type === "estimate"
      ? this.estimatesService.getEstimate(this.item.id)
      : this.jobsService.getJob(this.item.id));
    const config = {
      behavior: ModalBehavior.Dialog,
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      data: {
        id: this.item.id,
        docType: capitalizeFirstChar(this.item.type),
        tileType: this.tileType,
        initialData: document
      }
    };
    if(this.tileType === 'Assigned')
      await this.modalsService.open(AssignedDialogComponent, config);
    else
      await this.modalsService.open(AssignmentDialogComponent, config);
  }

  trimName(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength - 3) + '...';
    }
    return value;
  }

  isEventEnded(): boolean {
    return this.item.outEndTime.getTime() < Date.now();
  }

  isEventStarted(): boolean {
    return this.item.outStartTime.getTime() < Date.now();
  }

  protected readonly capitalizeFirstChar = capitalizeFirstChar;
}
