import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ProgressBarService, ProgressBarStageName } from "../../services/progress-bar.service";
import { Observable } from "rxjs";
import { ProgressBar, ProgressBarStage } from "../../models/progress-bar.model";
import { tap } from "rxjs/operators";

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() workflowId!: number;
  @Input() active!: ProgressBarStageName;

  progressBar$: Observable<ProgressBar> | null = null;

  constructor(
    private progressBarService: ProgressBarService
  ) { }

  ngOnInit() {
    this.progressBar$ = this.progressBarService.progressBarObservable(this.workflowId);
  }

  async openStage(stage: ProgressBarStage) {
    await this.progressBarService.openStage(stage, this.workflowId, this.active);
  }
}
