import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { currencify } from "../../helpers/common";
import { CommissionReportItem } from "../../models/reports.model";
import { Router } from "@angular/router";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: 'app-commissions-report-tile',
  templateUrl: './commissions-report-tile.component.html',
  styleUrls: ['./commissions-report-tile.component.scss']
})
export class CommissionsReportTileComponent implements OnInit {

  protected readonly currencify = currencify;

  @Input() item!: CommissionReportItem;
  @Input() kind!: 'Pending' | 'Paid';

  @HostBinding('attr.id') id!: string;

  get dividerNeed() {
    return document.getElementById(this.id)?.nextSibling?.nodeName === 'APP-COMMISSIONS-REPORT-TILE';
  }

  get checked() {
    return this.reportsService.selectedCommissionIds.has(this.item.id);
  }

  constructor(
    private router: Router,
    private reportsService: ReportsService
  ) { }

  ngOnInit() {
    this.id = `${this.item.wId}_${this.item.pId}_${this.item.id}`;
    if (this.kind === 'Paid') {
      this.item.amount = this.item.paid;
      this.item.amount -= this.item.overpay && this.item.overpay < 0
        ? this.item.overpay
        : 0;
    }
  }

  async openPayment(wId: number, pId: number) {
    await this.router.navigate([`/payments/${wId}/${pId}`]);
  }

  check(checked: boolean, itemId: any) {
    if (checked && !this.reportsService.selectedCommissionIds.has(itemId))
      this.reportsService.selectedCommissionIds.add(itemId);

    if (!checked && this.reportsService.selectedCommissionIds.has(itemId))
      this.reportsService.selectedCommissionIds.delete(itemId);
  }
}
