<dynamic-list #list class="h-100"
    *ngIf="!(loading$ | async)"
    (loadMoreTop)="loadItems('top', $event)"
    (loadMoreBottom)="loadItems('bottom', $event)"
    (itemEvent)="onItemEvent($event)"
    [initialTopItems]="initialTopItems"
    [initialBottomItems]="initialBottomItems"
    [firstHeader]="firstHeader$ | async"
    [style.display]="(dateFilter$ | async) ? 'none' : 'block'"
>
    <app-empty-state
        tab="Jobs"
        page="Schedule"
        [statusFilter]="(currentStatusFilter$ | async)!"
        [upcoming]="(list.topCount$ | async)! > 0"
    ></app-empty-state>
</dynamic-list>
<dynamic-list class="h-100" #dateFilterList
    *ngIf="(dateFilter$ | async) && !(dateFilterLoading$ | async)"
    (loadMoreTop)="loadDateFilterItems('top', $event)"
    (loadMoreBottom)="loadDateFilterItems('bottom', $event)"
    [canLoadMoreTop]="initialFilterCanLoadMoreTop"
    [canLoadMoreBottom]="initialFilterCanLoadMoreBottom"
    (itemEvent)="onItemEvent($event)"
    [initialTopItems]="initialFilterTopItems"
    [initialBottomItems]="initialFilterBottomItems"
    [firstHeader]="filterFirstHeader$ | async"
    [hasDateFilter]="true"
>
    <app-empty-state
        class="h-100"
        page="Schedule"
        filter="Range"
    ></app-empty-state>
</dynamic-list>
