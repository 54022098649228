import { Injectable } from '@angular/core';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import RecordRTC from 'recordrtc';
import { BehaviorSubject } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const fileExt = 'webm';
const mimeType = 'audio/webm';

@Injectable({
    providedIn: 'root'
})
export class AudioRecorderService {
    private stream: MediaStream | null = null;
    private recorder: RecordRTC.StereoAudioRecorder | null = null;
    private isRecordingSubject = new BehaviorSubject<boolean>(false);
    private canRecordSubject = new BehaviorSubject<boolean>(false);

    isRecording$ = this.isRecordingSubject.asObservable();
    canRecord$ = this.canRecordSubject.asObservable();

    constructor(
        private snackBar: MatSnackBar,
    ) {}

    private async setupRecording() {
        if (!navigator.mediaDevices?.getUserMedia) {
            this.canRecordSubject.next(false);
            return;
        }

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
                type: 'audio',
                mimeType,
                disableLogs: true
            });
            this.canRecordSubject.next(true);
        } catch (error) {
            console.log(error);
        }
    }

    async startRecording() {
        if (this.isRecordingSubject.value)
            return false;

        await this.setupRecording();
        if (!this.canRecordSubject.value) {
            showSnackbar(this.snackBar, { message: 'Recording device is not available' });
            return false;
        }

        this.isRecordingSubject.next(true);
        this.recorder?.record();
        return true;
    }

    cancelRecording() {
        this.stopMedia();
    }

    stopRecording(): Promise<File | null> {
        if (!this.isRecordingSubject.value)
            return Promise.resolve(null);

        return new Promise((resolve) => {
            this.recorder?.stop((blob: Blob) => {
                this.stopMedia();
                resolve(new File([blob], `recording.${fileExt}`, { type: mimeType }));
            });
        });
    }

    private stopMedia() {
        this.isRecordingSubject.next(false);
        this.recorder = null;

        if (this.stream) {
            for (const track of this.stream.getTracks()) {
                track.stop();
                this.stream.removeTrack(track);
            }
            this.stream = null;
        }
    }

}