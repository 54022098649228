import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from "@angular/material/legacy-tooltip";
import { IconsService } from './icons.service';

@Component({
    selector: 'app-icon',
    template: `
    `,
    providers: [MatTooltip]
})
export class IconComponent implements OnInit, OnChanges, OnDestroy {
    @Input() src!: string;
    @Input() display: 'inline-flex' | 'block' = 'inline-flex' // TODO should probably be inline-flex forever

    resizeObserver: ResizeObserver | null = null;

    svg: any;
    @Input() width = '';
    @Input() height = '';

    constructor(
      private el: ElementRef,
      private renderer: Renderer2,
      private iconsService: IconsService,
      private window: Window,
      private tooltip?: MatTooltip,
    ) { }

    ngOnInit() {
        this.setImage(this.src);
        this.renderer.setStyle(this.el.nativeElement, 'display', this.display);
        if (this.tooltip) {
            this.tooltip.tooltipClass = 'small-tooltip';
        }
        this.initResizeObserver();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.src && changes.src.currentValue !== changes.src.previousValue)
            this.setImage(changes.src.currentValue);
    }

    initResizeObserver() {
        this.resizeObserver = new ResizeObserver(() => {
            this.setElementSize();
        });
        this.resizeObserver.observe(this.window.document.body);
    }

    disconnectResizeObserver() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    async setImage(src: string) {
        let svgData = await this.iconsService.getSource(src);
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svgData);
        let h = /height="\d+"/.exec(svgData)?.toString() ?? '';
        let w = /width="\d+"/.exec(svgData)?.toString() ?? '';
        let w_d = /\d+/.exec(w)?.toString();
        let h_d = /\d+/.exec(h)?.toString();

        if (h_d && this.height === '') {
            this.height = h_d + 'px';
        }
        if (w_d && this.width === '') {
            this.width = w_d + 'px';
        }
    }

    private setElementSize() {
        this.renderer.setStyle(this.el.nativeElement, 'width', this.width);
        this.renderer.setStyle(this.el.nativeElement, 'height', this.height);
    }

    ngOnDestroy() {
        this.disconnectResizeObserver();
    }
}
