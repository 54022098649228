<div *ngIf="!showSmall else smallView" class="main-data d-flex">
  <div class="flex-fill h-100 d-flex flex-column justify-content-center align-items-center gap--8">
    <div *ngIf="rounder(paymentSummary.sBalanceDue) >= 0" class="d-flex align-items-center gap--4">
      <p class="light-title balance-refund-due">Balance due</p>
      <app-icon src="question-mark-tooltip"
                matTooltip="The amount awaiting to be paid by your customer at this time"
                [matTooltipShowDelay]="150"
                class="scale--85"
                #tooltip="matTooltip"
                (click)="tooltip.show()"
                [hideTooltipOnScroll]="tooltip"
      ></app-icon>
    </div>
    <p *ngIf="rounder(paymentSummary.sBalanceDue) < 0" class="light-title balance-refund-due">Refund due</p>
    <h2 [class.negative]="rounder(paymentSummary.sBalanceDue) < 0" class="fw-700">{{ currencify(paymentSummary.sBalanceDue) }}</h2>
  </div>
  <div class="details-divider-v"></div>
  <div class="detailed-data">
    <div class="item fw-600">
      <p [class.negative]="paymentSummary.canceled">Job<span *ngIf="paymentSummary.canceled"> (Canceled)</span></p><p [class.negative]="paymentSummary.canceled">{{ currencify(paymentSummary.job) }}</p>
    </div>
    <div class="details-divider-h"></div>
    <div class="more">
      <div class="item">
        <p>Expenses</p>
        <p>{{ currencify(paymentSummary.expenses) }}</p>
      </div>
      <div class="item fw-600">
        <p>{{ paymentSummary.canceled ? 'Loss' : 'Net Profit' }}</p><p [class.negative]="rounder(paymentSummary.netProfit) < 0" [class.positive]="rounder(paymentSummary.netProfit) > 0">{{ currencify(paymentSummary.netProfit) }}</p>
      </div>
    </div>
  </div>
</div>
<ng-template #smallView>
  <div class="main-data-mobile" [class.open]="isOpen">
    <div class="balance gap--4">
      <div *ngIf="!paymentSummary.canceled" class="d-flex align-items-center gap--4">
        <h4 class="balance-refund-due light-title" *ngIf="rounder(paymentSummary.sBalanceDue) >= 0">Balance due</h4>
        <app-icon src="question-mark-tooltip"
                  matTooltip="The amount awaiting to be paid by your customer at this time"
                  [matTooltipShowDelay]="150"
                  class="scale--85"
                  #tooltip="matTooltip"
                  (click)="tooltip.show()"
                  [hideTooltipOnScroll]="tooltip"
        ></app-icon>
      </div>
      <h4 *ngIf="paymentSummary.sBalanceDue < 0" class="light-title balance-refund-due">Refund due</h4>
      <h2 class="value fw-700" [class.negative]="paymentSummary.sBalanceDue < 0">{{ currencify(paymentSummary.sBalanceDue) }}</h2>
    </div>
    <div *ngIf="!isOpen" class="d-flex flex-column w-100 gap--8">
      <div class="d-flex justify-content-between aling-items-center">
        <small [class.negative]="paymentSummary.canceled">Job<span *ngIf="paymentSummary.canceled"> (Canceled)</span></small>
        <small>{{ paymentSummary.canceled ? 'Loss' : 'Net Profit' }}</small>
      </div>
      <div class="d-flex justify-content-between aling-items-center">
        <small class="fw-700">{{ currencify(paymentSummary.job) }}</small>
        <small [class.negative]="rounder(paymentSummary.netProfit) < 0" [class.positive]="rounder(paymentSummary.netProfit) > 0" class="fw-700">{{ currencify(paymentSummary.netProfit) }}</small>
      </div>
    </div>
    <div *ngIf="isOpen" class="data">
      <div class="d-flex justify-content-between align-items-center height--40 fw-600">
        <p [class.negative]="paymentSummary.canceled">Job<span *ngIf="paymentSummary.canceled"> (Canceled)</span></p>
        <p [class.negative]="paymentSummary.canceled">{{ currencify(paymentSummary.job) }}</p>
      </div>
      <div class="divider"></div>
      <div class="d-flex justify-content-between align-items-center">
        <h4>Expenses</h4>
        <p>{{ currencify(paymentSummary.expenses) }}</p>
      </div>
      <div class="d-flex justify-content-between align-items-center height--40 fw-600">
        <p>{{ paymentSummary.canceled ? 'Loss' : 'Net Profit' }}</p>
        <p [class.negative]="rounder(paymentSummary.netProfit) < 0" [class.positive]="rounder(paymentSummary.netProfit) > 0">{{ currencify(paymentSummary.netProfit) }}</p>
      </div>
    </div>
    <app-icon class="se-icon-button main-data-trigger" src="chev_down" (click)="toggleSummaryOpen()"></app-icon>
  </div>
</ng-template>
