import { AcceptanceStatus, MakeOptional } from "projects/common/src/public-api";
import { AddressComponents } from "../../../../common/src/lib/models/address-comments.model";
import { TimeRange } from "../../../../common/src/lib/models/time-range.model";

export interface JobTimeRange {
    id: number;
    jobId: number;
    startTime: Date;
    endTime: Date;
}

export interface JobUser {
    id: number;
    userId: number;
    assignedBy: number;
    assignedAt: Date;
    firstName: string;
    lastName: string;
    acceptanceStatus: AcceptanceStatus;
}

export type JobStatus = 'submitted' | 'canceled' | 'pending';

export interface Job {
    docType: 'Job';
    createdBy: number;
    createdAt: Date;
    address: AddressComponents;
    businessName: string | null;
    email: string;
    extNumber: string;
    id: number;
    jobType: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    ranges: TimeRange[];
    status: JobStatus;
    type: 'personal' | 'business';
    unit: string | null;
    workflowId: number;
    users: JobUser[];
    loopedInId: number | null;
    loopedInFn: string | null;
    loopedInLn: string | null;
    clientId: number;
    clientCreatedAt: Date;
    salesTaxPercentage: number;
    statusStoreForCancel: JobStatus | null;
    emailOpened: boolean;
    outPaidAmount: number;
    outTotalAmount: number;
}

export interface JobTileData {
    docType: 'Job';
    id: number;
    createdBy: number;
    createdAt: Date;
    firstName: string;
    lastName: string;
    businessName: string | null;
    startTime: Date;
    endTime: Date;
    jobType: string;
    phoneNumber: string;
    workflowId: number;
    rangeId: number;
    status: JobStatus;
    users: JobUser[];
    address: AddressComponents;
    clientId: number;
    unit: string;
    email: string;
    extNumber: string;
    clientCreatedAt: Date;
    salesTaxPercentage: number;
    statusStoreForCancel: JobStatus | null;
    outTotalAmount: number;
    outPaidAmount: number;
}

export function jobTileData(from: Job, rangeId: number): MakeOptional<JobTileData, 'clientId' | 'unit' | 'outTotalAmount' | 'outPaidAmount'> {
    const range = from.ranges.find(r => (r as any).rangeId === rangeId)!;
    
    return {
        docType: 'Job',
        id: from.id,
        createdBy: from.createdBy,
        createdAt: from.createdAt,
        businessName: from.businessName,
        firstName: from.firstName,
        lastName: from.lastName,
        jobType: from.jobType,
        phoneNumber: from.phoneNumber,
        startTime: range.startTime,
        endTime: range.endTime,
        rangeId,
        status: from.status,
        workflowId: from.workflowId,
        users: from.users,
        address: from.address,
        email: from.email,
        extNumber: from.extNumber,
        unit: from.unit ?? undefined,
        clientId: from.clientId,
        clientCreatedAt: from.clientCreatedAt,
        salesTaxPercentage: from.salesTaxPercentage,
        statusStoreForCancel: from.statusStoreForCancel,
        outTotalAmount: from.outTotalAmount,
        outPaidAmount: from.outPaidAmount,
    };
}

export function jobAcceptanceStatus(job: Job, userId: number): AcceptanceStatus {
    const user = job.users.find(user => user.userId === userId);
    return user?.acceptanceStatus ?? 'pending';
}
