<div class="base-tile">
    <ng-content select="[leading]"></ng-content>
    <div *ngIf="data && (!hideOnSmallScreens || (largeScreenSize$ | async)) && (data.title || data.subtitle)" class="title-and-subtitle">
        <p *ngIf="data.title" class="title" [class.past]="past">
            <span class="main-title">{{ data.title }}</span>
            <span *ngIf="data.subtitle && (largeScreenSize$ | async)" class="subtitle">&nbsp;{{ data.subtitle }}</span>
        </p>
        <h4 class="description" [class.past]="past">{{ data.description ?? '&nbsp;' }}</h4>
    </div>
    <ng-content select="[trailing]"></ng-content>
</div>