import { Component, Input, OnInit } from '@angular/core';
import { SupportChatService } from "../../services/support-chat.service";
import { Ticket } from "../../models/support-chat.model";

function formatTimeDifference(createdAt: Date, closedAt: Date | null) {
  if (!closedAt) {
    return undefined;
  }

  const diffInMilliseconds = closedAt.getTime() - createdAt.getTime();
  const diffInSeconds = diffInMilliseconds / 1000;
  const days = Math.floor(diffInSeconds / (3600 * 24));
  const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);

  const parts : string[] = [];
  if (days && days > 0) {
    parts.push(`${days}d`);
  }
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  return parts.join(' ');
}

@Component({
  selector: 'lib-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})

export class RatingComponent implements OnInit{

  @Input() ticket!: Ticket | null;

  ts?: string;
  hover!: boolean;
  ratingSent = false;

  selectedRating!: number;
  hoveredIndex!: number;

  constructor(
    private chatService: SupportChatService,
  ) { }

  async ngOnInit() {
    if (this.ticket?.rating) {
      this.selectedRating = this.ticket.rating;
      await this.submitRating();
    }
    this.ts = formatTimeDifference(this.ticket!.createdAt, this.ticket!.closedAt)
  }

  select(rating: number) {
    this.selectedRating = rating;
  }

  hoverSelect(selectIndex: number) {
    this.hover = true
    this.hoveredIndex = selectIndex;
  }

  async submitRating() {
    await this.chatService.sendRating(this.selectedRating, this.ticket!.id);
    this.ratingSent = true;
  }
}
