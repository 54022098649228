import { Component } from '@angular/core';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-suspended-business',
  templateUrl: './suspended-business.component.html',
  styleUrls: ['./suspended-business.component.scss']
})
export class SuspendedBusinessComponent {

  constructor(
    private authService: AuthService
  ) { }

  async signOut() {
    await this.authService.logout();
  }
}
