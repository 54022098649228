<div class="page main h-100 w-100 d-flex flex-column justify-content-start" *ngIf="!(loading$ | async)">

    <app-mobile-toolbar class="only-mobile-sm" [showMenu]="true"></app-mobile-toolbar>

    <app-toolbar type="menu" class="not-mobile-sm"></app-toolbar>

    <div class="flex-fill d-flex flex-column lobby-main">
        <app-onboarding-banner *ngIf="showOnboardingBanner$ | async"></app-onboarding-banner>

        <div class="container lobby_header d-flex flex-column">
            <div class="d-flex justify-content-center align-items-center">
                <app-tabs
                    linePosition="bottom"
                    [tabs]="(pages$ | async)!"
                    [activeTab]="(page$ | async)!"
                    (onTabSelect)="onTabSelected($event)"
                ></app-tabs>
            </div>
        </div>

        <div class="flex-fill lobby-content" [class.mt--24]="(page$ | async) !== 'Schedule'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
