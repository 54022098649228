import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../models/menu-items-config';
import { MatMenuTrigger } from '@angular/material/menu';
import { take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NavigationService } from '../../services/navigation.service';
import { Location } from '@angular/common';
import { ScheduleService } from "../../services/schedule.service";
import { LobbyService } from "../../services/lobby.service";

@Component({
  selector: 'app-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.scss']
})
export class MobileToolbarComponent implements OnInit {

  @ViewChild('menuTrigger') menuTrigger?: MatMenuTrigger;

  @Input() hideElements?: boolean;
  @Input() hideViewAsAndBusinessSelect?: boolean;
  @Input() leftAction?: string;
  @Input() showMenu?: boolean;
  @Input() keepSearchOpen?: boolean;

  @Output() backClick = new EventEmitter<void>();

  menuItems$ = this.menuService.menuItems$;
  searchingOnMobile = false;

  showLobbyOption = true;

  searchControl = new FormControl<string>('') as FormControl<string>;
  show = false;

  constructor(
    private auth: AuthService,
    private menuService: MenuService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private scheduleService: ScheduleService,
    private lobbyService: LobbyService,
    private router: Router,
    private location: Location,
    private element: ElementRef
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.show = getComputedStyle(this.element.nativeElement).display === 'block';
  }

  async ngOnInit() {
    this.show = getComputedStyle(this.element.nativeElement).display === 'block';
    this.showLobbyOption = this.router.url.split('/')[1] !== 'lobby';

    if(this.keepSearchOpen) {
      this.searchingOnMobile = true;
      this.hideElements = false;
      this.hideViewAsAndBusinessSelect = false;
    }

    const [ _, page, name ] = await this.navigationService.url$.pipe(take(1)).toPromise();
    if(page === 'customer-list') {
      const [ firstName, lastName ] = decodeURIComponent(name).split('+');
      if(firstName && lastName) {
        this.searchControl.setValue(firstName + ' ' + lastName, {emitEvent: false});
      }
    }
  }

  onBackClick() {
    this.scheduleService.showCalendarSubject.next(false);
    if(this.backClick.observers.length > 0) {
      this.backClick.emit();
    } else {
      this.location.back();
    }
  }

  mobileSearchFocusChanged(focused: boolean) {
    const delay = focused ? 0 : 100;
    setTimeout(() => {
      if(!focused) {
        this.clearSearchIfEmpty(focused);
      } else {
        this.searchingOnMobile = true;
        this.hideElements = true;
        this.hideViewAsAndBusinessSelect = true;
      }
    }, delay);
  }

  onSearchSuggestionClick(suggestion: any) {
    if(suggestion === null && this.searchingOnMobile && !this.keepSearchOpen) {
      this.searchingOnMobile = false;
    }

    const name = suggestion ? suggestion.name : null;
    this.searchControl.setValue(name, {emitEvent: false});
  }

  clear() {
    this.searchControl.setValue('');
    this.clearSearchIfEmpty(false);
  }

  async clearSearchIfEmpty(focused: boolean) {
    const value = this.searchControl.value;
    if(!value || value === '') {
      this.onSearchSuggestionClick(null);
    }
    this.searchingOnMobile = this.keepSearchOpen ? true : focused;
    this.hideElements = focused;
    this.hideViewAsAndBusinessSelect = focused;
  }

  logout() {
    this.auth.logout();
  }

}
