<div class="tile-wrapper" [class.past]="(isPast$ | async)!" (click)="onTileClick()">
    <app-base-tile
        [past]="(isPast$ | async)!"
        [data]="data"
    >
        <app-base-tile-time leading
            [timeData]="timeData"
        ></app-base-tile-time>
        <div class="info" trailing>
            <app-label class="label" color="grey" *ngIf="showDocType">Unavailable</app-label>
            <small *ngIf="showUserName$ | async" class="user-name px--12 py--4 fw-600">{{ document.firstName }}&nbsp;{{ document.lastName[0].toUpperCase() }}.</small>
        </div>
    </app-base-tile>
</div>