import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-google-auth',
  template: '',
})
export class GoogleAuthComponent implements OnInit {

  constructor(
      private authService: AuthService
  ) {}

  async ngOnInit() {
    const res = await this.authService.generateGoogleAuthLink();
    if(res.data.url) {
      window.location.href = res.data.url;
    } else {
      window.close();
    }
  }

}
