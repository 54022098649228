import { NgModule, NgZone, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SharedModule } from './modules/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';

import { LoginComponent } from './pages/login/login.component';
import { ProposalsComponent } from './pages/proposals/proposals.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SearchComponent } from './components/search/search.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileLogoComponent } from './components/profile-logo/profile-logo.component';
import { ProfileLogoModal } from './modals/profile-logo/profile-logo.component';
import { ProvidersRowComponent } from './components/providers-row/providers-row.component';
import { ChangeEmailComponent } from './modals/change-email/change-email.component';
import { ForgotPasswordModal } from './modals/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { ReauthenticationDialog } from './modals/reauthentication-dialog/reauthentication-dialog.component';
import { RequestInvitationModal } from './modals/request-invitation/request-invitation.component';
import { EventInfoComponent } from './pages/event-info/event-info.component';

import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { HideTooltipOnScrollDirective } from './directives/hide-tooltip-on-scroll.directive';

import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { LabelComponent } from './components/sent-label/label.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { MobileToolbarComponent } from './components/mobile-toolbar/mobile-toolbar.component';
import { ProfileFieldComponent } from './components/profile-field/profile-field.component';
import { LobbyComponent } from './pages/lobby/lobby.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UsersComponent } from './pages/profile/users/users.component';
import { UserTileComponent } from './pages/profile/users/user-tile/user-tile.component';
import { AddUserComponent } from './modals/add-user/add-user.component';
import { ItemsComponent } from "./pages/items/items.component";
import { CreateOrEditItemComponent } from './modals/create-or-edit-item/create-or-edit-item.component';
import { JoinComponent } from './pages/join/join.component';
import { UserAddedComponent } from './modals/user-added/user-added.component';
import { ViewAsButtonComponent } from './components/view-as-button/view-as-button.component';
import { UserButtonComponent } from './components/view-as-button/user-button/user-button.component';
import { AvailabilityModalComponent } from './modals/availability-modal/availability-modal.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { AvailabilityTileComponent } from './components/availability-tile/availability-tile.component';
import { FormsModule } from "@angular/forms";
import { OverlookComponent } from './pages/overlook/overlook.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ItemUploadComponent } from './modals/item-upload/item-upload.component';
import { ItemExampleComponent } from './modals/item-example/item-example.component';
import { ProposalComponent } from './pages/proposal/proposal.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { EditClientComponent } from './modals/edit-client/edit-client.component';
import { SummaryPreviewModal } from './modals/summary-preview/summary-preview.component';
import { ProposalItemsListComponent } from './components/proposal-items-list/proposal-items-list.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from "@angular/cdk/drag-drop";
import { ProposalSummaryComponent } from './components/proposal-summary/proposal-summary.component';
import { TimetableDialogComponent } from './modals/timetable-dialog/timetable-dialog.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { CreateBusinessComponent } from './pages/create-business/create-business.component';
import { WorkflowItemInfoComponent } from './components/workflow-item-info/workflow-item-info.component';
import { ScrollEventDirective } from './directives/scroll-event.directive';
import { CreateEditWorkflowItemComponent } from './modals/create-edit-workflow-item/create-edit-workflow-item.component';
import { CalendarModule, DateAdapter as CalendarDateAdapter} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { WorkflowPreparedComponent } from './components/workflow-prepared/workflow-prepared.component';
import { ProposalEditableComponent } from './components/proposal-editable/proposal-editable.component';
import { ProposalActionsComponent } from './components/proposal-actions/proposal-actions.component';
import { ProposalPreviewAndSendComponent } from './components/proposal-preview-and-send/proposal-preview-and-send.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportsPaymentsComponent } from './pages/reports-payments/reports-payments.component';
import { ReportsCommissionsComponent } from './pages/reports-commissions/reports-commissions.component';
import { ReportsTechPartsComponent } from './pages/reports-tech-parts/reports-tech-parts.component';
import { ReportsOverlookComponent } from './pages/reports-overlook/reports-overlook.component';
import { ReportsCompareComponent } from './pages/reports-compare/reports-compare.component';
import { SalesSummaryComponent } from './components/sales-summary/sales-summary.component';
import { WorkflowItemInfoMobileComponent } from './components/workflow-item-info-mobile/workflow-item-info-mobile.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { InvoiceActionsComponent } from './components/invoice-actions/invoice-actions.component';
import { InvoiceEditableComponent } from './components/invoice-editable/invoice-editable.component';
import { InvoiceItemsListComponent } from './components/invoice-items-list/invoice-items-list.component';
import { InvoiceSummaryComponent } from './components/invoice-summary/invoice-summary.component';
import { InvoicePreviewAndSendComponent } from './components/invoice-preview-and-send/invoice-preview-and-send.component';
import { PersonalComponent } from './pages/profile/personal/personal.component';
import { BusinessComponent } from './pages/profile/business/business.component';
import { RoleLabelComponent } from './pages/profile/users/role-label/role-label.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentSummaryComponent } from './components/payment-summary/payment-summary.component';
import { PaymentActionsComponent } from './components/payment-actions/payment-actions.component';
import { PaymentItemComponent } from './components/payment-item/payment-item.component';
import { AddEditPaymentComponent } from './modals/add-edit-payment/add-edit-payment.component';
import { RefundPaymentComponent } from './modals/refund-payment/refund-payment.component';
import { PayCommissionComponent } from './modals/pay-commission/pay-commission.component';
import { CustomerListComponent } from "./pages/customer-list/customer-list.component";
import { CustomerItemsListComponent } from './components/customer-items-list/customer-items-list.component';
import { CustomerTileComponent } from './components/customer-tile/customer-tile.component';
import { SelectBusinessButtonComponent } from './components/select-business-button/select-business-button.component';
import { BusinessButtonComponent } from './components/select-business-button/business-button/business-button.component';
import { UserSearchBarComponent } from './components/user-search-bar/user-search-bar.component';
import { AssignedComponent } from './pages/assigned/assigned.component';
import { AssignmentComponent } from './pages/assignment/assignment.component';
import { DatePipe } from "@angular/common";
import { AssignedDialogComponent } from './modals/assigned-dialog/assigned-dialog.component';
import { AssignmentDialogComponent } from './modals/assignment-dialog/assignment-dialog.component';
import { AssignTileComponent } from './components/assign-tile/assign-tile.component';
import { CreateEditWorkflowItemRestrictedComponent } from './modals/create-edit-workflow-item-restricted/create-edit-workflow-item-restricted.component';
import { SuspendedBusinessComponent } from './pages/suspended-business/suspended-business.component';
import { ResetComponent } from './pages/reset/reset.component';
import { GoogleAuthResultComponent } from './pages/google-auth-result/google-auth-result.component';
import { GoogleAuthComponent } from './pages/google-auth/google-auth.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { ItemTileComponent } from './components/item/item-tile.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { LeadTileComponent } from './components/lead-tile/lead-tile.component';
import { BaseTileComponent } from './components/base-tile/base-tile.component';
import { EstimatesComponent } from './pages/estimates/estimates.component';
import { EstimateTileComponent } from './components/estimate-tile/estimate-tile.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { JobTileComponent } from './components/job-tile/job-tile.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AvailabilityComponent } from './pages/availability/availability.component';
import { VersionPickerComponent } from './components/version-picker/version-picker.component';
import { BaseTileTimeComponent } from './components/base-tile/base-tile-time/base-tile-time.component';
import { TechPartsReportItemsComponent } from './components/tech-parts-report-items/tech-parts-report-items.component';
import { TechPartsReportTileComponent } from './components/tech-parts-report-tile/tech-parts-report-tile.component';
import { CommissionsReportItemsComponent } from './components/commissions-report-items/commissions-report-items.component';
import { CommissionsReportTileComponent } from './components/commissions-report-tile/commissions-report-tile.component';
import { PaymentsReportItemsComponent } from './components/payments-report-items/payments-report-items.component';
import { PaymentsReportTileComponent } from './components/payments-report-tile/payments-report-tile.component';
import { AllScheduleComponent } from './pages/all-schedule/all-schedule.component';
import { RatingComponent } from "../../../common/src/lib/components/rating/rating.component";
import { HelpComponent } from "./pages/profile/help/help.component";
import { RequestInvitationFormComponent } from "./components/request-invitation-form/request-invitation-form.component";
import { PoIListComponent } from './pages/poi-list/poi-list.component';
import { PoITileComponent } from './components/poi-tile/poi-tile.component';
import { HideTextDirective } from "./directives/hide-text.directive";
import { ChatComponent } from './components/chat/chat.component';
import { NotificationsButtonComponent } from './components/notifications-button/notifications-button.component';
import { ChatsListComponent } from './components/chats-list/chats-list.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { BusinessChatContentComponent } from './components/business-chat-content/business-chat-content.component';
import { SupabaseService } from './services/supabase.service';
import { ChatMessageInputComponent } from "./components/chat-message-input/chat-message-input.component";
import { OnboardingBannerComponent } from './components/onboarding-banner/onboarding-banner.component';
import { AfterLoginComponent } from './components/after-login/after-login.component';
import { WsErrorBannerComponent } from './components/ws-error-banner/ws-error-banner.component';
import { ShortNumberDirective } from './directives/short-number.directive';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    monthLabel: 'MMMM',
  },
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

export const zone = new NgZone({});
const supabaseService = new SupabaseService(environment.supabase, zone);

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    LoginComponent,
    ProposalsComponent,
    InvoicesComponent,
    SearchComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    ProfileLogoComponent,
    ProfileLogoModal,
    ProvidersRowComponent,
    DragNDropDirective,
    HideTooltipOnScrollDirective,
    ChangeEmailComponent,
    ForgotPasswordModal,
    ChangePasswordComponent,
    ReauthenticationDialog,
    RequestInvitationModal,
    LabelComponent,
    EmptyStateComponent,
    MobileToolbarComponent,
    ProfileFieldComponent,
    LobbyComponent,
    UsersComponent,
    UserTileComponent,
    AddUserComponent,
    ItemsComponent,
    CreateOrEditItemComponent,
    JoinComponent,
    UserAddedComponent,
    ViewAsButtonComponent,
    UserButtonComponent,
    ProposalComponent,
    AvailabilityModalComponent,
    UserSelectComponent,
    AvailabilityTileComponent,
    OverlookComponent,
    ScheduleComponent,
    ItemUploadComponent,
    ItemExampleComponent,
    MenuButtonComponent,
    EventInfoComponent,
    SummaryPreviewModal,
    TimetableDialogComponent,
    TimeInputComponent,
    CreateBusinessComponent,
    EditClientComponent,
    ProposalItemsListComponent,
    ProposalSummaryComponent,
    WorkflowItemInfoComponent,
    ScrollEventDirective,
    HideTextDirective,
    CreateEditWorkflowItemComponent,
    WorkflowPreparedComponent,
    ProposalEditableComponent,
    ProposalActionsComponent,
    ProposalPreviewAndSendComponent,
    WorkflowItemInfoMobileComponent,
    CalendarViewComponent,
    InvoiceComponent,
    InvoiceActionsComponent,
    InvoiceEditableComponent,
    InvoiceItemsListComponent,
    InvoiceSummaryComponent,
    InvoicePreviewAndSendComponent,
    PersonalComponent,
    BusinessComponent,
    RoleLabelComponent,
    ProgressBarComponent,
    PaymentComponent,
    PaymentSummaryComponent,
    PaymentActionsComponent,
    PaymentItemComponent,
    CalendarViewComponent,
    ReportsComponent,
    ReportsPaymentsComponent,
    ReportsCommissionsComponent,
    ReportsTechPartsComponent,
    ReportsOverlookComponent,
    ReportsCompareComponent,
    SalesSummaryComponent,
    AddEditPaymentComponent,
    RefundPaymentComponent,
    CustomerListComponent,
    CustomerItemsListComponent,
    CustomerTileComponent,
    PayCommissionComponent,
    SelectBusinessButtonComponent,
    BusinessButtonComponent,
    UserSearchBarComponent,
    AssignedComponent,
    AssignmentComponent,
    AssignedDialogComponent,
    CreateEditWorkflowItemRestrictedComponent,
    AssignTileComponent,
    AssignmentDialogComponent,
    SuspendedBusinessComponent,
    ResetComponent,
    GoogleAuthResultComponent,
    GoogleAuthComponent,
    DynamicListComponent,
    ItemTileComponent,
    LeadsComponent,
    LeadTileComponent,
    BaseTileComponent,
    EstimatesComponent,
    EstimateTileComponent,
    JobsComponent,
    JobTileComponent,
    LoadingComponent,
    AvailabilityComponent,
    VersionPickerComponent,
    BaseTileTimeComponent,
    TechPartsReportItemsComponent,
    TechPartsReportTileComponent,
    CommissionsReportItemsComponent,
    CommissionsReportTileComponent,
    PaymentsReportItemsComponent,
    PaymentsReportTileComponent,
    AllScheduleComponent,
    RatingComponent,
    HelpComponent,
    RequestInvitationFormComponent,
    PoIListComponent,
    PoITileComponent,
    ChatComponent,
    NotificationsButtonComponent,
    ChatsListComponent,
    NotificationsListComponent,
    BusinessChatContentComponent,
    ChatMessageInputComponent,
    OnboardingBannerComponent,
    AfterLoginComponent,
    WsErrorBannerComponent,
    ShortNumberDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    ClipboardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ServiceWorkerModule.register('service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:60000'
    }),
    FormsModule,
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory
    }),
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideEnvironmentNgxMask(),
    MatDatepickerModule,
    DatePipe,
    { provide: Window, useValue: window },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: 'DOMAIN', useValue: environment.domain },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: 'SupabaseService', useValue: supabaseService },
    { provide: SupabaseService, useValue: supabaseService },
    { provide: NgZone, useValue: zone },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }