import { BehaviorSubject, combineLatest, of } from "rxjs";
import { delay, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";
import { camelCaseToSnake } from "../../../../common/src/lib/services";
import { ClientsService } from "./clients.service";
import { FormControl, FormGroup } from "@angular/forms";
import { FormType } from "../../../../common/src/lib/services/forms.service";

export interface ClientSuggestion {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string
}

export type ClientSuggestionForm = FormType<ClientSuggestion>;

export class ClientSuggestionsService {

    private activatedOptionSubject = new BehaviorSubject<null | ClientSuggestion>(null);
    private hoveredOptionSubject = new BehaviorSubject<null | ClientSuggestion>(null);
    private autocompleteFieldFocused = new BehaviorSubject<keyof ClientSuggestion | null>(null);

    // Selected in mat-autocomplete via arrows
    set activeOption(option: null | ClientSuggestion) {
        this.activatedOptionSubject.next(option);
    }

    // Selected in mat-autocomplete by hovering
    set hoveredOption(option: null | ClientSuggestion) {
        this.hoveredOptionSubject.next(option);
    }

    set focusedField(focusedField: keyof ClientSuggestion | null) {
        this.autocompleteFieldFocused.next(focusedField);
    }

    private filedAndValue$ = combineLatest([
        this.autocompleteFieldFocused.pipe(delay(200)),
        this.form.valueChanges
    ]).pipe(
        map(([field, formValue]) => {
            if(field === null)
                return null;
            const value = formValue[field];
            if(!value || value === '')
                return null;
            return [field, value];
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    options$ = this.filedAndValue$.pipe(
        switchMap(values=> {
            if(values === null) {
                this.hoveredOption = null;
                this.activeOption = null;
                return of(null);
            }
            const [field, value] = values;
            return this.clientsService.clientAutocomplete(value, camelCaseToSnake(field), 5);
        }),
        map(result => {
            return result ?? [];
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    displayOption$ = combineLatest([this.filedAndValue$, this.hoveredOptionSubject, this.activatedOptionSubject]).pipe(
        map(([fieldAndValue, hoveredOption, activatedOption]) => {
            if(!fieldAndValue)
                return null;
            return hoveredOption ?? activatedOption;
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    constructor(
        private clientsService: ClientsService,
        private form: FormGroup<ClientSuggestionForm>
    ) {}

}