import { Component, EventEmitter, Output, Input, AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, tap, startWith, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements AfterViewInit, OnChanges {
    @Input() min: Date | null = null;
    @Input() max: Date | null = null;
    @Input() selected: [Date, Date] | null = null;
    @Input() placeholder = 'Filter by date';
    @Output() change = new EventEmitter<[Date, Date] | null>();
    @Output() status = new EventEmitter<boolean>();

    @ViewChild('picker') picker!: MatDateRangePicker<any>;

    datePickerOpen = false;
    dateFilterFromControl = new FormControl<moment.Moment | Date | null>(null);
    dateFilterToControl = new FormControl<moment.Moment | Date | null>(null);
    dateFilter$ = combineLatest([this.dateFilterFromControl.valueChanges, this.dateFilterToControl.valueChanges]).pipe(
        map(([from, to]) => [from ? moment(from) : null, to ? moment(to) : null]),
        distinctUntilChanged((prev, curr) => curr[0] !== null && curr[1] !== null && !curr[0].isSame(prev[0])),
        map(([from, to]) => [from?.toDate() ?? null, to?.endOf('day').toDate() ?? null]),
        startWith([null, null]),
        tap(([from, to]) => this.change.emit(from && to ? [from, to] : null)),
        shareReplay(1),
    );

    dateFilterText$ = this.dateFilter$.pipe(
        map((dates) => {
            const [fromDate, toDate] = dates;
            if (!(fromDate && toDate))
                return this.placeholder;

            const yearFormat = '/YYYY';
            const monthAndDateFormat = 'MM/DD';
            const from = moment(fromDate!);
            if (!toDate)
                return from.format(monthAndDateFormat + yearFormat);

            const to = moment(toDate);
            if (from.year() === to.year() && from.month() === to.month() && from.date() === to.date()) {
                return from.format(monthAndDateFormat + yearFormat);
            }

            if (from.year() === to.year())
                return from.format(monthAndDateFormat) + ' - ' + to.format(monthAndDateFormat + yearFormat);

            return from.format(monthAndDateFormat + yearFormat) + ' - ' + to.format(monthAndDateFormat + yearFormat);
        }),
    );

    showDateFilterClearButton$ = this.dateFilter$.pipe(
        map(([from, to]) => !!from && !!to)
    );

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        if (this.selected) {
            this.dateFilterFromControl.setValue(this.selected[0]);
            this.dateFilterToControl.setValue(this.selected[1]);
        }
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected?.currentValue === null && this.dateFilterToControl.value) 
            this.clearDateFilter();
    }

    clearDateFilter() {
        this.dateFilterFromControl.setValue(null, {emitEvent: true});
        this.dateFilterToControl.setValue(null, {emitEvent: true});
        this.status.emit(false);
    }

    clearDateFilterEvent(event: Event): void {
        event.stopPropagation();
        this.clearDateFilter();
    }
    
    openPicker() {
        this.picker.open();
    }
}
