<div class="wrapper h-100 d-flex flex-column" [class.dialog]="isModal">
    <div class="wrapper h-100 d-flex flex-column" [class.dialog]="isModal">

        <div *ngIf="isModal" class="header d-flex justify-content-between align-items-center">
            <h3>Notes & Tasks</h3>
            <app-icon style="scale: 1.2" class="se-icon-button no-bg" (click)="close.emit()"
                      src="close"
            ></app-icon>
        </div>

        <div class="content d-flex flex-column justify-content-center h-100" [class.is-modal]="isModal">
            <div *ngIf="showJobType && workflowId">
                <div class="job-type">
                    <h4 class="job-type-caption">Job Type</h4>
                    <mat-form-field class="textarea w-100" appearance="outline" appFormFieldFilled>
                        <textarea #jobTypeInput
                                [formControl]="jobTypeControl"
                                matInput
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="3"
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                id="notes-job-type"
                                (focus)="notesInFocus.emit(true)"
                                (focusout)="notesInFocus.emit(false)"
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="add-buttons d-flex justify-content-between align-items-center">
                <div class="icon-button" (click)="addNote()">
                    <app-icon width="28px" height="28px" class="se-icon-button" src="plus"></app-icon>
                    <p>Note</p>
                </div>
                <label class="d-flex align-content-center m-0" (click)="$event.stopPropagation()">
                    <app-icon width="28px" height="28px" class="se-icon-button" src="plus"></app-icon>
                    <input class="d-none" type="file" accept="image/*"
                        (click)="$event.stopPropagation()"
                        (change)="addImageNote($event, -1); imageInput.value = ''" #imageInput
                    >
                </label>
            </div>

            <div class="notes-list flex-fill" cdkDropList (cdkDropListDropped)="setNewNotePosition($event, notes)">
                <div class="note d-flex align-items-center" *ngIf="newNote">
                    <div class="empty-dot-control"></div>
                    <app-note
                        class="flex-fill"
                        [note]="newNote"
                        (onTextChange)="newNote.text = $event"
                        (onfocus)="noteFocused(NEW_NOTE_ID)"
                        (onunfocus)="noteUnfocused(NEW_NOTE_ID)"
                        [focused]="true"
                        (onImageClick)="imageClick($event)"
                        [currentUser]="currentUser"
                    ></app-note>
                    <div class="empty-dot-control"></div>
                </div>
                <div *ngFor="let note of notes let i = index" class="note d-flex align-items-center" cdkDrag
                     [cdkDragDisabled]="notes.length < 2">

                    <div class="dot-control handler" cdkDragHandle
                         [style.visibility]="notes.length > 1 ? 'visible' : 'hidden'"
                    >
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                    <app-note
                        #noteRef
                        class="flex-fill"
                        [id]="'note_' + i"
                        [note]="note"
                        (onDeleteClick)="deleteNote(i)"
                        (onfocus)="noteFocused(i)"
                        (onunfocus)="noteUnfocused(i)"
                        [focused]="note.inUseBy === currentUser.id"
                        (onImageClick)="imageClick(note)"
                        (onTextChange)="onNoteTextChange($event, i)"
                        [currentUser]="currentUser"
                    ></app-note>
                    <ng-container [ngTemplateOutlet]="noteMenuButton"
                                  [ngTemplateOutletContext]="{ note: note, index: i, ref: noteRef}"
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #noteMenuButton let-note="note" let-index="index" let-ref="ref">
    <div class="dot-control note-menu" [class.active]="menuTrigger.menuOpen"
         #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="changeStatusMenu">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
    <mat-menu #changeStatusMenu xPosition="before" class="no-margin mt--2">
        <div class="menu" (click)="onMenuClick($event)">
            <div class="d-flex flex-column gap--8">
                <ng-container *ngIf="note.image || note.imageName; else noPhoto">
                    <label class="menu__photo-button" (click)="menuTrigger.closeMenu()">
                        Edit photo
                        <input class="d-none" type="file" accept="image/*"
                               (change)="addImageNote($event, index, ref)">
                    </label>
                    <label class="menu__photo-button" (click)="deleteNoteImage(index)">
                        Delete photo
                    </label>
                </ng-container>
                <ng-template #noPhoto>
                    <label class="menu__photo-button" (click)="menuTrigger.closeMenu()">
                        Upload photo
                        <input class="d-none" type="file" accept="image/*"
                               (change)="addImageNote($event, index, ref)">
                    </label>
                </ng-template>
            </div>

            <mat-divider></mat-divider>

            <div class="d-flex flex-column gap--8 my--16">
                <ng-container *ngIf="note.status !== 'In Progress'; else unmark">
                    <button class="menu__status-button in-progress"
                            (click)="onStatusChange(index, 'In Progress'); menuTrigger.closeMenu()"
                    >In Progress
                    </button>
                </ng-container>
                <ng-container *ngIf="note.status !== 'Done'; else unmark">
                    <button class="menu__status-button done"
                            (click)="onStatusChange(index, 'Done'); menuTrigger.closeMenu()"
                    >Done
                    </button>
                </ng-container>
                <ng-template #unmark>
                    <button class="menu__status-button unmark"
                            (click)="onStatusChange(index, 'Unmark'); menuTrigger.closeMenu()"
                    >
                        {{ 'Unmark ' + (note.status === 'Done' ? 'Done' : 'in Progress') }}
                    </button>
                </ng-template>
            </div>

            <mat-divider></mat-divider>

            <ng-container *ngIf="usersService.users$ | async let users">
                <small class="menu__text">
                    By: <b>{{ getFullUserName(note.author!, users) }}</b></small>
                <small class="menu__text">From:
                    <b>{{ note.from }}</b></small>
            </ng-container>
        </div>
    </mat-menu>
</ng-template>
