import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'loader-button',
  templateUrl: './loader-button.component.html',
  styleUrls: ['./loader-button.component.scss']
})
export class LoaderButtonComponent implements OnInit, OnChanges {

  // @ViewChild('button') button!: ElementRef<HTMLElement>;

  @Input() text!: string;
  @Input() loading?: boolean;
  @Input() stroked?: boolean;
  @Input() diameter?: string;
  @Input() color?: string;
  @Input() adjustWidth?: boolean;

  @Output() onClick = new EventEmitter<Event>();

  width = '';

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.adjustWidth && changes.loading?.currentValue !== true && this.width === '') {
      setTimeout((() => {
        this.width = this.element.nativeElement.getBoundingClientRect().width + 'px';
      }).bind(this), 10);
    } else {
      this.width = '100%';
    }
  }

  click(event: Event) {
    if(!this.loading)
      this.onClick.emit(event);
  }
}
