<div class="d-flex flex-column main-content h-100">
  <div class="header">
    <h3>{{title}}</h3>
    <app-icon class="se-icon-button" src="close" (click)="close()"></app-icon>
  </div>
    <form [formGroup]="form" class="modal-form overflow-auto" *ngIf="(largeScreen$ | async)">
        <div class="d-flex gap--16" *ngIf="creation">
            <div class="w-100">
                <h4>Job Type</h4>
                <mat-form-field appearance="outline" class="textarea w-100" appFormFieldFilled>
          <textarea matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="2"
                    formControlName="jobType"
                    tabindex="1"
          ></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="type-select" [class.mt--4]="creation" [class.mb--28]="creation">
            <div class="type-selector" [class.active]="form.get('type')?.value === 'personal'"
                 (click)="toggleType('personal')"
            >
                Personal
            </div>
            <div class="type-selector" [class.active]="form.get('type')?.value === 'business'"
                 (click)="toggleType('business')"
            >
                Business
            </div>
        </div>

        <div class="d-flex gap--16" *ngIf="form.get('type')?.value === 'business'">
            <div class="w-100">
                <h4>Business name</h4>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput
                           formControlName="businessName"
                           tabindex="1"
                           id="business-name"
                    >
                    <mat-error *ngIf="form.get('businessName')!.hasError('emptyBusinessNameError')">
                        Business name is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex gap--16" [class.mt--4]="form.get('type')?.value === 'business'">
            <div>
                <h4>First name</h4>
                <mat-form-field appearance="outline" appFormFieldFilled class="equal-divide">
                    <input matInput
                           formControlName="firstName"
                           tabindex="2"
                           id="first-name"
                           (focus)="firstNameFocused = true; clientSuggestionsService.focusedField = 'firstName'"
                           (focusout)="firstNameFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.firstName ?? ''"
                           [matAutocomplete]="fnAutocomplete"
                           [appHideText]="!firstNameFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="fnTrigger"
                           #fnTrigger="matAutocompleteTrigger"
                    >
                    <mat-error *ngIf="form.get('firstName')!.hasError('emptyFirstNameError')">
                        First name is required
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null"
                            #fnAutocomplete="matAutocomplete">
                        <mat-option class="withSubtitle" *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ option.firstName }}
                            <p class="subtitle">{{ applyPhoneNumberMask(option.phoneNumber) }}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <h4>Last name</h4>
                <mat-form-field appearance="outline" appFormFieldFilled class="equal-divide">
                    <input matInput
                           formControlName="lastName"
                           tabindex="3"
                           (focus)="lastNameFocused = true; clientSuggestionsService.focusedField = 'lastName'"
                           (focusout)="lastNameFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.lastName ?? ''"
                           [matAutocomplete]="lnAutocomplete"
                           [appHideText]="!lastNameFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="lnTrigger"
                           #lnTrigger="matAutocompleteTrigger"
                    >
                    <mat-error *ngIf="form.get('lastName')!.hasError('emptyLastNameError')">
                        Last name is required
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #lnAutocomplete="matAutocomplete">
                        <mat-option class="withSubtitle" *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ option.lastName }}
                            <p class="subtitle">{{ option.email }}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex gap--16 mt--4">
            <div>
                <h4>Phone number</h4>
                <mat-form-field appearance="outline" appFormFieldFilled
                                class="equal-divide phone-number-field" [class.focused]="extInputFocused"
                >
                    <input matInput
                           formControlName="phoneNumber"
                           tabindex="4"
                           (input)="onPhoneNumberInputChange($event)"
                           (focus)="phoneNumberFocused = true; clientSuggestionsService.focusedField = 'phoneNumber'"
                           (focusout)="phoneNumberFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.phoneNumber ?? ''"
                           [matAutocomplete]="phoneAutocomplete"
                           [appHideText]="!phoneNumberFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="pnTrigger"
                           #pnTrigger="matAutocompleteTrigger"
                    >
                    <div class="ext-input-wrap">
                        <div class="ext-divider"></div>
                        <div class="ext-text">Ext</div>
                        <input (click)="$event.preventDefault(); $event.stopPropagation()"
                               formControlName="extNumber"
                               mask="0000"
                               inputmode="tel"
                               autocomplete="off"
                               tabindex="5"
                               (focus)="extInputFocused = true"
                               (focusout)="extInputFocused = false"
                               [class.empty]="emptyExtInput$ | async"
                        >
                    </div>
                    <mat-error *ngIf="form.get('phoneNumber')!.hasError('emptyPhoneNumberError')">
                        Phone number is required
                    </mat-error>
                    <mat-error *ngIf="form.get('phoneNumber')!.hasError('invalidPhoneNumberError')">
                        Invalid phone number. Re-enter
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #phoneAutocomplete="matAutocomplete">
                        <mat-option *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ applyPhoneNumberMask(option.phoneNumber) }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <h4>Email</h4>
                <mat-form-field appearance="outline" appFormFieldFilled class="equal-divide">
                    <input matInput
                           formControlName="email"
                           tabindex="6"
                           (focus)="emailFocused = true; clientSuggestionsService.focusedField = 'email'"
                           (focusout)="emailFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.email ?? 'example@gmail.com'"
                           [matAutocomplete]="emailAutocomplete"
                           [appHideText]="!emailFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="emailTrigger"
                           #emailTrigger="matAutocompleteTrigger"
                    >
                    <mat-error *ngIf="form.get('email')!.hasError('emptyEmailError')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="form.get('email')!.hasError('invalidEmailError')">
                        Invalid email. Re-enter
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #emailAutocomplete="matAutocomplete">
                        <mat-option *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ option.email }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex gap--16 mt--4">
            <div>
                <div class="d-flex justify-content-between">
                    <h4>Billing Address</h4>
                    <div class="clear" *ngIf="form.get('address')?.value"
                         (click)="this.form.get('address')?.setValue(''); address.focus();"
                    >
                        Clear
                    </div>
                </div>
                <mat-form-field appearance="outline" appFormFieldFilled class="address-width">
                    <input matInput
                           formControlName="address"
                           [matAutocomplete]="autocomplete"
                           tabindex="7"
                           #address
                           placeholder="Enter at least 7 characters for autocomplete"
                           [anchorAutocompleteOnScroll]="addressTrigger"
                           #addressTrigger="matAutocompleteTrigger"
                    >
                    <mat-autocomplete #autocomplete="matAutocomplete">
                        <mat-option (placeOptionClick)="onPlacesOptionSelected($event)" *ngFor="let option of autocompleteOptions$ | async" [value]="option">
                            {{ option.description }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.get('address')!.hasError('emptyAddressError')">
                        Address is required
                    </mat-error>
                    <mat-error *ngIf="form.get('address')!.hasError('addressIsString')">
                        Select a full address option from the dropdown
                    </mat-error>
                    <mat-error *ngIf="form.get('address')!.hasError('missingComponents')">
                        Select a full address option from the dropdown
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <h4>Unit</h4>
                <mat-form-field appearance="outline" appFormFieldFilled class="width--100">
                    <input matInput
                           formControlName="unit"
                           tabindex="8"
                           (keydown)="unitTab($event)"
                    >
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="flex-fill overflow-auto" *ngIf="!(largeScreen$ | async)">
        <form [formGroup]="form" class="modal-form-mobile">
            <div class="d-flex gap--16" *ngIf="creation">
                <div class="w-100">
                    <h4>Job Type</h4>
                    <mat-form-field appearance="outline" class="textarea w-100" appFormFieldFilled>
          <textarea matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="2"
                    formControlName="jobType"
                    tabindex="1"
          ></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="type-select" [class.mt--4]="creation" [class.mb--28]="creation">
                <div class="type-selector" [class.active]="form.get('type')?.value === 'personal'"
                     (click)="toggleType('personal')"
                >
                    Personal
                </div>
                <div class="type-selector" [class.active]="form.get('type')?.value === 'business'"
                     (click)="toggleType('business')"
                >
                    Business
                </div>
            </div>

            <div class="client-section-mobile" *ngIf="form.get('type')?.value === 'business'">
                <div class="client-label">Business name</div>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput
                           formControlName="businessName"
                           tabindex="1"
                    >
                    <mat-error *ngIf="form.get('businessName')!.hasError('emptyBusinessNameError')">
                        Business name is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="client-section-mobile">
                <div class="client-label">First name</div>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput
                           formControlName="firstName"
                           tabindex="2"
                           (focus)="firstNameFocused = true; clientSuggestionsService.focusedField = 'firstName'"
                           (focusout)="firstNameFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.firstName ?? ''"
                           [matAutocomplete]="fnAutocomplete"
                           [appHideText]="!firstNameFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="fnTrigger"
                           #fnTrigger="matAutocompleteTrigger"
                    >
                    <mat-error *ngIf="form.get('firstName')!.hasError('emptyFirstNameError')">
                        First name is required
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null"
                            #fnAutocomplete="matAutocomplete">
                        <mat-option class="withSubtitle" *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ option.firstName }}
                            <p class="subtitle">{{ applyPhoneNumberMask(option.phoneNumber) }}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="client-section-mobile">
                <div class="client-label">Last name</div>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput
                           formControlName="lastName"
                           tabindex="3"
                           (focus)="lastNameFocused = true; clientSuggestionsService.focusedField = 'lastName'"
                           (focusout)="lastNameFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.lastName ?? ''"
                           [matAutocomplete]="lnAutocomplete"
                           [appHideText]="!lastNameFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="lnTrigger"
                           #lnTrigger="matAutocompleteTrigger"
                    >
                    <mat-error *ngIf="form.get('lastName')!.hasError('emptyLastNameError')">
                        Last name is required
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #lnAutocomplete="matAutocomplete">
                        <mat-option class="withSubtitle" *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ option.lastName }}
                            <p class="subtitle">{{ option.email }}</p>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="client-section-mobile">
                <div class="client-label">Phone number</div>
                <mat-form-field appearance="outline" appFormFieldFilled
                                class="w-100 phone-number-field" [class.focused]="extInputFocused"
                >
                    <input matInput
                           formControlName="phoneNumber"
                           tabindex="4"
                           (input)="onPhoneNumberInputChange($event)"
                           (focus)="phoneNumberFocused = true; clientSuggestionsService.focusedField = 'phoneNumber'"
                           (focusout)="phoneNumberFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.phoneNumber ?? ''"
                           [matAutocomplete]="phoneAutocomplete"
                           [appHideText]="!phoneNumberFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="pnTrigger"
                           #pnTrigger="matAutocompleteTrigger"
                    >
                    <div class="ext-input-wrap">
                        <div class="ext-divider"></div>
                        <div class="ext-text">Ext</div>
                        <input (click)="$event.preventDefault(); $event.stopPropagation()"
                               formControlName="extNumber"
                               mask="0000"
                               inputmode="tel"
                               autocomplete="off"
                               tabindex="5"
                               (focus)="extInputFocused = true"
                               (focusout)="extInputFocused = false"
                               [class.empty]="emptyExtInput$ | async"
                        >
                    </div>
                    <mat-error *ngIf="form.get('phoneNumber')!.hasError('emptyPhoneNumberError')">
                        Phone number is required
                    </mat-error>
                    <mat-error *ngIf="form.get('phoneNumber')!.hasError('invalidPhoneNumberError')">
                        Invalid phone number. Re-enter
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #phoneAutocomplete="matAutocomplete">
                        <mat-option *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ applyPhoneNumberMask(option.phoneNumber) }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="client-section-mobile">
                <div class="client-label">Email</div>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput
                           formControlName="email"
                           tabindex="6"
                           placeholder="example@gmail.com"
                           (focus)="emailFocused = true; clientSuggestionsService.focusedField = 'email'"
                           (focusout)="emailFocused = false; unfocusClientSuggestionField()"
                           [placeholder]="(clientSuggestionsService.displayOption$ | async)?.email ?? 'example@gmail.com'"
                           [matAutocomplete]="emailAutocomplete"
                           [appHideText]="!emailFocused && !!(clientSuggestionsService.displayOption$ | async)"
                           [anchorAutocompleteOnScroll]="emailTrigger"
                           #emailTrigger="matAutocompleteTrigger"
                    >
                    <mat-error *ngIf="form.get('email')!.hasError('emptyEmailError')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="form.get('email')!.hasError('invalidEmailError')">
                        Invalid email. Re-enter
                    </mat-error>
                    <mat-autocomplete
                            (optionSelected)="onClientSuggestionSelected($event)"
                            (optionActivated)="clientSuggestionsService.activeOption = $event.option?.value ?? null" #emailAutocomplete="matAutocomplete">
                        <mat-option *ngFor="let option of clientSuggestionsService.options$ | async"
                                    (mouseenter)="clientSuggestionsService.hoveredOption = option"
                                    (mouseleave)="clientSuggestionsService.hoveredOption = null"
                                    [value]="option"
                        >
                            {{ option.email }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="client-section-mobile">
                <div class="client-label">Billing Address</div>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput #address
                           formControlName="address"
                           [matAutocomplete]="autocomplete"
                           placeholder="Enter at least 7 characters for autocomplete"
                           tabindex="7"
                           [anchorAutocompleteOnScroll]="addressTrigger"
                           #addressTrigger="matAutocompleteTrigger"
                    >
                    <mat-autocomplete #autocomplete="matAutocomplete">
                        <mat-option (placeOptionClick)="onPlacesOptionSelected($event)" *ngFor="let option of autocompleteOptions$ | async" [value]="option">
                            {{ option.description }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.get('address')!.hasError('emptyAddressError')">
                        Address is required
                    </mat-error>
                    <mat-error *ngIf="form.get('address')!.hasError('addressIsString')">
                        Select an option from the dropdown
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="client-section-mobile">
                <div class="client-label">Unit</div>
                <mat-form-field appearance="outline" appFormFieldFilled class="w-100">
                    <input matInput
                           formControlName="unit"
                           tabindex="8"
                           (keydown)="unitTab($event)"
                    >
                </mat-form-field>
            </div>
        </form>
    </div>

  <div class="client-button-block">
    <div class="button-section">
      <button mat-stroked-button (click)="close()">Close</button>
      <loader-button class="flex-fill" [loading]="loading"
                     [text]="creation ? 'Create' : 'Save'"
                     (onClick)="creation ? create() : save()"
      ></loader-button>
    </div>
  </div>
</div>


