import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { MonthViewDay } from 'calendar-utils';
import { ScheduleService } from "../../services/schedule.service";
import { filter, map, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";
import { CustomDateFormatter } from "../../providers/custom-date-formatter.provider";
import { TimetableService } from "../../services/timetable.service";
import moment from "moment";
import { DateFilter } from '../../models/date-filter.model';
import { LobbyService } from '../../services/lobby.service';
import { EstimatesService } from '../../services/estimates.service';
import { JobsService } from '../../services/jobs.service';
import { ViewAsService } from '../../services/view-as.service';
import { NavigationService } from '../../services/navigation.service';
import { LobbyPage, ScheduleTab } from '../../models/navigation.model';
import { UserTimetable } from "../../models/user-timetable.model";
import { PaginationOptions } from "../../services/pagination.service";
import { AvailabilityService } from "../../services/availability.service";
import { TimeRange } from "../../../../../common/src/lib/models/time-range.model";
import { UtilsService } from 'projects/common/src/public-api';

type CalendarViewItems = {
    estimates: CalendarEvent[];
    jobs: CalendarEvent[];
    unavailable: CalendarEvent[];
}

type UnavailabilityConfig = {
    [date: string]: null | {
        unavailable: boolean,
        full: boolean
    }
}

@Component({
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
        }
    ]
})

export class CalendarViewComponent implements OnInit, OnChanges, OnDestroy {

    @Input() selectedTab!: string;
    @Input() viewDate = new Date();

    @Output() dayWithEventsClicked = new EventEmitter<DateFilter>();

    selectedTabSubject = new BehaviorSubject(this.selectedTab);

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    eventCounter = 0;
    readonly daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    view$: Observable<CalendarView> = this.scheduleService.view$;
    calendarItemsSub?: Subscription;

    // currentUsersTimetable$ = combineLatest([this.timetableService.usersTimetables$, this.viewAsService.selectedUsersIds$]).pipe(
    //     map(([timetables, ids]) => {
    //         if(!ids || !timetables)
    //             return {};
    //         const selectedUsersTimetables: { [uid: number]: UserTimetable } = {};
    //         for(const id of ids) {
    //             selectedUsersTimetables[id] = timetables[id]!;
    //         }
    //         return selectedUsersTimetables;
    //     })
    // );

    // items$ = this.scheduleService.schedule$.pipe();

    legendItems: { title: string, class: string }[] = [];

    timetables$ = this.viewAsService.selectedUsersIds$.pipe(
        switchMap(userIds => {
            if (!userIds)
                return this.timetableService.usersTimetables$;
            return combineLatest(userIds.map(userId => this.timetableService.userTimetableObservable(userId))).pipe(
                map(timetables => {
                    return userIds.reduce((acc, curr) => {
                        acc[curr] = timetables.find(t => t.userId === curr)!;
                        return acc;
                    }, {} as { [p: number]: UserTimetable })
                })
            );
        })
    );
    timetables: { [p: number]: UserTimetable } = {};
    timetablesSub?: Subscription;

    documentObservableOptions$ = combineLatest([
        this.viewAsService.selectedUsersIds$,
        this.lobbyService.selectedDateRange$,
    ]).pipe(
        map(([selectedUserIds, dateRange]) => {
            if (!dateRange)
                return null;
            const options: PaginationOptions = {
                inStartDate: dateRange.from,
                inEndDate: dateRange.to,
            };
            if (selectedUserIds)
                options.inUserIds = selectedUserIds;
            return options;
        })
    );

    documents$ = combineLatest([
        this.selectedTabSubject.pipe(filter(tab => !!tab)),
        this.documentObservableOptions$,
        this.scheduleService.filterEstimateStatus$,
        this.scheduleService.filterJobStatus$
    ]).pipe(
        switchMap(([tab, documentOptions, filterEstimateStatus, filterJobStatus]) => {
            if (!documentOptions)
                return of([]);
            const options = {...documentOptions};
            if (options.inStartDate!.getTime() > Date.now() || options.inEndDate!.getTime() < Date.now()) {
                options.inDirection = options.inStartDate!.getTime() > Date.now() ? 'future' : 'past';
                switch (tab) {
                    case 'All':
                        return this.scheduleService.allScheduleForCalendarViewObservable(options, 'calendar');
                    case 'Estimates':
                        if (filterEstimateStatus !== 'All')
                            (options as any).inStatus = filterEstimateStatus.toLowerCase();
                        return this.estimatesService.estimatesObservable(options, 'calendar');
                    case 'Jobs':
                        if (filterJobStatus !== 'All')
                            (options as any).inStatus = filterJobStatus.toLowerCase();
                        return this.jobsService.jobsObservable(options, 'calendar');
                    case 'Unavailable':
                        return this.availabilityService.unavailabilityObservable(options, 'calendar');
                }
            }
            const pastOptions: PaginationOptions = {...options, inDirection: 'past'};
            const futureOptions: PaginationOptions = {...options, inDirection: 'future'};
            pastOptions.inEndDate = moment().endOf("day").toDate();
            futureOptions.inStartDate = moment().startOf("day").toDate();
            switch (tab) {
                case 'All':
                    return combineLatest([
                        this.scheduleService.allScheduleForCalendarViewObservable(pastOptions, 'calendar'),
                        this.scheduleService.allScheduleForCalendarViewObservable(futureOptions, 'calendar'),
                    ]).pipe(map(([past, future]) => [...past, ...future]));
                case 'Estimates':
                    if (filterEstimateStatus !== 'All') {
                        (pastOptions as any).inStatus = filterEstimateStatus.toLowerCase();
                        (futureOptions as any).inStatus = filterEstimateStatus.toLowerCase();
                    }
                    return combineLatest([
                        this.estimatesService.estimatesObservable(pastOptions, 'calendar'),
                        this.estimatesService.estimatesObservable(futureOptions, 'calendar'),
                    ]).pipe(map(([past, future]) => [...past, ...future]));
                case 'Jobs':
                    if (filterJobStatus !== 'All') {
                        (pastOptions as any).inStatus = filterJobStatus.toLowerCase();
                        (futureOptions as any).inStatus = filterJobStatus.toLowerCase();
                    }
                    return combineLatest([
                        this.jobsService.jobsObservable(pastOptions, 'calendar'),
                        this.jobsService.jobsObservable(futureOptions, 'calendar'),
                    ]).pipe(map(([past, future]) => [...past, ...future]));
                case 'Unavailable':
                    return combineLatest([
                        this.availabilityService.unavailabilityObservable(pastOptions, 'calendar'),
                        this.availabilityService.unavailabilityObservable(futureOptions, 'calendar'),
                    ]).pipe(map(([past, future]) => [...past, ...future]));
            }
            throw new Error(`Type ${this.selectedTab} is not supported`);
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    calendarEvents$: Observable<CalendarViewItems> = this.documents$.pipe(
        map(documents => {
            const items: CalendarViewItems = {
                estimates: [],
                jobs: [],
                unavailable: []
            };
            for (const document of documents) {
                const item: CalendarEvent = {
                    id: document.id,
                    title: '',
                    start: document.startTime!,
                    end: document.endTime
                };
                const arrName = ({
                    'Estimate': 'estimates',
                    'Job': 'jobs',
                    'Personal': 'unavailable',
                } as { [docType: string]: keyof CalendarViewItems })[document.docType!];

                if (document.docType === 'Estimate' || document.docType === 'Job') {
                    item.meta = {
                        status: document.status
                    };
                }

                // This is just a safety precaution but items[arrName] should never be undefined
                if (items[arrName])
                    items[arrName]!.push(item);

            }
            return items;
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    unavailableConfigSubject = new BehaviorSubject<UnavailabilityConfig>({});

    constructor(
        private scheduleService: ScheduleService,
        private timetableService: TimetableService,
        private availabilityService: AvailabilityService,
        private lobbyService: LobbyService,
        private estimatesService: EstimatesService,
        private jobsService: JobsService,
        private viewAsService: ViewAsService,
        public navigationService: NavigationService<LobbyPage, ScheduleTab>,
        private utilsService: UtilsService,
        // private calendarViewService: CalendarViewService
    ) {
    }

    async ngOnInit() {
        this.view$ = this.scheduleService.view$.pipe(
            startWith(CalendarView.Month)
        );
        this.prepLegend();
        this.initTimetables();
        this.setMonthDateFilter(this.viewDate);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedTab) {
            if (this.selectedTabSubject.value !== changes.selectedTab.currentValue) {
                this.selectedTabSubject.next(changes.selectedTab.currentValue);
            }
            this.prepLegend();
        }
    }

    initTimetables() {
        this.timetablesSub = this.timetables$.subscribe(timetables => {
            if (timetables) {
                this.timetables = timetables;
            }
        });
    }

    prepLegend() {
        this.legendItems = [];
        if (this.selectedTab === 'All' || this.selectedTab === 'Estimates')
            this.legendItems.push({
                class: 'dot_estimates',
                title: 'Estimates'
            });
        if (this.selectedTab === 'All' || this.selectedTab === 'Jobs')
            this.legendItems.push({
                class: 'dot_jobs',
                title: 'Jobs'
            });
        if (this.selectedTab === 'Jobs')
            this.legendItems.push({
                class: 'dot_pending',
                title: 'Pending'
            });
        if (this.selectedTab === 'Estimates' || this.selectedTab === 'Jobs')
            this.legendItems.push({
                class: 'dot_canceled',
                title: 'Canceled'
            });
        if (this.selectedTab === 'All' || this.selectedTab === 'Unavailable')
            this.legendItems.push({
                class: 'dot_unavailable',
                title: 'Unavailable'
            });
    }

    private getMonthStart(date: Date) {
        return moment(date).startOf('month').toDate();
    }

    private getCalendarStart(date: Date) {
        return moment(this.getMonthStart(date)).day(0).toDate();
    }

    private getMonthEnd(date: Date) {
        return moment(date).endOf('month').toDate();
    }

    private getCalendarEnd(date: Date) {
        return moment(this.getMonthEnd(date)).day(6).toDate();
    }

    formatDatePart(part: number) {
        const str = '' + part;
        if (str.length === 1)
            return 0 + str;
        return str;
    }


    setMonthDateFilter(date: Date) {
        this.viewDate = date;
        const filter = new DateFilter(this.getCalendarStart(date), this.getCalendarEnd(date));
        this.initConfig(filter);
        this.scheduleService.calendarViewFilter = filter;
        this.lobbyService.setSelectedDateRange(filter);
    }

    async previousMonth() {
        const filter = new DateFilter(this.getCalendarStart(this.viewDate), this.getCalendarEnd(this.viewDate));
        await this.initConfig(filter);
        this.scheduleService.calendarViewFilter = filter;
        this.lobbyService.setSelectedDateRange(filter);
    }

    async nextMonth() {
        const filter = new DateFilter(this.getCalendarStart(this.viewDate), this.getCalendarEnd(this.viewDate));
        await this.initConfig(filter);
        this.scheduleService.calendarViewFilter = filter;
        this.lobbyService.setSelectedDateRange(filter);
    }

    async initConfig(filter: DateFilter) {
        const res: UnavailabilityConfig = {};
        const date = new Date(filter.from!);
        date.setDate(date.getDate() - 1);
        const end = new Date(filter.to!);

        const timetables = await this.timetables$.pipe(take(1)).toPromise();
        const events = await this.calendarEvents$.pipe(take(1)).toPromise();
        while (date.getDate() !== end.getDate() || date.getMonth() !== end.getMonth()) {
            date.setDate(date.getDate() + 1);
            const key = date.getMonth() + '/' + date.getDate();

            if(date.getMonth() === this.viewDate.getMonth()) {
                res[key] = {
                    unavailable: this.initIsUnavailableDay(date, timetables),
                    full: this.initFullUnavailableDay(date, timetables, events)
                }
            } else {
                res[key] = null;
            }
        }
        this.unavailableConfigSubject.next(res);
    }

    isSameDay(eventDate: Date, cellDate: Date): boolean {
        const eventDateAsDate = new Date(eventDate);
        const cellDateAsDate = new Date(cellDate);

        return (
            eventDateAsDate.getDate() === cellDateAsDate.getDate() &&
            eventDateAsDate.getMonth() === cellDateAsDate.getMonth() &&
            eventDateAsDate.getFullYear() === cellDateAsDate.getFullYear()
        );
    }

    countEventsInDay(events: CalendarEvent[], day: string, status?: string): number {
        const dayAsDate = new Date(day);
        return events.filter(event => {
            const isSameDay = this.isSameDay(event.start, dayAsDate)
            if (status)
                return isSameDay && event.meta.status === status;
            return isSameDay;
        }).length;
    }

    hasEventsInAnyCategory(separatedItems: any, day: string): boolean {
        for (const key in separatedItems) {
            if (separatedItems.hasOwnProperty(key)) {
                if (this.countEventsInDay(separatedItems[key], day) > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    handleDayClick(day: MonthViewDay) {
        const date = moment(day.date);
        this.scheduleService.itemsListFilter = new DateFilter(
            date.startOf('day').toDate(),
            date.endOf('day').toDate()
        );
        this.lobbyService.setSelectedDateRange(this.scheduleService.itemsListFilter);
        this.scheduleService.showCalendarSubject.next(false);
    }

    ngOnDestroy() {
        this.calendarItemsSub?.unsubscribe();
        this.timetablesSub?.unsubscribe();
    }

    activeDayIsOpen: boolean = false;
    activeDay!: Date;
    iconStyle = {
        width: '32px',
        height: '18px',
    };

    initIsUnavailableDay(date: Date, timetables: { [p: number]: UserTimetable } | null) {
        if (!timetables)
            return false;
        for (const timetable of Object.values(timetables)) {
            if (timetable.ranges && timetable.ranges[date.getDay()] !== null)
                return false;
        }
        return true;
    }

    isUnavailableDay(date: Date, unavailableConfig: UnavailabilityConfig) {
        const key = date.getMonth() + '/' + date.getDate();
        return unavailableConfig[key]?.unavailable ?? false;
    }

    initFullUnavailableDay(date: Date, timetables: { [p: number]: UserTimetable } | null, events: CalendarViewItems) {
        if(!timetables || Object.keys(timetables).length !== 1)
            return false;
        const timetable = Object.values(timetables)[0];
        const estimates = events.estimates.filter(estimate => this.isSameDay(estimate.start, date));
        const jobs = events.jobs.filter(job => this.isSameDay(job.start, date));
        const unavailable = events.unavailable.filter(unavailable => this.isSameDay(unavailable.start, date));

        if(estimates.length > 0 || jobs.length > 0) {
            return false;
        }

        const dayOfWeek = date.getDay();
        const workingTimeRanges = timetable.ranges[dayOfWeek];
        if (!workingTimeRanges || unavailable.length === 0) {
            return false;
        }

        const coveredRanges = workingTimeRanges.filter((range) => {
            return this.isTimeRangeCovered(range, unavailable);
        })

        // Return true if all workingTimeRanges are covered by the unavailable items
        return coveredRanges.length === workingTimeRanges.length;
    }

    isTimeRangeCovered(range: TimeRange, listOfRanges: CalendarEvent<any>[]) {
        return this.utilsService.isTimeRangeCovered(range, listOfRanges.map(range => ({ startTime: range.start, endTime: range.end ?? range.start })));
    }

    fullUnavailableDay(date: Date, unavailableConfig: UnavailabilityConfig) {
        const key = date.getMonth() + '/' + date.getDate();
        return unavailableConfig[key]?.full ?? false;
    }

    isToday(date: Date): boolean {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    isPastDate(date: Date): boolean {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return date < currentDate;
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

}
