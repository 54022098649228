import { Injectable } from '@angular/core';
import { BusinessService } from './business.service';
import { SupabaseService } from "./supabase.service";
import { take } from "rxjs/operators";
import { ClientAutocompleteResult } from "../models/client-autocomplete-result.model";
import { ClientSearchResult } from "../models/client-search-result.model";
import { Estimate } from "../models/estimate.model";
import { Job } from "../models/jobs.model";
import { Invoice, InvoiceVersion } from "../models/invoice.model";
import { Proposal, ProposalVersion } from "../models/proposal.model";
import { BehaviorSubject } from 'rxjs';
import { frontToServerTranslation } from 'projects/common/src/lib/services/supabase.service';
export interface ClientItemsRequest {
  inFirstName: string,
  inLastName: string,
  inBusinessName: string,
  inEmail: string,
  inPhoneNumber: string,
  inLimit?: number,
  inOffset?: number,
  inUserIds?: number[],
  inStartDate?: Date,
  inEndDate?: Date,
}

export interface ClientSearchRequest {
  inTerm: string;
  inLimit?: number,
  inUserIds?: number[] | null,
}

const LOWER_CASE_MAP = {
  'Estimate': 'estimate',
  'Job': 'job',
  'Proposal': 'proposal',
  'Invoice': 'invoice',
} as const;

type STATUS_MAP = {
  'Estimate': Estimate['status'],
  'Job': Job['status'],
  'Proposal': ProposalVersion['status'],
  'Invoice': InvoiceVersion['status'],
};

type StatusColor = 'grey' | 'green' | 'yellow' | 'red';

export type ClientItemsResult<T extends Estimate | Job | Proposal | Invoice> = {
  itemType: typeof LOWER_CASE_MAP[T['docType']],
  id: number,
  workflowId: number,
  startDate: Date,
  endDate: Date,
  jobType: string,
  status: STATUS_MAP[T['docType']],
  statusColor: StatusColor,
  ownerFirstName: string,
  ownerLastName: string,
  assigneeCount: number | null,
  assigneeInfo: { firstName: string, lastName: string }[],
};

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  currentClientDataSubject = new BehaviorSubject<ClientItemsRequest | null>(null);
  private savedClientData: ClientItemsRequest | null = null;

  constructor(
    private businessService: BusinessService,
    private supabaseService: SupabaseService
  ) {}

  async clientAutocomplete(term: string, field: string, limit?: number) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    const data: any = {
      inTerm: term,
      inField: field
    };
    if(limit)
      data.inLimit = limit;
    return this.supabaseService.rpcFunc<ClientAutocompleteResult[]>(business!.businessId, 'client_autocomplete', frontToServerTranslation(data));
  }

  async clientSearch(data: ClientSearchRequest) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<ClientSearchResult[]>(business!.businessId, 'client_search', frontToServerTranslation(data));
  }

  async getItemsForClient(data: ClientItemsRequest) {
    const business = await this.businessService.selectedBusiness$.pipe(take(1)).toPromise();
    return this.supabaseService.rpcFunc<ClientItemsResult<any>[]>(business!.businessId, 'get_items_for_client', frontToServerTranslation(data));
  }

  saveClientsList() {
    this.savedClientData = this.currentClientDataSubject.value;
  }

  restoreClientsList() {
    this.currentClientDataSubject.next(this.savedClientData);
  }

}
