import { Inject, Injectable, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { shareReplay, map, switchMap, distinctUntilChanged, take, pairwise, startWith } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UserPermission, UserRole } from '../../../../common/src/lib/models/user-profile.model';
import { SupabaseService } from "./supabase.service";
import { capitalizeFirstChar } from "../../../../common/src/lib/services";
import { showSnackbar } from "../../../../common/src/lib/components/snackbar/snackbar.component";
import { User } from "@supabase/supabase-js";
import { ModalBehavior, ModalsService } from "../../../../common/src/lib/services/modals.service";
import { PushyService } from "./pushy.service";
import { environment } from "../../environments/environment";
import { SnackbarService } from "../../../../common/src/lib/components/snackbar/snackbar.service";
import moment from 'moment';
import { ComponentType } from '@angular/cdk/portal';
import { ConfirmationDialog } from 'projects/common/src/lib/modals/confirmation-dialog/confirmation-dialog.component';
import { LobbyService } from './lobby.service';

export interface UserI {
  uid: string,
  email: string,
  providers: string[],
  lastSignIn: Date,
}

export interface Role {
  role: UserRole,
  businessId: string,
  permissions: UserPermission[]
}

const providersMap: { [provider: string]: string } = {
  'password': 'Email',
  'google.com': 'Google',
  'facebook.com': 'Facebook',
  'apple.com': 'Apple',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth = this.supabaseService.supabase.auth;
  private changedValuesSubject = new BehaviorSubject<any>({});
  private authChanges$ = new ReplaySubject<User | null>();
  private savedRefreshToken: string | undefined = undefined;
  private loading = false;

  savedModal: {
    componentClass: ComponentType<unknown>,
    modalBehavior?: ModalBehavior,
    data?: any,
  } | undefined = undefined;

  user$ = this.authChanges$.pipe(
      startWith(null),
      pairwise(),
      map(([prev, curr]) => {
        if(environment.production) {
          if(prev === null && curr !== null) {
            if(!this.pushyService.isRegistered() && this.pushyService.canShowPrompt()){
              this.modalsService.open(ConfirmationDialog, {
                data: {
                  title: 'Enable notifications',
                  message: 'Would you like to enable notifications?',
                  actionTitle: 'Enable',
                  actionColor: 'primary',
                  cancelTitle: 'Cancel',
                  action: this.pushyService.register,
                  cancelAction: this.pushyService.dontShowAgain
                }
              });
            }
          } else if(prev !== null && curr === null) {
            this.pushyService.unregister();
          }
        }
        return curr;
      }),
      switchMap(user => {
        return this.changedValuesSubject.pipe(
            map(changedValues => {
              return user == null ? null : {
                uid: user.id,
                email: changedValues.email ?? user.email,
                providers: user.identities?.map(identity => capitalizeFirstChar(identity.provider)),
                lastSignIn: user.last_sign_in_at ? new Date(user.last_sign_in_at) : new Date()
              } as UserI
            })
        );
      }),
      distinctUntilChanged((prev, curr) => {
        if(prev === null && curr === null)
          return true;
        if(prev === null || curr === null) {
          if (!curr) {
            this.snackbarService.close();
            this.modalsService.close();
            this.lobbyService.setSelectedDateRange(null);
          }
          const invitation = this.getInvitationFromStorage();
          if(invitation) {
            this.router.navigate(['/join', invitation]);
          } else {
            this.router.navigate([curr ? 'lobby' : 'login']);
          }
          return false;
        }
        for(const key of Object.keys(prev)) {
          if(key === 'providers') {
            if(prev.providers.length !== curr.providers.length || prev.providers[0] !== curr.providers[0])
              return false;
            continue;
          }
          const prevValue = (prev as any)[key];
          const currValue = (curr as any)[key];
          if(prevValue !== currValue) {
            if(currValue instanceof Date && prevValue instanceof Date && moment(currValue).isSame(prevValue))
              continue;
            return false;
          }
        }
        return true;
      }),
      shareReplay(1)
  );

  unlinkedDoubleProviderAccount = false;
  popupReference: Window | null = null;

  constructor(
    @Inject('DOMAIN') private domain: string,
    private router: Router,
    private snackBar: MatSnackBar,
    private supabaseService: SupabaseService,
    private modalsService: ModalsService,
    private pushyService: PushyService,
    private snackbarService: SnackbarService,
    private lobbyService: LobbyService,
    private zone: NgZone
  ) {
    this.auth.onAuthStateChange((event, session) => {
      this.zone.run(() => {
        if(event === 'INITIAL_SESSION')
          this.auth.refreshSession();

        if(event === 'PASSWORD_RECOVERY')
          this.router.navigate(['/change-password']);

        this.authChanges$.next(session?.user ?? null);
      });
    });
  }

  getInvitationFromStorage(): string | null {
    return localStorage.getItem('invitation_link');
  }

  removeInvitationFromStorage() {
    localStorage.removeItem('invitation_link');
  }

  async signUpWithGoogle(redirectTo?: string) {
    return this.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: this.domain + '/' + (redirectTo ?? 'lobby'),
        queryParams: {
          prompt: 'select_account',
        },
      },
    });
  }
  
  async signInWithGoogle(redirectTo?: string) {
    const res = await this.signUpWithGoogle(redirectTo);
    return res;
  }

  async signInWithEmailAndPassword(email: string, password: string, redirect = true) {
    const res = await this.auth.signInWithPassword({ email, password });
    if(res.error) {
      throw res.error.message;
    } else if(redirect) {
      this.router.navigate(['/lobby']);
    }
  }

  async signUpWithEmailAndPassword(email: string, password: string, redirect = true) {
    const res = await this.auth.signUp({ email, password });
    if(res.error) {
      throw res.error.message;
    } else if(redirect) {
      this.router.navigate(['/lobby']);
    }
  }

  async reauthenticateWithPassword(password: string) {
    const user = await this.user$.pipe(take(1)).toPromise();
    const res = await this.auth.signInWithPassword({
      email: user!.email,
      password
    });
    if(res.error) {
      return Promise.reject(res.error.message);
    }
    return res;
  }

  async generateGoogleAuthLink() {
    return this.auth.signInWithOAuth({
      provider: 'google',
      options: {
        skipBrowserRedirect: true,
        redirectTo: this.domain + '/gauthres',
        queryParams: {
          prompt: 'select_account',
          ux_mode: 'popup',
        },
      },
    });
  }

  openSavedModal(authSuccess?: boolean) {
    if (this.savedModal)
      this.modalsService.open(this.savedModal.componentClass, {
        data: { ...this.savedModal.data, authSuccess },
        behavior: this.savedModal.modalBehavior,
      });
    this.savedModal = undefined;
  }

  async reauthenticateGoogle() {
    const prevUser = (await this.user$.pipe(take(1)).toPromise())!.uid;
    const url = this.domain + '/gauth';
    if (this.popupReference && !this.popupReference.closed) {
      this.popupReference.focus();
      throw new Error('reject');
    }
    this.popupReference = window.open(url, 'googleAuthPopup', 'width=500,height=600');
    this.popupReference?.focus();

    await new Promise<void>(async (resolve, reject) => {
      const onMessage = (event: MessageEvent) => this.receiveGoogleAuthPopupMessage(resolve, reject, event, prevUser);
      const onPageRefresh = () => this.popupReference?.close();

      window.addEventListener('message', onMessage, false);
      window.addEventListener('beforeunload', onPageRefresh);

      const timer = setInterval((async () => {
        if(!this.loading && (!this.popupReference || this.popupReference.closed)) {
          window.removeEventListener('message', onMessage);
          window.removeEventListener('beforeunload', onPageRefresh);
          clearInterval(timer);
          reject('manually-closed');
          await this.restoreSession();
          this.openSavedModal();
        }
      }).bind(this), 200);

      this.modalsService.open(ConfirmationDialog, {
        behavior: ModalBehavior.Dialog,
        disableClose: true,
        data: {
          title: 'Confirm your account',
          message: 'Sign in to your Google account in the pop-up window.',
          actionTitle: 'Cancel',
          showCancelButton: false,
          showCloseButton: false,
          action: async () => {
            window.removeEventListener('message', onMessage);
            window.removeEventListener('beforeunload', onPageRefresh);
            clearInterval(timer);
            await this.restoreSession();
            this.savedModal = undefined;
            this.popupReference?.close();
          }
        },
        prevComponentMetadata: this.savedModal,
      });
    });
  }

  async receiveGoogleAuthPopupMessage(resolve: (value: (void | PromiseLike<void>)) => void, reject: (reason?: any) => void, event: MessageEvent, prevUser: string) {
    if(event.source !== this.popupReference)
      return;

    if(event.data !== 'success') {
      reject('failed');
      this.openSavedModal(false);
      return;
    }

    this.loading = true;
    const user = (await this.auth.getUser()).data.user?.id ?? null;
    if(!!user && prevUser !== user) {
      this.logout();
      this.modalsService.close();
      this.savedModal = undefined;
      this.savedRefreshToken = undefined;
      this.loading = false;
      throw new Error('reject');
    }
    resolve();
    this.openSavedModal(true);
    this.loading = false;
  }

  sendPasswordResetEmail(email: string) {
    return this.auth.resetPasswordForEmail(email, {
      redirectTo: this.domain + '/update-password',
    });
  }

  async changePassword(newPassword: string) {
    const res = await this.auth.updateUser({ password: newPassword });
    if(res.error)
      throw res.error.message;
    await this.auth.refreshSession();
  }

  async changeAccountViaGoogle() {
    await this.auth.linkIdentity({
      provider: 'google',
      options: {
        redirectTo: this.domain + '/lobby',
        queryParams: {
          prompt: 'select_account',
        },
      },
    });
    showSnackbar(this.snackBar, {
      message: "Profile updated",
    });
  }

  async changeAccountViaEmail(email: string, password?: string) {
    const res = await this.auth.updateUser(password ? { email, password } : { email });
    if(res.error?.message === 'A user with this email address has already been registered') {
      throw 'emailInUse';
    }
    if(res.error?.message === 'Unable to validate email address: invalid format') {
      throw 'email';
    }
    if(res.error?.message === 'New password should be different from the old password.') {
      await this.auth.updateUser({ email });
    }
    this.auth.refreshSession();
    showSnackbar(this.snackBar, {
      message: "Profile updated",
    });
  }

  logout() {
    return this.auth.signOut({ scope: 'local' });
  }

  async saveSession() {
    this.savedRefreshToken = (await this.auth.getSession())?.data.session?.refresh_token;
  }

  async restoreSession() {
    if (this.savedRefreshToken) {
      await this.auth.refreshSession({ refresh_token: this.savedRefreshToken });
      this.savedRefreshToken = undefined;
    }
  }

}
