<div class="dialog wrapper">
  <div class="content">
      <h3>{{ data.title }}</h3>
      <p class="message">{{ data.message }} </p>
      <div class="footer">
          <button mat-stroked-button *ngIf="data.showCancelButton" (click)="cancel()">{{ data.cancelTitle ?? 'Cancel' }}</button>
          <loader-button id="button_confirmation_dialog" [class.without-cancel-button]="!data.showCancelButton" [loading]="loading" [text]="data.actionTitle" [color]="data.actionColor ?? 'warn'" (onClick)="action()"></loader-button>
      </div>
  </div>
  <app-icon *ngIf="data.showCancelButton" class="se-icon-button no-bg" (click)="cancel()" src="close" ></app-icon>
  <div class="background"></div>
</div>
