import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentSummary } from "../../models/payment.model";
import { currencify } from "../../helpers/common";
import { rounder } from "../../../../../common/src/lib/helpers/payments";

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss']
})
export class PaymentSummaryComponent {

  protected readonly currencify = currencify;
  protected readonly rounder = rounder;

  @Input() paymentSummary!: PaymentSummary;
  @Input() showSmall?: boolean;
  @Output() openedSummary = new EventEmitter<boolean>();

  isOpen = false;

  toggleSummaryOpen() {
    this.isOpen = !this.isOpen;
    this.openedSummary.emit(this.isOpen);
  }
}
