<div class="page main h-100 w-100 d-flex flex-column justify-content-start" *ngIf="user$ | async let user">

    <app-mobile-toolbar [keepSearchOpen]="true" class="only-mobile-sm" [showMenu]="true"></app-mobile-toolbar>

    <app-toolbar type="menu" class="not-mobile-sm"></app-toolbar>

    <div class="flex-fill d-flex flex-column lobby-main">

        <div class="container flex-fill lobby-content d-flex flex-column">
            <div class="header">
                <div class="date-picker-container">
                    <div class="d-flex date-picker-wrapper">
                        <div class="date-picker_top">
                            <app-date-range class="date-range-picker"
                                            [selected]="(dateFilterForPicker$ | async)"
                                            (change)="selectedRangeChange($event)"
                                            #dateRange
                            ></app-date-range>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <ng-container *ngIf="!loading else loader">
                <app-customer-items-list *ngIf="(hasItems$ | async) else emptyState"
                    [items]="(items$ | async)!"
                ></app-customer-items-list>
            </ng-container> -->
            <div class="position-relative w-100 flex-grow-1">
                <app-customer-items-list *ngIf="(hasItems$ | async) else emptyState"
                    [class.faded]="loading"
                    [items]="(items$ | async)!"
                    [showUser]="(showUser$ | async)!"
                ></app-customer-items-list>
                <div *ngIf="loading" class="loader">
                    <mat-spinner [diameter]="50"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #emptyState>
    <app-empty-state *ngIf="!loading"
        [filter]="(dateFilter$ | async) === null ? 'Search' : 'Range'"
    ></app-empty-state>
</ng-template>

<!-- <ng-template #loader>
    <div class="h-100 w-100 d-flex justify-content-center align-items-center position-absolute">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
</ng-template> -->