<div class="snackbar-container" [class.undo]="undo">
    <div *ngIf="data.actionText" class="timer">
        <h4>{{(timer | async)!/1000}}</h4>
    </div>
    <p class="message flex-fill not-mobile">{{data.message}}</p>
    <h4 class="message flex-fill only-mobile">{{data.message}}</h4>
    <div *ngIf="data.actionText" class="undo-button" (click)="undo()">
        <small>{{ data.actionText }}</small>
    </div>
    <app-icon class="close" src="close" (click)="close()"></app-icon>
</div>