import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-ws-error-banner',
  templateUrl: './ws-error-banner.component.html',
  styleUrls: ['./ws-error-banner.component.scss']
})
export class WsErrorBannerComponent implements OnInit, OnDestroy {

  seconds!: number;
  timer?: NodeJS.Timeout;

  constructor(
      private window: Window
  ) {}

  ngOnInit() {
    this.seconds = 30;
    this.timer = setInterval(() => {
      this.seconds--;
      if(this.seconds === 0) {
        this.clearTimer();
        setTimeout(() => {
          this.reload();
        }, 1000);
      }
    }, 1000);
  }

  reload() {
    this.window.location.reload();
  }

  clearTimer() {
    if(this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  ngOnDestroy() {
    this.clearTimer();
  }
}
