<div class="profile-photo" *ngIf="isForeign">
    <h4 class="name">{{ avatarText }}</h4>
</div>
<div class="d-flex flex-column ml-auto mw-100 overflow-hidden" [class.foreign]="isForeign">
    <div class="wrapper" 
        [class.audio]="isAudio" 
        [class.highlight-border]="isImage || isVideo || isForeign" 
        [class.attachment-without-text]="message.attachment && !message.content"
    >

        <ng-container *ngIf="message.attachment">
            <div *ngIf="isVideo || isImage" 
                class="attachment" [style.cursor]="isImage ? 'pointer' : 'default'" 
                (click)="isImage ? imageClicked.emit(src) : null"
            >
                <skeleton-img *ngIf="!isVideo" [style.width]="width + 'px'" [style.aspect-ratio]="aspectRatio" class="img" [src]="src"></skeleton-img>
                <video *ngIf="isVideo" class="video" [style.width]="width + 'px'" [style.aspect-ratio]="aspectRatio" [src]="src" controls></video>
            </div>

            <div *ngIf="isAudio" class="audio-attachment">
                <img class="play-pause" height="32" width="32" 
                    (click)="playing ? mediaElement.pause() : mediaElement.play()" 
                    [src]="'../../../assets/icons/' + (playing ? 'pause' : 'play') + '.svg'"
                >
                <div class="audio-progress">
                    <audio [src]="src" #mediaElement preload="auto"></audio>
                    <div style="margin: -8px 0; padding: 8px 0; display: flex"
                         (click)="seek($event, mediaElement)"
                         (mousedown)="seeking = true"
                         (mouseup)="seeking = false"
                         (mouseleave)="seeking = false"
                         (mousemove)="mouseMove($event, mediaElement)"
                    >
                        <progress [value]="progress$ | async" max="100"></progress>
                    </div>
                    <div class="info">
                        <caption class="time" [class.playing]="playing">{{ progressTime$ | async | date: playerTime }}</caption>
                        <ng-container *ngIf="!message.content" [ngTemplateOutlet]="bottom"></ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="message.content" class="text-footer">
            <div class="text">
                <h4 *ngIf="supportChat; else businessChat">{{ message.content }}</h4>
                <ng-template #businessChat><h4 chatMessage>{{ message.content }}</h4></ng-template>
            </div>
            <ng-container [ngTemplateOutlet]="bottom"></ng-container>
        </div>

        <ng-container
            *ngIf="!message.content && !isAudio"
            [ngTemplateOutlet]="bottom"
        ></ng-container>
    </div>
</div>

<ng-template #bottom>
    <div #bottom class="bottom" [class.attachment-without-text]="message.attachment && !message.content && !isAudio">
        <small *ngIf="message.createdAt" class="time">{{ message.createdAt | date: timeFormat}}</small>
        <img 
            *ngIf="message.status === 'delivered' || message.status === 'seen'"
            class="seen"
            [class.audio]="isAudio"
            [src]="'../../../assets/icons/message_' + message.status + '.svg'"
        >
    </div>
</ng-template>
