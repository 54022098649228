<div class="page bg">

    <app-toolbar type="signup"></app-toolbar>

    <div class="d-flex flex-column flex-fill justify-content-center align-items-stretch container w-100">
    
        <div class="header">
            <app-icon class="se-icon-button" src="back"  (click)="back()"></app-icon>
        </div>

        <div class="d-flex flex-fill justify-content-center align-items-start">
    
            <mat-card class="m-auto mat-elevation-z3">
    
                <mat-stepper class="content" linear="true" #stepper>
                    <mat-step>
                        <ng-container [ngTemplateOutlet]="firstStep"></ng-container>
                    </mat-step>
                    <mat-step>
                        <ng-container [ngTemplateOutlet]="secondStep"></ng-container>
                    </mat-step>
                </mat-stepper>
        
            </mat-card>
        </div>
    </div>
</div>

<ng-template #firstStep>
    <mat-card-title><h2 class="text-center">Reset password</h2></mat-card-title>
    <mat-card-subtitle><p class="text-center">The reset link will be sent <span class="d-inline-block">to your email</span></p></mat-card-subtitle>

        <form class="d-flex flex-column justify-content-center" [formGroup]="form">
            <h4>Email</h4>
            <mat-form-field appearance="outline" appFormFieldFilled>
                <input matInput #emailInput appFormFieldFilled formControlName="email"
                    autocomplete="email"
                    inputmode="email"
                    placeholder="Enter your email"
                    enterkeyhint="send"
                    tabindex="1"
                />
                <mat-error *ngIf="form.get('email')!.hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="form.get('email')!.hasError('email')">
                    Invalid email. Re-enter
                </mat-error>
                <mat-error *ngIf="form.get('email')!.hasError('emailNotFound')">
                    Email not found. Try another one
                </mat-error>
                <mat-error *ngIf="form.get('email')!.hasError('tooManyRequests')">
                    Too many requests, try again later
                </mat-error>
            </mat-form-field>
            <loader-button class="footer" [loading]="loading" text="Submit" (onClick)="submit()"></loader-button>
        </form>
</ng-template>

<ng-template #secondStep>
    <mat-card-title><h2 class="text-center">Email sent</h2></mat-card-title>
    <mat-card-subtitle>
        <p class="text-center">Go to your email <span class="d-inline-block">to reset your password</span></p>
        <p class="text-center">You may need to check your spam</p>
    </mat-card-subtitle>

    <button mat-flat-button color="primary" class="footer finish" routerLink="/login">Back to Login</button>

</ng-template>