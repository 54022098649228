import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeEmailComponent } from '../../../modals/change-email/change-email.component';
import { ChangePasswordComponent } from '../../../modals/change-password/change-password.component';
import { BusinessProfile } from '../../../../../../common/src/lib/models/business-profile.model';
import { AuthService } from '../../../services/auth.service';
import { ModalsService } from '../../../../../../common/src/lib/services/modals.service';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from '../../../services/users.service';
import { map, take } from 'rxjs/operators';
import { UserProfile } from '../../../../../../common/src/lib/models/user-profile.model';
import { showSnackbar } from 'projects/common/src/lib/components/snackbar/snackbar.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent {

  @Input() profile!: BusinessProfile;
  @Input() user!: UserProfile;
  @Input() showNameErrors?: {
    firstName: boolean,
    lastName: boolean,
  };

  @ViewChild('firstNameInput') firstNameInput!: ElementRef;
  @ViewChild('lastNameInput') lastNameInput!: ElementRef;

  email$ = this.authService.user$.pipe(
    map(user => user!.email)
  );

  hasEmailProvider$ = this.authService.user$.pipe(
    map(user => {
      return user?.providers.includes('Email');
    })
  );

  directPhoneNumberSubject = new BehaviorSubject('');
  directPhoneNumberError?: string;

  currentUserProfile$ = this.usersService.currentUser$;

  firstName$ = this.currentUserProfile$.pipe(
    map(user => user.firstName)
  );

  lastName$ = this.currentUserProfile$.pipe(
    map(user => user.lastName)
  );

  directPhoneNumber$ = this.currentUserProfile$.pipe(
    map(user => user.directPhoneNumber)
  );

  showDirectPhoneNumber$ = this.currentUserProfile$.pipe(
    map(user => user.showDirectPhoneNumber)
  );

  fakeFormGroup = new UntypedFormGroup({});

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private modalsService: ModalsService,
    private snackbar: MatLegacySnackBar,
  ) { }

  ngOnInit(): void {}

  async saveFirstName(value: string) {
    const trimmedValue = value.trim()
    if(!trimmedValue) {
      return "First name is required";
    }
    return this.saveInfoValue('firstName', trimmedValue);
  }

  async saveLastName(value: string) {
    const trimmedValue = value.trim()
    if(!trimmedValue) {
      return "Last name is required";
    }
    return this.saveInfoValue('lastName', trimmedValue);
  }
  async saveDirectPhoneNumber(value: string) {
    if(value.length !== 0 && value.length !== 10) {
      return "Invalid direct phone number. Re-enter";
    }
    return this.saveInfoValue('directPhoneNumber', value);
  }

  async changeVisibility(value: boolean) {
    return this.saveVisibilityStatus('showDirectPhoneNumber', value);
  }

  async saveInfoValue(field: string, v: string) {
    let value: string = v.trim();

    const user = await this.currentUserProfile$.pipe(take(1)).toPromise();

    if((user as any)[field] !== value) {
      try {
        (user as any)[field] = value;
        
        const data: any = {};
        data[field] = value;
        await this.usersService.updateUser(data);
        showSnackbar(this.snackbar, {
          message: "Profile updated"
        });
      } catch (e) {
        console.log(`account.component.ts > AccountComponent > saveInfoValue()`, e);
      }
    }

    return null;
  }

  async saveVisibilityStatus(field: string, v: boolean) {
    const user = await this.currentUserProfile$.pipe(take(1)).toPromise();

    if ((user as any)[field] !== v) {
      try {
        (user as any)[field] = v;
        
        const data: any = {};
        data[field] = v;
        await this.usersService.updateUser(data);
      } catch (e) {
        console.log(`account.component.ts > AccountComponent > saveInfoValue()`, e);
      }
    }

    return null;
  }

  async changeEmail() {
    this.modalsService.open(ChangeEmailComponent);
  }

  changePassword() {
    this.modalsService.open(ChangePasswordComponent);
  }
}
