import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, merge, Observable, of, timer } from "rxjs";
import { DynamicListComponent } from "../dynamic-list/dynamic-list.component";
import { debounce, debounceTime, filter, map, startWith, switchMap } from "rxjs/operators";

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent implements OnInit {

  @Input() menuOpen!: boolean;
  @Input() hideOnScroll = false;

  opacity$!: Observable<number>;

  ngOnInit() {
    this.opacity$ = !this.hideOnScroll ? of(1) : DynamicListComponent.scrolling$.pipe(
        switchMap(isScrolling =>
            isScrolling
                ? of(0.2) // Emit 0.2 immediately when scrolling starts
                : timer(500).pipe(map(() => 1)) // Emit 1 after 500ms delay when scrolling stops
        ),
        startWith(1) // Start with full opacity
    );
  }

}
