import { AfterContentInit, ContentChild, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { Subscription } from 'rxjs';
import { UtilsService } from "../services";

@Directive({
  selector: '[appFormFieldFilled]'
})
export class FormFieldFilledDirective implements AfterContentInit, OnDestroy {

  private sub?: Subscription;

  constructor(
    private renderer: Renderer2,
    private utilsService: UtilsService,
    private element: ElementRef
  ) { }

  @ContentChild(MatInput) input!: MatInput;

  ngAfterContentInit() {
    
    if(this.input) {

      if(this.input.ngControl.value && this.input.ngControl.value.length !== '') {
        this.renderer.addClass(this.element.nativeElement, 'filled');
      }

      this.checkAgain();

      this.sub = this.input.ngControl.valueChanges?.subscribe(value => {
        if(!value || value.length === 0) {
          this.renderer.removeClass(this.element.nativeElement, 'filled');
          
        } else {
          this.renderer.addClass(this.element.nativeElement, 'filled');
        }
      });
    } 
  }

  async checkAgain() {
    await this.utilsService.delay(100);

    if(this.input.ngControl.value && this.input.ngControl.value.length !== '') {
      this.renderer.addClass(this.element.nativeElement, 'filled');
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
