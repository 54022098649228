import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from '../../services/auth.service';
import { FormsService } from '../../../../../common/src/lib/services/forms.service';
import { ModalsService } from '../../../../../common/src/lib/services/modals.service';
import { ForgotPasswordModal } from '../forgot-password/forgot-password.component';
import { BusinessService } from '../../services/business.service';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

  form = this.formBuilder.group({
    email: new UntypedFormControl('', [Validators.email]),
    password: new UntypedFormControl(''),
    newPassword: new UntypedFormControl(''),
  });

  showPassword = false;
  loading = false;

  providers$ = this.authService.user$.pipe(
      map(user => user!.providers)
  );

  showSeparator$ = this.providers$.pipe(
      map(providers => providers.includes('Email') && providers.includes('Google'))
  );

  newPasswordRequired$ = this.providers$.pipe(map(providers => !providers.includes('Email')));

  showReauthentication = true;

  @ViewChild("stepper") stepper!: MatStepper;
  @ViewChild('passwordInput') passwordInput!: ElementRef;
  @ViewChild('newPasswordInput') newPasswordInput!: ElementRef;
  @ViewChild('emailInput') emailInput!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalsService: ModalsService,
    private formsService: FormsService,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    const user = await this.authService.user$.pipe(take(1)).toPromise();
    const fiveMinutes = 5 * 60 * 1000;
    if(Date.now() - user!.lastSignIn.getTime() < fiveMinutes) {
      this.showReauthentication = false;
    }
  }

  async validatePassword() {
    if(this.loading) {
      return;
    }
    
    if(this.formsService.validateRequired(this.form.get('password')!)) {
      this.loading = true;
      const { password } = this.form.value;
      try {
        await this.authService.reauthenticateWithPassword(password);
        this.nextStep();
      } catch (e) {
        this.form.get('password')!.setErrors({
          password: true
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
    this.loading = false;
  }

  nextStep() {
    this.stepper.next();
    setTimeout(() => this.emailInput.nativeElement.focus(), 500);
  }

  async changeEmail() {
    if(this.loading) {
      return;
    }
    const validEmail = this.formsService.validateRequired(this.form.get('email')!);
    const saveNewPassword = await this.newPasswordRequired$.pipe(take(1)).toPromise();
    const validPassword = saveNewPassword ? this.isNewPasswordValid() : true;
    if(validEmail && validPassword) {
      this.loading = true;
      const email = this.form.get('email')!.value;
      try {
        await this.authService.changeAccountViaEmail(email, saveNewPassword ? this.form.get('newPassword')?.value : undefined);
        this.close();
      } catch (error: any) {
        if(['emailInUse', 'email'].includes(error)) {
          this.form.get('email')!.setErrors({
            emailInUse: error === 'emailInUse',
            email: error === 'email'
          });
        } else {
          console.error(error);
        }
      }
    } else {
      this.form.markAllAsTouched();
    }

    this.emailInput.nativeElement.blur();
    this.newPasswordInput?.nativeElement.blur();
    this.loading = false;
  }

  isNewPasswordValid() {
    if(!this.formsService.validateRequired(this.form.get('newPassword')!)) {
      return false;
    }
    if(!this.form.controls.newPassword.value || this.form.controls.newPassword.value.length < 6) {
      this.form.controls.newPassword.setErrors({
        minlength: true
      });
      return false;
    }
    return true;
  }

  openForgotPassword() {
    this.modalsService.open(ForgotPasswordModal, {
      data: {
        backComponent: ChangeEmailComponent
      }
    });
  }

  onGoogleLinkStart() {
    this.loading = true;
  }

  onGoogleReauthStart() {
    this.authService.savedModal = {
      componentClass: ChangeEmailComponent
    };
    this.loading = true;
  }

  onGoogleLinkFailed(error: any) {
    console.log(`change-email.component.ts > ChangeEmailComponent > onGoogleLinkFailed()`, error);
    this.loading = false;
  }

  close() {
    this.modalsService.close();
  }
}
