import { Component, ViewChild } from '@angular/core';
import { BusinessService } from '../../services/business.service';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { UtilsService } from 'projects/common/src/public-api';
import { BusinessProfile } from '../../../../../common/src/lib/models/business-profile.model';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserProfile } from '../../../../../common/src/lib/models/user-profile.model';
import { ChatService } from '../../services/chat.service';
import { ViewAsService } from '../../services/view-as.service';

@Component({
  selector: 'app-select-business-button',
  templateUrl: './select-business-button.component.html',
  styleUrls: ['./select-business-button.component.scss']
})
export class SelectBusinessButtonComponent {

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  isMenuOpen = false;

  selectedBusiness$ = this.businessService.selectedBusiness$;
  largeScreen$ = this.utilsService.onScreenBreakpointChange('xl');

  buttonText$ = combineLatest([this.selectedBusiness$, this.largeScreen$]).pipe(
    map(([business, largeScreen]) => {
      if(!business)
        return null;
      return this.businessName(business, largeScreen);
    })
  );

  businesses$ = combineLatest([this.selectedBusiness$, this.businessService.businessesAndUsers$]).pipe(
    filter(([selectedBusiness, businessesAndUsers]) => !!selectedBusiness && !!businessesAndUsers),
    map(([selectedBusiness, businessesAndUsers]) => {
      if(!selectedBusiness)
        return businessesAndUsers!.data;
      const data = [...businessesAndUsers!.data];
      return data.sort((a, b) => {
        if(a.businessData.businessId === selectedBusiness.businessId || b.userData.status.includes('suspended'))
          return -1;
        if(b.businessData.businessId === selectedBusiness.businessId || a.userData.status.includes('suspended'))
          return 1;
        return 0;
      });
    })
  );

  unseenChatsCountByBusiness$  = this.chatService.unseenChatsCountByBusiness$;

  hasNotifications$ = combineLatest([this.businesses$, this.selectedBusiness$, this.unseenChatsCountByBusiness$]).pipe(
    map(([businesses, selectedBusiness, unseenChatsCounters]) => 
      businesses.some(business => business.businessData.businessId !== selectedBusiness!.businessId && unseenChatsCounters?.get(business.businessData.businessId))
    )
  );

  constructor(
    private businessService: BusinessService,
    private utilsService: UtilsService,
    private router: Router,
    private chatService: ChatService,
    private viewAsService: ViewAsService
  ) {}

  businessName(business: BusinessProfile, fullSize: boolean) {
    if(fullSize)
      return business.businessName;
    return business.businessName[0] + business.businessName[1];
  }

  onMenuClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  async onButtonClicked(business: { businessData: BusinessProfile, userData: UserProfile }) {
    // if(business.userData.status.includes('suspended'))
    //   return;
    const selectBusiness = await this.selectedBusiness$.pipe(take(1)).toPromise();
    if(selectBusiness!.businessId !== business.businessData.businessId) {
      this.viewAsService.clearSavedState();
      this.businessService.selectBusiness(business.businessData.businessId);
      this.menuTrigger.closeMenu();
    }
  }

  addBusiness() {
    this.router.navigate(['create']);
  }
}
