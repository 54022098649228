<div class="profile-field w-100">
  <div class="title-and-value" [class.edit]="editMode">

    <div class="title info-icon-wrapper mb">
      <p>{{ fieldTitle }}</p>
      <div *ngIf="tooltipMessage" class="icon" maskImageSrc="../../../../assets/icons/question-mark-tooltip.svg" [matTooltip]="tooltipMessage"
           [hideTooltipOnScroll]="tooltip" #tooltip="matTooltip" [matTooltipClass]="tooltipClass"
           [matTooltipPosition]="tooltipPosition" (click)="tooltip.show()"></div>
    </div>
    <div class="d-flex">
      <div class="edit-container" [class.large-field]="largeField" *ngIf="editMode else valueTemplate">
        <form [formGroup]="formGroup" class="w-100">
          <mat-form-field appFormFieldFilled appearance="outline" class="w-100">

            <input *ngIf="mask" #input matInput
                   [formControl]="valueControl"
                   [mask]="mask"
                   [validation]="false"
                   [attr.inputmode]="inputmode"
                   autocomplete="off"
                   autofocus
                   (focus)="focused = true"
                   (focusout)="focused = false"
            >
            <input *ngIf="!mask" #input matInput
                   [formControl]="valueControl"
                   [matAutocomplete]="autocomplete"
                   autocomplete="off"
                   autofocus
                   [attr.inputmode]="inputmode"
                   (focus)="focused = true"
                   (focusout)="focused = false"
                   #trigger="matAutocompleteTrigger"
                   [anchorAutocompleteOnScroll]="trigger"
            >
            <mat-autocomplete #autocomplete="matAutocomplete">
              <mat-option (placeOptionClick)="onPlacesOptionSelected($event)" [class.withSubtitle]="this.addressType === 'establishment'" *ngFor="let option of autocompleteOptions$ | async" [value]="option">
                {{ this.addressType === 'establishment' ? option.structured_formatting.main_text : option.description }}
                <p class="subtitle" *ngIf="this.addressType === 'establishment'">{{ option.structured_formatting.secondary_text }}</p>
              </mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="error" style="white-space: nowrap">
              {{ error }}
            </mat-error>
          </mat-form-field>
          <div class="buttons">
            <loader-button [adjustWidth]="false" [loading]="loading" text="Save"
                           (onClick)="save()"></loader-button>
            <div>
              <button class="w-100" mat-stroked-button (click)="cancel()">Cancel</button>
            </div>
          </div>
        </form>
      </div>
      <div class="notifications-switch" *ngIf="tooltipClass==='direct-phone-number__tooltip' && value && !editMode">
        <mat-slide-toggle
          [checked]="checkValue!"
          (change)="changeVisibility.emit($event.checked)"  color="primary"
        ></mat-slide-toggle>
        <small>{{(checkValue) ? 'Show' : 'Hide'}}</small>
      </div>
    </div>
    <mat-error *ngIf="initialError">{{ initialError }}</mat-error>

  </div>
  <div class="content" *ngIf="useContent else editButton">
    <ng-content></ng-content>
  </div>
  <ng-template #editButton>
    <p *ngIf="!disableEdit && !editMode" class="simple-text-button"
       (click)="setEditMode(true)">{{ !value ? 'Add' : 'Edit' }}</p>
  </ng-template>
</div>
<ng-template #valueTemplate>
  <p class="value" [class.no-edit]="disableEdit" *ngIf="value else notSet" (click)="setEditMode(true)">
    <span *ngIf="mask">{{ getValue() | mask: mask}}</span>
    <span *ngIf="!mask">{{ getValue() }}</span>
  </p>
  <ng-template #notSet>
    <div class="d-flex flex-column">
      <p class="notSet value" [class.no-edit]="disableEdit" (click)="setEditMode(true)">Not set</p>
    </div>
  </ng-template>
</ng-template>
