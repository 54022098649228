import { Client } from "projects/common/src/public-api";
import { Invoice, InvoiceStatus, InvoiceVersion } from "./invoice.model";
import { Proposal, ProposalStatus, ProposalVersion } from "./proposal.model";

export interface PoITileData {
  docType: 'Proposal' | 'Invoice';
  id: number;
  itemTime: Date | null;
  firstName: string;
  lastName: string;
  businessName: string | null;
  phoneNumber: string;
  status: ProposalStatus | InvoiceStatus | null;
  workflowId: number;
  salesTaxPercentage: number | null;
  opened?: boolean;
  jobType?: string;
  creatorId: number;
  creatorFirstName: string;
  creatorLastName: string;
};

export interface InvoiceRow {
  id: number;
  createdAt: Date;
  workflowId: number;
  createdBy: number;
  uid: number;
  canceledFromPayment: boolean;
  accepted: boolean;
  type: 'invoice';
  activeVersionId: number;
  activeStatus: InvoiceStatus;
  activeOpened: boolean;
  activeUserId: number;
  activeDate: Date;
  deletedAt: Date;
};

export interface ProposalRow {
  id: number;
  createdAt: Date;
  workflowId: number;
  createdBy: number;
  uid: number;
  canceledFromPayment: boolean;
  accepted: boolean;
  type: 'proposal';
  activeVersionId: number;
  activeStatus: ProposalStatus;
  activeOpened: boolean;
  activeUserId: number;
  activeDate: Date;
  activeAcceptedAt: Date;
  deletedAt: Date;
};

export interface InvoiceVersionClientRow extends Client {
  id: number;
  createdAt: Date;
  invoiceId: number;
  invoiceVersionId: number;
  activeClient: boolean;
};

export interface ProposalVersionClientRow extends Client {
  id: number;
  createdAt: Date;
  proposalId: number;
  proposalVersionId: number;
  activeClient: boolean;
};

export function tileDataFromDocument(document: Proposal | Invoice): PoITileData {
  const version = (document.versions as (ProposalVersion | InvoiceVersion)[]).find(version => version.id === document.activeVersionId)!;
  return {
      docType: document.docType,
      id: document.id,
      itemTime: version.clientSentAt ?? version.createdAt,
      firstName: version.clientFirstName,
      lastName: version.clientLastName,
      businessName: version.clientBusinessName,
      phoneNumber: version.clientPhoneNumber,
      status: version.status,
      workflowId: document.workflowId,
      salesTaxPercentage: version.clientSalesTaxPercentage,
      opened: version.opened,
      jobType: document.jobType,
      creatorId: version.creatorId,
      creatorFirstName: version.creatorFirstName,
      creatorLastName: version.creatorLastName,
  };
}