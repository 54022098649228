<div class="page main h-100 w-100 d-flex flex-column justify-content-start"
     style="overflow: hidden !important;"
     *ngIf="{ value: report$ | async } as report"
>

    <div class="content flex-fill overlay-scrollbar">
        <div class="container flex-fill d-flex flex-column h-100" fadeIn="500" [fadeInDelay]="200">

            <div class="dots-with-info-container">
                <div class="info-dot">
                    <div class="dot-orange"></div>
                    <p>Sales</p>
                </div>
                <div class="info-dot">
                    <div class="dot-green"></div>
                    <p>Net profit</p>
                </div>
                <div class="info-dot">
                    <div class="dot-blue"></div>
                    <p>Jobs</p>
                </div>
            </div>

            <ng-container *ngIf="{ value: datePickerLeftIsEmpty$ | async } as datePickerLeftIsEmpty">
                <ng-container *ngIf="{ value: datePickerRightIsEmpty$ | async } as datePickerRightIsEmpty">
                    <ng-container *ngIf="{ value: loading$ | async } as loading">
                        <div class="diagrams-container">
                            <div class="left-block">
                                <app-date-range class="date-range-picker"
                                                [selected]="(dateFilterLeft$ | async)"
                                                (change)="selectedRangeChange($event, 'left')"
                                >
                                </app-date-range>
                                <div class="diagrams" [class.without-date]="!datePickerLeftIsEmpty.value && report.value?.left">
                                    <p *ngIf="(datePickerLeftIsEmpty.value || !report.value?.left) && !loading.value" [class.highlight]="!datePickerRightIsEmpty.value && report.value?.right">
                                        Enter dates to compare
                                    </p>
                                    <mat-spinner class="m-auto" [diameter]="40"
                                                *ngIf="!datePickerLeftIsEmpty.value && loading.value"
                                    ></mat-spinner>
                                    <div class="diagrams-columns" *ngIf="!datePickerLeftIsEmpty.value && report.value?.left && !loading.value">
                                        <div class="diagram">
                                            <small class="count"
                                                [class.sales-highlight]="!report.value?.right"
                                                #tooltip="matTooltip"
                                                [matTooltip]="''"
                                                [shortNumber]="report.value!.left!.sales | currency"
                                                [shortNumberTooltip]="tooltip"
                                                [hideTooltipOnScroll]="tooltip"
                                            ></small>
                                            <ng-container *ngIf="!datePickerRightIsEmpty.value && report.value?.right">
                                                <div class="column sales"
                                                    *ngIf="calculateColumnHeight(report.value!.left!.sales, report.value!.right!.sales) let height"
                                                    [style.height]="height"
                                                    [class.border-radius-0]="height === '1px'"
                                                ></div>
                                            </ng-container>
                                            <small class="diagram-title">Sales</small>
                                            <ng-container *ngIf="!datePickerRightIsEmpty.value && report.value?.right">
                                                <small class="percent-difference"
                                                    *ngIf="{ value: calculatePercentageDifference(report.value!.left!.sales, report.value!.right!.sales) } as calc"
                                                    [class.negative-percentage]="!calc.value.isPositive"
                                                >
                                                    {{calc.value.percent}}
                                                </small>
                                            </ng-container>
                                        </div>

                                        <div class="diagram">
                                            <small class="count"
                                                [class.net-profit-highlight]="!report.value?.right"
                                                [class.negative]="report.value!.left!.np < 0"
                                                #tooltip2="matTooltip"
                                                [matTooltip]="''"
                                                [shortNumber]="report.value!.left!.np | currency"
                                                [shortNumberTooltip]="tooltip2"
                                                [hideTooltipOnScroll]="tooltip2"
                                            ></small>
                                            <ng-container *ngIf="!datePickerRightIsEmpty.value && report.value?.right">
                                                <div class="column net-profit"
                                                    *ngIf="calculateColumnHeight(report.value!.left!.np, report.value!.right!.np) let height"
                                                    [style.height]="height"
                                                    [class.negative]="report.value!.left!.np < 0"
                                                    [class.border-radius-0]="height === '1px'"
                                                ></div>
                                            </ng-container>
                                            <small class="diagram-title">Net profit</small>
                                            <ng-container *ngIf="!datePickerRightIsEmpty.value && report.value?.right">
                                                <small class="percent-difference"
                                                    *ngIf="{ value: calculatePercentageDifference(report.value!.left!.np, report.value!.right!.np) } as calc"
                                                    [class.negative-percentage]="!calc.value.isPositive"
                                                >
                                                    {{calc.value.percent}}
                                                </small>
                                            </ng-container>
                                        </div>

                                        <div class="diagram">
                                            <small class="count" [class.jobs-highlight]="!report.value?.right">{{report.value!.left!.jobs | number}}</small>
                                            <ng-container *ngIf="!datePickerRightIsEmpty.value && report.value?.right">
                                                <div class="column jobs"
                                                    *ngIf="calculateColumnHeight(report.value!.left!.jobs, report.value!.right!.jobs) let height"
                                                    [style.height]="height"
                                                    [class.border-radius-0]="height === '1px'"
                                                ></div>
                                            </ng-container>
                                            <small class="diagram-title">Jobs</small>
                                            <ng-container *ngIf="!datePickerRightIsEmpty.value && report.value?.right">
                                                <small class="percent-difference"
                                                    *ngIf="{ value: calculatePercentageDifference(report.value!.left!.jobs, report.value!.right!.jobs) } as calc"
                                                    [class.negative-percentage]="!calc.value.isPositive"
                                                >
                                                    {{calc.value.percent}}
                                                </small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="arrow horizontal">
                                <div class="left"></div>
                                <div class="center"></div>
                                <div class="right"></div>
                            </div>
                            <div class="arrow vertical">
                                <div class="left"></div>
                                <div class="center"></div>
                                <div class="right"></div>
                            </div>
                            <div class="right-block">
                                <app-date-range class="date-range-picker"
                                                [selected]="(dateFilterRight$ | async)"
                                                (change)="selectedRangeChange($event, 'right')"
                                >
                                </app-date-range>
                                <div class="diagrams" [class.without-date]="!datePickerRightIsEmpty.value && report.value?.right">
                                    <p *ngIf="(datePickerRightIsEmpty.value || !report.value?.right) && !loading.value" [class.highlight]="!datePickerLeftIsEmpty.value && report.value?.left">
                                        Enter dates to compare
                                    </p>
                                    <mat-spinner class="m-auto" [diameter]="40"
                                                *ngIf="!datePickerRightIsEmpty.value && loading.value"
                                    ></mat-spinner>
                                    <div class="diagrams-columns" *ngIf="!datePickerRightIsEmpty.value && report.value?.right && !loading.value">
                                        <div class="diagram">
                                            <small class="count"
                                                [class.sales-highlight]="!report.value?.left"
                                                #tooltip="matTooltip"
                                                [matTooltip]="''"
                                                [shortNumber]="report.value!.right!.sales | currency"
                                                [shortNumberTooltip]="tooltip"
                                                [hideTooltipOnScroll]="tooltip"
                                            ></small>
                                            <ng-container *ngIf="!datePickerLeftIsEmpty.value && report.value?.left">
                                                <div class="column sales"
                                                    *ngIf="calculateColumnHeight(report.value!.right!.sales, report.value!.left!.sales) let height"
                                                    [style.height]="height"
                                                    [class.border-radius-0]="height === '1px'"
                                                ></div>
                                            </ng-container>
                                            <small class="diagram-title">Sales</small>
                                            <ng-container *ngIf="!datePickerLeftIsEmpty.value && report.value?.left">
                                                <small class="percent-difference"
                                                    *ngIf="{ value: calculatePercentageDifference(report.value!.right!.sales, report.value!.left!.sales) } as calc"
                                                    [class.negative-percentage]="!calc.value.isPositive"
                                                >
                                                    {{calc.value.percent}}
                                                </small>
                                            </ng-container>
                                        </div>

                                        <div class="diagram">
                                            <small class="count"
                                                [class.net-profit-highlight]="!report.value?.left"
                                                [class.negative]="report.value!.right!.np < 0"
                                                #tooltip2="matTooltip"
                                                [matTooltip]="''"
                                                [shortNumber]="report.value!.right!.np | currency"
                                                [shortNumberTooltip]="tooltip2"
                                                [hideTooltipOnScroll]="tooltip2"
                                            ></small>
                                            <ng-container *ngIf="!datePickerLeftIsEmpty.value && report.value?.left">
                                                <div class="column net-profit"
                                                    *ngIf="calculateColumnHeight(report.value!.right!.np, report.value!.left!.np) let height"
                                                    [style.height]="height"
                                                    [class.negative]="report.value!.right!.np < 0"
                                                    [class.border-radius-0]="height === '1px'"
                                                ></div>
                                            </ng-container>
                                            <small class="diagram-title">Net profit</small>
                                            <ng-container *ngIf="!datePickerLeftIsEmpty.value && report.value?.left">
                                                <small class="percent-difference"
                                                    *ngIf="{ value: calculatePercentageDifference(report.value!.right!.np, report.value!.left!.np) } as calc"
                                                    [class.negative-percentage]="!calc.value.isPositive"
                                                >
                                                    {{calc.value.percent}}
                                                </small>
                                            </ng-container>
                                        </div>

                                        <div class="diagram">
                                            <small class="count" [class.jobs-highlight]="!report.value?.left">{{report.value!.right!.jobs | number}}</small>
                                            <ng-container *ngIf="!datePickerLeftIsEmpty.value && report.value?.left">
                                                <div class="column jobs"
                                                    *ngIf="calculateColumnHeight(report.value!.right!.jobs, report.value!.left!.jobs) let height"
                                                    [style.height]="height"
                                                    [class.border-radius-0]="height === '1px'"
                                                ></div>
                                            </ng-container>
                                            <small class="diagram-title">Jobs</small>
                                            <ng-container *ngIf="!datePickerLeftIsEmpty.value && report.value?.left">
                                                <small class="percent-difference"
                                                    *ngIf="{ value: calculatePercentageDifference(report.value!.right!.jobs, report.value!.left!.jobs) } as calc"
                                                    [class.negative-percentage]="!calc.value.isPositive"
                                                >
                                                    {{calc.value.percent}}
                                                </small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="only-mobile-sm show-before-sm bottom tabs primary-scrollbar">
        <app-tabs
                linePosition="top"
                [tabs]="pages"
                [activeTab]="(page$ | async)!"
                (onTabSelect)="onPageSelected($event)"
        ></app-tabs>
    </div>
</div>
