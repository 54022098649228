<div class="prepared-block" *ngIf="workflow && currentWorkflowVersion">
  <div class="prepared-by-for">
    PREPARED BY:
    <div class="prepared-by-for-data">
      <div *ngIf="currentWorkflowVersion.creatorBusinessName">{{ currentWorkflowVersion.creatorBusinessName }}</div>
      <div *ngIf="currentWorkflowVersion.creatorFirstName && currentWorkflowVersion.creatorLastName">{{ currentWorkflowVersion.creatorFirstName + ' ' + currentWorkflowVersion.creatorLastName }}</div>
      <div *ngIf="currentWorkflowVersion.creatorPhoneNumber">{{ currentWorkflowVersion.creatorPhoneNumber | mask: '(000) 000-0000' }}</div>
      <div *ngIf="business$ | async let business">{{ business.address | address }}</div>
      <div *ngIf="currentWorkflowVersion.creatorEmail">{{ currentWorkflowVersion.creatorEmail }}</div>
    </div>
  </div>
  <div class="prepared-by-for gap--3" *ngIf="preparedForData">
    <div class="d-flex justify-content-between">
      PREPARED FOR:
      <app-icon class="no-bg edit-button" src="pencil"
                (click)="openClientEdit()"
      ></app-icon>
    </div>
    <div class="prepared-by-for-data">
      <div *ngIf="preparedForData.type === 'business'">{{ preparedForData.businessName }}</div>
      <div *ngIf="preparedForData.name">{{ preparedForData.name }}</div>
      <div *ngIf="preparedForData.phoneNumber">{{ preparedForData.phoneNumber | mask: '(000) 000-0000' }}</div>
      <div *ngIf="preparedForData.address">{{ preparedForData.address | address }}</div>
      <div *ngIf="preparedForData.unit">{{ preparedForData.unit }}</div>
      <div *ngIf="preparedForData.email">{{ preparedForData.email }}</div>
    </div>
  </div>
</div>

<div class="prepared-block-mobile" *ngIf="workflow && currentWorkflowVersion">
  <div class="prepared-switcher-block">
    <div class="prepared-switcher"
         [class.active]="preparedSwitcherState === 'prepared-by'"
         (click)="preparedSwitcherState = 'prepared-by'"
    >
      PREPARED BY:
    </div>
    <div class="prepared-switcher-divider"></div>
    <div class="prepared-switcher"
         [class.active]="preparedSwitcherState === 'prepared-for'"
         (click)="preparedSwitcherState = 'prepared-for'"
    >
      PREPARED FOR:
    </div>
  </div>
  <div class="prepared-divider"></div>

  <div class="prepared-content">
    <div class="d-flex flex-column gap--4"
         *ngIf="preparedSwitcherState === 'prepared-by'"
    >
      <div *ngIf="business$ | async let business" [class.height--24]="!this.dataBlockOpened">{{ business.businessName }}</div>
      <div *ngIf="currentWorkflowVersion.creatorFirstName && currentWorkflowVersion.creatorLastName && this.dataBlockOpened">{{ currentWorkflowVersion.creatorFirstName + ' ' + currentWorkflowVersion.creatorLastName }}</div>
      <div *ngIf="currentWorkflowVersion.creatorPhoneNumber && this.dataBlockOpened">{{ currentWorkflowVersion.creatorPhoneNumber | mask: '(000) 000-0000' }}</div>
      <ng-container *ngIf="this.dataBlockOpened">
          <div *ngIf="business$ | async let business">{{ business.address | address }}</div>
      </ng-container>
      <div *ngIf="currentWorkflowVersion.creatorEmail && this.dataBlockOpened">{{ currentWorkflowVersion.creatorEmail }}</div>
    </div>
    <div class="d-flex flex-column gap--4"
         *ngIf="preparedSwitcherState === 'prepared-for'"
    >
      <div *ngIf="preparedForData.type === 'business'">{{ preparedForData.businessName }}</div>
      <div *ngIf="(preparedForData.name && preparedForData.type !== 'business') || this.dataBlockOpened">
        {{ preparedForData.name }}
      </div>
      <div *ngIf="preparedForData.phoneNumber && this.dataBlockOpened">
        {{ preparedForData.phoneNumber | mask: '(000) 000-0000' }}
      </div>
      <div *ngIf="preparedForData.address && this.dataBlockOpened">{{ preparedForData.address | address }}</div>
      <div *ngIf="preparedForData.unit && this.dataBlockOpened">{{ preparedForData.unit }}</div>
      <div *ngIf="preparedForData.email && this.dataBlockOpened">{{ preparedForData.email }}</div>
    </div>
    <app-icon class="no-bg edit-button" src="pencil" (click)="openClientEdit()"
              *ngIf="preparedSwitcherState === 'prepared-for'"
    ></app-icon>
  </div>

  <div class="prepared-arrow">
    <app-icon width="26" height="24" 
      class="no-bg"
      [class.rotate]="dataBlockOpened"
      src="chevron" 
      (click)="this.dataBlockOpened = !this.dataBlockOpened"
    ></app-icon>
  </div>
</div>
