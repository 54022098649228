<!-- TODO: User needs feedback in case there's no business -->
<div class="page bg" *ngIf="(state$ | async) let state">

  <app-toolbar type="signup"></app-toolbar>

  <div class="container d-flex flex-fill justify-content-center w-100">

      <mat-card class="mat-elevation-z3">

          <mat-card-title><h2 class="text-center second-title">{{ state === 'ready' ? "Let's create your account" : "Invitation not found" }}</h2></mat-card-title>

          <p *ngIf="state === 'not-found'" class="text-center" style="text-wrap: balance">Click the link in the invitation email to make sure you have the right invitation.<br>Contact the business owner if you still see this&nbsp;message</p>

          <form class="d-flex flex-column align-items-stretch" [formGroup]="accountForm" *ngIf="state === 'ready'">

              <h4>Email</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                  <input matInput #emailInput id="input_email"
                         formControlName="email"
                         type="email"
                         inputmode="email"
                         placeholder="Example@gmail.com"
                         autocomplete="email"
                         (focus)="emailFocused = true"
                         (focusout)="emailFocused = false"
                         enterkeyhint="next"
                         tabindex="3"
                  />
                  <mat-error *ngIf="accountForm.get('email')!.hasError('required') && accountForm.get('email')!.touched">
                      Email is required
                  </mat-error>
                  <mat-error *ngIf="accountForm.get('email')!.hasError('email') && accountForm.get('email')!.touched">
                      Invalid email. Re-enter
                  </mat-error>
                  <mat-error *ngIf="accountForm.get('email')!.hasError('emailInUse') && accountForm.get('email')!.touched">
                      Email already in use. <u class="emailSignIn" [routerLink]="'/login'">Sign in</u>
                  </mat-error>
              </mat-form-field>

              <h4>Create password</h4>
              <mat-form-field appearance="outline" appFormFieldFilled>
                  <input matInput #passwordInput id="input_password"
                         formControlName="password"
                         type="password"
                         placeholder="At least 6 characters"
                         autocomplete="new-password"
                         [type]="showPassword ? 'text' : 'password'"
                         enterkeyhint="go"
                         tabindex="4"
                  />
                  <img *ngIf="accountForm.get('password')!.value.length > 0" matSuffix
                       [src]="'../../../assets/icons/password-' + (showPassword ? 'hide' : 'show') +'.svg'"
                       (click)="showPassword = !showPassword">
                  <mat-error *ngIf="accountForm.get('password')!.hasError('required') && accountForm.get('password')!.touched">
                      Password is required
                  </mat-error>
                  <mat-error *ngIf="accountForm.get('password')!.hasError('minlength') && accountForm.get('password')!.touched">
                      at least 6 characters
                  </mat-error>
              </mat-form-field>

              <loader-button class="footer" [loading]="loading" text="Create" id="button_signup"
                             (onClick)="next()"></loader-button>

              <app-providers-row type="signup" redirectTo="join" (onStart)="saveInvitation()"></app-providers-row>
          </form>

      </mat-card>
  </div>
</div>
