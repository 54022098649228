import { Injectable, NgZone } from '@angular/core';
import { RealtimeChannel } from "@supabase/realtime-js";
import { combineLatest, Observable, of, timer } from "rxjs";
import { FormControl } from "@angular/forms";
import { catchError, debounce, finalize, map, pairwise, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { BusinessService } from 'projects/business-slick-estimates/src/app/services/business.service';
import { Chat, ChatContent, ChatList, ChatLogRecord, ChatMessageRecord, ChatRecord, ChatUser, ChatUserRecord, SendMessageResponse, SentMessage } from '../models/chat.model';
import { SupabaseService } from 'projects/business-slick-estimates/src/app/services/supabase.service';
import { UsersService } from 'projects/business-slick-estimates/src/app/services/users.service';
import { UtilsService } from 'projects/common/src/lib/services/utils.service';
import { frontToServerTranslation, serverToFrontTranslation } from 'projects/common/src/lib/services/supabase.service';

export const chatsLimit = 30;
export const messagesLimit = 30;
export const unseenIndicatorDebounceMs = 500;

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private readonly _supabase = this.supabaseService.supabase;
    private nextChannelSuffix = 0;
    private chatsIgnoreIds: number[] = [];
    private chatChannel: RealtimeChannel | null = null;
    private chatUserChannel: RealtimeChannel | null = null;

    messageInput: FormControl = new FormControl('');

    unseenChatsCountByBusiness$ = this.businessService.businessesAndUsers$.pipe(
        map(businessesAndUsers => businessesAndUsers?.data),
        tap(businesses => {
            if (businesses)
                return;
            this.chatList = {
                chats: null,
                canLoadMore: true
            };
            this.unsubscribe(this.chatChannel);
            this.unsubscribe(this.chatUserChannel);
        }),
        switchMap(businesses => {
            if (!businesses)
                return of(null);
            return combineLatest(
                businesses.map(business =>
                    business.userData.status.includes('suspended')
                        ? of(0)
                        : this.unseenChatsCountObservable({ businessId: business.businessData.businessId, userId: business.userData.id })
                    )
                ).pipe(
                    map(unseenChatsCounters => {
                        const map = new Map<string, number>();
                        for (let i = 0; i < unseenChatsCounters.length; i++)
                            map.set(businesses[i].businessData.businessId, unseenChatsCounters[i]);
                        return map;
                    })
                );
        }),
        shareReplay(1)
    );

    unseenChatsCount$ = combineLatest([this.unseenChatsCountByBusiness$, this.businessService.selectedBusiness$]).pipe(
        map(([unseenByBusiness, selectedBusiness]) => unseenByBusiness?.get(selectedBusiness!.businessId) ?? 0),
        shareReplay(1)
    );

    chatList: ChatList = {
        chats: null,
        canLoadMore: true
    };
    loadingChats = false;
    private currentPlayer: HTMLMediaElement | null = null;

    constructor(
        private businessService: BusinessService,
        private usersService: UsersService,
        private utilsService: UtilsService,
        private supabaseService: SupabaseService,
        private ngZone: NgZone
    ) {}

    setCurrentPlayer(player: HTMLMediaElement) {
        if(player !== this.currentPlayer) {
            if(this.currentPlayer !== null) {
                this.currentPlayer.pause();
            }
            this.currentPlayer = player;
        }
    }

    async businessId() {
        return (await this.businessService.selectedBusiness$.pipe(take(1)).toPromise())!.businessId;
    }

    async currentUserId() {
        return (await this.usersService.currentUser$.pipe(take(1)).toPromise()).id;
    }

    async getChat(id: number, useWorkflowId = false) {
        const res = await this._supabase.schema(await this.businessId()).rpc('get_chat', frontToServerTranslation({ inId: id, inUseWorkflowId: useWorkflowId }));
        if (res.error)
            throw res.error;
        const data = serverToFrontTranslation(res.data);
        return data?.length ? await this.updateChatUnseen(data[0]) as Chat : null;
    }

    async getChats(offset: number | null = null, limit?: number) {
        if (this.chatList.chats && (!this.chatList.canLoadMore || !offset || (offset && offset < this.chatList.chats.length)))
            return this.chatList.chats;

        let chats: Chat[] = [];
        this.ngZone.run(async () => {
            const inLimit = limit ?? chatsLimit;
            const res = await this._supabase
                .schema(await this.businessId())
                .rpc('get_chats', frontToServerTranslation({ inLimit, inOffset: offset }));
            if (res.error)
                throw res.error;
            chats = serverToFrontTranslation(res.data) as Chat[];
            if (chats.length < inLimit)
                this.chatList.canLoadMore = false;
            const promises: Promise<Chat>[] = [];
            for (const chat of chats)
                promises.push(this.updateChatUnseen(chat));
            chats = await Promise.all(promises);
            if (this.chatList.chats) {
                this.chatList.chats.push(...chats);
            } else {
                this.chatList.chats = chats;
                await this.initChatListSubscriptions();
            }
        });
        return chats;
    }

    async initChatListSubscriptions() {
        const insertFn = (chat: Chat) => {
            if (!this.chatList.chats || this.chatList.chats.find(c => c.id === chat.id) || this.chatList.canLoadMore && chat.lastMessageId < this.chatList.chats[this.chatList.chats.length - 1].lastMessageId)
                return;
            if (!this.chatList.chats.length || chat.lastMessageId < this.chatList.chats[this.chatList.chats.length - 1].lastMessageId) {
                this.chatList.chats.push(chat);
                return;
            }
            let index = 0;
            if (chat.lastMessageId < this.chatList.chats[0].lastMessageId)
                index = this.chatList.chats.findIndex((c, i, arr) => chat.lastMessageId < c.lastMessageId && chat.lastMessageId > arr[i + 1].lastMessageId) + 1;
            this.chatList.chats.splice(index, 0, chat);
        };

        const deleteFn = async (chat: Chat) => {
            if (!this.chatList.chats)
                return;
            const index = this.chatList.chats.findIndex(c => c.id === chat.id);
            if (index === -1)
                return;
            this.chatList.chats.splice(index, 1);
            if (this.chatList.chats.length < chatsLimit && this.chatList.canLoadMore && !this.loadingChats) {
                this.loadingChats = true;
                await this.getChats(this.chatList.chats.length, 1);
                this.loadingChats = false;
            }
        };

        this.chatChannel = await this.subscribeToChats(
            id => this.chatList.chats?.find(chat => chat.id === id) ?? null,
            insertFn,
            deleteFn,
            chat => this.chatList.chats?.unshift(this.chatList.chats.splice(this.chatList.chats.indexOf(chat), 1)[0])
        );

        this.chatUserChannel = await this.subscribeToChatUser(
            id => this.chatList.chats?.find(chat => chat.id === id) ?? null,
            chatUserId => this.chatList.chats?.find(chat => chat.users.find(chatUser => chatUser.id === chatUserId)) ?? null,
            insertFn,
            null,
            deleteFn
        );
    }

    chatObservable(id: number, useWorkflowId = false) {
        let chatChannel: RealtimeChannel | null = null;
        let workflowChannel: RealtimeChannel | null = null;
        return new Observable<Chat | null>(observer => {
            let chat: Chat | null = null;

            const getChatAndSetObserver = async () => {
                chat = await this.getChat(id, useWorkflowId);
                this.ngZone.run(() => observer.next(chat && chat.lastMessageId === null ? null : chat));
                return chat;
            };

            const subscribeToChanges = async (chatId: number) => {
                const businessId = await this.businessId();
                const currentUserId = await this.currentUserId();
                chatChannel = this._supabase
                    .channel(`chat_${chatId}_#${this.nextChannelSuffix++}`)
                    .on(
                        'postgres_changes',
                        { event: '*', schema: businessId, table: 'chat_user', filter: `chat_id=eq.${chatId}` },
                        async (payload) => {
                            const chatCopy = chat ? {...chat} as Chat : null;
                            if (chatCopy)
                                chatCopy.users = [...chatCopy.users];
                            if (payload.eventType === 'INSERT') {
                                const newData = serverToFrontTranslation(payload.new) as ChatUserRecord;
                                if (!chatCopy) {
                                    if (newData.userId === currentUserId)
                                        await getChatAndSetObserver();
                                    return;
                                }
                                const user = (await this.usersService.users$.pipe(take(1)).toPromise())?.find(user => user.id === newData.userId)!;
                                chatCopy.users.push({
                                    id: newData.id,
                                    userId: newData.userId,
                                    lastSeenId: newData.lastSeenId,
                                    mute: newData.mute,
                                    typingAt: null,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                });
                            } else if (payload.eventType === 'UPDATE') {
                                if (!chatCopy)
                                    return;
                                const newData = serverToFrontTranslation(payload.new) as ChatUserRecord;
                                const index = chatCopy.users.findIndex(user => user.userId === newData.userId)!;
                                const user = {...chatCopy.users[index]};
                                user.lastSeenId = newData.lastSeenId;
                                user.mute = newData.mute;
                                if (newData.userId !== currentUserId)
                                    user.typingAt = newData.typingAt;
                                chatCopy.users[index] = user;
                            } else { // DELETE
                                if (!chatCopy)
                                    return;
                                const user = chatCopy.users.find(user => user.id === payload.old.id);
                                if (!user)
                                    return;
                                if (user.userId === currentUserId) {
                                    chat = null;
                                    this.ngZone.run(() => observer.next(null));
                                    return;
                                }
                                chatCopy.users.splice(chatCopy.users.indexOf(user), 1);
                            }
                            if (chatCopy.lastMessageId)
                                this.ngZone.run(async () => observer.next(await this.updateChatUnseen(chatCopy)));
                            chat = chatCopy;
                        }
                    )
                    .on(
                        'postgres_changes',
                        { event: 'UPDATE', schema: await this.businessId(), table: 'chat', filter: `id=eq.${chatId}` },
                        async (payload) => {
                            const oldData = serverToFrontTranslation(payload.old) as ChatRecord;
                            const data = serverToFrontTranslation(payload.new) as ChatRecord;
                            if (oldData.deletedAt !== data.deletedAt) {
                                this.ngZone.run(async () => observer.next(!chat || data.deletedAt ? null : await this.updateChatUnseen(chat)));
                                return;
                            }
                            if (!chat)
                                return;
                            const chatCopy = {...chat} as Chat;
                            chatCopy.subject = data.subject;
                            if (data.lastMessageId && chatCopy.lastMessageId !== data.lastMessageId) {
                                chatCopy.lastMessageId = data.lastMessageId;
                                const res = await this._supabase
                                    .schema(await this.businessId())
                                    .from('chat_message')
                                    .select('sender_id, created_at')
                                    .eq('id', data.lastMessageId)
                                    .single();
                                if (res.error)
                                    throw res.error;
                                const messageData = serverToFrontTranslation(res.data);
                                chatCopy.lastMessageAt = messageData.createdAt;
                                if (messageData.senderId === currentUserId) {
                                    const user = chatCopy.users.find(user => user.userId === currentUserId)!;
                                    user.lastSeenId = data.lastMessageId;
                                }
                            }
                            this.ngZone.run(async () => observer.next(await this.updateChatUnseen(chatCopy)));
                            chat = chatCopy;
                        }
                    )
                    .subscribe();
            }

            getChatAndSetObserver()
                .then(async (chat) => {
                    if (!chat) {
                        if (useWorkflowId) {
                            workflowChannel = this._supabase
                                .channel(`chat_workflow_${id}_#${this.nextChannelSuffix++}`)
                                .on(
                                    'postgres_changes',
                                    { event: 'INSERT', schema: await this.businessId(), table: 'chat', filter: `workflow_id=eq.${id}` },
                                    () => getChatAndSetObserver().then(chat => chat ? subscribeToChanges(chat.id) : null)
                                )
                                .subscribe();
                        }
                        return;
                    }
                    await subscribeToChanges(chat.id);
                });
        })
        .pipe(
            finalize(() => {
                this.unsubscribe(chatChannel);
                this.unsubscribe(workflowChannel);
            }),
            shareReplay(1),
        );
    }

    private unseenChatsCountObservable(businessUser?: { businessId: string, userId: number }) {
        let channel: RealtimeChannel | null = null;
        const unseenChatsInfo: Map<number, {
            lastMessageId: number | null,
            lastSeenId: number | null,
            chatUserId: number,
            mute: boolean
        }> = new Map();
        return new Observable<number>(observer => {
            const setObserver = () => this.ngZone.run(() => observer.next(
                Array.from(unseenChatsInfo.values()).filter(value => value.lastMessageId && value.lastMessageId !== value.lastSeenId && !value.mute).length
            ));
            const getUnseenInfosAndSetObserver = async () => {
                const businessId = businessUser?.businessId ?? await this.businessId();
                const res = await this._supabase.schema(businessId).rpc('get_unseen_chats_info');
                if (res.error) {
                    console.error(res.error);
                    observer.error(res.error);
                    return;
                }
                for (const chatInfo of serverToFrontTranslation(res.data))
                    unseenChatsInfo.set(chatInfo.id, {
                        lastMessageId: chatInfo.lastMessageId,
                        lastSeenId: chatInfo.lastSeenId,
                        chatUserId: chatInfo.chatUserId,
                        mute: chatInfo.mute
                    });
                setObserver();
            };

            const subscribeToChanges = async () => {
                const businessId = businessUser?.businessId ?? await this.businessId();
                const currentUserId = businessUser?.userId ?? await this.currentUserId();
                channel = this._supabase
                    .channel(`chat_unseen_count_${currentUserId}_#${this.nextChannelSuffix++}`)
                    .on(
                        'postgres_changes',
                        { event: 'UPDATE', schema: businessId, table: 'chat' },
                        async (payload) => {
                            const data = serverToFrontTranslation(payload.new) as ChatRecord;
                            const oldData = serverToFrontTranslation(payload.old) as ChatRecord;
                            if (data.deletedAt && !oldData.deletedAt) {
                                unseenChatsInfo.delete(data.id);
                                setObserver();
                                return;
                            }
                            if (data.lastMessageId !== oldData.lastMessageId || !data.deletedAt && oldData.deletedAt) {
                                if (!data.lastMessageId || this.chatsIgnoreIds.includes(data.id))
                                    return;
                                const chatInfo = unseenChatsInfo.get(data.id);
                                if (chatInfo) {
                                    chatInfo.lastMessageId = data.lastMessageId;
                                    setObserver();
                                    return;
                                }
                                const res = await this._supabase
                                    .schema(businessId)
                                    .from('chat_user')
                                    .select('id, last_seen_id, mute')
                                    .eq('chat_id', data.id)
                                    .eq('user_id', currentUserId);
                                if (res.error) {
                                    console.error(res.error);
                                    observer.error(res.error);
                                    return;
                                }
                                if (!res.data?.length) {
                                    this.chatsIgnoreIds.push(data.id);
                                    return;
                                }
                                const chatUserData = serverToFrontTranslation(res.data)[0];
                                unseenChatsInfo.set(data.id, {
                                    lastMessageId: data.lastMessageId,
                                    lastSeenId: chatUserData.lastSeenId,
                                    chatUserId: chatUserData.id,
                                    mute: chatUserData.mute
                                });
                                setObserver();
                            }
                        }
                    )
                    .on(
                        'postgres_changes',
                        { event: '*', schema: businessId, table: 'chat_user', filter: `user_id=eq.${currentUserId}` },
                        async (payload) => {
                            if (payload.eventType === 'DELETE')
                                return;

                            const data = serverToFrontTranslation(payload.new) as ChatUserRecord;
                            if (payload.eventType === 'INSERT') {
                                if (data.userId === currentUserId) {
                                    unseenChatsInfo.set(data.chatId, {
                                        lastMessageId: data.lastSeenId,
                                        lastSeenId: data.lastSeenId,
                                        chatUserId: data.id,
                                        mute: data.mute
                                    });
                                    const index = this.chatsIgnoreIds.findIndex(id => id === data.chatId);
                                    if (index !== -1)
                                        this.chatsIgnoreIds.splice(index, 1);
                                    return;
                                }
                            } else { // UPDATE
                                const oldData = serverToFrontTranslation(payload.old) as ChatUserRecord;
                                if (data.lastSeenId === oldData.lastSeenId && data.mute === oldData.mute)
                                    return;
                                const chatInfo = unseenChatsInfo.get(data.chatId)!;
                                if (chatInfo) {
                                    chatInfo.lastSeenId = data.lastSeenId;
                                    chatInfo.mute = data.mute;
                                } else {
                                    if (data.lastSeenId !== oldData.lastSeenId) // own message
                                        unseenChatsInfo.set(data.chatId, {
                                            lastMessageId: data.lastSeenId,
                                            lastSeenId: data.lastSeenId,
                                            chatUserId: data.id,
                                            mute: data.mute
                                        });
                                    return;
                                }
                            }
                            setObserver();
                        }
                    )
                    .on(
                        'postgres_changes',
                        { event: 'DELETE', schema: businessId, table: 'chat_user' },
                        async (payload) => {
                            const chatId = Array.from(unseenChatsInfo.entries()).find(entry => entry[1].chatUserId === payload.old.id)?.[0];
                            if (!chatId)
                                return;
                            unseenChatsInfo.delete(chatId);
                            this.chatsIgnoreIds.push(chatId);
                            setObserver();
                        }
                    )
                    .subscribe();
            }

            getUnseenInfosAndSetObserver().then(() => subscribeToChanges());
        })
        .pipe(
            startWith(0),
            pairwise(),
            debounce(([prev, cur]) => timer(prev < cur ? unseenIndicatorDebounceMs : 0)),
            map(([_, cur]) => cur),
            finalize(() => this.unsubscribe(channel)),
            shareReplay({ bufferSize: 1, refCount: true }),
            catchError(_ => of(0))
        );
    }

    async subscribeToChats(
        getChat: (id: number) => Chat | null,
        onInsert: (chat: Chat) => void,
        onDelete: (chat: Chat) => void,
        onNewMessage: (chat: Chat, senderId: number) => void
    ) {
        const businessId = await this.businessId();
        return new Promise<RealtimeChannel>(resolve => {
            const channel = this._supabase
            .channel(`chat_#${this.nextChannelSuffix++}`)
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: businessId, table: 'chat' },
                async (payload) => {
                    const data = serverToFrontTranslation(payload.new) as ChatRecord;
                    if (this.chatsIgnoreIds.includes(data.id))
                        return;
                    let chat = getChat(data.id);
                    if (!chat) {
                        if (data.deletedAt)
                            return;
                        chat = await this.getChat(data.id);
                        if (!chat)
                            return;
                        const currentUserId = await this.currentUserId();
                        if (chat.users.find(u => u.userId === currentUserId))
                            onInsert(chat);
                        else
                            this.chatsIgnoreIds.push(chat.id);
                        return;
                    } else if (data.deletedAt) {
                        onDelete(chat);
                        return;
                    }
                    chat.subject = data.subject;
                    if (data.lastMessageId && chat.lastMessageId !== data.lastMessageId) {
                        chat.lastMessageId = data.lastMessageId;
                        await this.updateChatUnseen(chat, true);
                        const res = await this._supabase
                            .schema(await this.businessId())
                            .from('chat_message')
                            .select('created_at, sender_id')
                            .eq('id', data.lastMessageId)
                            .single();
                        if (res.error)
                            throw res.error;
                        const messageData = serverToFrontTranslation(res.data);
                        chat.lastMessageAt = messageData.createdAt;
                        onNewMessage(chat, messageData.senderId); //show new messages indicator, move to top
                    }
                }
            )
            .subscribe(_ => resolve(channel));
        });
    }

    async subscribeToChatUser(
        getChat: (id: number) => Chat | null,
        getChatByChatUserId: ((id: number) => Chat | null) | null,
        onInsert: ((chat: Chat) => void) | null,
        onUpdate: ((chat: Chat) => void) | null,
        onDelete: ((chat: Chat) => void)
    ) {
        const businessId = await this.businessId();
        return new Promise<RealtimeChannel>(resolve => {
            const channel = this._supabase
            .channel(`chat_user_#${this.nextChannelSuffix++}`)
            .on(
                'postgres_changes',
                { event: '*', schema: businessId, table: 'chat_user' },
                async (payload) => {
                    const currentUserId = await this.currentUserId();
                    if (payload.eventType !== 'DELETE') {
                        const data = serverToFrontTranslation(payload.new) as ChatUserRecord;
                        if (payload.eventType === 'INSERT') {
                            if (data.userId === currentUserId) {
                                const chat = await this.getChat(data.chatId);
                                if (chat) {
                                    if (this.chatsIgnoreIds.includes(chat.id))
                                        this.chatsIgnoreIds.splice(this.chatsIgnoreIds.findIndex(id => id === chat.id), 1);
                                    onInsert?.(chat);
                                }
                            } else {
                                const chat = getChat(data.chatId);
                                if (!chat)
                                    return;
                                const businessUser =  (await this.usersService.users$.pipe(take(1)).toPromise())?.find(u => u.id === data.userId);
                                const user: ChatUser = {...data, firstName: businessUser?.firstName ?? '', lastName: businessUser?.lastName ?? ''};
                                chat.users.push(user);
                                onUpdate?.(chat);
                            }
                            return;
                        }
                        // UPDATE
                        const chat = getChat(data.chatId);
                        if (!chat)
                            return;

                        if (data.userId !== currentUserId) {
                            const user = chat.users.find(u => u.userId === data.userId)!;
                            if (data.typingAt !== user.typingAt)
                                user.typingAt = data.typingAt;
                        } else {
                            const user = chat.users.find(u => u.userId === data.userId)!;
                            user.lastSeenId = data.lastSeenId;
                            chat.hasUnseenMessages = chat.lastMessageId !== data.lastSeenId;
                        }
                        onUpdate?.(chat);
                    } else { // DELETE
                        if (!getChatByChatUserId)
                            return;
                        const chatUserId = payload.old.id;
                        const chat = getChatByChatUserId(chatUserId);
                        if (chat) {
                            const index = chat.users.findIndex(user => user.id === chatUserId);
                            if (index > -1) {
                                if (chat.users[index].userId === currentUserId) {
                                    onDelete(chat);
                                } else {
                                    chat.users.splice(index, 1);
                                    onUpdate?.(chat);
                                }
                            }
                        }
                    }
                }
            )
            .subscribe(_ => resolve(channel));
        });
    }

    async subscribeToMessages(chatId: number, onInsert: (message: ChatMessageRecord) => void) {
        const businessId = await this.businessId();
        return new Promise<RealtimeChannel>(resolve => {
            const channel = this._supabase
            .channel(`chat_message_${chatId}_#${this.nextChannelSuffix++}`)
            .on(
                'postgres_changes',
                {event: 'INSERT', schema: businessId, table: 'chat_message', filter: `chat_id=eq.${chatId}`},
                (payload) => onInsert(serverToFrontTranslation(payload.new))
            )
            .subscribe(_ => resolve(channel));
        });
    }

    async subscribeToLogs(chatId: number, onInsert: (log: ChatLogRecord) => void) {
        const businessId = await this.businessId();
        return new Promise<RealtimeChannel>(resolve => {
            const channel = this._supabase
            .channel(`chat_log_${chatId}_#${this.nextChannelSuffix++}`)
            .on(
                'postgres_changes',
                {event: 'INSERT', schema: businessId, table: 'chat_log', filter: `chat_id=eq.${chatId}`},
                (payload) => onInsert(serverToFrontTranslation(payload.new))
            )
            .subscribe(_ => resolve(channel));
        });
    }
    
    async updateChatUnseen(chat: Chat, useDebounce = false) {
        const currentUserId = await this.currentUserId();
        const user = chat.users.find((user) => user.userId === currentUserId);
        if (user) {
            const hasUnseen = () => (chat.lastMessageId ?? 0) > (user.lastSeenId ?? 0);
            if (useDebounce && hasUnseen())
                setTimeout(() => chat.hasUnseenMessages = hasUnseen(), unseenIndicatorDebounceMs);
            else
                chat.hasUnseenMessages = hasUnseen();
        }
        return {...chat};
    }
    
    // Messages and logs
    async getChatContent(chatId: number, firstMessageId: number | null = null) {
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('get_chat_content', frontToServerTranslation({ inChatId: chatId, inLimit: messagesLimit, inFirstMessageId: firstMessageId }));
        if (res.error)
            throw res.error;
        return serverToFrontTranslation(res.data) as ChatContent;
    }

    async setLastMessageSeen(id: number, chatId: number) {
        const res = await this._supabase
            .schema(await this.businessId())
            .from('chat_user')
            .update(frontToServerTranslation({ lastSeenId: id }))
            .eq('chat_id', chatId)
            .eq('user_id', await this.currentUserId());
        if (res.error)
            throw res.error;
    }

    async setMute(chatId: number, mute: boolean) {
        const res = await this._supabase
            .schema(await this.businessId())
            .from('chat_user')
            .update(frontToServerTranslation({ mute }))
            .eq('chat_id', chatId)
            .eq('user_id', await this.currentUserId());
        if (res.error)
            throw res.error;
    }

    async setUserTyping(chatId: number, isTyping: boolean) {
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('set_chat_user_typing', frontToServerTranslation({ inChatId: chatId, inTyping: isTyping }));
        if (res.error)
            throw res.error;
    }

    async markAllChatsSeen() {
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('mark_all_chats_seen');
        if (res.error)
            throw res.error;
    }

    async setSubject(chatId: number, subject: string) {
        const res = await this._supabase
            .schema(await this.businessId())
            .from('chat')
            .update(frontToServerTranslation({ subject }))
            .eq('id', chatId);
        if (res.error)
            throw res.error;
    }

    async addUserToChat(chatId: number, userId: number) {
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('add_user_to_chat', frontToServerTranslation({ inChatId: chatId, inUserId: userId }));
        if (res.error)
            throw res.error;
    }

    async removeUserFromChat(chatId: number, userId: number) {
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('remove_user_from_chat', frontToServerTranslation({ inChatId: chatId, inUserId: userId }));
        if (res.error)
            throw res.error;
    }

    async addMetaDataToPath(file: File, path: string) {
        const type = file.type.split('/')[0];
        if (!['image', 'video', 'audio'].includes(type))
            return path;
        let pathAndType = `${path};${type}`;
        if (type === 'audio') {
            const duration = await this.utilsService.getAudioDurationMs(file);
            return `${pathAndType};${duration}`;
        }
        let dimensions;
        try {
            dimensions = await (type === 'image' ? this.utilsService.getImageDimensions(file) : this.utilsService.getVideoDimensions(file));
        } catch {
            return pathAndType;
        }
        return `${pathAndType};${dimensions.width}x${dimensions.height}`;
    }
    
    async sendMessage(subject: string, message: SentMessage, content: string | null, attachment: string | null, userIds: number[] | null, workflowId: number): Promise<number>;
    async sendMessage(subject: string, message: SentMessage, content: string | null, attachment: string | null, userIds: number[], workflowId?: null): Promise<number>;
    async sendMessage(chatId: number, message: SentMessage, content: string | null, attachment?: string | null, userIds?: number[] | null, workflowId?: null): Promise<void>;
    async sendMessage(chatIdOrSubject: string | number, message: SentMessage, content: string | null, attachment: string | null, userIds?: number[] | null, workflowId?: number | null) {
        const data: any = {
            inContent: content,
            inAttachment: attachment ?? null,
            inUserIds: userIds ?? null,
        };
        if (workflowId)
            data.inWorkflowId = workflowId;
        data[typeof chatIdOrSubject === 'string' ? 'inSubject' : 'inChatId'] = chatIdOrSubject;
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('send_message', frontToServerTranslation(data));
        if (res.error)
            throw res.error;

        const messageData = serverToFrontTranslation(res.data)[0] as SendMessageResponse;

        message.id = messageData.id;
        message.createdAt = messageData.createdAt;

        return typeof chatIdOrSubject === 'string' ? messageData.chatId : undefined as void;
    }

    async sendAttachmentMessage(subject: string, message: SentMessage, content: string | null, file: File, userIds: number[] | null, workflowId: number): Promise<number>;
    async sendAttachmentMessage(subject: string, message: SentMessage, content: string | null, file: File, userIds: number[]): Promise<number>;
    async sendAttachmentMessage(chatId: number, message: SentMessage, content: string | null, file: File,userIds?: number[] | null): Promise<void>;
    async sendAttachmentMessage(chatIdOrSubject: string | number, message: SentMessage, content: string, file: File, userIds: number[] | null, workflowId?: number | null) {
        if(!file.type.startsWith('image/') && !file.type.startsWith('audio/'))
            throw new Error('Only audio and image files are allowed');

        const splitName = file.name.split('.');
        const fileName = this.utilsService.randomString(16) + '.' + splitName[splitName.length - 1];
        const chatId = typeof chatIdOrSubject === 'string' 
            ? await (workflowId ? this.createChat(chatIdOrSubject, userIds, workflowId) : this.createChat(chatIdOrSubject, userIds!))
            : chatIdOrSubject;
        const path = `${await this.businessId()}/${chatId}/${fileName}`;
        const pathWithData = await this.addMetaDataToPath(file, path);

        message.attachment = pathWithData;

        this.supabaseService.uploadFile('chat', path, file)
            .then(_ => this.sendMessage(chatId, message, content, pathWithData, userIds));

        return typeof chatIdOrSubject === 'string' ? chatId : undefined as void;
    }

    async createChat(subject: string, userIds: number[]): Promise<number>;
    async createChat(subject: string, userIds: number[] | null, workflowId: number): Promise<number>;
    async createChat(subject: string, userIds: number[] | null, workflowId?: number) {
        const data: any = {
            inSubject: subject,
            inUserIds: userIds
        };
        if (workflowId)
            data.inWorkflowId = workflowId;
        const res = await this._supabase
            .schema(await this.businessId())
            .rpc('create_chat', frontToServerTranslation(data));
        if (res.error)
            throw res.error;

        return (serverToFrontTranslation(res.data) as number);
    }

    unsubscribe(channel: RealtimeChannel | null) {
        return channel ? this._supabase.removeChannel(channel) : null;
    }

}
