
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BusinessService } from '../../services/business.service';
import { take } from "rxjs/operators";
import { ModalsService } from "projects/common/src/lib/services/modals.service";

@Component({
  selector: 'app-providers-row',
  templateUrl: './providers-row.component.html',
  styleUrls: ['./providers-row.component.scss']
})
export class ProvidersRowComponent implements OnInit {

  error?: string;

  @Input() type: 'signup' | 'login' | 'link' | 'reauthenticate' = 'login';
  @Input() redirectTo?: string;

  @Output() onStart = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  @Output() onFailed = new EventEmitter();

  get title() {
    return {
      signup: 'Sign Up',
      login: 'Sign in',
      link: 'Sign Up',
      reauthenticate: 'Sign in',
    }[this.type] + ' with Google';
  }

  businessId!: string;

  signingWithGoogle = false;

  constructor(
    private authService: AuthService,
    private modalsService: ModalsService,
  ) { }

  ngOnInit(): void {
    if (this.authService.unlinkedDoubleProviderAccount) {
      this.authService.unlinkedDoubleProviderAccount = false;
      this.error = "The Google account is incorrect. If you signed up with your email, use your email and password to log in"
    }
  }

  async googleSignIn() {
    this.onStart.emit();
    await this.authService.saveSession();
    try {
      this.error = undefined;
      if (this.type === 'link') {
        await this.authService.changeAccountViaGoogle();
      } else if (this.type === 'reauthenticate') {
        await this.authService.reauthenticateGoogle();
      } else if (this.type === 'login') {
        await this.authService.signInWithGoogle(this.redirectTo);
      } else {
        await this.authService.signUpWithGoogle(this.redirectTo);
      }
      this.onSuccess.emit();
    } catch (error: any) {
      console.log(`providers-row.component.ts > ProvidersRowComponent > googleSignIn()`, error);
      if (error.code === 'auth/account-exists-with-different-credential' || error.code === 'auth/credential-already-in-use') {
        this.error = this.type === 'link' ? 'Account already in use' : 'Invalid entry. Try a different provider';
      } else if (error === 'reject') {
        this.error = 'Account mismatch. Try another one';
      }
      await this.authService.restoreSession();
      this.onFailed.emit(error);
    }
  }

}
