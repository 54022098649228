import { inject, Injectable } from '@angular/core';
import { BusinessService } from './business.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { BusinessProfile } from '../../../../common/src/lib/models/business-profile.model';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UsersService } from './users.service';
import { SupabaseService } from "./supabase.service";
import { Assigned } from "../models/assigned.model";

export const canOpenAssignedPage = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const assignedService = inject(AssignedService);
  const router = inject(Router);
  return assignedService.items$.pipe(
    map(items => items.length > 0 ? true : router.parseUrl('/lobby')),
    take(1)
  ).toPromise();
}

@Injectable({
  providedIn: 'root'
})
export class AssignedService {

  items$ = combineLatest([
    this.businessService.selectedBusiness$.pipe(filter(b => !!b)) as Observable<BusinessProfile>,
    this.usersService.currentUser$
  ]).pipe(
    switchMap(([business, currentUser]) => {
      return this.supabaseService.rpc<Assigned[]>({
        cName: 'assigned',
        schema: business.businessId,
        fn: 'get_assigned_items',
        tables: ['workflow', 'user', 'estimate', 'estimate_client', 'estimate_range', 'job', 'job_client', 'job_range', 'job_user'],
        options: { inUserId: currentUser.id }
      },
      (changes, items) => {
        if (!(changes.table === 'workflow' && changes.eventType === 'UPDATE'))
          return null;
        const data = changes.new as any;
        const item = items.find(item => data[`${item.type}Id`] === item.id);
        if (item)
          item.jobType = data.jobType;
        return items;
      });
    }),
    shareReplay(1),
  );

  constructor(
    private businessService: BusinessService,
    private usersService: UsersService,
    private supabaseService: SupabaseService,
  ) { }

}
