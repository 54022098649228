import { Component, ViewChild } from '@angular/core';
import { AssignedService } from '../../services/assigned.service';
import { Assigned } from "../../models/assigned.model";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { DynamicListComponent, PaginationListItem } from "../../components/dynamic-list/dynamic-list.component";
import { AssignTileComponent } from "../../components/assign-tile/assign-tile.component";
import { dateString } from "../../../../../common/src/lib/services";
import { Router } from "@angular/router";


function assignedItemsToPaginationList(items: Assigned[]) {
  const result: PaginationListItem<AssignTileComponent<'Assigned'>>[] = [];
  for(const item of items) {
    if(item.type === 'estimate')
      result.push({
        componentClass: AssignTileComponent,
        header: dateString(item.outStartTime),
        args: { item, tileType: 'Assigned' }
      });
    else
      for(const user of item.assignedToUsers!) {
        result.push({
          componentClass: AssignTileComponent,
          header: dateString(item.outStartTime),
          args: {
            item: {
              ...item,
              assignedToFirstName: user.firstName,
              assignedToLastName: user.lastName,
              assignedToId: user.userId,
            },
            tileType: 'Assigned'
          }
        });
      }
  }
  return result;
}
@Component({
  selector: 'app-assigned',
  templateUrl: './assigned.component.html',
  styleUrls: ['./assigned.component.scss']
})
export class AssignedComponent {

  @ViewChild('list') dynamicList!: DynamicListComponent<AssignTileComponent<'Assigned'>>;

  listItems$ = this.assignedService.items$.pipe(
      map((items, index) => {
        if(index === 0)
          this.loading$.next(false);
        if(items.length === 0)
          this.router.navigate(['/lobby']);
        return assignedItemsToPaginationList(items);
      })
  );
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private assignedService: AssignedService,
    private router: Router,
  ) { }

}
