import { Component, Input } from '@angular/core';
import { UtilsService } from "../../../../../common/src/lib/services";

export interface BaseTileData {
  title?: string,
  subtitle?: string,
  description?: string,
}

@Component({
  selector: 'app-base-tile',
  templateUrl: './base-tile.component.html',
  styleUrls: ['./base-tile.component.scss']
})
export class BaseTileComponent {

  @Input() data!: BaseTileData;
  @Input() past!: boolean;
  @Input() hideOnSmallScreens = true;

  largeScreenSize$ = this.utilsService.onScreenBreakpointChange('md');

  constructor(
      private utilsService: UtilsService
  ) {}

}
