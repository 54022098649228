import { Component, Input } from '@angular/core';
import { currencify, round } from "../../helpers/common";
import { UtilsService } from "../../../../../common/src/lib/services";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['./sales-summary.component.scss'],
})
export class SalesSummaryComponent {

  protected readonly currencify = currencify;
  protected readonly round = round;

  @Input() selectedTab!: string;
  @Input() data!: any;

  loading$ = this.reportsService.loading$;

  infoToFill = {
    paymentsAll: [
      {
        id: 'p.all.paid',
        text: 'Paid',
        onlyExpand: false,
        property: 'paid'
      },
      {
        id: 'p.all.pending',
        text: 'Pending',
        onlyExpand: true,
        property: 'pending'
      },
      {
        id: 'p.all.total',
        text: 'Total Sales',
        onlyExpand: true,
        property: 'totalSales'
      }
    ],
    paymentsPaid: [
      {
        id: 'p.paid.paid',
        text: 'Total paid',
        onlyExpand: false,
        property: 'paid'
      },
      {
        id: 'p.paid.avg_s_d',
        text: 'Avg. sale / day',
        onlyExpand: true,
        property: 'avgSalePerDay'
      },
      {
        id: 'p.paid.avg_s_j',
        text: 'Avg. sale / jobs',
        onlyExpand: true,
        property: 'avgSalePerJob'
      },
      {
        id: 'p.paid.avg_j_d',
        text: 'Avg. jobs / day',
        onlyExpand: true,
        property: 'avgJobsPerDay'
      }
    ],
    paymentsPending: [
      {
        id: 'p.pending.pending',
        text: 'Pending',
        onlyExpand: false,
        property: 'pending'
      },
      {
        id: 'p.pending.nc',
        text: 'Jobs',
        onlyExpand: true,
        property: 'jobs'
      }
    ],
    commissionsPending: [
      {
        id: 'c.pending.pending',
        text: 'Commissions',
        onlyExpand: false,
        property: 'pending'
      },
      {
        id: 'c.pending.jobs',
        text: 'Jobs',
        onlyExpand: true,
        property: 'jobs'
      }
    ],
    commissionsPaid: [
      {
        id: 'c.paid.paid',
        text: 'Commissions',
        onlyExpand: false,
        property: 'paid'
      },
      {
        id: 'c.paid.jobs',
        text: 'Jobs',
        onlyExpand: true,
        property: 'jobs'
      }
    ],
    techPartsPending: [
      {
        id: 't.pending.total',
        text: 'Total Sales',
        onlyExpand: false,
        property: 'totalSales'
      },
      {
        id: 't.pending.pending',
        text: 'Pending Tech Parts',
        onlyExpand: true,
        property: 'pending'
      }
    ],
    techPartsPaid: [
      {
        id: 't.paid.total',
        text: 'Total Sales',
        onlyExpand: false,
        property: 'totalSales'
      },
      {
        id: 't.paid.paid',
        text: 'Tech parts paid',
        onlyExpand: true,
        property: 'paid'
      }
    ]
  }

  showAll = false;

  constructor(
    private utilService: UtilsService,
    private reportsService: ReportsService,
  ) { }

  get isMobile() {
    return this.utilService.isMobile();
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  redAmount(fieldId: string, data: any): boolean {
    return (
      (fieldId === 'p.all.paid' || fieldId === 'p.paid.paid')
      && data.needsRefund
    ) || (
      fieldId === 'c.paid.paid' && data.paid > data.total
    );
  }
}
