import { Component } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { DateFilter } from '../../models/date-filter.model';
import { currencify, formatMinutes, round } from "../../helpers/common";
import { pageNameToPath, ReportsPages, reportsPages } from "../../models/navigation.model";
import { NavigationService } from "../../services/navigation.service";
import { Router } from "@angular/router";
import { UtilsService } from "../../../../../common/src/lib/services";
import { Observable } from "rxjs";
import { OverlookReport } from "../../models/reports.model";

@Component({
  selector: 'app-reports-overlook',
  templateUrl: './reports-overlook.component.html',
  styleUrls: ['./reports-overlook.component.scss']
})
export class ReportsOverlookComponent {

  protected readonly round = round;
  protected readonly currencify = currencify;
  protected readonly formatMinutes = formatMinutes;

  report$: Observable<OverlookReport> = this.reportsService.report$ as Observable<OverlookReport>

  dateFilter$ = this.reportsService.selectedDateRangeForEvent$;
  pages = Array.from(reportsPages);
  page$ = this.navigationService.page$;

  get skeletonHeight() {
    return this.utilsService.isMobile() ? 248 : 204;
  }

  constructor(
    private reportsService: ReportsService,
    private navigationService: NavigationService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  selectedRangeChange(event: [Date, Date] | null) {
    this.reportsService.setSelectedDateRange(event ? DateFilter.from(event) : null);
  }

  onPageSelected(page: string) {
    const chosenPage = pageNameToPath(page as ReportsPages);
    this.router.navigateByUrl('reports/' + [chosenPage]);
  }

  getCompletion(completed: any, canceled: any): string {
    if (!completed || completed === 0)
      return '0';

    if (!canceled || canceled === 0)
      return '100';

    return 1-(canceled/completed) === 0 ? '0' : (1-(canceled/completed)).toFixed(2);
  }
}
