<div *ngIf="progressBar$ | async let progressBar" class="progress-bar-wrapper">

  <div class="progress-bar-stage"
       [class.active]="active === 'estimate'"
       [class.can-open]="progressBar.estimate.canOpen"
       (click)="openStage(progressBar.estimate)"
  >
    <div class="progress-bar-line first" [class]="progressBar.estimate.status"></div>
    <div class="progress-bar-title">Estimate</div>
  </div>

  <div class="progress-bar-stage"
       [class.active]="active === 'proposal'"
       [class.can-open]="progressBar.proposal.canOpen"
       (click)="openStage(progressBar.proposal)"
  >
    <div class="progress-bar-line" [class]="progressBar.proposal.status"></div>
    <div class="progress-bar-title">Proposal</div>
  </div>

  <div class="progress-bar-stage"
       [class.active]="active === 'job'"
       [class.can-open]="progressBar.job.canOpen"
       (click)="openStage(progressBar.job)"
  >
    <div class="progress-bar-line" [class]="progressBar.job.status"></div>
    <div class="progress-bar-title">Job</div>
  </div>

  <div class="progress-bar-stage"
       [class.active]="active === 'invoice'"
       [class.can-open]="progressBar.invoice.canOpen"
       (click)="openStage(progressBar.invoice)"
  >
    <div class="progress-bar-line"
         [class]="progressBar.invoice.status"
         [class.last]="!progressBar.payment.canOpen"
    ></div>
    <div class="progress-bar-title">Invoice</div>
  </div>

  <div class="progress-bar-stage"
       [class.active]="active === 'payment'"
       [class.can-open]="progressBar.payment.canOpen"
       (click)="openStage(progressBar.payment)"
       *ngIf="progressBar.payment.canOpen"
  >
    <div class="progress-bar-line last" [class]="progressBar.payment.status"></div>
    <div class="progress-bar-title">Payment</div>
  </div>

</div>
