import { SupabaseClient } from "@supabase/supabase-js";
import { TransformOptions } from "@supabase/storage-js/src/lib/types";
import moment from "moment";
import { camelCaseToSnake, snakeCaseToCamel } from "./utils.service";

export interface SupabaseService {
  supabase: SupabaseClient,
  downloadFile: (bucketName: string, fileName: string, options?: { transform?: TransformOptions }) => Promise<File | null>,
  uploadFile: (bucketName: string, fileName: string, file: File) => Promise<{
    data: {
        publicUrl: string;
    };
  }>,
};

export function serverToFrontTranslation(obj: object): any {
  if (typeof obj === 'string' && isNaN(+obj) && moment(obj, moment.ISO_8601, true).isValid())
      return new Date(obj);

  if (!obj || typeof obj !== 'object')
      return obj;

  if (Array.isArray(obj)) {
      return obj.map(i => serverToFrontTranslation(i));
  }

  const res: any = {};

  for (const key in obj) {
      const value = (obj as any)[key];
      res[snakeCaseToCamel(key)] = serverToFrontTranslation(value);
  }
  return res;
}

export function frontToServerTranslation(obj: object): any {

  if (obj instanceof Date)
      return obj.toISOString();

  if (!obj || typeof obj !== 'object')
      return obj;

  if (Array.isArray(obj)) {
      return obj.map(i => frontToServerTranslation(i));
  }

  const res: any = {};
  for (const key in obj) {
      const value = (obj as any)[key];
      res[camelCaseToSnake(key)] = frontToServerTranslation(value);
  }
  return res;
}