import { SupabaseService } from "../../services/supabase.service";
import { CommissionReport, CommissionReportItem } from "../../models/reports.model";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { limitStep } from "../../services/reports-service-extras";
import { workflowChangesHandler } from "../../services/reports.service";

const commissionTables = [
  'workflow_user',
  'user',
  'commission',
  'payment',
  'invoice',
  'invoice_version',
  'invoice_version_client',
  'proposal',
  'proposal_version',
  'proposal_version_client'
];

export function commissionsPendingPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_pending_commission',
      schema: businessId,
      fn: 'commissions_pending_commission',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function commissionsPendingJobs(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_pending_jobs',
      schema: businessId,
      fn: 'commissions_pending_jobs',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function commissionsPendingList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<CommissionReportItem[]>({
      cName: 'commissions_pending_report',
      schema: businessId,
      fn: 'commissions_pending_report',
      tables: [...commissionTables, 'workflow'],
      options: {
        row_limit: limitStep,
        offset_count: 0,
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    workflowChangesHandler,
    'report'
  );
}

export function commissionsPendingListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_pending_report_count',
      schema: businessId,
      fn: 'commissions_pending_report_count',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function commissionsPendingAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_pending_report_ids',
      schema: businessId,
      fn: 'commissions_pending_report_ids',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initCommissionsPending(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    commissionsPendingPending(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    commissionsPendingJobs(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    commissionsPendingListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    commissionsPendingAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([pending, jobs, count, allIds]) => {
      return {
        type: 'pending',
        pending: pending as unknown as number,
        jobs: jobs as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as CommissionReport;
    })
  );
}

export function commissionsPaidPaid(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_paid_commission',
      schema: businessId,
      fn: 'commissions_paid_commission',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function commissionsPaidJobs(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_paid_jobs',
      schema: businessId,
      fn: 'commissions_paid_jobs',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function commissionsPaidList(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc<CommissionReportItem[]>({
      cName: 'commissions_paid_report',
      schema: businessId,
      fn: 'commissions_paid_report',
      tables: [...commissionTables, 'workflow'],
      options: {
        row_limit: limitStep,
        offset_count: 0,
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    workflowChangesHandler,
    'report'
  );
}

export function commissionsPaidListCount(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_paid_report_count',
      schema: businessId,
      fn: 'commissions_paid_report_count',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function commissionsPaidAllIds(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return supabaseService.rpc({
      cName: 'commissions_paid_report_ids',
      schema: businessId,
      fn: 'commissions_paid_report_ids',
      tables: commissionTables,
      options: {
        start_time: startTime,
        end_time: endTime,
        ...(selectedUsers && { selected_users: selectedUsers })
      }
    },
    _ => null,
    'report'
  );
}

export function initCommissionsPaid(
  supabaseService: SupabaseService,
  businessId: string,
  selectedUsers: number[] | null,
  startTime: Date,
  endTime: Date
) {
  return combineLatest([
    commissionsPaidPaid(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    commissionsPaidJobs(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    commissionsPaidListCount(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    ),
    commissionsPaidAllIds(
      supabaseService,
      businessId,
      selectedUsers,
      startTime,
      endTime
    )
  ]).pipe(
    map(([paid, jobs, count, allIds]) => {
      return {
        type: 'paid',
        paid: (paid as unknown as any)[0].totalPaid,
        total: (paid as unknown as any)[0].totalNeed,
        jobs: jobs as unknown as number,
        count: count as unknown as number,
        allIds: allIds as unknown as number[]
      } as CommissionReport;
    })
  );
}
