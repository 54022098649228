import { Injectable } from '@angular/core';
import { DateFilterService } from './date-filter.service';

@Injectable({
  providedIn: 'root'
})
export class LobbyService extends DateFilterService {

  constructor() {
    super();
  }
  
}
