import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, finalize, map, startWith, switchMap, tap } from "rxjs/operators";
import { ProgressBar, ProgressBarStage } from "../models/progress-bar.model";
import { Router } from "@angular/router";
import { rpcFilter, SupabaseService } from "./supabase.service";
import { BusinessService } from "./business.service";
import { BusinessProfile } from "../../../../common/src/lib/models/business-profile.model";

export type ProgressBarStageName = 'estimate' | 'proposal' | 'job' | 'invoice' | 'payment';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  backRoute: 'estimates' | 'proposals' | 'jobs' | 'invoices' | 'payments' | undefined;
  observables: { [id: number]: Observable<ProgressBar> } = {};

  private _timePassage = new Subject();
  private timePassage$ = this._timePassage.asObservable().pipe(startWith(1));

  private selectedBusiness$ = this.businessService.selectedBusiness$.pipe(
    filter(businessProfile => !!businessProfile)
  ) as Observable<BusinessProfile>;

  progressBarObservable(workflowId: number): Observable<ProgressBar> {
    if(!this.observables[workflowId]) {
      this.observables[workflowId] = combineLatest([
        this.selectedBusiness$.pipe(
            switchMap(business => this.supabaseService.rpc<any>({
                  cName: 'progress_bar_' + workflowId,
                  schema: business.businessId,
                  fn: 'get_progress_bar',
                  tables: [
                    { table: 'estimate', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'job', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'proposal_version', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'invoice_version', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'payment', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'paid', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'refunded', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'other', filter: rpcFilter('workflow_id', 'eq', workflowId) },
                    { table: 'commission', filter: rpcFilter('workflow_id', 'eq', workflowId) }
                  ],
                  options: { in_workflow_id: workflowId }
                },
                _ => null,
                'progress_bar'
            ))
        ),
        this.timePassage$
      ]).pipe(
          map(([progressBar, _]) => progressBar),
          finalize(() => {
            delete this.observables[workflowId];
          })
      );
    }
    return this.observables[workflowId];
  }

  constructor(
    private supabaseService: SupabaseService,
    private businessService: BusinessService,
    private router: Router
  ) {
    setInterval(() => {
      this._timePassage.next();
    }, 20000);
  }

  // private getEstimateStage(
  //   estimate: Estimate | null,
  //   proposal: Proposal | null,
  //   job: Job | null,
  //   invoice: Invoice | null
  // ): ProgressBarStage {
  //   const stage: ProgressBarStage = {
  //     canOpen: false,
  //     status: 'grey',
  //     route: 'estimates'
  //   };
  //
  //   if (!estimate)
  //     return stage;
  //
  //   stage.canOpen = true;
  //   if (estimate.status === 'canceled') {
  //     stage.status = 'red';
  //     return stage;
  //   }
  //
  //   if (proposal || job || invoice) {
  //     stage.status = 'green';
  //     return stage
  //   }
  //
  //   return stage;
  // }
  //
  // private getProposalStage(proposal: Proposal | null): ProgressBarStage {
  //   const stage: ProgressBarStage = {
  //     canOpen: false,
  //     status: 'grey',
  //     route: 'proposals'
  //   };
  //
  //   if (!proposal)
  //     return stage;
  //
  //   if (proposal.activeStatus === 'created') {
  //     stage.canOpen = true;
  //   } else if (
  //     proposal.activeStatus === 'declined'
  //     || proposal.activeStatus === 'canceled'
  //     || proposal.activeStatus === 'canceledFromPayment'
  //   ) {
  //     stage.canOpen = true;
  //     stage.status = 'red';
  //   } else if (proposal.activeStatus === 'sent') {
  //     stage.canOpen = true;
  //     stage.status = 'yellow';
  //   } else if (proposal.activeStatus === 'accepted') {
  //     stage.canOpen = true;
  //     stage.status = 'green';
  //   }
  //
  //   return stage;
  // }
  //
  // private getJobStage(job: Job | null, invoice: Invoice | null, payment: Payment | null): ProgressBarStage {
  //   const stage: ProgressBarStage = {
  //     canOpen: false,
  //     status: 'grey',
  //     route: 'jobs'
  //   };
  //
  //   if (!job)
  //     return stage;
  //
  //   stage.canOpen = true;
  //   if (job.status === 'canceled' || (payment && payment.canceled)) {
  //     stage.status = 'red';
  //     return stage;
  //   }
  //
  //   if (job.status === 'pending') {
  //     stage.status = 'yellow';
  //     return stage;
  //   }
  //
  //   if (invoice) {
  //     stage.status = 'green';
  //     return stage
  //   }
  //
  //   return stage;
  // }
  //
  // private getInvoiceStage(invoice: Invoice | null): ProgressBarStage {
  //   const stage: ProgressBarStage = {
  //     canOpen: false,
  //     status: 'grey',
  //     route: 'invoices'
  //   };
  //
  //   if (!invoice)
  //     return stage;
  //
  //   if (invoice.activeStatus === 'created') {
  //     stage.canOpen = true;
  //   } else if (
  //     invoice.activeStatus === 'canceled'
  //     || invoice.activeStatus === 'canceledFromPayment'
  //   ) {
  //     stage.canOpen = true;
  //     stage.status = 'red';
  //   } else if (invoice.activeStatus === 'sent') {
  //     stage.canOpen = true;
  //     stage.status = 'yellow';
  //   } else if (invoice.activeStatus === 'paid') {
  //     stage.canOpen = true;
  //     stage.status = 'green';
  //   }
  //
  //   return stage;
  // }
  //
  // private getPaymentStage(payment: Payment | null, proposal: Proposal | null): ProgressBarStage {
  //   const stage: ProgressBarStage = {
  //     canOpen: false,
  //     status: 'grey',
  //     route: 'payments'
  //   };
  //
  //   if (!payment)
  //     return stage;
  //
  //   let settled = false;
  //
  //   if (payment.totalToPay)
  //     settled = payment.totalToPay - alreadyPaid(payment) <= 0;
  //
  //   const refundDue = !payment?.totalToPay
  //     ? false
  //     : payment.totalToPay - alreadyPaid(payment) < 0;
  //
  //   if (payment?.canceled || refundDue) {
  //     stage.canOpen = true;
  //     stage.status = 'red';
  //     return stage;
  //   }
  //
  //   const invoiceExisting = !!payment?.invoiceId;
  //   if (invoiceExisting) {
  //     stage.canOpen = true;
  //     stage.status = 'yellow';
  //
  //     if (settled) {
  //       let commissionsSettled = true;
  //       let overCommission = false;
  //       const noJobCommissions = payment.jobCommissions.length === 0 || !payment.jobCommissions;
  //       if (!noJobCommissions)
  //         payment.jobCommissions.forEach(commission => {
  //           if (commission.amount > commission.paid)
  //             commissionsSettled = false;
  //           if (commission.amount < commission.paid)
  //             overCommission = true;
  //         });
  //       const noSalesCommissions = payment.salesCommissions.length === 0 || !payment.salesCommissions;
  //       if (!noSalesCommissions)
  //         payment.salesCommissions.forEach(commission => {
  //           if (commission.amount > commission.paid)
  //             commissionsSettled = false;
  //           if (commission.amount < commission.paid)
  //             overCommission = true;
  //         });
  //       let techPartsSettled = true;
  //       const noTechParts = payment.other.length === 0 || !payment.other;
  //       if (!noTechParts)
  //         payment.other.forEach(other => {
  //           if (other.type === 'tech-part' && !other.paidTechPart)
  //             techPartsSettled = false;
  //         });
  //
  //       if (overCommission)
  //         stage.status = 'red';
  //       else if (commissionsSettled && techPartsSettled)
  //         stage.status = 'green';
  //     }
  //   } else {
  //     if (proposal?.accepted)
  //       stage.canOpen = true;
  //
  //     const noDepositReq = !payment.totalToPay || payment.totalToPay === 0;
  //
  //     if (noDepositReq || (!noDepositReq && settled))
  //       stage.status = 'grey';
  //     else
  //       stage.status = 'yellow';
  //   }
  //
  //   return stage;
  // }

  async openStage(stage: Omit<ProgressBarStage, 'status'>, workflowId: number, current: ProgressBarStageName) {
    if (stage.canOpen && this.mapActiveToRoute(current) !== stage.route) {
      await this.router.navigate([`/${stage.route}/${workflowId}/${stage.id}`]);
      this.backRoute = this.mapActiveToRoute(current);
    }
  }

  private mapActiveToRoute(active: ProgressBarStageName) {
    switch (active) {
      case 'estimate':
        return 'estimates';
      case 'proposal':
        return 'proposals';
      case 'job':
        return 'jobs';
      case 'invoice':
        return 'invoices';
      case 'payment':
        return 'payments';
    }
  }
}
