export const SCREEN_BREAKPOINTS = {
    xxs: 0,
    xs: 320,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1600,
    xxxxl: 1800,
}