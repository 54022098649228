import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseTileData } from "../base-tile/base-tile.component";
import { NgxMaskService } from "ngx-mask";
import { timer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import moment from "moment";
import { Lead } from "../../../../../common/src/lib/models/lead.model";
import { ViewAsService } from '../../services/view-as.service';

function formatUnits(units: moment.unitOfTime.Diff[], date: Date) {
  for(const unit of units) {
    const amount = moment().diff(date, unit);
    if(amount > 1)
      return `${amount} ${unit}s ago`;
    if(amount > 0)
      return `${amount} ${unit} ago`;
  }
  return null;
}

@Component({
  selector: 'app-lead-tile',
  templateUrl: './lead-tile.component.html',
  styleUrls: ['./lead-tile.component.scss']
})
export class LeadTileComponent implements OnInit {

  @Input() lead!: Lead;
  @Output() tileClick = new EventEmitter<Lead>();
  @Output() deleteClick = new EventEmitter<Lead>();
  data!: BaseTileData;

  timePassed$ = timer(1000).pipe(
      startWith(0),
      map(_ => {
        return formatUnits(['day', 'hour', 'minute'], this.lead.createdAt) ?? moment(this.lead.createdAt).format('h:mm A') + ', Today';
      })
  );

  showOwner$ = this.viewAsService.selectedUsers$.pipe(map(users => users === 'All' || users.length > 1));

  constructor(
      private maskService: NgxMaskService,
      private viewAsService: ViewAsService,
  ) {}

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    const phoneNumber = this.maskService.applyMask(this.lead.phoneNumber, '(000) 000-0000');
    this.data = {
      title: this.lead.firstName + ' ' + this.lead.lastName,
      subtitle: phoneNumber,
      description: this.lead.jobType
    };
  }
  async onDeleteClick(event: Event) {
    event.stopPropagation();
    this.deleteClick.emit(this.lead);
  }

}
