import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { NavigationService } from '../../services/navigation.service';
import { SeparatedItems } from 'projects/common/src/public-api';
import { ClientItemsResult } from "../../services/clients.service";

function sortDateHeaders(reversed: boolean, notSetFirst = true) {
  return (a: string, b: string) => {

    if (a === 'Not set')
      return notSetFirst ? 1 : -1;
    if (b === 'Not set')
      return notSetFirst ? -1 : 1;

    if (a === 'Today')
      return -1;
    if (b === 'Today')
      return 1;


    if (a === 'Yesterday')
      return -1;
    if (b === 'Yesterday')
      return 1;

    if (a === 'Tomorrow')
      return -1;
    if (b === 'Tomorrow')
      return 1;

    if (reversed) {
      return (new Date(b)).getTime() - (new Date(a)).getTime();
    }
    return (new Date(a)).getTime() - (new Date(b)).getTime();
  };
}

@Component({
  selector: 'app-customer-items-list',
  templateUrl: './customer-items-list.component.html',
  styleUrls: ['./customer-items-list.component.scss']
})
export class CustomerItemsListComponent implements OnInit {

  @Input() items!: SeparatedItems<ClientItemsResult<any>>;
  @Input() showUser = false;

  timePassage$ = new BehaviorSubject<void>(undefined);
  // itemsCount$ = this.itemsListService.itemsCount$;
  page$ = this.navigationService.page$;
  tab$ = this.navigationService.tab$;

  prevPast: any;
  prevFuture: any;

  jobTypeObservables: {[id: string]: Observable<string>} = {};

  get headers() {
    // console.log(this.items);
    if(!this.items)
      return [];
    return Object.keys(this.items);
  }

  constructor(
    private navigationService: NavigationService,
  ) {
    setInterval(() => {
      this.timePassage$.next();
    },20000);
  }

  ngOnInit() {
    // console.log(this.items)
  }

  // ngAfterViewInit() {
  //   const itemsIds = Object.values(this.items)
  //     .reduce((acc, val) => acc.concat(val), [])
  //     .map(item => item.id as string);
  //   for(const itemId of itemsIds) {
  //     this.jobTypeObservables[itemId] = this.notesService.jobTypeObservable(itemId).pipe(
  //       tap(_ => console.log(_))
  //     );
  //   }
  // }

  // headers$ = combineLatest([this.page$, this.items$]).pipe(
  //   map(([page, items], index) => {
  //     const now = new Date();
  //     const rawHeaders = Object.keys(items);
  //     if (page === 'Proposals')
  //       rawHeaders.sort(sortDateHeaders(true));
  //     const past: string[] = [];
  //     const future: string[] = [];

  //     rawHeaders.forEach(header => {
  //       if (page === 'Proposals') {
  //         future.push(header);
  //       } else {
  //         if (header === 'Today') {
  //           const itemsForToday = items[header];
  //           const pastItems = itemsForToday.filter((item: any) => item.end && item.end < now);
  //           const futureItems = itemsForToday.filter((item: any) => item.end && item.end > now);

  //           if (pastItems.length > 0 && futureItems.length > 0) {
  //             past.push(header + ' (History)');
  //             items[header + ' (History)'] = pastItems;
  //             future.push(header + ' (Upcoming)');
  //             items[header + ' (Upcoming)'] = futureItems;
  //           } else if (pastItems.length === 0) {
  //             future.push(header)
  //           } else if (futureItems.length === 0) {
  //             past.push(header)
  //           }
  //         } else {
  //           if (new Date(header) < now || header === 'Yesterday') {
  //             past.push(header);
  //           } else if (header !== 'Today (Upcoming)' && header !== 'Today (History)') {
  //             future.push(header);
  //           }
  //         }
  //       }
  //     });

  //     return {past: past, future: future, index: index};
  //   }),
  //   tap((data) => {
  //     if (
  //       data.index === 0 ||
  //       JSON.stringify(this.prevFuture) !== JSON.stringify(data.future) ||
  //       JSON.stringify(this.prevPast) !== JSON.stringify(data.past)
  //     ) {
  //       setTimeout(() => {
  //         const future = document.getElementById('future');
  //         future?.scrollIntoView({block: 'start'});
  //       }, 0);
  //     }
  //     this.prevPast = data.past;
  //     this.prevFuture = data.future;
  //   })
  // );

}
