import { SupabaseService } from "./supabase.service";
import {
  RealtimePostgresChangesPayload,
  RealtimePostgresDeletePayload,
  RealtimePostgresInsertPayload,
  RealtimePostgresUpdatePayload
} from "@supabase/supabase-js";
import { limitStep } from "./items.service";
import { ProposalStatus } from "../models/proposal.model";
import { InvoiceStatus } from "../models/invoice.model";
import { serverToFrontTranslation } from "projects/common/src/lib/services/supabase.service";

export interface PaginationOptions {
  inStatus?: ProposalStatus | InvoiceStatus,
  inOffset?: number,
  inLimit?: number,
  inDirection?: 'future' | 'past',
  inUserIds?: number[],
  inStartDate?: Date,
  inEndDate?: Date,
}

export interface ReportPaginationOptions {
  startTime?: Date,
  endTime?: Date,
  rowLimit?: number,
  offsetCount?: number,
  selectedUsers?: number[]
}

// export type EventType = REALTIME_POSTGRES_CHANGES_LISTEN_EVENT;
export type EventType = '*' | 'INSERT' | 'UPDATE' | 'DELETE';
type EventTypes<T extends { [p: string]: any }> = {
  '*': (payload: RealtimePostgresChangesPayload<T>) => void,
  'INSERT': (payload: RealtimePostgresInsertPayload<T>) => void,
  'UPDATE': (payload: RealtimePostgresUpdatePayload<T>) => void,
  'DELETE': (payload: RealtimePostgresDeletePayload<T>) => void,
}

export class Pagination<PT extends { [p: string]: any }, OT extends { [p: string]: any } = PT> {

  private channel: any;
  private subscribed = false;

  constructor(
      private supabaseService: SupabaseService,
      private fn: string,
      private businessId: string,
      channelName?: string
  ) {
    this.channel = this.supabaseService.supabase.realtime.channel(channelName ?? 'pagination_' + fn);
  }

  async get<O extends PaginationOptions | ReportPaginationOptions>(options?: O) {
    if(!this.subscribed) {
      this.channel.subscribe();
      this.subscribed = true;
    }
    return this.supabaseService.rpcFunc<PT[]>(this.businessId, this.fn, options ?? { inLimit: limitStep, inOffset: 0 });
  }

  on<O extends PT | OT, E extends EventType = '*'>(event: E, table: string, callback: EventTypes<O>[E], filter?: string) {
    this.channel = this.channel.on(
        'postgres_changes',
        {event, schema: this.businessId, table: table, filter},
        (payload: any) => callback(serverToFrontTranslation(payload))
    );
    return this;
  }

  destroy() {
    if (this.channel) {
      this.supabaseService.supabase.removeChannel(this.channel);
    }
  }

}
